import { toast } from 'react-toastify';
import { reactLocalStorage } from 'reactjs-localstorage';

export const getTokens = () => {
  return {
    Authorization: reactLocalStorage.get("Authorization"),
    userType: reactLocalStorage.get("userType"),
    "Access-Control-Allow-Origin": "*",
  };
};

export const getTypes = (value, body) => {
  if (value.params) {
    if (typeof body == "object") {
      return { params: { id: body.id }, body: body };
    } else {
      return { params: null, body: body };
    }
  } else if (value.query) {
    return { query: body };
  }

  return {};
};

export const apiCallSuccess = (data) => {
  toast.success("Action successful!");
};

export const apiCallError = (errorMsg, setError) => {
  toast.error(errorMsg);
  setError(errorMsg);
};

export const allowOnlyPositiveInteger = (event) => {
  let inputValue = event.target.value.replace(/\D/g, "");

  if (inputValue !== "") {
    inputValue = parseInt(inputValue, 10);

    if (inputValue <= 0 || isNaN(inputValue)) {
      inputValue = "";
    }
  }

  return inputValue;
};

export const capitalizeFirstLetter = (event) => {
  const sentence = event.target.value;
  const words = sentence?.split(' ');
  const capitalizedWords = words.map(word => {
    const firstLetter = word.charAt(0).toUpperCase();
    const restOfWord = word.slice(1).toLowerCase();
    return firstLetter + restOfWord;
  });
  const updatedSentence = capitalizedWords.join(' ');

  return updatedSentence;
}

export const capitalizeFirstLetter2 = (text) => {
  const sentence = text;
  const words = sentence?.split(' ');
  const capitalizedWords = words.map(word => {
    const firstLetter = word.charAt(0).toUpperCase();
    const restOfWord = word.slice(1).toLowerCase();
    return firstLetter + restOfWord;
  });
  const updatedSentence = capitalizedWords.join(' ');

  return updatedSentence;
}
export function getVideoDuration(videoFile) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();

    reader.onload = function(event) {
      const blob = new Blob([event.target.result], { type: videoFile.type });
      const video = document.createElement('video');
      const url = URL.createObjectURL(blob);

      video.addEventListener('loadedmetadata', function() {
        const duration = video.duration;
        resolve(duration);
      });

      video.src = url;
    };

    reader.onerror = function(error) {
      reject(error);
    };

    reader.readAsArrayBuffer(videoFile);
  });
}
export function getFileTypeFromUrl(url) {
  const extension = url.split('.').pop().toLowerCase();
  if (extension === 'jpg' || extension === 'jpeg' || extension === 'png' || extension === 'gif') {
      return 'Image';
  } else if (extension === 'pdf') {
      return 'PDF';
  } else {
      return 'Unknown';
  }
}

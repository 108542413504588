import React, { useState, useEffect, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { apiCallError } from "../../../../utils";
import { JSON_API } from "../../../../services/api/jsonApiCallWithInterceptor";
import Loader from "../../../../Component/Loader";
import Table from "../../../../Component/Table";

const ManageTags = () => {
  const [tags, settags] = useState([]);
  const [searchText, setsearchText] = useState("");
  const [modal, setModal] = useState(false);
  const [modalToggleView, setmodalToggleView] = useState(null);
  const [deleteId, setdeleteId] = useState("");
  const [isLoading, setisLoading] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    getAllBookingStatusTags();
  }, []);

  const getAllBookingStatusTags = async () => {
    let res = await JSON_API["getAllBookingStatusTags"]();

    if (res.isSuccess) {
      settags(res.data.data);
    }
  };

  const openModal = () => {
    setModal(true);
  };

  const deleteLanguage = async (id) => {
    try {
      setisLoading(true);
      let res = await JSON_API["deleteBookingStatusTag"]({ id: id });

      if (res.isSuccess) {
        getAllBookingStatusTags();
        setModal(false);
        toast.success("Booking status tag deleted successfully!");
      } else {
        apiCallError(res?.error?.message);
      }
      setisLoading(false);
    } catch (error) {
      setisLoading(false);
      apiCallError(error.message);
    }
  };

  const filterData = () => {
    if (searchText !== "") {
      return tags.filter((item) =>
        JSON.stringify(item).toLowerCase().includes(searchText.toLowerCase())
      );
    }

    return tags;
  };

  const tableData = useMemo(() => {
    return {
      columns: [
        {
          label: "SL",
          field: "sl",
          sort: "asc",
          width: 270,
        },
        {
          label: "Tag Title",
          field: "name",
          sort: "asc",
          width: 150,
        },
        {
          label: "Tag Colour",
          field: "color",
          sort: "asc",
          width: 150,
        },
        {
          label: "Action",
          field: "action",
          sort: "asc",
          width: 150,
        },
      ],
      rows:
        filterData()?.length > 0
          ? filterData().map((item, idx) => {
              return {
                sl: idx + 1,
                name: (
                  <div className="widget-content p-0">
                    <div className="widget-content-wrapper">
                      <div className="widget-content-left flex2">
                        <div
                          className="widget-heading"
                          style={{ opacity: 1, textAlign: "center" }}
                        >
                          {item?.paymentTag}
                        </div>
                      </div>
                    </div>
                  </div>
                ),
                color: (
                  <div className="widget-content p-0">
                    <div className="widget-content-wrapper">
                      <div
                        className="widget-content-left flex2"
                        style={{ textAlign: "center" }}
                      >
                        <input
                          disabled
                          type="color"
                          value={item.paymentTagColor}
                        />
                      </div>
                    </div>
                  </div>
                ),
                action: (
                  <div>
                    <button
                      className="me-2 btn-icon btn-icon-only btn btn-outline-danger"
                      onClick={() => {
                        openModal();
                        setmodalToggleView(0);
                        setdeleteId(item._id);
                      }}
                    >
                      <i className="fa-solid fa-trash-can" />
                    </button>
                    <button
                      className="me-2 btn-icon btn-icon-only btn btn-outline-success"
                      onClick={() => {;
                        navigate(`/edit-booking-status-tag/${item._id}`);
                      }}
                    >
                      <i class="fa-regular fa-pen-to-square"></i>
                    </button>
                  </div>
                ),
              };
            })
          : [],
    };
  }, [tags, searchText]);

  const renderModalView = (index) => {
    switch (index) {
      case 0:
        return (
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">Delete Language</h5>
            </div>
            <div className="modal-body">
              <div className="deleteModalContainer p-5">
                <i className="ri-close-circle-line deleteIcon"></i>
                <h3>Are You Sure?</h3>
                <span>Do You Want To Delete This Information?</span>
                <div className="deleteModalNote">
                  <span>*Note : This data will be permanently deleted</span>
                </div>
              </div>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-link"
                onClick={() => {
                  setModal(false);
                }}
              >
                Cancel
              </button>
              <button
                type="button"
                className="btn btn-primary"
                onClick={() => {
                  deleteLanguage(deleteId);
                }}
              >
                Delete
              </button>
            </div>
          </div>
        );
      default:
        return null;
    }
  };

  return (
    <>
      {isLoading && <Loader />}
      <Table
        striped
        tableTitle="All Languages"
        data={tableData}
        modal={modal}
        setModal={setModal}
        modalToggleView={modalToggleView}
        renderModalView={renderModalView}
        onSearch={(val) => setsearchText(val)}
      />
    </>
  );
};

export default ManageTags;

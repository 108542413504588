import React, { useEffect, useRef, useState } from 'react'
import { fileUpload, requestData } from '../../../services/api/apiHelpers';
import { toast } from 'react-toastify';
import { useNavigate, useParams } from 'react-router-dom';
import Loader from '../../../Component/Loader';

const AddTravelAgency = () => {
    let initial = {
        name: "",
        image: ""
    }
    const [travelAgencydata, setTravelAgencyData] = useState(initial)
    const [loading, setLoading] = useState(false)
    const imgInputRef = useRef(null);
    const navigate = useNavigate()
    const Urlid = useParams();
    const id = Urlid.id;

    console.log("travelAgencydata", travelAgencydata)


    const handleImage = async (e) => {
        if (e.target.files.length === 0) return;
        const x = Array.from(e.target.files)[0];
        setTravelAgencyData((prev) => ({
            ...prev,
            image: URL.createObjectURL(x)
        }))
        const DATA = new FormData();
        DATA.append("image", e.target.files[0]);
        try {
            setLoading(true);
            let imgUpRes = await fileUpload("/admin/onlineTravelAdvisorImage", "POST", DATA);
            console.log("99999", imgUpRes)
            if (imgUpRes?.status) {
                setTravelAgencyData((prev) => ({
                    ...prev,
                    image: imgUpRes?.url
                }))
                toast.success("Image Uploaded Successfully")
            } else {
                toast.error(
                    "Something went wrong while uploading image! Please try after some time."
                );
            }
        } catch (error) {
            toast.error(
                "Something went wrong while uploading image! Please try after some time."
            );
        }
        setLoading(false);
    };

    const handleSubmit = async (e) => {
        e.preventDefault()
        if (id) {
            try {
                setLoading(true)
                let res = await requestData(`/admin/update-online-Travel-advisor/${id}`, "PUT", travelAgencydata);
                if (res && res.status) {
                    setLoading(false)
                    toast.success(res.message);
                    navigate("/manage_travel_agency")
                }
                else{
                    toast.error(res?.message)
                    setLoading(false)
                }
            } catch (error) {
                toast.error(error.message)
                setLoading(false)
            }
        } else
            try {
                setLoading(true)
                let res = await requestData("/admin/add-online-Travel-advisor", "POST", travelAgencydata);
                if (res && res.status) {
                    setLoading(false)
                    toast.success(res.message);
                    navigate("/manage_travel_agency")
                }
                else{
                    toast.error(res?.message)
                    setLoading(false) 
                }
            } catch (error) {
                toast.error(error.message)
                setLoading(false)
            }
    }

    const getAllTravelAgencies = async () => {
        try {
            setLoading(true)
            let res = await requestData("/admin/view-online-Travel-advisor", "GET");
            console.log("wewew", res)
            if (res) {
                const data = res?.data?.filter((item) => item._id === id);
                console.log("data", data)
                setTravelAgencyData(data[0])
                setLoading(false)
            }
        } catch (error) {
            toast.error(error.message)
            setLoading(false)
        }
    }

    useEffect(() => {
        if (id) getAllTravelAgencies()
    }, [id])

    return (
        <div>
            {loading && <Loader />}
            <form className="" onSubmit={(e) => handleSubmit(e)}>
                <div component="div" className="TabsAnimation appear-done enter-done">
                    <div className="main-card mb-3 card">
                        <div className="card-body">
                            <div className="card-title">{id ? "Edit" : "Add"} Travel Agency</div>
                            <div className="row" style={{ padding: "1rem" }}>
                                <div className="col-md-4">
                                    <div className="mb-3">
                                        <label htmlFor="name" className="form-label">
                                            Travel Advisor Agency Name
                                        </label>
                                        <input
                                            name="name"
                                            id="name"
                                            placeholder="Your travel agency name"
                                            type="text"
                                            className="form-control"
                                            autoComplete="false"
                                            value={travelAgencydata.name}
                                            onChange={(e) => setTravelAgencyData((prev) => ({
                                                ...prev,
                                                name: e.target.value
                                            }))}
                                            min={0}
                                        />
                                    </div>

                                </div>
                                <div className="col-md-4">
                                    <div className="mb-3">
                                        <label htmlFor="bannerImg" className="form-label">
                                            Banner Image*
                                        </label>
                                        <input
                                            name="bannerImg"
                                            id="bannerImg"
                                            type="file"
                                            accept="image/*"
                                            ref={imgInputRef}
                                            className="form-control"
                                            onChange={(e) => handleImage(e)}
                                        />
                                    </div>
                                    {travelAgencydata.image && (
                                        <div className='d-flex'>
                                            <img
                                                style={{ height: "100px", width: "100px" }}
                                                src={travelAgencydata.image}
                                                alt="bannerImg"
                                            />
                                            <i
                                                className="fa-solid fa-x"
                                                style={{ cursor: "pointer" }}
                                                onClick={() => {
                                                    setTravelAgencyData((prev) => ({
                                                        ...prev,
                                                        image: ""
                                                    }));
                                                    if (imgInputRef.current) {
                                                        imgInputRef.current.value = "";
                                                    }
                                                }}
                                            ></i>

                                        </div>
                                    )}
                                </div>
                            </div>

                            {id ? <button
                                className="mt-2 btn btn-primary ml-auto d-table"
                                type="submit"
                            >
                                Update
                            </button> :
                                <button
                                    className="mt-2 btn btn-primary ml-auto d-table"
                                    type="submit"
                                >
                                    Submit
                                </button>}
                        </div>
                    </div>
                </div>
            </form>
        </div>
    )
}

export default AddTravelAgency

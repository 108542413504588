import React from "react";
import i from "../../src/images/citynights.8df3ac24685306a68061.jpg";
import { Link } from "react-router-dom";

export default function Details() {
  return (
    <>
      <div className="info-details">
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-12">
              <div className="obser-top">
                <p>Policy Details</p>
                <div className="obser-top_right">
                  <Link to="#" download>
                    Download as PDF
                  </Link>

                  <Link
                    className="rgt-bt"
                    to="/"
                    onClick={(e) => e.preventDefault()}
                  >
                    Print
                  </Link>
                </div>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-lg-12">
              <div className="des-detail" style={{ flexWrap: "nowrap" }}>
                <div className="wrap-icon">
                  <Link to="#">
                  <img src="" alt="" />
                  </Link>
                </div>
                <div className="des-title">
                  <h4> Title</h4>
                  <p>
                    {" "}
                    Lorem Ipsum is simply dummy text of the printing and
                    typesetting industry. Lorem Ipsum has been the industrys
                    standard dummy text ever since the 1500s, when an unknown
                    printer took a galley of type and scrambled it to make a
                    type specimen book. It has survived not only five centuries,
                  </p>
                </div>
                <div className="rigth-arrow">
                  <Link to="">
                    <i className="fa-solid fa-arrow-right" />
                  </Link>
                </div>
              </div>
            </div>
            <div className="">
              <div className="des-detail">
                <div className="col-lg-4 col-md-6 col-12">
                  <div className="DetailsData">
                    <span>Name</span>
                    <h6>Shamil Roy</h6>
                  </div>
                  <div className="DetailsData">
                    <span>Email</span>
                    <h6>Shamil Roy@gmail.com</h6>
                  </div>
                  <div className="DetailsData">
                    <span>Language</span>
                    <h6>English</h6>
                  </div>
                  <div className="DetailsData">
                    <span>Site Name</span>
                    <h6>abc</h6>
                  </div>
                  <div className="DetailsData">
                    <span>Activity Category</span>
                    <h6 className="">xyz</h6>
                  </div>
                  <div className="DetailsData">
                    <span>Sub Topic</span>
                    <h6 className="">xyz</h6>
                  </div>
                </div>
                <div className="col-lg-4 col-md-6 col-12">
                  <div className="DetailsData">
                    <span>Mobile No.</span>
                    <h6>5689432087</h6>
                  </div>
                  <div className="DetailsData">
                    <span>City</span>
                    <h6>kolkata</h6>
                  </div>
                  <div className="DetailsData">
                    <span>Country</span>
                    <h6>India</h6>
                  </div>
                  <div className="DetailsData">
                    <span>Section</span>
                    <h6>musical</h6>
                  </div>
                  <div className="DetailsData">
                    <span>Person</span>
                    <h6>34</h6>
                  </div>
                  <div className="DetailsData">
                    <span>Sub Category</span>
                    <h6>abc</h6>
                  </div>
                </div>
                <div className="col-lg-4 col-md-6 col-12">
                  <div className="DetailsData">
                    <span>Zipcode</span>
                    <h6>3456</h6>
                  </div>
                  <div className="DetailsData">
                    <span>Unit</span>
                    <h6>20</h6>
                  </div>
                  <div className="DetailsData">
                    <span>Activity Type</span>
                    <h6 className="">food</h6>
                  </div>
                  <div className="DetailsData">
                    <span>Activity Title</span>
                    <h6 className="">xyz</h6>
                  </div>
                  <div className="DetailsData">
                    <span>Topic</span>
                    <h6 className="">xyz</h6>
                  </div>
                  <div className="DetailsData">
                    <span>Address</span>
                    <h6 className="">xyz</h6>
                  </div>
                </div>
              </div>
            </div>

            <div className="mt-5">
              <div className="col-md-12 col-12">
                <div className="">
                  <h2 className="ProfilepageTitle">Image</h2>
                </div>
                <div className="employeeDetails" style={{ padding: 20 }}>
                  <div className="row">
                    <div className="col-md-4 col-12">
                      <div className="task_img">
                        <img src={i} className="img-fluid" alt="" />
                      </div>
                    </div>
                    <div className="col-md-4 col-12">
                      <div className="task_img">
                        <img src={i} className="img-fluid" alt="" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { toast } from "react-hot-toast";
import { capitalizeFirstLetter, apiCallError } from "../../../../utils";
import { JSON_API } from "../../../../services/api/jsonApiCallWithInterceptor";
import Loader from "../../../../Component/Loader";

let initialValues = {
  paymentTag: "",
  paymentTagColor: "",
};

let createErrors = {
  paymentTag: "",
  paymentTagColor: "",
};

function EditTag() {
  const params = useParams();
  const navigate = useNavigate();
  const [isLoading, setisLoading] = useState(false);
  const [language, setlanguage] = useState(initialValues);
  const [errors, seterrors] = useState(createErrors);

  useEffect(() => {
    getAllBookingStatusTags();
  }, []);

  const getAllBookingStatusTags = async () => {
    let res = await JSON_API["getAllBookingStatusTags"]();
    console.log("all tags", res.data);

    if (res.isSuccess) {
      const tagsData = res.data.data.find((item) => item._id === params.id);
      setlanguage(tagsData);
      return res.data.data;
    }
  };

  const handleChange = (event) => {
    let input;

    if (event.target.name === "paymentTag") {
      input = capitalizeFirstLetter(event);
    } else {
      input = event.target.value;
    }

    setlanguage({ ...language, [event.target.name]: input });
  };

  const handleValidation = () => {
    const { paymentTag, paymentTagColor } = language;
    if (paymentTag === "") {
      seterrors({
        ...createErrors,
        paymentTag: "Please provide a status title",
      });

      return false;
    }
    if (paymentTagColor === "") {
      seterrors({
        ...createErrors,
        paymentTagColor: "Please provide a status color",
      });

      return false;
    }

    return true;
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (handleValidation()) {
        try {
          setisLoading(true);
          let res = await JSON_API["editBookingStatusTag"]({
            id: params.id,
            ...language,
          });
          if (res.isSuccess) {
            setlanguage(initialValues);
            seterrors(createErrors);
            toast.success("Booking Status Tag Added Successfully!");
            navigate("/manage-bookings-status-tags");
          } else {
            apiCallError(res?.error?.message);
          }
          setisLoading(false);
        } catch (error) {
          setisLoading(false);
          apiCallError(error.message);
        }
    //   }
    }
  };

  return (
    <>
      {isLoading && <Loader />}
      <form className="">
        <div component="div" className="TabsAnimation appear-done enter-done">
          <div className="main-card mb-3 card">
            <div className="card-body">
              <div className="card-title">Edit Booking Status Tag</div>
              <div className="row">
                <div className="col-md-6">
                  <div className="mb-3">
                    <label
                      htmlFor="paymentTag"
                      className="form-label font-weight-bold"
                      style={{ margin: "0.5rem 0.5rem" }}
                    >
                      Tag Title
                    </label>
                    <input

                      name="paymentTag"
                      id="paymentTag"
                      type="text"
                      className="form-control"
                      autoComplete="false"
                      value={language.paymentTag}
                      onChange={handleChange}
                    />
                  </div>
                  {errors.paymentTag && (
                    <span className="errorMessageText text-danger">
                      {errors.paymentTag}
                    </span>
                  )}
                </div>
                <div className="col-md-6">
                  <div className="mb-3">
                    <label
                      htmlFor="paymentTagColor"
                      className="form-label font-weight-bold"
                      style={{ margin: "0.5rem 0.5rem" }}
                    >
                      Tag Colour
                    </label>
                    <input
                      name="paymentTagColor"
                      id="paymentTagColor"
                      type="color"
                      className="form-control"
                      autoComplete="false"
                      value={language.paymentTagColor}
                      onChange={handleChange}
                    />
                  </div>
                  {errors.paymentTagColor && (
                    <span className="errorMessageText text-danger">
                      {errors.paymentTagColor}
                    </span>
                  )}
                </div>
              </div>

              <div className="d-flex" style={{ justifyContent: "flex-end" }}>
                <button
                  className="mt-2 btn mr-2 d-table"
                  onClick={() => navigate("/manage-bookings-status-tags")}
                >
                  Cancel
                </button>
                <button
                  className="mt-2 btn btn-primary d-table"
                  type="submit"
                  onClick={(e) => handleSubmit(e)}
                >
                  Submit
                </button>
              </div>
            </div>
          </div>
        </div>
      </form>
    </>
  );
}

export default EditTag;

import React, { useEffect } from "react";
import { useState } from "react";
import Select from "react-select";
import { checkFileDimension } from "../../../utils";
import toast from "react-hot-toast";
import { fileUpload, requestData } from "../../../services/api/apiHelpers";
import { useRef } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import useCountries from "../../../Hooks/useCountries";
import Loader from "../../../Component/Loader";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";

const EditDayOutInCity = () => {
    const navigate = useNavigate()
  const initial = {
    countryId: "",
    countryName: "",
    cityId: "",
    cityName: "",
    activityId: "",
    activityName: "",
    title: "",
    description: "",
    image1: "",
    image2: "",
  };

  let error = {
    countryId: "",
    countryName: "",
    cityId: "",
    cityName: "",
    activityId: "",
    activityName: "",
    title: "",
    description: "",
    image1: "",
    image2: "",
  };

  const [dayOutCityData, setDayOutCityData] = useState(initial);
  const [errors, seterrors] = useState(error);
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const inputRef = useRef(null);
  const fileInputRef = useRef(null);
  var imageReg = /[\/.](gif|jpg|jpeg|tiff|png|webp)$/i;
  const URLid = useParams();
  const id = URLid?.id;
  const {
    countries,
    cities,
    fetchCities,
    loading,
    fetchActivityAgainstCity,
    activity,
  } = useCountries();
  console.log("dayOutCityDatsasaa", dayOutCityData);
  const imageHandle= async(file, state)=>{

    setIsLoading(true);

    if (imageReg.test(file.type)) {
      let fileObj = await checkFileDimension(file);
      if (fileObj?.height > 720) {
        seterrors({
          ...errors,
          image1: "Image height should be less than 720px",
        });
      } else if (fileObj?.width > 1280) {
        seterrors({
          ...errors,
          image1: "Image width should be less than 1280px",
        });
      } else {
        seterrors({ ...errors, image1: "" });

        let data = new FormData();
        data.append("image", file);
        let imgUpRes = await fileUpload("/admin/tourImage", "POST", data);
        console.log("res", imgUpRes);
        if (imgUpRes && imgUpRes.status) {

          if(state){
            setDayOutCityData((prev) => ({ ...prev, image1: imgUpRes.url }));

          }else{
            setDayOutCityData((prev) => ({ ...prev, image2: imgUpRes.url }));

          }
        } else {
          toast.error(imgUpRes.message);
        }
      }
    } else {
      toast.error("Please Upload Valid Image");
    }

    setIsLoading(false);
  }

  // const imageHandle = async (file, state) => {
  //   console.log("state", state);
  //   // if (state) {
  //     alert("top");
  //     if (!file) {
  //       return;
  //     }

  //     setIsLoading(true);

  //     if (imageReg.test(file.type)) {
  //       let fileObj = await checkFileDimension(file);
  //       if (fileObj?.height > 720) {
  //         seterrors({
  //           ...errors,
  //           image1: "Image height should be less than 720px",
  //         });
  //       } else if (fileObj?.width > 1280) {
  //         seterrors({
  //           ...errors,
  //           image1: "Image width should be less than 1280px",
  //         });
  //       } else {
  //         seterrors({ ...errors, image1: "" });

  //         let data = new FormData();
  //         data.append("image", file);
  //         let imgUpRes = await fileUpload("/admin/tourImage", "POST", data);
  //         console.log("res", imgUpRes);
  //         if (imgUpRes && imgUpRes.status) {
  //           setDayOutCityData((prev) => ({ ...prev, image1: imgUpRes.url }));
  //         } else {
  //           toast.error(imgUpRes.message);
  //         }
  //       }
  //     } else {
  //       toast.error("Please Upload Valid Image");
  //     }

  //     setIsLoading(false);
  //   // } else {
  //   //   alert("bottom");
  //   //   if (!file) {
  //   //     return;
  //   //   }

  //     setIsLoading(true);

  //     if (imageReg.test(file.type)) {
  //       let fileObj = await checkFileDimension(file);
  //       if (fileObj?.height > 240) {
  //         seterrors({
  //           ...errors,
  //           image2: "Image height should be less than 240px",
  //         });
  //       } else if (fileObj?.width > 480) {
  //         seterrors({
  //           ...errors,
  //           image2: "Image width should be less than 480px",
  //         });
  //       } else {
  //         seterrors({ ...errors, image2: "" });

  //         let data = new FormData();
  //         data.append("image", file);
  //         let imgUpRes = await fileUpload("/admin/tourImage", "POST", data);
  //         console.log("res", imgUpRes);
  //         if (imgUpRes && imgUpRes.status) {
  //           setDayOutCityData((prev) => ({ ...prev, image2: imgUpRes.url }));
  //         } else {
  //           toast.error(imgUpRes.message);
  //         }
  //       }
  //     } else {
  //       toast.error("Please Upload Valid Image");
  //     }

  //     setIsLoading(false);
  //   }
  // };

  const handleValidation = () => {
    if (!dayOutCityData.countryId) {
      toast.error("Country is required");
      return false;
    }
    if (!dayOutCityData.cityId) {
      toast.error("City is required");
      return false;
    }
    if (!dayOutCityData.title) {
      toast.error("Title is required");
      return false;
    }
    if (!dayOutCityData.description) {
      toast.error("Description is required");
      return false;
    }
    if (!dayOutCityData.image1) {
      toast.error("Picture 1 is required");
      return false;
    }
    if (!dayOutCityData.image2) {
      toast.error("Picture 2 is required");
      return false;
    }
    return true;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (handleValidation()) {
      setIsLoading(true);
      try {
        let res = await requestData(
          `/admin/updateminiBlog/${id}`,
          "PUT",
          dayOutCityData
        );
        if (res && res.status) {
          toast.success(res?.message);
          setDayOutCityData(initial);
          setIsLoading(false);
          navigate("/manage-dayOutInCity")
        }
      } catch (error) {
        toast.error(error?.message || "something went wrong ! please try again")
        setDayOutCityData(initial);
        setIsLoading(false);
      }
    }
  };

  const getSingleMiniBlog = async () => {
    setIsLoading(true)
    let res = await requestData(`/admin/view-one-miniBlog/${id}`, "GET");
    console.log("asdasdas", res?.data?.[0])
    if (res && res.status) {
      setDayOutCityData(res?.data?.[0]);
      setIsLoading(false)
    }
  };

  useEffect(() => {
    if (id) getSingleMiniBlog()
  }, []);

  useEffect(() => {
    setIsLoading(loading);
  }, [loading]);

  return (
    <>
      {isLoading && <Loader />}
      <div className="main-card">
        <form onSubmit={handleSubmit}>
          <div component="div" className="TabsAnimation appear-done enter-done">
            <div className="main-card mb-3 card">
              <div className="card-body">
                <div className="card-title">Update Day Out In (city)</div>
                <div className="row">
                  {/* country */}
                  <div className="col-md-4">
                    <div className="mb-3">
                      <label htmlFor="countryId" className="form-label">
                        Country
                        <span
                          style={{
                            fontSize: "20px",
                            fontWeight: "bold",
                            color: "red",
                          }}
                        >
                          {" "}
                          *
                        </span>
                      </label>
                      <Select
                        name="countryId"
                        id="countryId"
                        classNamePrefix="select"
                        ref={inputRef}
                        options={countries?.map((item, idx) => ({
                          value: item?._id,
                          label: item?.name,
                        }))}
                        value={{
                          value: dayOutCityData.countryId,
                          label: dayOutCityData.countryName,
                        }}
                        // isMulti
                        onChange={(val) => {
                          fetchCities(val.value);
                          setDayOutCityData((prev) => {
                            console.log(prev);
                            return {
                              ...prev,
                              countryName: val?.label,
                              countryId: val.value,
                            };
                          });
                        }}
                      />
                    </div>
                  </div>
                  {/* city */}
                  <div className="col-md-4">
                    <div className="mb-3">
                      <label htmlFor="cityId" className="form-label">
                        City
                        <span
                          style={{
                            fontSize: "20px",
                            fontWeight: "bold",
                            color: "red",
                          }}
                        >
                          {" "}
                          *
                        </span>
                      </label>

                      <Select
                        name="cityId"
                        id="cityId"
                        classNamePrefix="select"
                        ref={inputRef}
                        options={cities?.map((item, idx) => ({
                          value: item?._id,
                          label: item?.cityName,
                        }))}
                        value={{
                          value: dayOutCityData?.cityId,
                          label: dayOutCityData?.cityName,
                        }}
                        onChange={(val) => {
                          fetchActivityAgainstCity(val.value);
                          setDayOutCityData((prev) => {
                            // console.log(prev);
                            return {
                              ...prev,
                              cityName: val?.label,
                              cityId: val.value,
                            };
                          });
                        }}
                      />
                    </div>
                  </div>
                  {/* Activity */}
                  <div className="col-md-4">
                    <div className="mb-3">
                      <label htmlFor="categoryId" className="form-label">
                        Activity
                        {/* <span
                          style={{
                            fontSize: "20px",
                            fontWeight: "bold",
                            color: "red",
                          }}
                        >
                          {" "}
                          *
                        </span> */}
                      </label>

                      <Select
                        name="activityId"
                        id="activityId"
                        classNamePrefix="select"
                        ref={inputRef}
                        options={activity?.map((item) => ({
                          value: item._id,
                          label: item.activityTitle,
                        }))}
                        value={{
                          value: dayOutCityData.activityId,
                          label: dayOutCityData.activityName,
                        }}
                        // isMulti
                        onChange={(val) => {
                          console.log("value", val);
                          setDayOutCityData((prev) => {
                            console.log(prev);
                            return {
                              ...prev,
                              activityId: val?.value,
                              activityName: val?.label,
                            };
                          });
                        }}
                      />
                    </div>
                  </div>

                  {/* title */}
                  <div className="col-md-12">
                    <label for="title">
                      Title{" "}
                      <span
                        style={{
                          fontSize: "20px",
                          fontWeight: "bold",
                          color: "red",
                        }}
                      >
                        {" "}
                        *
                      </span>
                    </label>
                    <input
                      type="text"
                      class="form-control"
                      id="title"
                      placeholder="Enter Title here"
                      name="title"
                      value={dayOutCityData?.title}
                      onChange={(e) =>
                        setDayOutCityData((prev) => ({
                          ...prev,
                          title: e.target.value,
                        }))
                      }
                    />
                  </div>
                  {/* Description */}
                  <div className="col-md-12">
                    <label for="description">
                      Description{" "}
                      <span
                        style={{
                          fontSize: "20px",
                          fontWeight: "bold",
                          color: "red",
                        }}
                      >
                        {" "}
                        *
                      </span>
                    </label>
                    {/* <textarea
                      class="form-control"
                      id="description"
                      rows="3"
                      name="description"
                      value={dayOutCityData?.description}
                      onChange={(e) =>
                        setDayOutCityData((prev) => ({
                          ...prev,
                          description: e.target.value,
                        }))
                      }
                    ></textarea> */}
                     <CKEditor
                              className="editor-container"
                              editor={ClassicEditor}
                              data={dayOutCityData?.description}
                              onReady={(editor) => {
                                console.log("Editor is ready to use!", editor);
                              }}
                              onChange={(event, editor) => {
                                const data = editor.getData();
                                console.log("opopop", { event, editor, data });

                                setDayOutCityData((state) => ({
                                  ...state,
                                  description : data
                                  
                                }));
                              }}
                              onBlur={(event, editor) => {
                                console.log("Blur.", editor);
                              }}
                              onFocus={(event, editor) => {
                                console.log("Focus.", editor);
                              }}
                            />
                  </div>
                  {/* picture 1 */}
                  <div className="col-md-12">
                    <div className="mb-3">
                      <label htmlFor="image1" className="form-label">
                        Picture 1 ( please upload a picture of sizes 720px *
                        1280px)
                        <span
                          style={{
                            fontSize: "20px",
                            fontWeight: "bold",
                            color: "red",
                          }}
                        >
                          {" "}
                          *
                        </span>
                      </label>
                      <input
                        name="image1"
                        id="image1"
                        type="file"
                        accept={"image/*"}
                        ref={fileInputRef}
                        className="form-control"
                        // multiple
                        // onChange={(e) =>
                        //   imageHandle(Array.from(e.target.files), true)
                        // }
                        onChange={(e) => {
                          imageHandle(e.target.files[0], true);
                        }}
                      />
                    </div>
                    <div style={{ display: "flex" }}>
                      {dayOutCityData?.image1 && (
                        <>
                          <div style={{ display: "flex" }}>
                            <img
                              src={dayOutCityData?.image1}
                              style={{
                                height: "100px",
                                width: "100px",
                              }}
                              className="img-fluid px-2"
                            />
                            <i
                              onClick={() => {
                                setDayOutCityData((prev) => ({
                                  ...prev,
                                  image1: "",
                                }));
                              }}
                              style={{ top: "0", cursor: "pointer" }}
                              className="fa fa-times-circle-o"
                              aria-hidden="true"
                            ></i>
                          </div>
                        </>
                      )}
                    </div>
                    <span style={{ color: "red" }}>
                      By adding a picture here I agree that I’m liable for any
                      copyright infringement.
                    </span>
                  </div>
                  {/* picture 2 */}
                  <div className="col-md-12">
                    <div className="mb-3">
                      <label htmlFor="image2" className="form-label">
                        Picture 2 ( please upload a picture of sizes 240px *
                        480px)
                        <span
                          style={{
                            fontSize: "20px",
                            fontWeight: "bold",
                            color: "red",
                          }}
                        >
                          {" "}
                          *
                        </span>
                      </label>
                      <input
                        name="image2"
                        id="image2"
                        type="file"
                        accept={"image/*"}
                        ref={fileInputRef}
                        className="form-control"
                        // multiple
                        onChange={(e) => {
                          imageHandle(e.target.files[0], false);
                        }}
                      />
                    </div>
                    <div style={{ display: "flex" }}>
                      {dayOutCityData?.image2 && (
                        <>
                          <div style={{ display: "flex" }}>
                            <img
                              src={dayOutCityData?.image2}
                              style={{
                                height: "100px",
                                width: "100px",
                              }}
                              className="img-fluid px-2"
                            />
                            <i
                              onClick={() => {
                                setDayOutCityData((prev) => ({
                                  ...prev,
                                  image2: "",
                                }));
                              }}
                              style={{ top: "0", cursor: "pointer" }}
                              className="fa fa-times-circle-o"
                              aria-hidden="true"
                            ></i>
                          </div>
                        </>
                      )}
                    </div>
                    <span style={{ color: "red" }}>
                      By adding a picture here I agree that I’m liable for any
                      copyright infringement.
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div>
            <button type="submit" className="btn btn-primary">
              Submit
            </button>
          </div>
        </form>
      </div>
    </>
  );
};

export default EditDayOutInCity;

// Hooks
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams, useNavigate } from "react-router-dom";

// Helpers
import { toast } from "react-toastify";

// Utilities
import {
  capitalizeFirstLetter,
  apiCallError,
} from "../../../../utils";

// Redux actions
import { getAllBlogTypes } from "../../../../redux/adminSlice/blogSlice";

// API functions
import { JSON_API } from "../../../../services/api/jsonApiCallWithInterceptor";

// Components
import Loader from "../../../../Component/Loader";

const initialValues = {
  name: "",
};

const createErrors = {
  name: "",
  //   activityTypeId: "",
};

function EditCategory() {
  const dispatch = useDispatch();
  const params = useParams();
  const navigate = useNavigate();
  const [category, setsection] = useState(initialValues);
  const [error, setError] = useState("");
  const [errors, seterrors] = useState(createErrors);
  const [isLoading, setisLoading] = useState(false);
  // Redux stores
  const { blog_types } = useSelector((state) => state.blogsSlice);

  const typeData = blog_types?.find((item) => item?._id === params.id);

  useEffect(() => {
    dispatch(getAllBlogTypes());
    setsection({ name: typeData.name });
  }, []);

  const handleChange = (event) => {
    event.preventDefault();
    let input = capitalizeFirstLetter(event);
    setsection({ ...category, [event.target.name]: input });
  };

  const handleValidation = () => {
    const { name } = category;

    if (name === "") {
      seterrors({ ...errors, name: "Category name is required" });

      return false;
    }

    return true;
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (handleValidation()) {
      try {
        setisLoading(true);
        let res = await JSON_API["editBlogType"]({
          id: params.id,
          ...category,
        });

        if (res.isSuccess) {
          seterrors(createErrors);
          setsection(initialValues);
          setisLoading(false);
          toast.success('Blog Type edited successfully ')
          navigate("/manage-blog-types");
        } else {
          setisLoading(false);
          apiCallError(res?.error?.message, setError);
        }
      } catch (error) {
        setisLoading(false);
        apiCallError(error.message, setError);
      }
    }
  };

  return (
    <>
      {isLoading && <Loader />}
      <form className="" onSubmit={(e) => handleSubmit(e)}>
        <div component="div" className="TabsAnimation appear-done enter-done">
          <div className="main-card mb-3 card">
            <div className="card-title">Edit Blog Type</div>
            {/* <div className="row"> */}
            <div className="col-md-12">
              <div className="mb-3">
                <label htmlFor="name" className="form-label">
                  Type*
                </label>
                <input
                  name="name"
                  id="name"
                  type="text"
                  className="form-control"
                  autoComplete="false"
                  value={category.name}
                  onChange={(e) => handleChange(e)}
                />
              </div>
              {errors.name && (
                <span className="errorMessageText text-danger">
                  {errors.name}
                </span>
              )}
            </div>

            <div className="d-flex m-2">
              <button
                type="button"
                className="btn btn-link"
                onClick={() => {
                  navigate("/manage-blog-types");
                }}
              >
                Cancel
              </button>
              <button
                className="mt-2 btn btn-primary  d-table"
                type="submit"
                style={{ margin: "5px" }}
              >
                Edit
              </button>
            </div>
          </div>
        </div>
      </form>
    </>
  );
}

export default EditCategory;

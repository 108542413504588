import React from "react";

// Components
import { ToastContainer } from "react-toastify";
import AppRoutes from "./routes/AppRoutes";

import "./App.css";
import "../src/assets/base.css";
import "react-toastify/dist/ReactToastify.css";
import toast, { Toaster } from 'react-hot-toast';

function App() {
  return (
    <React.Fragment>
      <div className="App">
        <AppRoutes />
      </div>

      <ToastContainer />
      <Toaster />
    </React.Fragment>
  );
}

export default App; 


// hhhhhh

import React, { useState, useEffect } from "react";
import Loader from "../../../Component/Loader";
import { capitalizeFirstLetter } from "../../../utils";
import { toast } from "react-hot-toast";
import { requestData } from "../../../services/api/apiHelpers";
import { useParams, useNavigate } from "react-router-dom";

function EditParticipants() {
  const [parType, setparType] = useState({ type: "", ageRange: "" });
  const [isLoading, setisLoading] = useState(false);
  const [parTypes, setparTypes] = useState([]);
  const params = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    getParTypes();
  }, []);

  const getParTypes = async () => {
    let res = await requestData("/admin/view-participant-type", "GET");
    if (res.status) {
      setparTypes(res.data);
      const partype_data = res?.data.find((item) => item?._id === params.id);
      setparType(partype_data);
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!parType.type) {
      toast.error("Please provide participant type name");
      return;
    }
    if (!parType.ageRange) {
      toast.error("Please provide participant type age");
      return;
    }
    setisLoading(true);
    let res = await requestData(
      `/admin/update-participant-type/${params.id}`,
      "PUT",
      parType
    );

    if (res && res.status) {
      setisLoading(false);
      toast.success("Participant type edited successfully.");
      setparType({ type: "", ageRange: "" });
      navigate("/manage-participants");
    } else {
      setisLoading(false);
      toast.error(res?.error?.message);
    }
  };

  return (
    <>
      {isLoading && <Loader />}
      <form action="" onSubmit={handleSubmit}>
        <div component="div" className="TabsAnimation appear-done enter-done">
          <div className="main-card mb-3 card">
            <div className="card-title">EDIT PARTICIPANT TYPE</div>
            <div className="row">
              <div className="col-md-12">
                <div className="mb-3">
                  <label htmlFor="type" className="form-label">
                    Type*
                  </label>
                  <input
                    name="type"
                    id="type"
                    type="text"
                    className="form-control"
                    autoComplete="false"
                    value={parType.type}
                    onChange={(e) => {
                      setparType((prevState) => ({
                        ...prevState,
                        [e.target.name]: capitalizeFirstLetter(e),
                      }));
                    }}
                  />
                </div>
              </div>
              <div className="col-md-12">
                <div className="mb-3">
                  <label htmlFor="type" className="form-label">
                    Age Range*
                  </label>
                  <input
                    name="ageRange"
                    id="ageRange"
                    type="text"
                    className="form-control"
                    autoComplete="false"
                    value={parType?.ageRange}
                    onChange={(e) => {
                      setparType((prevState) => ({
                        ...prevState,
                        [e.target.name]: e.target.value,
                      }));
                    }}
                  />
                </div>
              </div>
            </div>

            <div style={{ display: "flex", justifyContent: "flex-end" }}>
              <button
                className="mt-2 btn btn-secondary d-table"
                style={{ margin: "5px" }}
                onClick={() => navigate("/manage-participants")}
              >
                Cancel
              </button>
              <button
                className="mt-2 btn btn-primary d-table"
                type="submit"
                style={{ margin: "5px" }}
              >
                Edit
              </button>
            </div>
          </div>
        </div>
      </form>
    </>
  );
}

export default EditParticipants;

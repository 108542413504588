import axios from "axios";
import { BASE_URL, API_ENDPOINT_PATH } from "../../constants/config";
const API_URL = BASE_URL + API_ENDPOINT_PATH;

export async function requestData(url, method, data = {}) {
  let token = localStorage.getItem("Authorization");
  let apiUrl = API_URL + url;
  var myHeaders = new Headers();
  myHeaders.append("Content-Type", "application/json");
  myHeaders.append("Authorization", token);
  myHeaders.append("userType", "Admin");
  myHeaders.append("Access-Control-Allow-Origin", BASE_URL);
  var raw = JSON.stringify(data);

  var requestOptions = {};
  if (method === "POST") {
    requestOptions = {
      method: method,
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };
  } else if (method === "PUT") {
    requestOptions = {
      method: method,
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };
  } else {
    requestOptions = {
      method: method,
      headers: myHeaders,
      redirect: "follow",
    };
  }

  return await fetch(apiUrl, requestOptions)
    .then((response) => response.json())
    .then((result) => {
      return result;
    })
    .catch((error) => console.log("error", error));
}

export async function fileUpload(
  url,
  method,
  file,
) {
  let token = localStorage.getItem("Authorization");
  let user_type = localStorage.getItem('userType');
  let apiEndpoint = API_URL + url;
  let headers = {
    Authorization: token,
    userType: user_type,
  };

  return await fetch(apiEndpoint, {
    method: method,
    body: file,
    redirect: "follow",
    headers: headers,
  })
    .then((response) => {
      return response.json();
    })
    .then(
      (result) => {
        return result;
      },
      (error) => {
        return error;
      }
    );
}

export const ApiHelperFunction = async (props) => {
  let token = localStorage.getItem("Authorization");

  const { urlPath, method, data } = props;

  let config = {
    method: `${method}`,
    url: `${API_URL}${urlPath}`,
    headers: {
      "Content-Type": "application/json",
      Authorization: token,
      userType: "Admin",
    },
    data: data,
  };
  let responseData;

  await axios(config)
    .then(function (res) {
      responseData = res;
    })
    .catch(function (error) {
      if (error?.response?.status === 401) {
        responseData = error;
      } else {
        responseData = error;
      }
    });

  return responseData;
};

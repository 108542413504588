// Hooks
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

// Helpers
import { toast } from "react-toastify";

// Utils
import {
  apiCallError,
  allowOnlyPositiveInteger,
} from "../../../utils/common-utils";

// Redux actions
import { getAllGifts } from "../../../redux/adminSlice/giftSlice";

// API
import { JSON_API } from "../../../services/api/jsonApiCallWithInterceptor";

// Components
import Loader from "../../../Component/Loader";

function AddGift() {
  const dispatch = useDispatch();
  const [isLoading, setisLoading] = useState(false);
  const [price, setprice] = useState("");
  const [error, setError] = useState("");
  // const priceRegex=/^[0-9]$/

  // Redux stores
  const { gifts } = useSelector((state) => state.giftsSlice);
  console.log("gifts", gifts);

  useEffect(() => {
    dispatch(getAllGifts());
  }, []);

  const handleSubmit = async (event) => {
    event.preventDefault();
    // console.log("languageCode", language);
    if (price === "") {
      toast.error("Please add a valid price in digits");
    } else {
      // console.log(language);
      let check = gifts.find((item) => item.price === price);

      if (check) {
        toast.error("The amount is already added.");
      } else {
        try {
          setisLoading(true);
          let res = await JSON_API["adminAddGift"]({ price: price });
          console.log("this response", res);

          if (res.isSuccess) {
            setprice("");
            toast.success("Gift added successfully ");
          } else {
            apiCallError(res?.error?.message, setError);
          }
          setisLoading(false);
        } catch (error) {
          setisLoading(false);
          apiCallError(error.message, setError);
        }
      }
    }
  };

  return (
    <>
      {isLoading && <Loader />}
      <form className="">
        <div component="div" className="TabsAnimation appear-done enter-done">
          <div className="main-card mb-3 card">
            <div className="card-body">
              <div className="card-title">Add Gift</div>
              <div className="col-md-12">
                <div
                  className="mb-3"
                  style={{ display: "flex", paddingTop: "5px" }}
                >
                  <label
                    htmlFor="price"
                    className="form-label"
                    style={{ margin: "0.5rem 0.5rem" }}
                  >
                    Price
                  </label>
                  <input
                    name="price"
                    id="price"
                    type="number"
                    className="form-control"
                    autoComplete="false"
                    value={price}
                    onChange={(e) => {
                      setprice(allowOnlyPositiveInteger(e));
                    }}
                  />
                </div>
              </div>

              <button
                className="mt-2 btn btn-primary ml-auto d-table"
                type="submit"
                onClick={(e) => handleSubmit(e)}
              >
                Submit
              </button>
            </div>
          </div>
        </div>
      </form>
    </>
  );
}

export default AddGift;

import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";


function ActivityAvailabilityCalendar() {
    return (<div>
        Work In Progress...
    </div>);
}

export default ActivityAvailabilityCalendar;

import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import { ApiHelperFunction } from "../../services/api/apiHelpers";

let initialState = {
    status: "idle",
    commissions: [],
    isLoading: false,
    isSuccess: false,
    isError: false,
};

export const getAllCommissions = createAsyncThunk(
    "get_all_commissions",
    async () => {
        let response = await ApiHelperFunction({
            urlPath: "/admin/view-commission-percentage",
            method: "GET",
        });

        if (response.status === 200) {
            return response.data;
        } else {
            toast.error("Can't get data. Something went wrong");
        }
    }
);

export const MerchantCommissionsSlice = createSlice({
    name: "merchant_commissions",
    initialState,
    reducers: {
        merchantCommissionDataClearState: (state) => {
            state.status = "idle";
            state.commissions = [];
            state.isLoading = false;
            state.isSuccess = false;
            state.isError = false;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(getAllCommissions.pending, (state) => {
                state.status = "loading";
                state.isLoading = true;
            })
            .addCase(getAllCommissions.fulfilled, (state, { payload }) => {
                state.status = "success";
                state.isSuccess = true;
                state.commissions = payload.data;
            })
            .addCase(getAllCommissions.rejected, (state) => {
                state.status = "failed";
                state.isError = true;
                state.isSuccess = false;
            });
    },
});

export const { merchantCommissionDataClearState } = MerchantCommissionsSlice.actions;

export default MerchantCommissionsSlice.reducer;

// Hooks
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams, useNavigate } from "react-router-dom";

// Utils
import { apiCallError } from "../../../utils";
import { formatTimeToAMPM } from "../../../utils/time-functions";

// Helpers
import { toast } from "react-toastify";

// API
import { requestData } from "../../../services/api/apiHelpers";

// Redux actions
import {
  getAllActivities,
  getActivityAvailabilityForADate,
} from "../../../redux/adminSlice/activitySlice";

// Components
import TimePicker from "react-time-picker";
import Loader from "../../../Component/Loader";
import Select from "react-select";

import "react-time-picker/dist/TimePicker.css";
import "./styles.css";

let initialValues = {
  activityDetailsId: "",
  activityDetailsName: "",
  activityMaxPerson: "",
  tourDate: "",
  time: "",
  cutoffTime: "",
  changePrice: 0,
  remeningUser: 0,
};

let createErrors = {
  time: "",
  cutoffTime: "",
  changePrice: "",
  remeningUser: "",
};

function EditAvailability() {
  const dispatch = useDispatch();
  const params = useParams();
  const navigate = useNavigate();
  const [activity, setactivity] = useState(initialValues);
  const [timeSlots, settimeSlots] = useState([]);
  const [error, setError] = useState("");
  const [errors, seterrors] = useState(createErrors);
  const [isLoading, setisLoading] = useState(false);
  const { slots_for_a_date, activities } = useSelector(
    (state) => state.adminActivityActionSlice
  );
  const queryString = window.location.search;
  const queryParams = new URLSearchParams(queryString);
  useEffect(() => {
    const slot_date = queryParams.get("date");
    const slot_time = queryParams.get("time_slot");
    dispatch(
      getActivityAvailabilityForADate({
        date: slot_date,
        activityId: params.acti_id,
      })
    );
    dispatch(getAllActivities());
    setactivity(
      slots_for_a_date.filter((item) => item?._id === params.slot_id)[0]
    );
    settimeSlots(
      slots_for_a_date.map((item) => item?.time !== slot_time && item?.time)
    );
  }, []);
  const activityValue = activities?.find(
    (item) => item?._id === params.acti_id
  );

  const handleChange = (event) => {
    event.preventDefault();

    setactivity({
      ...activity,
      [event.target.name]: event.target.value,
    });
  };

  const handleTimeChange = (time) => {
    let formattedTime = formatTimeToAMPM(time);
    setactivity({ ...activity, time: formattedTime });
  };

  const allowOnlyPositiveInteger = (event) => {
    event.preventDefault();

    let inputValue = event.target.value.replace(/\D/g, "");

    if (inputValue !== "") {
      inputValue = parseInt(inputValue, 10);

      if (inputValue <= 0 || isNaN(inputValue)) {
        inputValue = "";
      }
    }

    setactivity({ ...activity, [event.target.name]: inputValue });
  };

  const handleValidation = () => {
    const { time, cutoffTime, changePrice, remeningUser } = activity;

    if (time === "") {
      seterrors({ ...createErrors, time: "Start time is required." });

      return false;
    }
    if (timeSlots.includes(time)) {
      seterrors({
        ...createErrors,
        time: "This start time is already available.",
      });

      return false;
    }
    if (cutoffTime === "") {
      seterrors({ ...createErrors, cutoffTime: "Cutoff time is required" });

      return false;
    }
    if (changePrice === 0) {
      seterrors({
        ...createErrors,
        changePrice: "Change price is required.",
      });

      return false;
    }
    if (remeningUser === 0) {
      seterrors({
        ...createErrors,
        remeningUser: "Number of remaining seats is required.",
      });

      return false;
    }

    return true;
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (handleValidation()) {
      if (activity.remeningUser <= activityValue?.tourPerson) {
        try {
          setisLoading(true);
          let res = await requestData(
            `/admin/edit-availability/${params.slot_id}`,
            "PUT",
            activity
          );
          if (res && res.status) {
            dispatch(
              getActivityAvailabilityForADate({
                date: queryParams.get("date"),
                activityId: params.acti_id,
              })
            );
            seterrors(createErrors);
            setactivity(initialValues);
            setisLoading(false);
            toast.success("Activity availability edited successfully.");
            navigate("/check-activity-availability");
          } else {
            setisLoading(false);
            apiCallError(res?.error?.message, setError);
          }
        } catch (error) {
          setisLoading(false);
          apiCallError(error.message, setError);
        }
      } else {
        toast.error(
          `Available seats cannot be more than ${activityValue?.tourPerson} for this activity.`
        );
        return;
      }
    } else {
      window.scrollTo({
        top: 0,
        left: 0,
        behavior: "smooth",
      });
    }
  };

  return (
    <>
      {isLoading && <Loader />}
      <form className="" onSubmit={(e) => handleSubmit(e)}>
        <div component="div" className="TabsAnimation appear-done enter-done">
          <div className="main-card mb-3 card">
            <div className="card-body">
              <div className="card-title">Edit Slot Details</div>
              <div className="row">
                <div className="col-md-6">
                  <div className="mb-3">
                    <label
                      htmlFor="activityDetailsId"
                      className="form-label font-weight-bold"
                    >
                      Activity*
                    </label>
                    <Select
                      isDisabled
                      name="activityDetailsId"
                      id="activityDetailsId"
                      classNamePrefix="select"
                      options={activities?.map((item) => ({
                        value: item?._id,
                        label: item?.activityTitle,
                      }))}
                      value={{
                        value: activityValue?._id,
                        label: activityValue?.activityTitle,
                      }}
                      // isMulti
                    />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="mb-3">
                    <label
                      className="form-label font-weight-bold"
                      htmlFor="tourDate"
                    >
                      Date*
                    </label>
                    <input
                      name="tourDate"
                      id="tourDate"
                      type="text"
                      className="form-control"
                      value={activity.tourDate}
                      disabled
                    />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="mb-3">
                    <label
                      className="form-label font-weight-bold"
                      htmlFor="tourDate"
                    >
                      Start Time*
                    </label>
                    <TimePicker
                      id="time"
                      clockIcon={null}
                      clearIcon={null}
                      className="form-control"
                      hourPlaceholder="hh"
                      minutePlaceholder="mm"
                      value={activity.time}
                      format="hh:mm a"
                      openClockOnFocus={false}
                      onChange={handleTimeChange}
                    />
                  </div>
                  {errors.time && (
                    <span className="errorMessageText text-danger">
                      {errors.time}
                    </span>
                  )}
                </div>
                <div className="col-md-6">
                  <div className="mb-3">
                    <label
                      className="form-label font-weight-bold"
                      htmlFor="tourDate"
                    >
                      Booking Cutoff Time*
                    </label>
                    <TimePicker
                      id="time"
                      clockIcon={null}
                      clearIcon={null}
                      className="form-control"
                      hourPlaceholder="hh"
                      minutePlaceholder="mm"
                      value={activity.cutoffTime}
                      format="hh:mm a"
                      openClockOnFocus={false}
                      onChange={handleTimeChange}
                    />
                  </div>
                  {errors.cutoffTime && (
                    <span className="errorMessageText text-danger">
                      {errors.cutoffTime}
                    </span>
                  )}
                </div>
                <div className="col-md-6">
                  <div className="mb-3">
                    <label
                      htmlFor="changePrice"
                      className="form-label font-weight-bold"
                    >
                      Price To Set*
                    </label>
                    <input
                      name="changePrice"
                      id="changePrice"
                      type="number"
                      min={"0"}
                      className="form-control"
                      autoComplete="false"
                      value={activity.changePrice}
                      onChange={allowOnlyPositiveInteger}
                    />
                  </div>
                  {errors.changePrice && (
                    <span className="errorMessageText text-danger">
                      {errors.changePrice}
                    </span>
                  )}
                </div>
                <div className="col-md-6">
                  <div className="mb-3">
                    <label
                      htmlFor="remeningUser"
                      className="form-label font-weight-bold"
                    >
                      Remaining Seats*
                    </label>
                    <input
                      name="remeningUser"
                      id="remeningUser"
                      type="number"
                      min={"0"}
                      className="form-control"
                      autoComplete="false"
                      value={activity.remeningUser}
                      onChange={allowOnlyPositiveInteger}
                    />
                  </div>
                  {errors.remeningUser && (
                    <span className="errorMessageText text-danger">
                      {errors.remeningUser}
                    </span>
                  )}
                </div>
              </div>

              <div style={{ display: "flex", justifyContent: "flex-end" }}>
                <button
                  className="mt-2 btn btn-secondary d-table"
                  onClick={() => navigate("/check-activity-availability")}
                  style={{ marginRight: "10px" }}
                >
                  Cancel
                </button>
                <button
                  className="mt-2 btn btn-primary d-table"
                  type="submit"
                  onClick={() => {}}
                >
                  Submit
                </button>
              </div>
            </div>
          </div>
        </div>
      </form>
    </>
  );
}

export default EditAvailability;

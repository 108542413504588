// Hooks
import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams, useNavigate } from "react-router-dom";

// Helpers
import { toast } from "react-toastify";

// Utilities
import {
  capitalizeFirstLetter,
  apiCallSuccess,
  apiCallError,
} from "../../../utils";
import useImgUploader from "../../../Hooks/use-img-uploader";

// API functions
import { JSON_API } from "../../../services/api/jsonApiCallWithInterceptor";

// Redux actions
import { getAllSections } from "../../../redux/adminSlice/sectionSlice";
import { getAllActivityTypes } from "../../../redux/adminSlice/activitySlice";

// Components
import Loader from "../../../Component/Loader";

const initialValues = {
  activityTypeId: "",
  subType: "",
  title: "",
};

const createErrors = {
  activityTypeId: "",
  subType: "",
  title: "",
};

const subtypes = ["activities", "sights", "cities"];

const imgUpApiConfig = {
  url: "/admin/uploadActivitySiteImage",
  propertyName: "data",
};

const EditSection = () => {
  // function AddSection() {
  const dispatch = useDispatch();
  const params = useParams();
  const navigate = useNavigate();
  const fileInputRef = useRef(null);
  const [section, setsection] = useState(initialValues);
  const [isSubtypeSite, setisSubtypeSite] = useState(false);
  const { Img, setImg, imageHandler, imgComp, imgLoading } =
    useImgUploader(imgUpApiConfig);
  const [error, setError] = useState("");
  const [errors, seterrors] = useState(createErrors);
  const [modal, setModal] = useState(false);
  const [isLoading, setisLoading] = useState(false);
  // Redux stores
  const { activity_types } = useSelector(
    (state) => state.adminActivityActionSlice
  );
  const { sections } = useSelector((state) => state.adminSectionActionsSlice);
  console.log("sections", sections);

  const sectionData = sections.find((item) => item?._id === params.id);
  // Component did mount
  useEffect(() => {
    dispatch(getAllActivityTypes());
    dispatch(getAllSections());
    if (sectionData.subType === "sights") {
      setisSubtypeSite(true);
      setImg(sectionData.image)
      setsection((prev) => {
        return {
          ...prev,
          title: sectionData.title,
          subType: sectionData.subType,
          activityTypeId: sectionData.activityTypeId,
          image: sectionData.image
        };
      });
    } else {
      setsection((prev) => {
        return {
          ...prev,
          title: sectionData.title,
          subType: sectionData.subType,
          activityTypeId: sectionData.activityTypeId,
        };
      });
    }
    window.scrollTo({
      top: 100,
      left: 100,
      behavior: "smooth",
    });
  }, []);

  const handleChange = (event) => {
    // event.preventDefault();
    // console.log(event.target.name, event.target.value);
    let input;

    if (
      event.target.name === "activityTypeId" ||
      event.target.name === "subType"
    ) {
      input = event.target.value;
    } else {
      input = capitalizeFirstLetter(event);
    }

    setsection({ ...section, [event.target.name]: input });
  };

  const handleEditValidation = () => {
    const { title, activityTypeId, subType } = section;

    if (title === "") {
      seterrors({ ...errors, title: "Section title is required" });

      return false;
    }
    if (activityTypeId === "") {
      seterrors({
        ...errors,
        activityTypeId: "Activity type is required",
      });

      return false;
    }

    if (subType === "") {
      seterrors({ ...errors, subType: "Section Type is required" });

      return false;
    }

    return true;
  };

  const handleEditSubmit = async (event) => {
    event.preventDefault();

    if (handleEditValidation()) {
      console.log("Edit submit", section);
      let bodyData = { id: `${params.id}`, ...section };
      if (Img !== "" && typeof Img !== "undefined") {
        bodyData.image = Img;
      }
      try {
        setisLoading(true);
        let res = await JSON_API["adminEditSection"](bodyData);

        if (res.isSuccess) {
          dispatch(getAllSections());
          setModal(false);
          setsection(initialValues);
          seterrors(createErrors);
          setImg("");
          if (fileInputRef.current) {
            fileInputRef.current.value = "";
          }
          setisLoading(false);
          // apiCallSuccess(res.data);
          toast.success("Section edited successfully.");
          navigate("/manage-sections");
        } else {
          setisLoading(false);
          apiCallError(res?.error?.message, setError);
        }
      } catch (error) {
        setisLoading(false);
        apiCallError(error.message, setError);
      }
    }
  };

  return (
    <>
      {isLoading && <Loader />}
      <form className="" onSubmit={(e) => handleEditSubmit(e)}>
        <div component="div" className="TabsAnimation appear-done enter-done">
          <div className="main-card mb-3 card">
            <div className="card-body">
              
            <div className="card-title">Edit Section</div>
            <div className="row">
            <div className="col-md-4">
              <div className="mb-3">
                <label htmlFor="title" className="form-label">
                  Name*
                </label>
                <input
                  name="title"
                  id="title"
                  type="text"
                  className="form-control"
                  autoComplete="false"
                  value={section.title}
                  onChange={(e) => handleChange(e)}
                />
              </div>
              {errors.title && (
                <span className="errorMessageText text-danger">
                  {errors.title}
                </span>
              )}
            </div>
            <div className="col-md-4">
              <div className="mb-3">
                <label htmlFor="activityTypeId" className="form-label">
                  Choose Activity Type*
                </label>
                <select
                  disabled
                  name="activityTypeId"
                  id="activityTypeId"
                  className="form-control form-select"
                  value={section.activityTypeId}
                  onChange={(e) => handleChange(e)}
                >
                  <option value="" key={0}>
                    Select
                  </option>
                  {activity_types?.map((item, idx) => {
                    return (
                      <>
                        <option key={idx + 1} value={`${item?._id}`}>
                          {item?.name}
                        </option>
                      </>
                    );
                  })}
                </select>
              </div>
              {errors.activityTypeId && (
                <span className="errorMessageText text-danger">
                  {errors.activityTypeId}
                </span>
              )}
            </div>

            <div className="col-md-4">
              <div className="mb-3">
                <label htmlFor="subType" className="form-label">
                  Choose Activity Subtype*
                </label>
                <select
                  disabled
                  name="subType"
                  id="subType"
                  className="form-control form-select"
                  value={section.subType}
                  onChange={(e) => {
                    if (e.target.value === "") {
                      setisSubtypeSite(true);
                    } else {
                      setisSubtypeSite(false);
                    }
                    handleChange(e);
                  }}
                >
                  <option value="" key={0}>
                    Select
                  </option>
                  {subtypes?.map((item, idx) => {
                    return (
                      <>
                        <option key={idx + 1} value={`${item}`}>
                          {item.charAt(0).toUpperCase() + item.slice(1)}
                        </option>
                      </>
                    );
                  })}
                </select>
              </div>
              {errors.subType && (
                <span className="errorMessageText text-danger">
                  {errors.subType}
                </span>
              )}
            </div>

            </div>
            {isSubtypeSite && (
              <div className="col-md-12">
                <div className="mb-3">
                  <label htmlFor="image" className="form-label">
                    Image
                  </label>
                  <input
                    name="image"
                    id="image"
                    type="file"
                    accept={"image/*"}
                    ref={fileInputRef}
                    className="form-control"
                    onChange={imageHandler}
                  />
                </div>
                {imgComp}
              </div>
            )}

            <div className="d-flex m-2" style={{ justifyContent: "flex-end" }}>
              <button
                type="button"
                className="mt-2 btn btn-link d-table"
                onClick={() => {
                  setModal(false);
                  navigate("/manage-sections");
                }}
              >
                Cancel
              </button>
              <button
                type="submit"
                className="mt-2 btn btn-primary d-table ml-2"
              >
                Edit
              </button>
            </div>
            </div>

          </div>
        </div>
      </form>
    </>
  );
};

export default EditSection;

// Hooks
import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

// Helpers
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import toast from "react-hot-toast";

// Redux actions
import { getAllTerms } from "../../../redux/adminSlice/termsAndConSlice";

// API functions
import { JSON_API } from "../../../services/api/jsonApiCallWithInterceptor";

// Components
import Loader from "../../../Component/Loader";
import { CKEditor } from "@ckeditor/ckeditor5-react";

function EditTerm() {
  const params = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [content, setcontent] = useState("");
  const [isLoading, setisLoading] = useState(false);
  // Redux stores
  const { terms } = useSelector((state) => state.termsConditionSlice);

  const termData = terms.find((item) => item?._id === params.id);

  useEffect(() => {
    dispatch(getAllTerms());
    setcontent(termData?.topic);
  }, []);

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (content === "") {
      toast.error("Please provide terms and conditions content");
    } else {
      try {
        setisLoading(true);
        let res = await JSON_API["adminEditTerms"]({
          id: params.id,
          topic: content,
        });

        if (res.isSuccess) {
          setcontent("");
          toast.success("Terms & Conditions updated successfully.");
          navigate("/manage-terms-conditions");
        } else {
          toast.error(res?.error?.message);
        }

        setisLoading(false);
      } catch (error) {
        console.log("Failed to edit terms and conditions due to ", error.message);
        setisLoading(false);
      }
    }
  };

  return (
    <>
      {isLoading && <Loader />}
      <form action="" onSubmit={handleSubmit}>
        <div component="div" className="TabsAnimation appear-done enter-done">
          <div className="main-card mb-3 card">
            <div className="card-body">
              <div className="card-title">Edit Terms And Conditions</div>
              <div className="col-md-12">
                <div className="mb-3">
                  <label htmlFor="Faq" className="form-label font-weight-bold">
                    Details*
                  </label>
                  <CKEditor
                    className="editor-container"
                    editor={ClassicEditor}
                    data={content}
                    onReady={(editor) => {
                      // You can store the "editor" and use when it is needed.
                      console.log("Editor is ready to use!", editor);
                    }}
                    onChange={(event, editor) => {
                      const data = editor.getData();
                      console.log({ event, editor, data });
                      setcontent(data);
                    }}
                    onBlur={(event, editor) => {
                      console.log("Blur.", editor);
                    }}
                    onFocus={(event, editor) => {
                      console.log("Focus.", editor);
                    }}
                  />
                </div>
              </div>

              <button
                className="mt-2 btn btn-primary  d-table"
                type="submit"
                style={{ margin: "5px" }}
              >
                Submit
              </button>
            </div>
          </div>
        </div>
      </form>
    </>
  );
}

export default EditTerm;

// Hooks
import React, { useState, useRef, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

// Helpers
import { toast } from "react-toastify";

// Utilities
import {
  capitalizeFirstLetter,
  apiCallSuccess,
  apiCallError,
} from "../../../utils";

// API functions
import { JSON_API } from "../../../services/api/jsonApiCallWithInterceptor";

import { getAllDigitalInfos } from "../../../redux/adminSlice/digitalInfoSlice";

// Components
import Layout from "../../../Layout";
import Loader from "../../../Component/Loader";

const initialValues = {
  topic: "",
  desc: "",
};

const createErrors = {
  topic: "",
  desc: "",
  //   activityTypeId: "",
};

function AddInfo() {
  const dispatch = useDispatch();
  const [category, setsection] = useState(initialValues);
  const [error, setError] = useState("");
  const [errors, seterrors] = useState(createErrors);
  const [isLoading, setisLoading] = useState(false);

  const handleChange = (event) => {
    event.preventDefault();
    // console.log(event.target.name, event.target.value);
    let input;

    if (event.target.name === "desc") {
      input = event.target.value;
    } else {
      input = capitalizeFirstLetter(event);
    }

    setsection({ ...category, [event.target.name]: input });
  };

  const handleValidation = () => {
    const { topic, desc } = category;

    if (topic === "") {
      seterrors({ ...createErrors, topic: "Topic name is required" });

      return false;
    }
    if (desc === "") {
      seterrors({ ...createErrors, desc: "Topic description is required" });

      return false;
    }
    if (desc.length < 50) {
      seterrors({
        ...createErrors,
        desc: "Topic description should be atleast 50 characters.",
      });

      return false;
    }

    return true;
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    // console.log("submitting");
    if (handleValidation()) {
      try {
        setisLoading(true);
        let res = await JSON_API["adminAddDigitalInfo"](category);

        if (res.isSuccess) {
          dispatch(getAllDigitalInfos());
          seterrors(createErrors);
          setsection(initialValues);
          // apiCallSuccess(res.data);
          toast.success("Digital information added successfully.");
        } else {
          apiCallError(res?.error?.message, setError);
        }
        setisLoading(false);
      } catch (error) {
        setisLoading(false);
        apiCallError(error.message, setError);
      }
    }
  };

  return (
    <>
      {isLoading && <Loader />}

      <form className="" onSubmit={(e) => handleSubmit(e)}>
        <div component="div" className="TabsAnimation appear-done enter-done">
          <div className="main-card mb-3 card">
            <div className="card-title">Add Digital Information</div>
            {/* <div className="row"> */}
            <div className="col-md-12">
              <div className="mb-3">
                <label htmlFor="topic" className="form-label">
                  Topic
                </label>
                <input
                  name="topic"
                  id="topic"
                  type="text"
                  className="form-control"
                  autoComplete="false"
                  value={category.topic}
                  onChange={(e) => handleChange(e)}
                />
              </div>
              {errors.topic && (
                <span className="errorMessageText text-danger">
                  {errors.topic}
                </span>
              )}
            </div>
            <div className="col-md-12">
              <div className="mb-3">
                <label htmlFor="desc" className="form-label">
                  Description
                </label>
                <textarea
                  name="desc"
                  id="desc"
                  className="form-control"
                  autoComplete="false"
                  value={category.desc}
                  onChange={(e) => handleChange(e)}
                />
              </div>
              {errors.desc && (
                <span className="errorMessageText text-danger">
                  {errors.desc}
                </span>
              )}
            </div>

            {/* </div> */}

            <button
              className="mt-2 btn btn-primary ml-auto d-table"
              type="submit"
              style={{ margin: "5px" }}
            >
              Add
            </button>
          </div>
        </div>
      </form>
    </>
  );
}

export default AddInfo;

// Hooks
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";

// Helpers
import { toast } from "react-toastify";

// Utils
import { apiCallSuccess, apiCallError } from "../../../utils/common-utils";

// API
import { JSON_API } from "../../../services/api/jsonApiCallWithInterceptor";

// Redux actions
import { getAllGifts } from "../../../redux/adminSlice/giftSlice";
import { getAllDetailsChangeRequest } from "../../../redux/adminSlice/merchantActionsSlice";

// Components
import Layout from "../../../Layout";
import Table from "../../../Component/Table";

const ManageDetailsChangeRequests = () => {
  // function AddLanguage() {
  const dispatch = useDispatch();
  // Redux stores
  const { gifts } = useSelector((state) => state.giftsSlice);
  const { details_change_requests_new } = useSelector(
    (state) => state.adminMerchantActionSlice
  );
  // Component did mount
  useEffect(() => {
    dispatch(getAllGifts());
    dispatch(getAllDetailsChangeRequest());
  }, []);

  let filterData = details_change_requests_new?.filter((item) => {
    return item?.rejectStatus === false;
  });


  const tableData = {
    columns: [
      {
        label: "SL",
        field: "sl",
        sort: "asc",
        width: 100,
      },
      {
        label: "Merchant Name",
        field: "merchant_name",
        sort: "asc",
        width: 150,
      },
      {
        label: "Merchant Company",
        field: "merchant_company",
        sort: "asc",
        width: 150,
      },
      // {
      //   label: "Status",
      //   field: "status",
      //   sort: "asc",
      //   width: 150,
      // },
      {
        label: "View Details",
        field: "detail_view",
        sort: "asc",
        width: 150,
      },
    ],
    rows:
      filterData?.length > 0
        ? filterData.map((item, idx) => {
            console.log("wwwwawawawa", item?.rejectStatus);
            return {
              sl: idx + 1,
              merchant_name: (
                <div className="widget-content p-0">
                  <div className="widget-content-wrapper">
                    <div className="widget-content-left flex2">
                      <div
                        className="widget-heading"
                        style={{ opacity: 1, textAlign: "center" }}
                      >
                        {item?.merchant?.firstName?.charAt(0)?.toUpperCase() +
                          item?.merchant?.firstName?.slice(1) +
                          " " +
                          item?.merchant?.lastName?.charAt(0)?.toUpperCase() +
                          item?.merchant?.lastName?.slice(1)}
                      </div>
                      {/* <div className="widget-subheading opacity-7">UI Designer</div> */}
                    </div>
                  </div>
                </div>
              ),
              merchant_company: (
                <div className="widget-content p-0">
                  <div className="widget-content-wrapper">
                    <div className="widget-content-left flex2">
                      <div
                        className="widget-heading"
                        style={{ opacity: 1, textAlign: "center" }}
                      >
                        {item?.merchant?.companyName}
                      </div>
                      {/* <div className="widget-subheading opacity-7">UI Designer</div> */}
                    </div>
                  </div>
                </div>
              ),
              // status: (
              //   <button
              //     className={`badge ${
              //       item?.status ? "bg-success" : "bg-danger"
              //     } border-0`}
              //   >
              //     {item?.status ? "Active" : "Deactivated"}
              //   </button>
              // ),
              detail_view: (
                <div>
                  {/* <button
                    className="me-2 btn-icon btn-icon-only btn btn-outline-danger"
                    onClick={() => {
                      openModal();
                      setmodalToggleView(1);
                      setdeleteId(gifts[idx]._id);
                    }}
                  >
                    <i className="fa-solid fa-trash-can" />
                  </button>
                  <button
                    className="me-2 btn-icon btn-icon-only btn btn-outline-success"
                    onClick={() => {
                      openModal();
                      setmodalToggleView(0);
                      setitemIndex(idx);
                    }}
                  >
                    <i class="fa-regular fa-pen-to-square"></i>
                  </button> */}
                  {/* <Link to={`/change-request-detail-view/${item?.merchantId}`} state={item}> */}
                  <Link
                    to={`/change-request-detail-view/${item?._id}`}
                    state={item}
                  >
                    <button className="me-2 btn-icon btn-icon-only btn btn-outline-primary">
                      <i class="fa fa-info-circle" aria-hidden="true"></i>
                    </button>
                  </Link>
                </div>
              ),
            };
          })
        : [],
  };

  return (
    <Table
      striped
      tableTitle="All Merchant Details Change Requests"
      data={tableData}
    />
  );
};

export default ManageDetailsChangeRequests;

import { MDBDataTable } from "mdbreact";
import { useEffect, useState } from "react";
import Layout from "../../../Layout";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getAccounts } from "../../../redux/adminSlice/merchantAccountSlice";
import { JSON_API } from "../../../services/api/jsonApiCallWithInterceptor";
import { toast } from "react-toastify";
import Modal from "../../../Component/Modal/Modal";

const INITIAL = {
  firstName: "",
  lastName: "",
  email: "",
  designation: "subMerchant",
  position: "",
  mobile: "",
  acessLogin: "",
  emailNotification: [],
};
const INITIAL_CHECKBOX = {
  productQuality: false,
  bookings: false,
  customerQuestions: false,
  accounting: false,
  reviews: false,
};

const validator = ({ firstName, lastName, mobile, password }) => {
  if (firstName?.trim().length < 2) {
    toast.error("Length of firstname cannot be less than 2 characters");
    return false;
  }

  if (lastName?.trim().length < 2) {
    toast.error("Length of lastname cannot be less than 2 characters");
    return false;
  }
  if (password?.length < 9) {
    toast.error("Length of password cannot be less than 9 characters");
    return false;
  }
  if (mobile?.length < 6) {
    toast.error("Length of mobile number cannot be less than 6 characters");
    return false;
  }

  return true;
};
const AccountManagement = () => {
  const dispatch = useDispatch();
  const { subMerchants } = useSelector((state) => state.merchantAccountSlice);
  const [showModal, setShowModal] = useState(false);
  const [modalToggleView, setModalToggleView] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [subMerchant, setSubMerchant] = useState({ ...INITIAL });
  const [enableRadio, setEnableRadio] = useState(false);
  const [enableCheckboxes, setEnableCheckboxes] = useState(false);
  const [checkboxVal, setCheckboxDefaultVal] = useState({
    ...INITIAL_CHECKBOX,
  });

  // ! Helper Fn
  const subMerchantNotif = subMerchants?.map((item) => item?.emailNotification);
  const check = (param, idx) => {
    if (
      subMerchantNotif?.[idx]?.some(
        (item) => item?.title === param && item?.status === true
      )
    ) {
      return (
        <div>
          <i className="fa-solid fa-check" style={{ color: "#46b616" }} />
        </div>
      );
    } else
      return (
        <div>
          <i className="fa-solid fa-xmark" style={{ color: "#cf2507" }} />
        </div>
      );
  };

  // ! Delete Handler
  const deleteHandler = async (id) => {
    if (!window.confirm("Are you sure ? ")) return;
    try {
      let response = await JSON_API["deleteSubMerchant"]({ id });

      console.log("RESPONSE", response);
      if (response?.isSuccess) {
        dispatch(getAccounts());
        console.log("success", response);
        toast.success(response?.data?.message || "Action successfull");
      } else {
        console.log("error", response);
        toast.error(response?.error?.message);
      }
    } catch (error) {
      toast.error(error?.message);
    }
  };

  // ! Submit Handler
  const submitHandler = async (e) => {
    e.preventDefault();
    if (isLoading) return;
    const validation = validator(subMerchant);
    if (!validation) return;

    try {
      setIsLoading(true);
      let response = await JSON_API["editSubMerchant"]({ ...subMerchant });

      console.log("RESPONSE", response);
      if (response?.isSuccess) {
        console.log("success", response);
        toast.success(response?.data?.message || "Action successfull");
        setIsLoading(false);
        dispatch(getAccounts());
        setShowModal(false);
        setModalToggleView(null);
      } else {
        console.log("error", response);
        setIsLoading(false);
        toast.error(response?.error?.message);
      }
    } catch (error) {
      setIsLoading(false);
      console.log("ERROR IN CREATING SUB MERCHANT", error);
      toast.error(error?.message);
    }
  };

  // ! Register change
  const changeHandler = (e) => {
    // console.log('e', e.target.value);

    setSubMerchant((oldState) => {
      oldState[e.target.name] = e.target.value;
      return { ...oldState };
    });
  };

  // ~ ChangeHandler for email notifications
  const notificationHandler = (e) => {
    setCheckboxDefaultVal((oldState) => {
      oldState[e.target.name] = e.target.checked;
      return { ...oldState };
    });

    if (e.target.checked) {
      setSubMerchant((prev) => {
        return {
          ...prev,
          emailNotification: [
            ...prev.emailNotification,
            {
              title: e.target.name,
              status: e.target.checked,
              desc: e.target.getAttribute("data-desc"),
            },
          ],
        };
      });
      return;
    }

    if (!e.target.checked) {
      console.log(e.target.checked);
      setSubMerchant((oldState) => {
        let update = [...oldState.emailNotification].filter(
          (item) => item.title !== e.target.name
        );
        oldState.emailNotification = update;
        return { ...oldState };
      });
    }
  };

  console.log("subMerchants", subMerchants);

  // ! Edit Handler
  const editHandler = (item) => {
    console.log(item, item.emailNotification);
    setModalToggleView(0);
    setShowModal(true);
    setSubMerchant({
      id: item._id,
      firstName: item.firstName,
      lastName: item.lastName,
      email: item.email,
      designation: item.designation,
      position: item.position,
      mobile: item.mobile,
      acessLogin: item.acessLogin,
      emailNotification: item.emailNotification,
    });
    setEnableRadio(() =>
      item.acessLogin === "no" ||
      item.acessLogin === undefined ||
      item.acessLogin === ""
        ? false
        : true
    );
    setEnableCheckboxes(
      () =>
        item.emailNotification?.some((item) => item?.status === true) || false
    );

    setCheckboxDefaultVal((prev) => {
      let update = {};
      item.emailNotification?.forEach(
        (item) => (update[item?.title] = item?.status || false)
      );
      return {
        ...prev,
        ...update,
      };
    });
  };

  const tableData = {
    columns: [
      {
        label: "SL",
        field: "sl",
        sort: "asc",
        width: 10,
      },
      {
        label: "Name",
        field: "name",
        sort: "asc",
        width: 50,
        height: 100,
      },
      {
        label: "Email Id",
        field: "email",
        sort: "asc",
        width: 50,
      },
      {
        label: "Login Access & account type",
        field: "login",
        sort: "asc",
        width: 10,
      },

      {
        label: "Position",
        field: "position",
        sort: "asc",
        width: 20,
      },
      {
        label: "Email Notification (Customer questions)",
        field: "customerQuestions",
        sort: "asc",
        width: 10,
      },
      {
        label: "Email Notification (Bookings)",
        field: "bookings",
        sort: "asc",
        width: 10,
      },
      {
        label: "Email Notification (Product quality)",
        field: "productQuality",
        sort: "asc",
        width: 10,
      },
      {
        label: "Email Notification (Accounting)",
        field: "accounting",
        sort: "asc",
        width: 10,
      },
      {
        label: "Email Notification (Reviews)",
        field: "reviews",
        sort: "asc",
        width: 10,
      },

      {
        label: "Actions",
        field: "actions",
        sort: "asc",
        width: 10,
      },
    ],
    rows:
      subMerchants?.length > 0
        ? subMerchants.map((item, idx) => {
            return {
              sl: idx + 1,
              name: <div>{`${item?.firstName} ${item?.lastName}`}</div>,
              email: <div className="widget-content p-0">{item?.email}</div>,
              login: (
                <div>
                  {item.acessLogin === "no" ||
                  item.acessLogin === undefined ||
                  item.acessLogin === "" ? (
                    "No"
                  ) : (
                    <>
                      <strong>Yes</strong>
                      <br />
                      <span>
                        {item.acessLogin === "marchentAdminstrative"
                          ? "Marchent Adminstrative"
                          : item.acessLogin[0].toUpperCase() +
                            item.acessLogin.slice(1)}
                      </span>
                    </>
                  )}
                </div>
              ),
              position: <div>{item?.position}</div>,
              customerQuestions: check("customerQuestions", idx),
              bookings: check("bookings", idx),
              productQuality: check("productQuality", idx),
              accounting: check("accounting", idx),
              reviews: check("reviews", idx),
              actions: (
                <div className="dropdown vaskar">
                  <button
                    className="btn dropdown-toggle"
                    type="button"
                    id="dropeditButton"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                  >
                    <i class="metismenu-icon fa-solid fa-ellipsis-vertical"></i>
                  </button>
                  <div
                    className="dropdown-menu"
                    aria-labelledby="dropeditButton"
                  >
                    <Link
                      className="dropdown-item"
                      onClick={(e) => {
                        e.preventDefault();
                        editHandler(item);
                      }}
                    >
                      <i class="fa-solid fa-pencil"></i>
                      &nbsp;Edit
                    </Link>
                    {item.designation !== "marchentAdminstrative" && (
                      <Link
                        className="dropdown-item"
                        onClick={() => deleteHandler(item._id)}
                      >
                        <i class="fa-regular fa-trash-can"></i>&nbsp;Delete
                      </Link>
                    )}
                  </div>
                </div>
              ),
            };
          })
        : [],
  };
  useEffect(() => {
    dispatch(getAccounts());
  }, [dispatch]);

  const renderModalView = (index = 0) => {
    switch (index) {
      case 0:
        return (
          <div className="card p-4">
            <h4 className="text-primary pb-4">Edit Profile</h4>
            <form autoComplete="off" onSubmit={submitHandler}>
              <div className="row">
                <div className="form-group col-md-6 col-12">
                  <label
                    className="font-weight-bold"
                    htmlFor="exampleInputEmail1"
                  >
                    First name
                  </label>
                  <input
                    type="text"
                    name="firstName"
                    className="form-control"
                    id="exampleInputEmail12"
                    aria-describedby="emailHelp"
                    autoComplete="off"
                    onChange={changeHandler}
                    value={subMerchant?.firstName}
                    required={true}
                  />
                </div>
                <div className="form-group col-md-6 col-12">
                  <label
                    className="font-weight-bold"
                    htmlFor="exampleInputPassword1"
                  >
                    Last name
                  </label>
                  <input
                    type="text"
                    name="lastName"
                    className="form-control"
                    id="exampleInputPassword14"
                    autoComplete="off"
                    onChange={changeHandler}
                    value={subMerchant?.lastName}
                    required={true}
                  />
                </div>
                <div className="form-group col-md-6 col-12">
                  <label
                    className="font-weight-bold"
                    htmlFor="exampleInputPassword1"
                  >
                    Email ID
                  </label>
                  <input
                    type="email"
                    name="email"
                    className="form-control"
                    id="exampleInputPassword16"
                    autoComplete="off"
                    onChange={changeHandler}
                    value={subMerchant?.email}
                    required={true}
                  />
                </div>

                {subMerchant.designation !== "marchentAdminstrative" && (
                  <div className="form-group col-md-6 col-12">
                    <label
                      className="font-weight-bold"
                      htmlFor="exampleInputPasswor"
                    >
                      Position
                    </label>
                    <input
                      type="text"
                      name="position"
                      className="form-control"
                      id="exampleInputPasswor"
                      autoComplete="off"
                      onChange={changeHandler}
                      value={subMerchant?.position}
                      required={true}
                    />
                  </div>
                )}
                <div className="form-group col-md-6 col-12">
                  <label className="font-weight-bold" htmlFor="mobile">
                    Mobile Number
                  </label>
                  <input
                    type="number"
                    name="mobile"
                    className="form-control"
                    id="mobile"
                    autoComplete="off"
                    onChange={changeHandler}
                    value={subMerchant?.mobile}
                    required={true}
                  />
                </div>

                <div
                  className="form-check form-switch mb-2 "
                  style={{ paddingLeft: "13px", display: "flex", gap: "5px" }}
                >
                  <label
                    className="form-check-label font-weight-bold"
                    htmlFor="flexSwitchCheckDefault1"
                  >
                    Login Access
                  </label>
                  <input
                    onClick={(e) => {
                      setEnableRadio(e.target.checked);
                      setSubMerchant((prev) => {
                        return {
                          ...prev,
                          acessLogin: "no",
                        };
                      });
                    }}
                    type="checkbox"
                    role="switch"
                    id="flexSwitchCheckDefault1"
                    checked={enableRadio}
                  />
                </div>
                {enableRadio && (
                  <div className="mb-3 fade-in">
                    <div
                      style={{
                        display: "flex",
                        gap: "5px",
                        alignItems: "center",
                      }}
                    >
                      <input
                        style={{
                          display: "flex",
                          alignSelf: "self-start",
                          marginTop: "4px",
                        }}
                        onChange={changeHandler}
                        type="radio"
                        id="marchentAdminstrative"
                        name="acessLogin"
                        checked={
                          subMerchant?.acessLogin === "marchentAdminstrative"
                        }
                        value="marchentAdminstrative"
                      />
                      <label className="mb-0" htmlFor="marchentAdminstrative">
                        <b>Merchant Adminstrative</b>
                        <br />
                        <span>Has full access Can invite users and edit</span>
                      </label>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        gap: "5px",
                        alignItems: "center",
                      }}
                    >
                      <input
                        style={{
                          display: "flex",
                          alignSelf: "self-start",
                          marginTop: "4px",
                        }}
                        onChange={changeHandler}
                        type="radio"
                        id="finance"
                        name="acessLogin"
                        checked={subMerchant?.acessLogin === "finance"}
                        value="finance"
                      />
                      <label className="mb-0" htmlFor="finance">
                        <b>Finance</b>
                        <br />
                        <span>Can access financial info only</span>
                      </label>
                    </div>
                    <div style={{ display: "flex", gap: "5px" }}>
                      <input
                        style={{
                          display: "flex",
                          alignSelf: "self-start",
                          marginTop: "4px",
                        }}
                        onChange={changeHandler}
                        type="radio"
                        id="operations"
                        name="acessLogin"
                        checked={subMerchant?.acessLogin === "operations"}
                        value="operations"
                      />
                      <label className="mb-0" htmlFor="operations">
                        <b>Operations</b>
                        <br />
                        <span>Can create and manage products</span>
                        <br />
                        <span>Can request changes to products</span>
                        <br />
                        <span>Can view, approve, and cancel bookings</span>
                        <br />
                        <span>Cannot access financial info</span>
                      </label>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        gap: "5px",
                        alignItems: "center",
                      }}
                    >
                      <input
                        style={{
                          display: "flex",
                          alignSelf: "self-start",
                          marginTop: "4px",
                        }}
                        onChange={changeHandler}
                        type="radio"
                        id="guides"
                        name="acessLogin"
                        value="guides"
                        checked={subMerchant?.acessLogin === "guides"}
                      />
                      <label className="mb-0" htmlFor="guides">
                        <b>Guides</b>
                        <br />
                        <span>Can view, approve, and cancel bookings</span>
                        <br />
                        <span>Cannot access financial info</span>
                      </label>
                    </div>
                  </div>
                )}
                <div
                  className="form-check form-switch myFormChk mb-2"
                  style={{
                    paddingLeft: "13px",
                    display: "flex",
                    gap: "5px",
                    alignItems: "center",
                  }}
                >
                  <label
                    className="form-check-label font-weight-bold"
                    style={{ display: "block" }}
                    htmlFor="flexSwitchCheckDefault"
                  >
                    Receive email notifications
                  </label>
                  <input
                    onChange={(e) => {
                      setEnableCheckboxes(e.target.checked);
                      setSubMerchant((oldState) => {
                        oldState.emailNotification = [];
                        return { ...oldState };
                      });
                      setCheckboxDefaultVal({ ...INITIAL_CHECKBOX });
                    }}
                    className="f"
                    style={{ display: "block" }}
                    type="checkbox"
                    role="switch"
                    id="flexSwitchCheckDefault"
                    checked={enableCheckboxes}
                  />
                </div>
                {enableCheckboxes && (
                  <div className="fade-in">
                    <div
                      style={{
                        display: "flex",
                        gap: "5px",
                        alignItems: "center",
                      }}
                    >
                      <input
                        style={{
                          display: "flex",
                          alignSelf: "self-start",
                          marginTop: "4px",
                        }}
                        type="checkbox"
                        id="customerQuestions"
                        name="customerQuestions"
                        data-desc="Receive emails from customers or about customer questions"
                        onChange={notificationHandler}
                        checked={checkboxVal.customerQuestions}
                      />
                      <label
                        htmlFor="customerQuestions"
                        className="mb-0"
                        style={{ display: "flex", flexDirection: "column" }}
                      >
                        <b>Customer Questions </b>
                        Receive emails from customers or about customer
                        questions
                      </label>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        gap: "5px",
                        alignItems: "center",
                      }}
                    >
                      <input
                        style={{
                          display: "flex",
                          alignSelf: "self-start",
                          marginTop: "4px",
                        }}
                        type="checkbox"
                        id="bookings"
                        name="bookings"
                        data-desc="Receive emails such as new bookings, confirmations pending, and cancellations"
                        onChange={notificationHandler}
                        checked={checkboxVal.bookings}
                      />
                      <label
                        className="mb-0"
                        htmlFor="bookings"
                        style={{ display: "flex", flexDirection: "column" }}
                      >
                        <b>Bookings </b>
                        Receive emails such as new bookings, confirmations
                        pending, and cancellations
                      </label>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        gap: "5px",
                        alignItems: "center",
                      }}
                    >
                      <input
                        style={{
                          display: "flex",
                          alignSelf: "self-start",
                          marginTop: "4px",
                        }}
                        type="checkbox"
                        id="productQuality"
                        name="productQuality"
                        data-desc="Receive emails about insights and optimization, plus quality checks for new products and edits"
                        onChange={notificationHandler}
                        checked={checkboxVal.productQuality}
                      />
                      <label
                        className="mb-0"
                        htmlFor="productQuality"
                        style={{ display: "flex", flexDirection: "column" }}
                      >
                        <b>Product Quality </b>
                        Receive emails about insights and optimization, plus
                        quality checks for new products and edits
                      </label>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        gap: "5px",
                        alignItems: "center",
                      }}
                    >
                      <input
                        style={{
                          display: "flex",
                          alignSelf: "self-start",
                          marginTop: "4px",
                        }}
                        type="checkbox"
                        id="accounting"
                        name="accounting"
                        data-desc="Receive emails about payments and billing"
                        onChange={notificationHandler}
                        checked={checkboxVal.accounting}
                      />
                      <label
                        className="mb-0"
                        htmlFor="accounting"
                        style={{ display: "flex", flexDirection: "column" }}
                      >
                        <b>Accounting </b>
                        Receive emails about payments and billing
                      </label>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        gap: "5px",
                        alignItems: "center",
                      }}
                    >
                      <input
                        style={{
                          display: "flex",
                          alignSelf: "self-start",
                          marginTop: "4px",
                        }}
                        type="checkbox"
                        id="reviews"
                        name="reviews"
                        data-desc="Receive emails about payments and billing"
                        onChange={notificationHandler}
                        checked={checkboxVal.reviews}
                      />
                      <label
                        className="mb-0"
                        htmlFor="reviews"
                        style={{ display: "flex", flexDirection: "column" }}
                      >
                        <b>Reviews </b>
                        Receive emails about customer reviews
                      </label>
                    </div>
                  </div>
                )}
              </div>
              <div style={{ display: "flex", flexDirection: "row-reverse" }}>
                <button className="btn btn-primary btn-lg font-weight-bold mt-4">
                  SAVE CHANGES
                </button>
              </div>
            </form>
          </div>
        );
      default:
        return null;
    }
  };

  return (
    <div className="main-card mb-3 card p-4" style={{ overflow: "scroll" }}>
      <h4 className="text-primary pb-4">Account Management</h4>
      <MDBDataTable striped data={tableData} />
      {showModal && (
        <Modal
          closeModal={setShowModal}
          renderModalView={renderModalView}
          modalToggleView={modalToggleView}
        />
      )}
    </div>
  );
};

export default AccountManagement;

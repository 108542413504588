import { useState } from 'react';
import { fileUpload } from '../services/api/apiHelpers';
import { toast } from 'react-toastify';

const useImgUploader = config => {
  const [Img, setImg] = useState('');
  const [imgLoading, setImgLoading] = useState(false);
  const {
    url,
    propertyName,
    deleteBtn = false,
    errorText,
    height,
    width,
    borderRadius,
    otherStyles = {},
    loadingText,
    altText,
  } = config;

  const resetter = () => {
    setImg('');
    setImgLoading(false);
  };

  const imageHandler = async e => {
    if (e.target.files.length === 0) return;
    if (imgLoading) return;

    const localURL = Array.from(e.target.files)[0];
    setImg(URL.createObjectURL(localURL));

    const DATA = new FormData();
    DATA.append('image', e.target.files[0]);

    try {
      setImgLoading(true);
      let imgUpRes = await fileUpload(url, 'POST', DATA);
      if (imgUpRes?.status) {
        setImg(imgUpRes[propertyName] || imgUpRes?.url);
      } else {
        toast.error(errorText || 'Something went wrong while uploading image! Please try after some time.');
      }
    } catch (error) {
      toast.error(errorText || 'Something went wrong while uploading image! Please try after some time.');
    }
    setImgLoading(false);
  };

  const imgComp = (
    <>
      <p className="text-primary mt-2">{imgLoading && (loadingText || 'Uploading please wait...')}</p>
      {Img !== '' && (
        <div>
          <img
            alt={altText || 'Uploaded Img'}
            style={{
              height: height || '70px',
              width: width || '120px',
              borderRadius: borderRadius || '3px',
              ...otherStyles,
            }}
            src={Img}></img>
          {deleteBtn && <i className="fa-solid fa-xmark" style={{ color: '#c6151e' }} />}
        </div>
      )}
    </>
  );

  return {
    Img,
    setImg,
    imgLoading,
    setImgLoading,
    imageHandler,
    imgComp,
    resetter,
  };
};
export default useImgUploader;

import { useEffect, useState } from "react";
import { requestData } from "../services/api/apiHelpers";

function useGet(URL = "") {
  const [data, setData] = useState([]);

  useEffect(() => {
    (async () => {
      let result = await requestData(URL, "GET");
      if (result && result.status && result.data) {
        setData(result.data);
      }
    })();
  }, []);
  return data;
}

export default useGet;

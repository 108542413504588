// Hooks
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

// Components
import Layout from "../../../Layout";
import { Formik } from "formik";
import Table from "../../../Component/Table";

// Helpers
import * as Yup from "yup";
import { toast } from "react-toastify";

// Utils
import { apiCallSuccess, apiCallError } from "../../../utils/common-utils";

// API functions
import { JSON_API } from "../../../services/api/jsonApiCallWithInterceptor";

// Redux actions
import { getAllLegalNotices } from "../../../redux/adminSlice/legalNoticeSlice";
import { useNavigate } from "react-router-dom";

let initialValues = {
  managmentPersonName: "",
  contactInformation: "",
  comapnyRegistration: "",
  VATRegistrationNo: "",
  address: "",
  id: "",
};
const ManageLegalNotice = () => {
  // export default function AddLegalNotice() {

  
  const dispatch = useDispatch();
  const [key, setkey] = useState(Math.random());
  const [error, setError] = useState("");
  const [modal, setModal] = useState(false);
  const [modalToggleView, setmodalToggleView] = useState(null);
  const [itemIndex, setitemIndex] = useState(0);
  const [editObject, seteditObject] = useState(initialValues);
  const [deleteId, setdeleteId] = useState("");
  const navigate = useNavigate();

  // Component did mount
  useEffect(() => {
    window.scrollTo(0, 0);
    dispatch(getAllLegalNotices());
  }, [dispatch]);

  // Redux stores
  const { legal_notices } = useSelector((state) => state.legalNoticeSlice);

  const openModal = () => {
    setModal(true);
  };

  const legalNoticeSchema = Yup.object().shape({
    managmentPersonName: Yup.string().required(
      "Contact person name is required."
    ),
    contactInformation: Yup.string().test(
      "len",
      "Please enter a 10 digit mobile number",
      (val) => val?.length === 10
    ),
    comapnyRegistration: Yup.string().required(
      "Company registration number is required."
    ),
    VATRegistrationNo: Yup.string().required(
      "VAT registration number is required."
    ),
    address: Yup.string().required("Company address is required."),
  });

  const submitLegalNotice = async (values, resetFormFunc) => {
    // console.log(values);
    let bodyData = {
      managmentPersonName: values?.managmentPersonName,
      contactInformation: values?.contactInformation,
      comapnyRegistration: values?.comapnyRegistration,
      VATRegistrationNo: values?.VATRegistrationNo,
      address: values?.address,
    };

    try {
      let res = await JSON_API["adminAddLegalNotice"](bodyData);

      if (res.isSuccess) {
        dispatch(getAllLegalNotices());
        resetFormFunc(initialValues);
        apiCallSuccess(res?.data);
      } else {
        apiCallError(res?.error?.message, setError);
      }
    } catch (err) {
      apiCallError(err.message, setError);
    }
  };

  const legalNoticeEditSchema = Yup.object().shape({
    managmentPersonName: Yup.string().required(
      "Contact person name is required."
    ),
    contactInformation: Yup.string().test(
      "len",
      "Please enter a 10 digit mobile number",
      (val) => val?.length === 10
    ),
    comapnyRegistration: Yup.string().required(
      "Company registration number is required."
    ),
    VATRegistrationNo: Yup.string().required(
      "VAT registration number is required."
    ),
    address: Yup.string().required("Company address is required."),
  });

  const editLegalNoticeInfo = async (values, resetFormFunc) => {
    let bodyData = { ...values };
    // console.log(bodyData);

    try {
      let res = await JSON_API["editLegalNotice"](bodyData);

      if (res.isSuccess) {
        dispatch(getAllLegalNotices());
        setModal(false);
        seteditObject(null);
        resetFormFunc(editObject);
        apiCallSuccess(res.data);
      } else {
        apiCallError(res?.error?.message, setError);
      }
    } catch (error) {
      apiCallError(error.message, setError);
    }
  };

  const deleteLegalNoticeInfo = async (id) => {
    try {
      let res = await JSON_API["adminDeleteLegalNotice"]({ id });

      if (res.isSuccess) {
        dispatch(getAllLegalNotices());
        setModal(false);
        apiCallSuccess(res.data);
      } else {
        apiCallError(res?.error?.message, setError);
      }
    } catch (error) {
      apiCallError(error.message, setError);
    }
  };

  const tableData = {
    columns: [
      {
        label: "SL",
        field: "sl",
        sort: "asc",
        width: 50,
      },
      {
        label: "Description",
        field: "name",
        sort: "asc",
        width: 100,
      },

      // {
      //   label: "Contact",
      //   field: "contact",
      //   sort: "asc",
      //   width: 100,
      // },
      // {
      //   label: "Company Regt. No.",
      //   field: "company_regt_no",
      //   sort: "asc",
      //   width: 100,
      // },
      // {
      //   label: "VAT Regt. No.",
      //   field: "vat_regt_no",
      //   sort: "asc",
      //   width: 100,
      // },
      {
        label: "Action",
        field: "action",
        sort: "asc",
        width: 150,
      },
    ],
    rows:
      legal_notices?.length > 0
        ? legal_notices.map((item, idx) => {
          
            return {
              sl: idx + 1,
              name: (
                <div className="widget-content p-0">
                  <div className="widget-content-wrapper">
                    <div
                      className="widget-content-left flex2"
                      style={{ textAlign: "center" }}
                    >
                      <div   dangerouslySetInnerHTML={{ __html: item?.legalNotice  }}>
                        {/* {item?.legalNotice} */}
                      </div>
                      {/* <div className="widget-subheading opacity-7">UI Designer</div> */}
                    </div>
                  </div>
                </div>
              ),

              // contact: (
              //   <div className="widget-content p-0">
              //     <div className="widget-content-wrapper">
              //       <div
              //         className="widget-content-left flex2"
              //         style={{ textAlign: "center" }}
              //       >
              //         <div className="widget-heading" style={{ opacity: 1 }}>
              //           {item?.contactInformation}
              //         </div>
              //         {/* <div className="widget-subheading opacity-7">UI Designer</div> */}
              //       </div>
              //     </div>
              //   </div>
              // ),
              // company_regt_no: (
              //   <div className="widget-content p-0">
              //     <div className="widget-content-wrapper">
              //       <div
              //         className="widget-content-left flex2"
              //         style={{ textAlign: "center" }}
              //       >
              //         <div className="widget-heading" style={{ opacity: 1 }}>
              //           {item?.comapnyRegistration}
              //         </div>
              //         {/* <div className="widget-subheading opacity-7">UI Designer</div> */}
              //       </div>
              //     </div>
              //   </div>
              // ),
              // vat_regt_no: (
              //   <div className="widget-content p-0">
              //     <div className="widget-content-wrapper">
              //       <div
              //         className="widget-content-left flex2"
              //         style={{ textAlign: "center" }}
              //       >
              //         <div className="widget-heading" style={{ opacity: 1 }}>
              //           {item?.VATRegistrationNo}
              //         </div>
              //         {/* <div className="widget-subheading opacity-7">UI Designer</div> */}
              //       </div>
              //     </div>
              //   </div>
              // ),
              action: (
                <div>
                  {/* <button
                    className="me-2 btn-icon btn-icon-only btn btn-outline-danger"
                    onClick={() => {
                      openModal();
                      setmodalToggleView(1);
                      setdeleteId(item._id);
                    }}>
                    <i className="fa-solid fa-trash-can" />
                  </button> */}
                  <button
                    className="me-2 btn-icon btn-icon-only btn btn-outline-success"
                    onClick={() => {
                      // openModal();
                      // setmodalToggleView(0);
                      // setitemIndex(idx);
                      // seteditObject({
                      //   managmentPersonName: item?.managmentPersonName,
                      //   contactInformation: item?.contactInformation,
                      //   comapnyRegistration: item?.comapnyRegistration,
                      //   VATRegistrationNo: item?.VATRegistrationNo,
                      //   address: item?.address,
                      //   id: item?._id,
                      // });
                      // console.log("edit", editObject);
                      navigate(`/edit-legal-notice/${item._id}`);
                    }}
                  >
                    <i class="fa-regular fa-pen-to-square"></i>
                  </button>
                  {/* <button
                    className="me-2 btn-icon btn-icon-only btn btn-outline-primary"
                    onClick={() => {
                      //   openModal();
                      //   setmodalToggleView(1);
                    }}>
                    <i class="fa fa-info-circle" aria-hidden="true"></i>
                  </button> */}
                </div>
              ),
            };
          })
        : [],
  };

  const renderModalView = (index) => {
    switch (index) {
      case 0:
        return (
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">Edit Info</h5>
            </div>
            <Formik
              key={key}
              initialValues={editObject}
              validationSchema={legalNoticeEditSchema}
              validateOnChange={false}
              validateOnBlur={false}
              onSubmit={(values, { resetForm }) => {
                editLegalNoticeInfo(values, resetForm);
              }}
            >
              {({
                handleChange,
                handleSubmit,
                setFieldValue,
                values,
                errors,
              }) => (
                <>
                  <div className="modal-body">
                    <div className="row" style={{ margin: "0.1rem" }}>
                      <div className="col-md-6">
                        <div className="mb-3">
                          <label
                            htmlFor="managmentPersonName"
                            className="form-label"
                          >
                            Full name of the person
                          </label>
                          <input
                            name="managmentPersonName"
                            id="managmentPersonName"
                            placeholder="For example, Walter Ray Smith"
                            type="text"
                            className="form-control"
                            autoComplete="false"
                            value={values.managmentPersonName}
                            onChange={(e) =>
                              setFieldValue(
                                "managmentPersonName",
                                e.target.value
                              )
                            }
                          />
                        </div>
                        {errors.managmentPersonName && (
                          <span className="errorMessageText text-danger">
                            {errors.managmentPersonName}
                          </span>
                        )}
                      </div>
                      <div className="col-md-6">
                        <div className="mb-3">
                          <label
                            htmlFor="contactInformation"
                            className="form-label"
                          >
                            Contact number
                          </label>
                          <input
                            name="contactInformation"
                            id="contactInformation"
                            placeholder="7866345235 or 03325512397"
                            type="text"
                            className="form-control"
                            autoComplete="false"
                            value={values.contactInformation}
                            onChange={(e) =>
                              setFieldValue(
                                "contactInformation",
                                e.target.value
                              )
                            }
                          />
                        </div>
                        {errors.contactInformation && (
                          <span className="errorMessageText text-danger">
                            {errors.contactInformation}
                          </span>
                        )}
                      </div>
                      <div className="col-md-6">
                        <div className="mb-3">
                          <label
                            htmlFor="comapnyRegistration"
                            className="form-label"
                          >
                            Company Regt.No.
                          </label>
                          <input
                            name="comapnyRegistration"
                            id="comapnyRegistration"
                            // placeholder="E.g.,waltersmith@ymail.com"
                            type="text"
                            className="form-control"
                            autoComplete="false"
                            value={values.comapnyRegistration}
                            onChange={(e) =>
                              setFieldValue(
                                "comapnyRegistration",
                                e.target.value
                              )
                            }
                          />
                        </div>
                        {errors.comapnyRegistration && (
                          <span className="errorMessageText text-danger">
                            {errors.comapnyRegistration}
                          </span>
                        )}
                      </div>
                      <div className="col-md-6">
                        <div className="mb-3">
                          <label
                            htmlFor="VATRegistrationNo"
                            className="form-label"
                          >
                            VAT Registration No.
                          </label>
                          <input
                            name="VATRegistrationNo"
                            id="VATRegistrationNo"
                            type="text"
                            className="form-control"
                            autoComplete="false"
                            value={values.VATRegistrationNo}
                            onChange={(e) =>
                              setFieldValue("VATRegistrationNo", e.target.value)
                            }
                          />
                        </div>
                        {errors.VATRegistrationNo && (
                          <span className="errorMessageText text-danger">
                            {errors.VATRegistrationNo}
                          </span>
                        )}
                      </div>
                    </div>

                    <div className="col-md-12">
                      <div className="mb-3">
                        <label htmlFor="address" className="form-label">
                          Company Address
                        </label>
                        <textarea
                          name="address"
                          id="address"
                          className="form-control"
                          autoComplete="false"
                          value={values.address}
                          onChange={(e) =>
                            setFieldValue("address", e.target.value)
                          }
                        />
                      </div>
                      {errors.address && (
                        <span className="errorMessageText text-danger">
                          {errors.address}
                        </span>
                      )}
                    </div>
                  </div>
                  <div className="modal-footer">
                    <button
                      type="button"
                      className="btn btn-link"
                      onClick={() => {
                        setModal(false);
                        // seteditObject(null);
                      }}
                    >
                      Cancel
                    </button>
                    <button
                      type="button"
                      className="btn btn-primary"
                      onClick={(e) => handleSubmit()}
                    >
                      Edit
                    </button>
                  </div>
                </>
              )}
            </Formik>
          </div>
        );
      case 1:
        return (
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">Delete Info</h5>
            </div>
            <div className="modal-body">
              <div className="deleteModalContainer p-5">
                <i className="ri-close-circle-line deleteIcon"></i>
                <h3>Are You Sure?</h3>
                <span>Do You Want To Delete This Information?</span>
                <div className="deleteModalNote">
                  <span>*Note : This data will be permanently deleted</span>
                </div>
              </div>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-link"
                onClick={() => {
                  setModal(false);
                }}
              >
                Cancel
              </button>
              <button
                type="button"
                className="btn btn-primary"
                onClick={() => {
                  deleteLegalNoticeInfo(deleteId);
                }}
              >
                Delete
              </button>
            </div>
          </div>
        );
      default:
        return null;
    }
  };

  return (
    <>
      {/* <Form btntxt="Add" city={false} textarea={false} radio={false} inputFile={false} check={false} /> */}
      {/* <Formik
                key={key}
                initialValues={initialValues}
                validationSchema={legalNoticeSchema}
                onSubmit={(values, { resetForm }) => {
                  submitLegalNotice(values, resetForm);
                }}
              >
                {({ handleChange, handleSubmit, setFieldValue, values, errors }) => (
                  <div
                    component="div"
                    className="TabsAnimation appear-done enter-done"
                  >
                    <div className="main-card mb-3 card">
                      <div className="card-body">
                        <div className="card-title">Add Details</div>
                        <div className="row" style={{ margin: "0.1rem" }}>
                          <div className="col-md-6">
                            <div className="mb-3">
                              <label
                                htmlFor="managmentPersonName"
                                className="form-label"
                              >
                                Full name of the person
                              </label>
                              <input
                                name="managmentPersonName"
                                id="managmentPersonName"
                                placeholder="For example, Walter Ray Smith"
                                type="text"
                                className="form-control"
                                autoComplete="false"
                                value={values.managmentPersonName}
                                onChange={(e) =>
                                  setFieldValue("managmentPersonName", e.target.value)
                                }
                              />
                            </div>
                            {errors.managmentPersonName && (
                              <span className="errorMessageText text-danger">
                                {errors.managmentPersonName}
                              </span>
                            )}
                          </div>
                          <div className="col-md-6">
                            <div className="mb-3">
                              <label
                                htmlFor="contactInformation"
                                className="form-label"
                              >
                                Contact number
                              </label>
                              <input
                                name="contactInformation"
                                id="contactInformation"
                                placeholder="7866345235 or 03325512397"
                                type="text"
                                className="form-control"
                                autoComplete="false"
                                value={values.contactInformation}
                                onChange={(e) =>
                                  setFieldValue("contactInformation", e.target.value)
                                }
                              />
                            </div>
                            {errors.contactInformation && (
                              <span className="errorMessageText text-danger">
                                {errors.contactInformation}
                              </span>
                            )}
                          </div>
                          <div className="col-md-6">
                            <div className="mb-3">
                              <label
                                htmlFor="comapnyRegistration"
                                className="form-label"
                              >
                                Company Regt.No.
                              </label>
                              <input
                                name="comapnyRegistration"
                                id="comapnyRegistration"
                                type="text"
                                className="form-control"
                                autoComplete="false"
                                value={values.comapnyRegistration}
                                onChange={(e) =>
                                  setFieldValue("comapnyRegistration", e.target.value)
                                }
                              />
                            </div>
                            {errors.comapnyRegistration && (
                              <span className="errorMessageText text-danger">
                                {errors.comapnyRegistration}
                              </span>
                            )}
                          </div>
                          <div className="col-md-6">
                            <div className="mb-3">
                              <label
                                htmlFor="VATRegistrationNo"
                                className="form-label"
                              >
                                VAT Registration No.
                              </label>
                              <input
                                name="VATRegistrationNo"
                                id="VATRegistrationNo"
                                type="text"
                                className="form-control"
                                autoComplete="false"
                                value={values.VATRegistrationNo}
                                onChange={(e) =>
                                  setFieldValue("VATRegistrationNo", e.target.value)
                                }
                              />
                            </div>
                            {errors.VATRegistrationNo && (
                              <span className="errorMessageText text-danger">
                                {errors.VATRegistrationNo}
                              </span>
                            )}
                          </div>
                        </div>
      
                        <div className="col-md-12">
                          <div className="mb-3">
                            <label htmlFor="address" className="form-label">
                              Company Address
                            </label>
                            <textarea
                              name="address"
                              id="address"
                              className="form-control"
                              autoComplete="false"
                              value={values.address}
                              onChange={(e) =>
                                setFieldValue("address", e.target.value)
                              }
                            />
                          </div>
                          {errors.address && (
                            <span className="errorMessageText text-danger">
                              {errors.address}
                            </span>
                          )}
                        </div>
      
                        <button
                          className="mt-2 btn btn-primary ml-auto d-table"
                          type="submit"
                          onClick={(e) => handleSubmit()}
                        >
                          Submit
                        </button>
                      </div>
                    </div>
                  </div>
                )}
              </Formik> */}

      <Table
        striped
        tableTitle={"All Legal Notice Receivers"}
        data={tableData}
        modal={modal}
        setModal={setModal}
        modalToggleView={modalToggleView}
        renderModalView={renderModalView}
      />
    </>
  );
  //   }
};

export default ManageLegalNotice;

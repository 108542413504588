import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import { ApiHelperFunction } from "../../services/api/apiHelpers";

let initialState = {
  status: "idle",
  merchant_details: {},
  merchants: [],
  draft_merchants: [],
  details_change_requests: [],
  details_change_requests_new: [],
  new_merchant_requests: [],
  isLoading: false,
  isSuccess: false,
  isError: false,
};

export const getAllMerchants = createAsyncThunk(
  "admin_get_all_merchants",
  async () => {
    let response = await ApiHelperFunction({
      urlPath: "/admin/get-all-merchant-profile",
      method: "GET",
    });

    if (response.status === 200) {
      return response.data;
    } else {
      toast.error("Can't get data. Something went wrong");
    }
  }
);

export const getAllDraftMerchants = createAsyncThunk('admin_draft_merchants', async () => {
  let response = await ApiHelperFunction({
    urlPath: "/admin/viewDraftMarchents",
    method: "GET",
  });
  if (response.status === 200) {
    return response.data;
  } else {
    toast.error("Can't get data. Something went wrong");
  }
});

export const getAllDetailsChangeRequest = createAsyncThunk('admin_details_change_request', async (id) => {
  let response = await ApiHelperFunction({
    urlPath: id ? `/admin/account-update-request/${id}`: "/admin/account-update-request",
    method: "GET",
  });

  if (response.status === 200) {
    return response.data;
  } else {
    toast.error("Can't get data. Something went wrong");
  }
});

export const getAllNewMerchantRequests = createAsyncThunk('new_merchant_requests', async () => {
  let response = await ApiHelperFunction({
    urlPath: '/admin/upcoming-merchant-request',
    method: 'GET'
  });
  if (response.status === 200) {
    return response.data;
  } else {
    toast.error("Can't get data. Something went wrong");
  }
})

export const MerchantActionSlice = createSlice({
  name: "admin_merchant_operations",
  initialState,
  reducers: {
    merchantDataClearState: (state) => {
      state.status = "idle";
      state.merchant_details = {};
      state.merchants = [];
      state.draft_merchants = [];
      state.details_change_requests = [];
      state.isLoading = false;
      state.isSuccess = false;
      state.isError = false;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAllMerchants.pending, (state) => {
        state.status = "loading";
        state.isLoading = true;
      })
      .addCase(getAllMerchants.fulfilled, (state, { payload }) => {
        if(payload !== undefined){
          state.status = "success";
          state.isSuccess = true;
          state.merchants = payload.data;
        }
        else{
        }

      })
      .addCase(getAllMerchants.rejected, (state) => {
        state.status = "failed";
        state.isError = true;
        state.isSuccess = false;
      })
      .addCase(getAllDraftMerchants.pending, (state) => {
        state.status = "loading";
        state.isLoading = true;
      })
      .addCase(getAllDraftMerchants.fulfilled, (state, { payload }) => {
        state.status = "success";
        state.isSuccess = true;
        state.draft_merchants = payload?.data;
      })
      .addCase(getAllDraftMerchants.rejected, (state) => {
        state.status = "failed";
        state.isError = true;
        state.isSuccess = false;
      })
      .addCase(getAllDetailsChangeRequest.pending, (state) => {
        state.status = "loading";
        state.isLoading = true;
      })
      .addCase(getAllDetailsChangeRequest.fulfilled, (state, { payload }) => {

        if(payload !== undefined){
          state.status = "success";
          state.isSuccess = true;
          state.details_change_requests = payload?.data;
          state.details_change_requests_new = payload?.data;
        }
        else{

        }
      })
      .addCase(getAllDetailsChangeRequest.rejected, (state) => {
        state.status = "failed";
        state.isError = true;
        state.isSuccess = false;
      })
      .addCase(getAllNewMerchantRequests.pending, (state) => {
        state.status = "loading";
        state.isLoading = true;
      })
      .addCase(getAllNewMerchantRequests.fulfilled, (state, { payload }) => {
        if(payload !== undefined){
          state.status = "success";
          state.isSuccess = true;
          state.new_merchant_requests = payload.data;
        }
        

      })
      .addCase(getAllNewMerchantRequests.rejected, (state) => {
        state.status = "failed";
        state.isError = true;
        state.isSuccess = false;
      });
  },
});

export const { merchantDataClearState } = MerchantActionSlice.actions;

export default MerchantActionSlice.reducer;

// Hooks
import React, { useState, useRef, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams, useNavigate } from "react-router-dom";

// Helpers
import { toast } from "react-toastify";

// Utilities
import { apiCallError } from "../../../utils/common-utils";

// API functions
import { JSON_API } from "../../../services/api/jsonApiCallWithInterceptor";

// Redux actions
import { getAllActivities } from "../../../redux/adminSlice/activitySlice";
import { getAllMerchants } from "../../../redux/adminSlice/merchantActionsSlice";
import { getAllAssignedActivity } from "../../../redux/adminSlice/assignActivitySlice";

// Components
import Loader from "../../../Component/Loader";
import Select from "react-select";

let initialValues = {
  activityId: [],
  assignTo: "",
};

let createErrors = {
  activityId: "",
  assignTo: "",
};

function EditAssignedActivities() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const params = useParams();
  const [error, setError] = useState("");
  const [errors, seterrors] = useState(createErrors);
  const [modal, setModal] = useState(false);
  const [categoryEdit, setCategoryEdit] = useState(initialValues);
  const inputRef = useRef(null);
  const [isLoading, setisLoading] = useState(false);
  // Redux stores
  const { assigned_activities } = useSelector(
    (state) => state.adminAssignActivitySlice
  );
  const { activities } = useSelector((state) => state.adminActivityActionSlice);
  const { merchants } = useSelector((state) => state.adminMerchantActionSlice);
  const assignedActivity = assigned_activities.find(
    (item) => item?._id === params.id
  );

  // Component did mount
  useEffect(() => {
    dispatch(getAllAssignedActivity());
    dispatch(getAllActivities());
    dispatch(getAllMerchants());
    setCategoryEdit({
      ...categoryEdit,
      activityId: assignedActivity?.activity,
      assignTo: assignedActivity?.assignDetails?._id,
    });
  }, []);

  const handleEditChange = (event) => {
    event.preventDefault();
    setCategoryEdit({
      ...categoryEdit,
      [event.target.name]: event.target.value,
    });
  };

  const handleEditValidation = () => {
    const { activityId, assignTo } = categoryEdit;

    if (activityId.length === 0) {
      seterrors({
        ...createErrors,
        activityId: "Atleast 1 activity is required",
      });

      return false;
    }
    if (assignTo === "") {
      seterrors({
        ...createErrors,
        assignTo: "Activity is required",
      });

      return false;
    }

    return true;
  };

  const handleEditSubmit = async (event) => {
    event.preventDefault();
    if (handleEditValidation()) {
      try {
        setisLoading(true);
        let res = await JSON_API["adminEditAssignedActivity"]({
          id: params.id,
          ...categoryEdit,
        });

        if (res.isSuccess) {
          dispatch(getAllAssignedActivity());
          setModal(false);
          seterrors(createErrors);
          setCategoryEdit(initialValues);
          if (inputRef.current) {
            inputRef.current.value = "";
          }
          setisLoading(false);
          toast.success("Activity reassigned to merchant successfully");
          navigate("/manage-assigned-activity");
        } else {
          setisLoading(false);
          apiCallError(res?.error?.message, setError);
        }
      } catch (error) {
        setisLoading(false);
        apiCallError(error.message, setError);
      }
    }
  };

  return (
    <>
      {isLoading && <Loader />}
      <form className="" onSubmit={(e) => handleEditSubmit(e)}>
        <div component="div" className="TabsAnimation appear-done enter-done">
          <div className="main-card mb-3 card">
            <div className="card-body">
              <div className="card-title">Edit Assigned Activities</div>
              <div className="col-md-12">
                <div className="mb-3">
                  <label htmlFor="activityId" className="form-label">
                    Activity*
                  </label>
                  <Select
                    name="activityId"
                    id="activityId"
                    classNamePrefix="select"
                    ref={inputRef}
                    options={activities?.map((item, idx) => ({
                      value: item?._id,
                      label: item?.activityTitle,
                    }))}
                    value={categoryEdit?.activityId?.map((item) => {
                      let find = activities?.find(
                        (it) => it?._id === item?._id
                      );
                      return {
                        value: find?._id,
                        label: find?.activityTitle,
                      };
                    })}
                    isMulti
                    onChange={(val) => {
                      setCategoryEdit((prev) => {
                        return {
                          ...prev,
                          activityId: val.map((item) => ({
                            _id: item.value,
                            activityTitle: item.label,
                          })),
                        };
                      });
                    }}
                  />
                </div>
                {errors.activityId && (
                  <span className="errorMessageText text-danger">
                    {errors.activityId}
                  </span>
                )}
              </div>
              <div className="col-md-12">
                <div className="mb-3">
                  <label htmlFor="assignTo" className="form-label">
                    Merchants*
                  </label>
                  <select
                    name="assignTo"
                    id="assignTo"
                    className="form-control form-select"
                    value={categoryEdit.assignTo}
                    ref={inputRef}
                    onChange={(e) => handleEditChange(e)}
                  >
                    <option value="" key={0}>
                      Select
                    </option>
                    {merchants?.map((item, idx) => {
                      return (
                        <>
                          <option key={idx + 1} value={`${item?._id}`}>
                            {item?.firstName.charAt(0).toUpperCase() +
                              item?.firstName.slice(1) +
                              " " +
                              item?.lastName.charAt(0).toUpperCase() +
                              item?.lastName.slice(1)}
                          </option>
                        </>
                      );
                    })}
                  </select>
                </div>
                {errors.assignTo && (
                  <span className="errorMessageText text-danger">
                    {errors.assignTo}
                  </span>
                )}
              </div>

              <div style={{ display: "flex", justifyContent: "flex-end" }}>
                <button
                  type="button"
                  className="mt-2 btn btn-link"
                  onClick={() => {
                    navigate("/manage-assigned-activity");
                  }}
                >
                  Cancel
                </button>
                <button type="submit" className="mt-2 btn btn-primary">
                  Edit
                </button>
              </div>
            </div>
          </div>
        </div>
      </form>
    </>
  );
}

export default EditAssignedActivities;

import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';
import { ApiHelperFunction } from '../../services/api/apiHelpers';

let initialState = {
  status: 'idle',
  activity_types: [],
  activity_sites: [],
  sites_of_a_city: [],
  site_destinations: [],
  all_destination: [],
  activities: [],
  draft_activities: [],
  merchant_additions: [],
  slots_for_the_month: [],
  slots_for_a_date: [],
  isLoading: false,
  isSuccess: false,
  isError: false,
  newActivityCnt: 0,
};

export const getAllActivityTypes = createAsyncThunk('get_all_activity_types', async () => {
  let response = await ApiHelperFunction({
    urlPath: '/admin/admin-view-activity-type',
    method: 'GET',
  });

  if (response.status === 200) {
    return response.data;
  } else {
    return false;
  }
});

export const getAllActivitySites = createAsyncThunk('get_all_activity_sites', async () => {
  let response = await ApiHelperFunction({
    urlPath: '/admin/viewActivitySite',
    method: 'GET',
  });
  if (response.status === 200) {
    return response.data;
  } else {
    toast.error("Can't get data. Something went wrong");
  }
});

export const getAllSitesForACity = createAsyncThunk('get_all_sites_for_a_city', async (cityId) => {
  let response = await ApiHelperFunction({
    urlPath: `/admin/sitename-against-city/${cityId}`,
    method: 'GET',
  });
  if (response.status === 200) {
    return response.data;
  } else {
    toast.error("Can't get data. Something went wrong");
  }
});

export const getAllSiteDestinations = createAsyncThunk('get_all_site_destinations', async (site_id) => {
  let response = await ApiHelperFunction({
    urlPath: `/admin/view-destination/${site_id}`,
    method: 'GET',
  });
  if (response.status === 200) {
    return response.data;
  } else {
    toast.error("Can't get data. Something went wrong");
  }
});
export const getallDestination = createAsyncThunk('get_all_destination', async () => {
  let response = await ApiHelperFunction({
    urlPath: '/admin/view-destination-all',
    method: 'GET',
  });
  if (response.status === 200) {
    return response.data;
  } else {
    toast.error("Can't get data. Something went wrong");
  }
});
export const getAllActivities = createAsyncThunk('get_all_activities', async (params) => {

  let response = await ApiHelperFunction({
    urlPath: '/admin/viewActivityDetails/' + params,
    method: 'GET',
  });
  if (response.status === 200) {
    return response.data;
  } else {
    toast.error("Can't get data. Something went wrong");
  }
});

export const getAllDraftActivities = createAsyncThunk('get_all_draft_activities', async () => {
  let response = await ApiHelperFunction({
    urlPath: '/admin/viewDraftActivityDetails',
    method: 'GET',
  });

  if (response.status === 200) {
    return response.data;
  } else {
    toast.error(response?.error?.data?.message);
  }
})

export const getAllMerchantAdditions = createAsyncThunk('get_all_merchant_activity_additions', async () => {
  let response = await ApiHelperFunction({
    urlPath: '/admin/viewMerchantApprovalActivity',
    method: 'GET',
  });
  if (response.status === 200) {
    return response.data;
  } else {
    toast.error(response?.error?.data?.message);
  }
});

export const getAvailabilityForAMonth = createAsyncThunk(
  'get_availability_for_a_month',
  async (data = { year: '', month: '', activityId: '' }) => {
    let response = await ApiHelperFunction({
      urlPath: '/admin/view-monthwise-availability',
      method: 'POST',
      data: {
        year: data.year,
        month: data.month,
        id: data.activityId
      }
    });
    if (response.status === 200) {
      return response.data;
    } else {
      toast.error(response?.error?.data?.message);
    }
  })

export const getActivityAvailabilityForADate = createAsyncThunk(
  'get_activity_availability_for_a_date',
  async (data = { date: '', activityId: '' }) => {
    let response = await ApiHelperFunction({
      urlPath: '/admin/view-availability',
      method: 'POST',
      data: {
        tourDate: data.date,
        id: data.activityId
      },
    });
    if (response.status === 200) {
      return response.data;
    } else {
      toast.error(response?.error?.data?.message);
    }
  });

// New Product
export const newMerchantActivity = createAsyncThunk(
  'newMerchantActivity',
  async (data = { date: '', activityId: '' }) => {
    let response = await ApiHelperFunction({
      urlPath: '/admin/newMerchantActivity',
      method: 'GET'
    });

    if (response.status === 200) {
      localStorage.setItem('newActivityCnt', response?.data?.newActivityCnt);
      return response?.data?.newActivityCnt;
    } else {
      toast.error(response?.error?.data?.message);
    }
  });


export const merchantActivityChange = createAsyncThunk(
  'merchantActivityChange',
  async (data = { date: '', activityId: '' }) => {
    let response = await ApiHelperFunction({
      urlPath: '/admin/merchantActivityChange',
      method: 'GET'
    });

    localStorage.setItem('merchantActivityChangeCnt', 0);

    if (response.status === 200) {
      localStorage.setItem('merchantActivityChangeCnt', response?.data?.merchantActivityChangeCnt);
      return response?.data?.merchantActivityChangeCnt;
    } else {
      toast.error(response?.error?.data?.message);
    }
  });

export const ActivitiesSlice = createSlice({
  name: 'activity_actions',
  initialState,
  reducers: {
    activityActionsClearState: state => {
      state.status = 'idle';
      state.activity_types = [];
      state.activity_sites = [];
      state.sites_of_a_city = [];
      state.site_destinations = [];
      state.activities = [];
      state.draft_activities = [];
      state.merchant_additions = [];
      state.slots_for_the_month = [];
      state.slots_for_a_date = [];
      state.isLoading = false;
      state.isSuccess = false;
      state.isError = false;
      state.newActivityCnt = 0;
    },
    newActivityCnt: state => {
      state.newActivityCnt = 0;
    }
  },
  extraReducers: builder => {
    builder
      .addCase(getAllActivityTypes.pending, state => {
        state.status = 'loading';
        state.isLoading = true;
      })
      .addCase(getAllActivityTypes.fulfilled, (state, { payload }) => {
        state.status = 'success';
        state.isSuccess = true;

        state.activity_types = payload?.data;
      })
      .addCase(getAllActivityTypes.rejected, state => {
        state.status = 'failed';
        state.isError = true;
        state.isSuccess = false;
      })
      .addCase(getAllActivitySites.pending, state => {
        state.status = 'loading';
        state.isLoading = true;
      })
      .addCase(getAllActivitySites.fulfilled, (state, { payload }) => {
        state.status = 'success';
        state.isSuccess = true;

        state.activity_sites = payload?.data;
      })
      .addCase(getAllActivitySites.rejected, state => {
        state.status = 'failed';
        state.isError = true;
        state.isSuccess = false;
      })
      .addCase(getAllSitesForACity.pending, state => {
        state.status = 'loading';
        state.isLoading = true;
      })
      .addCase(getAllSitesForACity.fulfilled, (state, { payload }) => {
        state.status = 'success';
        state.isSuccess = true;
        state.sites_of_a_city = payload?.data;
      })
      .addCase(getAllSitesForACity.rejected, state => {
        state.status = 'failed';
        state.isError = true;
        state.isSuccess = false;
      })
      .addCase(getAllSiteDestinations.pending, state => {
        state.status = 'loading';
        state.isLoading = true;
      })
      .addCase(getAllSiteDestinations.fulfilled, (state, { payload }) => {
        state.status = 'success';
        state.isSuccess = true;

        state.site_destinations = payload?.data;
      })
      .addCase(getAllSiteDestinations.rejected, state => {
        state.status = 'failed';
        state.isError = true;
        state.isSuccess = false;
      })

      .addCase(getallDestination.pending, state => {
        state.status = 'loading';
        state.isLoading = true;
      })
      .addCase(getallDestination.fulfilled, (state, { payload }) => {
        state.status = 'success';
        state.isSuccess = true;

        state.all_destination = payload?.data;
      })
      .addCase(getallDestination.rejected, state => {
        state.status = 'failed';
        state.isError = true;
        state.isSuccess = false;
      })
      .addCase(getAllActivities.pending, state => {
        state.status = 'loading';
        state.isLoading = true;
      })
      .addCase(getAllActivities.fulfilled, (state, { payload }) => {
        state.status = 'success';
        state.isSuccess = true;

        state.activities = payload?.data;
      })
      .addCase(getAllActivities.rejected, state => {
        state.status = 'failed';
        state.isError = true;
        state.isSuccess = false;
      })
      .addCase(getAllDraftActivities.pending, state => {
        state.status = 'loading';
        state.isLoading = true;
      })
      .addCase(getAllDraftActivities.fulfilled, (state, { payload }) => {
        state.status = 'success';
        state.isSuccess = true;

        state.draft_activities = payload?.data;
      })
      .addCase(getAllDraftActivities.rejected, state => {
        state.status = 'failed';
        state.isError = true;
        state.isSuccess = false;
      })
      .addCase(getAllMerchantAdditions.pending, state => {
        state.status = 'loading';
        state.isLoading = true;
      })
      .addCase(getAllMerchantAdditions.fulfilled, (state, { payload }) => {
        state.status = 'success';
        state.isSuccess = true;
        state.merchant_additions = payload?.data;
      })
      .addCase(getAllMerchantAdditions.rejected, state => {
        state.status = 'failed';
        state.isError = true;
        state.isSuccess = false;
      })
      .addCase(getAvailabilityForAMonth.pending, state => {
        state.status = 'loading';
        state.isLoading = true;
      })
      .addCase(getAvailabilityForAMonth.fulfilled, (state, { payload }) => {
        state.status = 'success';
        state.isSuccess = true;

        state.slots_for_the_month = payload?.data;
      })
      .addCase(getAvailabilityForAMonth.rejected, state => {
        state.status = 'failed';
        state.isError = true;
        state.isSuccess = false;
      })
      .addCase(getActivityAvailabilityForADate.pending, state => {
        state.status = 'loading';
        state.isLoading = true;
      })
      .addCase(getActivityAvailabilityForADate.fulfilled, (state, { payload }) => {
        state.status = 'success';
        state.isSuccess = true;
        state.slots_for_a_date = payload?.data;
      })
      .addCase(getActivityAvailabilityForADate.rejected, state => {
        state.status = 'failed';
        state.isError = true;
        state.isSuccess = false;
      });
  },
});

export const { activityActionsClearState, newActivityCnt } = ActivitiesSlice.actions;

export default ActivitiesSlice.reducer;

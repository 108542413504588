// Hooks
import React, { useState, useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";

// Helpers
import { toast } from "react-toastify";

// API
import { JSON_API } from "../../../services/api/jsonApiCallWithInterceptor";

// Redux actions
import { getProfileDetails } from "../../../redux/authSlice/adminProfileSlice";
import { getAllDraftActivities } from "../../../redux/adminSlice/activitySlice";

// Components
import Table from "../../../Component/Table";
import Loader from "../../../Component/Loader";

let currencies = [];
for (const [key, value] of Object.entries(
  require("../../../constants/currencies.json")
)) {
  currencies.push(value);
}

function ManageActivityDrafts() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [searchText, setsearchText] = useState("");
  const [error, setError] = useState("");
  const [isLoading, setisLoading] = useState(false);
  const [modal, setModal] = useState(false);
  const [modalToggleView, setmodalToggleView] = useState(null);
  const [deleteId, setdeleteId] = useState("");

  const { draft_activities } = useSelector(
    (state) => state.adminActivityActionSlice
  );

  useEffect(() => {
    dispatch(getProfileDetails());
    dispatch(getAllDraftActivities());
  }, []);

  const deleteActivity = async (id) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          setisLoading(true);
          let res = await JSON_API["adminDeleteActivity"]({ id: id });
          if (res.isSuccess) {
            dispatch(getAllDraftActivities());
            setModal(false);
            setisLoading(false);
            Swal.fire({
              title: "Deleted!",
              text: "Activity has been deleted.",
              icon: "success",
            });
          } else {
            setisLoading(false);
            toast.error(res?.error?.message);
          }
        } catch (error) {
          setisLoading(false);
          toast.error(error?.message);
        }
      }
    });
    return;
  };

  const filterData = () => {
    if (searchText !== "") {
      return draft_activities.filter((item) =>
        JSON.stringify(item).toLowerCase().includes(searchText.toLowerCase())
      );
    }
    return draft_activities;
  };
  const tableData = useMemo(() => {
    return {
      columns: [
        {
          label: "SL",
          field: "sl",
          sort: "asc",
          width: 50,
        },
        {
          label: "Tour Code",
          field: "activity_code",
          sort: "asc",
          width: 100,
        },
        {
          label: "Tour Name",
          field: "activity_name",
          sort: "asc",
          width: 100,
        },
        {
          label: "Action",
          field: "action",
          sort: "asc",
          width: 130,
        },
      ],
      rows:
        filterData()?.length > 0
          ? filterData().map((item, idx) => {
              return {
                sl: idx + 1,
                activity_code: (
                  <div className="widget-content p-0">
                    <div className="widget-content-wrapper">
                      <div className="widget-content-left flex2">
                        <div
                          className="zwidget-heading"
                          style={{ opaactivity: 1, textAlign: "center" }}
                        >
                          {item.productCode ? item.productCode : "no_code"}
                        </div>
                      </div>
                    </div>
                  </div>
                ),
                activity_name: (
                  <div className="widget-content p-0">
                    <div className="widget-content-wrapper">
                      <div className="widget-content-left flex2">
                        <div
                          className="zwidget-heading"
                          style={{ opaactivity: 1, textAlign: "center" }}
                        >
                          {item.activityTitle ? item.activityTitle : "no_name"}
                        </div>
                      </div>
                    </div>
                  </div>
                ),
                action: (
                  <div>
                    <button
                      className="me-2 btn-icon btn-icon-only btn btn-outline-danger"
                      onClick={() => {
                        setmodalToggleView(0);
                        deleteActivity(item?._id);
                      }}
                    >
                      <i className="fa-solid fa-trash-can" />
                    </button>
                    <button
                      className="me-2 btn-icon btn-icon-only btn btn-outline-success"
                      onClick={() => {
                        navigate(`/edit-activity-drafts/${item?._id}`);
                      }}
                    >
                      <i class="fa-regular fa-pen-to-square"></i>
                    </button>
                  </div>
                ),
              };
            })
          : [],
    };
  }, [draft_activities, searchText]);

  const renderModalView = (index) => {
    switch (index) {
      case 0:
        return (
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">Delete Draft</h5>
            </div>
            <div className="modal-body">
              <div className="deleteModalContainer p-5">
                <i className="ri-close-circle-line deleteIcon"></i>
                <h3>Are You Sure?</h3>
                <span>Do You Want To Delete This Information?</span>
                <div className="deleteModalNote">
                  <span>*Note : This data will be permanently deleted</span>
                </div>
              </div>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-link"
                onClick={() => {
                  setModal(false);
                }}
              >
                Cancel
              </button>
              <button
                type="button"
                className="btn btn-primary"
                onClick={() => {
                  deleteActivity(deleteId);
                }}
              >
                Delete
              </button>
            </div>
          </div>
        );
      default:
        return null;
    }
  };

  return (
    <>
      {isLoading && <Loader />}

      <Table
        striped
        tableTitle="Drafts"
        data={tableData}
        modal={modal}
        setModal={setModal}
        modalToggleView={modalToggleView}
        renderModalView={renderModalView}
        onSearch={(val) => setsearchText(val)}
      />
    </>
  );
}

export default ManageActivityDrafts;

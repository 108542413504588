// Hooks
import React, { useState, useRef, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

// Helpers
import { toast } from "react-toastify";

// Utilities
import {
  capitalizeFirstLetter,
  apiCallSuccess,
  apiCallError,
} from "../../../utils";

// API functions
import { fileUpload } from "../../../services/api/apiHelpers";
import { JSON_API } from "../../../services/api/jsonApiCallWithInterceptor";

// Redux actions
import { getAllDigitalInfos } from "../../../redux/adminSlice/digitalInfoSlice";

// Components
import Layout from "../../../Layout";
import Table from "../../../Component/Table";
import Loader from "../../../Component/Loader";
import { useNavigate } from "react-router-dom";

const initialValues = {
  topic: "",
  desc: "",
};

const createErrors = {
  topic: "",
  desc: "",
  //   activityTypeId: "",
};

function ManageInfo() {
  const dispatch = useDispatch();
  const [category, setsection] = useState(initialValues);
  const [error, setError] = useState("");
  const [errors, seterrors] = useState(createErrors);
  const [modal, setModal] = useState(false);
  const [modalToggleView, setmodalToggleView] = useState(null);
  const [itemIndex, setitemIndex] = useState(0);
  const [categoryEdit, setCategoryEdit] = useState(initialValues);
  const [deleteId, setdeleteId] = useState("");
  const [isLoading, setisLoading] = useState(false);
  const navigate = useNavigate();

  // Component did mount
  useEffect(() => {
    dispatch(getAllDigitalInfos());
  }, []);

  // Redux stores
  const { digital_infos } = useSelector((state) => state.digitalInfoSlice);
  console.log("digital_infos", digital_infos);

  const openModal = () => {
    setModal(true);
  };

  const handleEditChange = (event) => {
    event.preventDefault();
    // console.log(event.target.name, event.target.value);
    let input;

    if (event.target.name === "desc") {
      input = event.target.value;
    } else {
      input = capitalizeFirstLetter(event);
    }

    setCategoryEdit({
      ...categoryEdit,
      [event.target.name]: input,
    });
  };

  const handleEditValidation = () => {
    const { topic, desc } = categoryEdit;

    if (topic === "") {
      seterrors({ ...createErrors, topic: "Topic name is required" });

      return false;
    }
    if (desc === "") {
      seterrors({ ...createErrors, desc: "Topic description is required" });

      return false;
    }
    if (desc.length < 50) {
      seterrors({
        ...createErrors,
        desc: "Topic description should be atleast 50 characters.",
      });

      return false;
    }

    return true;
  };

  const handleEditSubmit = async (event) => {
    event.preventDefault();
    console.log("Edit submit", categoryEdit);

    if (handleEditValidation()) {
      try {
        let res = await JSON_API["adminEditDigitalInfo"]({
          id: digital_infos._id,
          ...categoryEdit,
        });
        setisLoading(true);

        if (res.isSuccess) {
          dispatch(getAllDigitalInfos());
          setModal(false);
          seterrors(createErrors);
          setCategoryEdit(initialValues);
          setisLoading(false);
          apiCallSuccess(res.data);
        } else {
          apiCallError(res?.error?.message, setError);
        }
      } catch (error) {
        apiCallError(error.message, setError);
      }
    }
  };

  const deleteCategory = async (id) => {
    try {
      let res = await JSON_API["adminDelteCategory"]({ id: id });
      // console.log("Res", res);
      setisLoading(true);

      if (res.isSuccess) {
        dispatch(getAllDigitalInfos());
        setModal(false);
        // ActivityApiCallSuccess(res.data);
      } else {
        // ActivityApiCallError(res?.error?.message);
      }
    } catch (error) {
      //   ActivityApiCallError(error.message);
    }
  };

  const tableData = {
    columns: [
      {
        label: "SL",
        field: "sl",
        sort: "asc",
        width: 270,
      },
      {
        label: "Topic",
        field: "topic",
        sort: "asc",
        width: 150,
      },

      {
        label: "Action",
        field: "action",
        sort: "asc",
        width: 150,
      },
    ],
    rows: [
      {
        sl: 1,
        topic: (
          <div className="widget-content p-0">
            <div className="widget-content-wrapper">
              <div className="widget-content-left flex2">
                <div
                  className="widget-heading"
                  style={{ opacity: 1, textAlign: "center" }}
                >
                  {digital_infos?.topic}
                </div>
                {/* <div className="widget-subheading opacity-7">UI Designer</div> */}
              </div>
            </div>
          </div>
        ),

        action: (
          <div>
            {/* <button
              className="me-2 btn-icon btn-icon-only btn btn-outline-danger"
              onClick={() => {
                openModal();
                setmodalToggleView(1);
                // console.log("Delete id", activity_types[idx]._id);
                setdeleteId(digital_infos._id);
              }}
            >
              <i className="fa-solid fa-trash-can" />
            </button> */}
            <button
              className="me-2 btn-icon btn-icon-only btn btn-outline-success"
              onClick={() => {
                // openModal();
                // setmodalToggleView(0);
                // setitemIndex(digital_infos._id);
                // // console.log("edit", activity_types[idx]?.name);
                // setCategoryEdit({
                //   ...categoryEdit,
                //   topic: digital_infos?.topic,
                //   desc: digital_infos?.desc,
                // });
                navigate(`/edit-digital-info`);
              }}
            >
              <i class="fa-regular fa-pen-to-square"></i>
            </button>
          </div>
        ),
      },
    ],
  };

  const renderModalView = (index) => {
    switch (index) {
      case 0:
        return (
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">Edit Info</h5>
            </div>
            <form
              className=""
              onSubmit={(e) => handleEditSubmit(e)}
              encType="multipart/form-data"
            >
              <div className="modal-body">
                <div className="col-md-12">
                  <div className="mb-3">
                    <label htmlFor="topic" className="form-label">
                      Topic
                    </label>
                    <input
                      name="topic"
                      id="topic"
                      type="text"
                      className="form-control"
                      autoComplete="false"
                      value={categoryEdit.topic}
                      onChange={(e) => handleEditChange(e)}
                    />
                  </div>
                  {errors.topic && (
                    <span className="errorMessageText text-danger">
                      {errors.topic}
                    </span>
                  )}
                </div>
                <div className="col-md-12">
                  <div className="mb-3">
                    <label htmlFor="desc" className="form-label">
                      Description
                    </label>
                    <textarea
                      name="desc"
                      id="desc"
                      className="form-control"
                      autoComplete="false"
                      value={categoryEdit.desc}
                      onChange={(e) => handleEditChange(e)}
                    />
                  </div>
                  {errors.desc && (
                    <span className="errorMessageText text-danger">
                      {errors.desc}
                    </span>
                  )}
                </div>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-link"
                  onClick={() => {
                    setModal(false);
                    setCategoryEdit(initialValues);
                  }}
                >
                  Cancel
                </button>
                <button type="submit" className="btn btn-primary">
                  Edit
                </button>
              </div>
            </form>
          </div>
        );
      case 1:
        return (
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">Delete Category</h5>
            </div>
            <div className="modal-body">
              <div className="deleteModalContainer p-5">
                <i className="ri-close-circle-line deleteIcon"></i>
                <h3>Are You Sure?</h3>
                <span>Do You Want To Delete This Information?</span>
                <div className="deleteModalNote">
                  <span>*Note : This data will be permanently deleted</span>
                </div>
              </div>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-link"
                onClick={() => {
                  setModal(false);
                }}
              >
                Cancel
              </button>
              <button
                type="button"
                className="btn btn-primary"
                onClick={() => {
                  deleteCategory(deleteId);
                }}
              >
                Delete
              </button>
            </div>
          </div>
        );
      default:
        return null;
    }
  };

  return (
    <>
      {isLoading && <Loader />}

      <Table
        striped
        tableTitle="All Digital Information Topics"
        data={tableData}
        modal={modal}
        setModal={setModal}
        modalToggleView={modalToggleView}
        renderModalView={renderModalView}
      />
    </>
  );
}

export default ManageInfo;

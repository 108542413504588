// Hooks
import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";

// Utils
import {
  capitalizeFirstLetter,
  apiCallSuccess,
  apiCallError,
} from "../../../utils";

// Helpers
import { toast } from "react-toastify";

// API
import { fileUpload } from "../../../services/api/apiHelpers";
import { JSON_API } from "../../../services/api/jsonApiCallWithInterceptor";

// Redux actions
import { getAllCareers } from "../../../redux/adminSlice/careerSlice";

import Loader from "../../../Component/Loader";

let initialValues = {
  category: "",
  subCategory: "",
  description: "",
};

let createErrors = {
  category: "",
  subCategory: "",
  description: "",
};

function AddCareers() {
  const dispatch = useDispatch();
  const fileInputRef = useRef(null);
  const [press, setpress] = useState(initialValues);
  const [Img, setImg] = useState(null);
  const [error, setError] = useState("");
  const [errors, seterrors] = useState(createErrors);
  const [isLoading, setisLoading] = useState(false);
  const [modal, setModal] = useState(false);
  const [modalToggleView, setmodalToggleView] = useState(null);
  const [pressEdit, setpressEdit] = useState(initialValues);
  const [itemIndex, setitemIndex] = useState("");
  const [deleteId, setdeleteId] = useState("");
  // Redux stores
  const { careers } = useSelector((state) => state.careerSlice);

  // Component did mount
  useEffect(() => {
    dispatch(getAllCareers());
  }, []);

  const openModal = () => {
    setModal(true);
  };

  const handleChange = (event) => {
    event.preventDefault();
    let input;

    if (event.target.name !== "description") {
      input = capitalizeFirstLetter(event);
    } else {
      input = event.target.value;
    }
    setpress({ ...press, [event.target.name]: input });
  };

  const handleValidation = () => {
    const { category, subCategory, description } = press;

    if (category === "") {
      seterrors({ ...errors, category: "Topic is required" });

      return false;
    }
    if (subCategory === "") {
      seterrors({ ...errors, subCategory: "Subtopic is required" });

      return false;
    }
    if (description === "") {
      seterrors({ ...errors, description: "Description is required" });

      return false;
    }
    return true;
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (handleValidation()) {
      if (Img === null) {
        toast.error("Picture is required");
      } else {
        if (Img.type === "image/png" || Img.type === "image/jpeg") {
          const DATA = new FormData();
          DATA.append("image", Img);
          setisLoading(true);
          let imgUpRes = await fileUpload("/admin/careerImage", "POST", DATA);
          if (imgUpRes.status) {
            try {
              let res = await JSON_API["adminAddCareer"]({
                ...press,
                image: imgUpRes.url,
              });

              if (res.isSuccess) {
                dispatch(getAllCareers());
                seterrors(createErrors);
                setImg(null);
                setpress(initialValues);
                if (fileInputRef.current) {
                  fileInputRef.current.value = "";
                }
                setisLoading(false);
                apiCallSuccess(res.data);
              } else {
                apiCallError(res?.error?.message, setError);
              }
            } catch (error) {
              apiCallError(error.message, setError);
            }
          } else {
            apiCallError(imgUpRes?.error?.message, setError);
          }
        } else {
          toast.error("Picture should be a .png or .jpg type");
        }
      }
    }
  };

  const handleEditChange = (event) => {
    event.preventDefault();
    setpressEdit({ ...pressEdit, [event.target.name]: event.target.value });
  };

  const handleEditValidation = () => {
    const { category, subCategory, description } = pressEdit;

    if (category === "") {
      seterrors({ ...errors, category: "Topic is required" });

      return false;
    }
    if (subCategory === "") {
      seterrors({ ...errors, subCategory: "Subtopic is required" });

      return false;
    }
    if (description === "") {
      seterrors({ ...errors, description: "Description is required" });

      return false;
    }
    return true;
  };

  const handleEditSubmit = async (event) => {
    event.preventDefault();
    let bodyData = { id: `${careers[itemIndex]._id}`, ...pressEdit };

    if (Img === null) {
      if (handleEditValidation()) {
        try {
          let res = await JSON_API["adminEditCareer"](bodyData);

          if (res.isSuccess) {
            dispatch(getAllCareers());
            setModal(false);
            seterrors(createErrors);
            setpressEdit(initialValues);
            apiCallSuccess(res.data);
          } else {
            apiCallError(res?.error?.message, setError);
          }
        } catch (error) {
          apiCallError(error.message, setError);
        }
      }
    } else {
      if (Img.type === "image/png" || Img.type === "image/jpeg") {
        if (handleEditValidation()) {
          const DATA = new FormData();
          DATA.append("image", Img);
          setisLoading(true);
          let imgUpRes = await fileUpload("/admin/careerImage", "POST", DATA);
          if (imgUpRes && imgUpRes.status) {
            try {
              let res = await JSON_API["adminEditCareer"]({
                ...bodyData,
                image: imgUpRes.url,
              });

              if (res.isSuccess) {
                dispatch(getAllCareers());
                setModal(false);
                seterrors(createErrors);
                setImg(null);
                setpressEdit(initialValues);
                setisLoading(false);
                apiCallSuccess(res.data);
              } else {
                apiCallError(res?.error?.message, setError);
              }
            } catch (error) {
              apiCallError(error.message, setError);
            }
          } else {
            toast.error("Something went wrong!");
          }
        }
      } else {
        toast.error("Picture is not a .png or .jpg type");
      }
    }
  };

  const deleteCareer = async (id) => {
    try {
      let res = await JSON_API["adminDeleteCareer"]({ id: id });
      if (res.isSuccess) {
        dispatch(getAllCareers());
        setModal(false);
        apiCallSuccess(res.data);
      } else {
        apiCallError(res?.error?.message, setError);
      }
    } catch (error) {
      apiCallError(error.message, setError);
    }
  };

  const tableData = {
    columns: [
      {
        label: "SL",
        field: "sl",
        sort: "asc",
        width: 50,
      },
      {
        label: "Picture",
        field: "picture",
        sort: "asc",
        width: 100,
        height: 100,
      },
      {
        label: "Category",
        field: "category",
        sort: "asc",
        width: 100,
      },
      {
        label: "Subcategory",
        field: "subCategory",
        sort: "asc",
        width: 100,
      },
      {
        label: "Action",
        field: "action",
        sort: "asc",
        width: 150,
      },
    ],
    rows:
      careers?.length > 0
        ? careers.map((item, idx) => {
            return {
              sl: idx + 1,
              picture: (
                <div className="widget-content p-0" key={idx}>
                  <div className="widget-content-wrapper">
                    <img src={`${item.image}`} alt="" className="img-fluid" />
                  </div>
                </div>
              ),
              category: (
                <div className="widget-content p-0">
                  <div className="widget-content-wrapper">
                    <div className="widget-content-left flex2">
                      <div className="widget-heading" style={{ opacity: 1 }}>
                        {item?.category}
                      </div>
                    </div>
                  </div>
                </div>
              ),
              subCategory: (
                <div className="widget-content p-0" key={idx}>
                  <div className="widget-content-wrapper">
                    <div className="widget-content-left flex2">
                      <div className="widget-heading" style={{ opacity: 1 }}>
                        {item?.subCategory}
                      </div>
                    </div>
                  </div>
                </div>
              ),
              action: (
                <div>
                  <button
                    className="me-2 btn-icon btn-icon-only btn btn-outline-danger"
                    onClick={() => {
                      openModal();
                      setmodalToggleView(1);
                      setdeleteId(careers[idx]?._id);
                    }}
                  >
                    <i className="fa-solid fa-trash-can" />
                  </button>
                  <button
                    className="me-2 btn-icon btn-icon-only btn btn-outline-success"
                    onClick={() => {
                      openModal();
                      setmodalToggleView(0);
                      setitemIndex(idx);
                      setpressEdit({
                        ...pressEdit,
                        category: careers[idx].category,
                        subCategory: careers[idx].subCategory,
                        description: careers[idx].description,
                      });
                    }}
                  >
                    <i class="fa-regular fa-pen-to-square"></i>
                  </button>
                </div>
              ),
            };
          })
        : [],
  };

  const renderModalView = (index) => {
    switch (index) {
      case 0:
        return (
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">Edit Career Info</h5>
            </div>
            <form
              className=""
              onSubmit={(e) => handleEditSubmit(e)}
              encType="multipart/form-data"
            >
              <div className="modal-body">
                <div className="row" style={{ margin: "0.1rem" }}>
                  <div className="col-md-6">
                    <div className="mb-3">
                      <label htmlFor="category" className="form-label">
                        Category*
                      </label>
                      <input
                        name="category"
                        id="category"
                        // placeholder="Your first name, e.g. Walter"
                        type="text"
                        className="form-control"
                        autoComplete="false"
                        value={pressEdit.category}
                        onChange={(e) => handleEditChange(e)}
                      />
                    </div>
                    {errors.category && (
                      <span className="errorMessageText text-danger">
                        {errors.category}
                      </span>
                    )}
                  </div>
                  <div className="col-md-6">
                    <div className="mb-3">
                      <label htmlFor="subCategory" className="form-label">
                        Subcategory*
                      </label>
                      <input
                        name="subCategory"
                        id="subCategory"
                        // placeholder="Your first name, e.g. Walter"
                        type="text"
                        className="form-control"
                        autoComplete="false"
                        value={pressEdit.subCategory}
                        onChange={(e) => handleEditChange(e)}
                      />
                    </div>
                    {errors.subCategory && (
                      <span className="errorMessageText text-danger">
                        {errors.subCategory}
                      </span>
                    )}
                  </div>
                </div>

                <div className="col-md-12">
                  <div className="mb-3">
                    <label htmlFor="description" className="form-label">
                      Description*
                    </label>
                    <textarea
                      name="description"
                      id="description"
                      // placeholder="Your first name, e.g. Walter"
                      className="form-control"
                      autoComplete="false"
                      value={pressEdit.description}
                      onChange={(e) => handleEditChange(e)}
                    />
                  </div>
                  {errors.bannerTitle && (
                    <span className="errorMessageText text-danger">
                      {errors.bannerTitle}
                    </span>
                  )}
                </div>
                <div className="col-md-12">
                  <div className="mb-3">
                    <label htmlFor="picture" className="form-label">
                      Picture*
                    </label>
                    <input
                      name="picture"
                      id="picture"
                      type="file"
                      ref={fileInputRef}
                      className="form-control"
                      onChange={(e) => setImg(e.target.files[0])}
                    />
                  </div>
                </div>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-link"
                  onClick={() => {
                    setModal(false);
                  }}
                >
                  Cancel
                </button>
                <button type="submit" className="btn btn-primary">
                  Edit
                </button>
              </div>
            </form>
          </div>
        );
      case 1:
        return (
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">Delete Info</h5>
            </div>
            <div className="modal-body">
              <div className="deleteModalContainer p-5">
                <i className="ri-close-circle-line deleteIcon"></i>
                <h3>Are You Sure?</h3>
                <span>Do You Want To Delete This Information?</span>
                <div className="deleteModalNote">
                  <span>*Note : This data will be permanently deleted</span>
                </div>
              </div>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-link"
                onClick={() => {
                  setModal(false);
                }}
              >
                Cancel
              </button>
              <button
                type="button"
                className="btn btn-primary"
                onClick={() => {
                  deleteCareer(deleteId);
                }}
              >
                Delete
              </button>
            </div>
          </div>
        );
      default:
        return null;
    }
  };

  return (
    <>
      {isLoading && <Loader />}

      <form
        className=""
        onSubmit={(e) => handleSubmit(e)}
        encType="multipart/form-data"
      >
        <div component="div" className="TabsAnimation appear-done enter-done">
          <div className="main-card mb-3 card">
            <div className="card-body">
              <div className="card-title">Add Careers</div>
              <div className="row" style={{ margin: "0.1rem" }}>
                <div className="col-md-6">
                  <div className="mb-3">
                    <label htmlFor="category" className="form-label">
                      Category*
                    </label>
                    <input
                      name="category"
                      id="category"
                      type="text"
                      className="form-control"
                      autoComplete="false"
                      value={press.category}
                      onChange={(e) => handleChange(e)}
                    />
                  </div>
                  {errors.category && (
                    <span className="errorMessageText text-danger">
                      {errors.category}
                    </span>
                  )}
                </div>
                <div className="col-md-6">
                  <div className="mb-3">
                    <label htmlFor="subCategory" className="form-label">
                      Subcategory*
                    </label>
                    <input
                      name="subCategory"
                      id="subCategory"
                      type="text"
                      className="form-control"
                      autoComplete="false"
                      value={press.subCategory}
                      onChange={(e) => handleChange(e)}
                    />
                  </div>
                  {errors.subCategory && (
                    <span className="errorMessageText text-danger">
                      {errors.subCategory}
                    </span>
                  )}
                </div>
              </div>

              <div className="col-md-12">
                <div className="mb-3">
                  <label htmlFor="description" className="form-label">
                    Description*
                  </label>
                  <textarea
                    name="description"
                    id="description"
                    className="form-control"
                    autoComplete="false"
                    value={press.description}
                    onChange={(e) => handleChange(e)}
                  />
                </div>
                {errors.description && (
                  <span className="errorMessageText text-danger">
                    {errors.description}
                  </span>
                )}
              </div>
              <div className="col-md-12">
                <div className="mb-3">
                  <label htmlFor="picture" className="form-label">
                    Picture*
                  </label>
                  <input
                    name="picture"
                    id="picture"
                    type="file"
                    ref={fileInputRef}
                    className="form-control"
                    onChange={(e) => setImg(e.target.files[0])}
                  />
                </div>
              </div>

              <button
                className="mt-2 btn btn-primary ml-auto d-table"
                type="submit"
              >
                Submit
              </button>
            </div>
          </div>
        </div>
      </form>
      {/* 
        <Table
          striped
          tableTitle="All Career Postings"
          data={tableData}
          modal={modal}
          setModal={setModal}
          modalToggleView={modalToggleView}
          renderModalView={renderModalView}
        /> */}
    </>
  );
}

export default AddCareers;

// Hooks
import React, { useState, useEffect, useRef, useMemo } from "react";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { getOnlyFullDate } from "../../../utils";

// Helpers
import { toast } from "react-toastify";

// API
import { JSON_API } from "../../../services/api/jsonApiCallWithInterceptor";

import DatePicker from "react-date-picker";

import Table from "../../../Component/Table";
import Select from "react-select";

import "react-date-picker/dist/DatePicker.css";
import "react-calendar/dist/Calendar.css";
import "react-time-picker/dist/TimePicker.css";
import "./styles.css";
import { BASE_URL } from "../../../constants/config";

function Bookinginvoice({marchantId}) {

const urlString=useLocation()

const [isFromOrderInvoice, setisFromOrderInvoice] = useState(false);
useEffect(()=>{
  if(urlString.pathname==="/view-bookingInvoice"){

    setisFromOrderInvoice(true);
  }else{
    setisFromOrderInvoice(false)
  }
},[urlString.pathname])

const fetchPdf=async(item)=>{
  
let token = localStorage.getItem("Authorization");
    setisLoading(true)
    try {
      const response = await fetch(`${BASE_URL}api/v1/admin/view-booking-ticket/${item.orderId}/${item.activity[0]._id}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          "authorization":token,
          "userType":"Admin"
        },
      });
      if(response && response.status){
        setisLoading(false)
      }
       const blob = await response.blob();
       const blobUrl = URL.createObjectURL(blob);
       const a = document.createElement('a');
       a.href = blobUrl;
       a.download = 'downloaded-file'; 
       document.body.appendChild(a);
       a.click();
       document.body.removeChild(a);
       URL.revokeObjectURL(blobUrl);

      if (!response.ok) {
        throw new Error('Failed to fetch PDF');
      }

      const pdfBlob = await response.blob();
      const pdfUrl = URL.createObjectURL(pdfBlob);

      const embedElement = document.createElement('embed');
      embedElement.setAttribute('src', pdfUrl);
      embedElement.setAttribute('type', 'application/pdf');
      embedElement.setAttribute('width', '100%');
      embedElement.setAttribute('height', '600px');

      const pdfContainer = document.getElementById('pdf-container');
      pdfContainer.appendChild(embedElement);
    
    } catch (error) {
      toast.error(error.message);
    }
  }

  const inputRef = useRef(null);

  const [activity, setactivity] = useState({
    marchantId:marchantId,
  });
  const [searchText, setsearchText] = useState("");
  const [isLoadings, setisLoading] = useState(false);
  const [cancelStatuses, setcancelStatuses] = useState([]);
  const [cancelmodal, setCancelModal] = useState(false);
  // Redux stores
  const { activities } = useSelector((state) => state.adminActivityActionSlice);

  const { bookings } = useSelector((state) => state.bookingSlice);
  const [bookingData, setBookingData] = useState([]);
  const [id, setId] = useState();

  const reset = () => {
    setactivity({
      activityId: "",
      activityTitle: "",
      startDate: "",
      endDate: "",
    });
  };
  useEffect(() => {
    if (bookings?.length > 0) {
      setBookingData(bookings);
      setcancelStatuses(bookings?.map(() => ({ cancelled: false })));
    }
  }, [bookings]);

  const handleStartDateChange = (date) => {
    setactivity({
      ...activity,
      startDate: getOnlyFullDate(date),
    });
  };
  const handleEndDateChange = (date) => {
    setactivity({
      ...activity,
      endDate: getOnlyFullDate(date),
    });
  };

  const handleSubmit = async (event) => {
   if(event


   ) event.preventDefault();
   
  let tempactivity={...activity,marchantId:marchantId} 
    try {
      let res = await JSON_API["getAllBookings"](tempactivity);
      if (res.isSuccess) {
        setBookingData(res?.data?.data);
        reset();
        toast.success("Activity booking get success");
      } else {
        toast.error("Activity Booking get failed");
      }
    } catch (error) {

      toast.error("Failed to get bookings due to ", error.message);
    }
  };


  useEffect(()=>{
    handleSubmit()
  },[marchantId])
  const handleReset = (e) => {
    e.preventDefault();
    if (inputRef.current) {
      inputRef.current.value = "";
    }
  };
  const handleClick = (id) => {
    setCancelModal(true);
    setId(id);
  };

  const filterData = () => {
    if (searchText !== "") {
      return bookingData.filter((item) =>
        JSON.stringify(item).toLowerCase().includes(searchText.toLowerCase())
      );
    }
    return bookingData;
  };

  const handleCloseModal = (e) => {
    e.preventDefault();
  };

  const tableData = useMemo(() => {
    return {
      columns: [
        {
          label: "SL",
          field: "sl",
          sort: "asc",
          width: 100,
        },
        {
          label: "Activity Name",
          field: "activity_name",
          sort: "asc",
          width: 150,
        },
        {
          label: "Booked By",
          field: "name",
          sort: "asc",
          width: 150,
        },
        {
          label: "Booking ID",
          field: "booking_id",
          width: 100,
        },
        {
          label: "Cancel Booking",
          field: "cancel_booking",
        },
        {
          label: "Tickets",
          field: "detail_view",
          sort: "asc",
          width: 150,
        },
      ],
      rows:
        filterData()?.length > 0
          ? filterData().map((item, idx) => {
              return {
                sl: idx + 1,
                activity_name: (
                  <div className="widget-content p-0">
                    <div className="widget-content-wrapper">
                      <div className="widget-content-left flex2">
                        <div
                          className="widget-heading"
                          style={{ opacity: 1, textAlign: "center" }}
                        >
                          {item?.activity[0]?.activityTitle}
                        </div>
                      </div>
                    </div>
                  </div>
                ),
                name: (
                  <div className="widget-content p-0">
                    <div className="widget-content-wrapper">
                      <div className="widget-content-left flex2">
                        <div
                          className="widget-heading"
                          style={{ opacity: 1, textAlign: "center" }}
                        >
                          {item?.userDetails?.firstName
                            ?.charAt(0)
                            ?.toUpperCase() +
                            item?.userDetails?.firstName
                              ?.slice(1)
                              ?.toLowerCase() +
                            " " +
                            item?.userDetails?.lastName
                              ?.charAt(0)
                              ?.toUpperCase() +
                            item?.userDetails?.lastName
                              ?.slice(1)
                              ?.toLowerCase()}
                        </div>
                      </div>
                    </div>
                  </div>
                ),
                cancel_booking: (
                  <div className="widget-content p-0">
                    <div className="widget-content-wrapper">
                      <div className="widget-content-left flex2">
                        <button
                          disabled={
                            item?.bookingStatus === "cancelled" ? true : false
                          }
                          className={`btn ${
                            item?.bookingStatus === "confirmed"
                              ? "btn-primary"
                              : "btn-secondary"
                          }`}
                          style={{
                            width: "90px",
                            backgroundColor:
                              item?.bookingStatus === "confirmed"
                                ? "green"
                                : "red",
                          }}
                          onClick={() => handleClick(item?._id)}
                        >
                          {item?.bookingStatus === "confirmed"
                            ? "CANCEL"
                            : "CANCELLED"}
                        </button>
                      </div>
                    </div>
                  </div>
                ),
                booking_id: (
                  <div className="widget-content p-0">
                    <div className="widget-content-wrapper">
                      <div className="widget-content-left flex2">
                        <div
                          className="widget-heading"
                          style={{ opacity: 1, textAlign: "center" }}
                        >
                          {item?.alpfaNueID}
                        </div>
                      </div>
                    </div>
                  </div>
                ),
                detail_view: (
                  <div>                
                    <button className="downloadInvoice1" onClick={()=>{
                      fetchPdf(item)
                    }} >Download Invoice</button>               
                  </div>
                ),
              };
            })
          : [],
    };
  }, [bookingData, searchText,isFromOrderInvoice])

  return (
    <>
      {/* {isLoadings && <Loader />} */}
      <form
        className=""
        onSubmit={(e) => handleSubmit(e)}
        onClick={(e) => handleCloseModal(e)}
      >
        <div component="div" className="TabsAnimation appear-done enter-done">
          <div className="main-card mb-3 card">
            <div className="card-body">
              <div className="card-title">Activity Bookings</div>
              <div className="row" style={{ padding: "1rem" }}>
                <div className="col-md-4">
                  <div className="mb-3">
                    <label
                      htmlFor="activityId"
                      className="form-label font-weight-bold"
                    >
                      Activity*
                    </label>
                    <Select
                      name="activityId"
                      id="activityId"
                      classNamePrefix="select"
                      ref={inputRef}
                      options={activities.map((item, idx) => ({
                        value: item?._id,
                        label: item?.activityTitle,
                      }))}
                      onChange={(val) => {
                        setactivity((prev) => {
                          return {
                            ...prev,
                            activityId: val?.value,
                            activityTitle: val?.label,
                          };
                        });
                      }}
                    />
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="mb-3">
                    <label
                      className="form-label font-weight-bold"
                      htmlFor="tourDate"
                    >
                      Start Date
                    </label>
                    <DatePicker
                      id="tourDate"
                      inputRef={inputRef}
                      calendarIcon={null}
                      className="form-control"
                      clearIcon={null}
                      value={activity.startDate}
                      format="y-MM-dd"
                      // closeCalendar  // on 'select'
                      shouldCloseCalendar={({ reason }) =>
                        reason === "select" || reason === "outsideAction"
                      }
                      onChange={handleStartDateChange}
                    />
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="mb-3">
                    <label
                      className="form-label font-weight-bold"
                      htmlFor="tourDate"
                    >
                      End Date
                    </label>
                    <DatePicker
                      id="tourDate"
                      inputRef={inputRef}
                      calendarIcon={null}
                      className="form-control"
                      clearIcon={null}
                      value={activity.endDate}
                      format="y-MM-dd"
                      // closeCalendar  // on 'select'
                      shouldCloseCalendar={({ reason }) =>
                        reason === "select" || reason === "outsideAction"
                      }
                      onChange={handleEndDateChange}
                    />
                  </div>
                </div>
              </div>

              <div className="buttons">
                <div style={{ display: "flex", justifyContent: "flex-end" }}>
                  <button
                    className="mt-2 btn btn-link d-table"
                    onClick={handleReset}
                    style={{ marginRight: "10px" }}
                  >
                    Reset
                  </button>
                  <button
                    className="mt-2 btn btn-primary d-table"
                    type="submit"
                    onClick={(e) => handleSubmit(e)}
                  >
                    Submit
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
      <Table
        striped
        tableTitle="All Booking Details"
        data={tableData}
        onSearch={(val) => setsearchText(val.target.value)}
        isSearchable={false}
        tabflag={true}
      />
      {/* {cancelmodal && <CancelModal Closemodal={setCancelModal} id={id} />} */}
    </>
  );
}

export default Bookinginvoice;

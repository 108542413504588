// Hooks
import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";

// Utils
import {
  capitalizeFirstLetter,
  apiCallSuccess,
  apiCallError,
} from "../../../utils";

// Helpers
import moment from "moment";
import toast from "react-hot-toast";

// API
import { JSON_API } from "../../../services/api/jsonApiCallWithInterceptor";

// Redux actions
import { getAllSpecialOffers } from "../../../redux/adminSlice/specialOfferSlice";
import { getAllActivities } from "../../../redux/adminSlice/activitySlice";

// Components
import Loader from "../../../Component/Loader";
// import DateTimePicker from "react-datetime-picker";
import Datetime from "react-datetime";
import Select from "react-select";
import { formatTimeToAMPM } from "../../../utils";

// import "react-datetime-picker/dist/DateTimePicker.css";
import "react-calendar/dist/Calendar.css";
import "react-datetime/css/react-datetime.css";
import TimePicker from "react-time-picker";
import "react-time-picker/dist/TimePicker.css";

let initialValues = {
  specialOfferName: "",
  duration_value: 0,
  duration_unit: "",
  activityDetailsId: [],
  offerType: "",
  discountPercentage: 0,
  description: "",
  offer_type: "",
  limitedStartDate: "",
  limitedEndDate: "",
  limitedOfTime: "",
  earlyNoOfDays: "",
  lastMinuteValue: "",
  lastMinuteUnit: "",
  slotStartDate: "",
  slotEndDate: ""
};

const createErrors = {
  specialOfferName: "",
  duration_value: "",
  duration_unit: "",
  activityDetailsId: "",
  offerType: "",
  discountPercentage: "",
  description: "",
  earlyNoOfDays: ""
};

const duration_units = ["minutes", "hours", "days"];

export default function AddSpecialOffers() {
  const dispatch = useDispatch();
  const datetimeInputRef = useRef(null);
  const [selectedDateTime, setSelectedDateTime] = useState(new Date());
  const [banner, setbanner] = useState(initialValues);
  const [error, setError] = useState("");
  const [errors, seterrors] = useState(createErrors);
  const [isLoading, setisLoading] = useState(false);
  // Redux stores
  const { activities } = useSelector((state) => state.adminActivityActionSlice);
  // console.log("activities", activities);
  const [openDate, setOpendate] = useState(false);
  const [offerType, setOfferType] = useState([
    {
      "label": "Limited Time Offer",
      "value": 'limitedTimeOffer',
    },
    {
      "label": "Early Bird Offer",
      "value": 'earlyBirdOffer',
    },
    {
      "label": "Last Minute offer",
      "value": 'lastMinuteOffer',
    },
  ])
  const [selectoffertype, setSelectOfferType] = useState({})
  const [noOfDays, setNoOfDays] = useState(0)
  const [times, settimes] = useState({});

  useEffect(() => {
    dispatch(getAllActivities());
  }, [dispatch]);

  const handleDateTimeChange = (date) => {
    // console.log("date", date, moment(date).format("MMMM/DD/YYYY"));
    setSelectedDateTime(moment(date).format());
  };

  const handleChange = (event) => {
    event.preventDefault();
    // console.log(event.target.name, event.target.value);
    let input;

    if (
      event.target.name === "description" ||
      event.target.name === "duration_unit"
    ) {
      input = event.target.value;
    } else {
      input = capitalizeFirstLetter(event);
    }

    setbanner({ ...banner, [event.target.name]: input });
  };

  const handleValidation = () => {
    const {
      specialOfferName,
      duration_value,
      duration_unit,
      offerType,
      discountPercentage,
      earlyNoOfDays,
    } = banner;
    if (earlyNoOfDays < 7 ) {
      seterrors({
        ...errors,
        specialOfferName: "Number of days should be greter then 7 days",
      });
    }
    if (specialOfferName === "") {
      seterrors({
        ...errors,
        specialOfferName: "Special offer name is required",
      });

      return false;
    }
    if (duration_value === 0 && duration_unit === "") {
      seterrors({ ...errors, duration_value: "Offer duration is required" });

      return false;
    }
    // if (banner.activityDetailsId.length === 0) {
    //   seterrors({
    //     ...errors,
    //     activityDetailsId: "Please select atleast 1 activity",
    //   });
    // }
    if (offerType === "") {
      seterrors({
        ...errors,
        offerType: "Offer description description is required",
      });

      return false;
    }
    if (discountPercentage === 0) {
      seterrors({
        ...errors,
        discountPercentage: "Discount percentage is required",
      });

      return false;
    }

    return true;
  };

  const handleSubmit = async (event) => {
    console.log('bal')
    event.preventDefault();
    // console.log(selectedDateTime.toString());
    let bodyData = {
      ...banner,
      startDate: selectedDateTime,
      endDate:selectedDateTime,
      duration: { value: banner.duration_value, unit: banner.duration_unit },
    };

    console.log(bodyData);
    if (handleValidation()) {
      try {
        setisLoading(true);
        let res = await JSON_API["adminAddSpecialOffer"](bodyData);

        if (res.isSuccess) {
          dispatch(getAllSpecialOffers());
          seterrors(createErrors);
          setSelectedDateTime(new Date());
          if (datetimeInputRef.current) {
            datetimeInputRef.current.value = "";
          }
          setbanner(initialValues);
          setisLoading(false);
          // apiCallSuccess(res.data);
          toast.success("Special offer added successfully");
        } else {
          setisLoading(false);
          apiCallError(res?.error?.message, setError);
        }
      } catch (error) {
        setisLoading(false);
        apiCallError(error.message, setError);
      }
    }
  };

  return (
    <>
      {isLoading && <Loader />}
      <form className="" onSubmit={(e) => handleSubmit(e)}>
        <div component="div" className="TabsAnimation appear-done enter-done">
          <div className="main-card mb-3 card">
            <div className="card-body">
              <div className="card-title" style={{
                fontWeight: 'bolder',
                color: '#000',
                justifyContent: 'center',
              }}>Add Special Offer</div>
              <div className="row" style={{ margin: "0.1rem" }}>
                <div className="col-md-6">
                  <div className="mb-3">
                    <label htmlFor="specialOfferName" className="form-label font-weight-bold">
                      Special Offer Name*
                    </label>
                    <input
                      type="text"
                      name="specialOfferName"
                      id="specialOfferName"
                      // placeholder="Your first name, e.g. Walter"
                      className="form-control"
                      autoComplete="false"
                      value={banner.specialOfferName}
                      onChange={(e) => handleChange(e)}
                    />
                  </div>
                  {errors.specialOfferName && (
                    <span className="errorMessageText text-danger">
                      {errors.specialOfferName}
                    </span>
                  )}
                </div>
                <div className="col-md-6">
                  <div className="mb-3">
                    <label className="form-label font-weight-bold">Offer Starts At*</label>
                    <div className="row">
                      <div className="col-md-6">
                        <input
                          type="text"
                          name="duration_value"
                          id="duration_value"
                          // placeholder="Your first name, e.g. Walter"
                          className="form-control"
                          autoComplete="false"
                          onFocus={() => setOpendate(true)}
                          value={moment(selectedDateTime).format("DD-MM-YYYY")}
                        />
                      </div>
                      <div className="col-md-6">
                        <button
                          type="button"
                          className="btn btn-warning"
                          onClick={() => setOpendate(!openDate)}
                        >
                          Select
                        </button>
                      </div>
                    </div>

                    {openDate && (
                      <Datetime
                        // renderInput={renderInput}
                        // utc={true}

                        // value={selectedDateTime ? selectedDateTime : ''}
                        ref={datetimeInputRef}
                        closeOnSelect
                        input={false}
                        // open={true}
                        onChange={(date) => {
                          handleDateTimeChange(date);
                        }}
                        // timeFormat={false}
                        onClose={() => setOpendate(false)}
                      />
                    )}
                  </div>
                </div>
                
                <div className="col-md-6">
                  <div>
                    <label htmlFor="duration_value" className="form-label font-weight-bold">
                      Valid for*
                    </label>
                  </div>
                  <div className="" style={{ display: "flex" }}>
                    <div className="col-md-3" style={{ marginLeft: "-15px" }}>
                      <div className="mb-3">
                        <input
                          type="number"
                          name="duration_value"
                          id="duration_value"
                          // placeholder="Your first name, e.g. Walter"
                          className="form-control"
                          autoComplete="false"
                          value={banner.duration_value}
                          onChange={(e) => handleChange(e)}
                        />
                      </div>
                      {errors.duration_value && (
                        <span className="errorMessageText text-danger">
                          {errors.duration_value}
                        </span>
                      )}
                    </div>
                    <div className="col-md-3">
                      <div className="mb-3">
                        <select
                          name="duration_unit"
                          id="duration_unit"
                          className="form-control form-select"
                          onChange={(e) => handleChange(e)}
                        >
                          <option value="" key={0}>
                            Select
                          </option>
                          {duration_units?.map((item, idx) => {
                            return (
                              <>
                                <option key={idx + 1} value={`${item}`}>
                                  {item}
                                </option>
                              </>
                            );
                          })}
                        </select>
                      </div>
                      {errors.duration_value && errors.duration_unit && (
                        <span className="errorMessageText text-danger">
                          {errors.duration_value}
                        </span>
                      )}
                    </div>
                  </div>
                </div>
                {/* <div className="col-md-6">
                  <div className="mb-3">
                    <label htmlFor="activityDetailsId" className="form-label">
                      Activity*
                    </label>
                    <Select
                      name="activityDetailsId"
                      id="activityDetailsId"
                      classNamePrefix="select"
                      options={activities.map((item, idx) => ({
                        value: item?._id,
                        label: item?.activityTitle,
                      }))}
                      value={banner.activityDetailsId.map((item) => {
                        let find = activities.find((it) => it._id === item);
                        return {
                          value: find?._id,
                          label: find?.activityTitle,
                        };
                      })}
                      isMulti
                      onChange={(val) => {
                        setbanner((prev) => {
                          return {
                            ...prev,
                            activityDetailsId: val.map((item) => item.value),
                          };
                        });
                      }}
                    />
                  </div>
                  {errors.activityDetailsId && (
                    <span className="errorMessageText text-danger">
                      {errors.activityDetailsId}
                    </span>
                  )}
                </div> */}
                <div className="col-md-6">
                  <label
                    htmlFor="filter"
                    className="form-label font-weight-bold"
                  >
                    Offer Type
                  </label>
                  <Select
                    name="filter"
                    id="filter"
                    classNamePrefix="select"
                    // ref={inputRef}
                    options={offerType}
                    value={[
                      {
                        label: selectoffertype.label,
                        value: selectoffertype.value
                      },
                    ]}
                    // isMulti
                    onChange={(val) => {
                      console.log("value", val);
                      setSelectOfferType(val)
                      setbanner((prev) => {
                        let data = { ...banner }
                        data.offerType = val.value
                        return JSON.parse(JSON.stringify(data))
                      })
                    }}
                  />
                </div>

                {
                  selectoffertype && selectoffertype.value == "limitedTimeOffer" &&
                  <span>* Attract customers to activity dates and time slots you struggle to fill.</span>
                }
                {
                  selectoffertype && selectoffertype.value == "earlyBirdOffer" &&
                  <>
                    <span>
                      * To attract bookings at least 30 days in advance {'\n'}
                    </span>
                    <br />
                    <span>
                      Notice period: Early-bird offer
                      All bookings made at least (7) days in advance will be eligible for this  offer

                    </span>
                  </>
                }
                {
                  selectoffertype && selectoffertype.value == "limitedTimeOffer" &&
                  <div>
                    <div className="d-flex" style={{ alignItems: "center" }}>
                      <div className="col-md-6">
                        <div className="mb-3">
                          <TimePicker
                            clockIcon={null}
                            clearIcon={null}
                            className="form-control"
                            hourPlaceholder="hh"
                            minutePlaceholder="mm"
                            value={times.times}
                            minTime=""
                            format="hh:mm a"
                            openClockOnFocus={false}
                            onChange={(time) => {
                              let formattedTime = formatTimeToAMPM(time);

                              settimes(formattedTime);
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                }
                {
                  selectoffertype && selectoffertype.value == "lastMinuteOffer" &&
                  <>
                    <span>
                      * For activities happening very soon, e.g. in 3 days’ time  {'\n'}
                    </span>
                    <br />
                    <span>
                      Notice period: Last-minute offer
                      For any unsold spots up to (3) days before the activity
                    </span>
                  </>
                }
                {
                  selectoffertype && selectoffertype.value == "earlyBirdOffer" &&
                  <div className="col-md-6">
                    <label
                      htmlFor="filter"
                      className="form-label font-weight-bold"
                    >
                      Number Of days
                    </label>
                    <span>
                      (* Minimum value will be 7 days)
                    </span>
                    <input
                      type="number"
                      name="duration_value"
                      id="duration_value"
                      placeholder="Number Of days"
                      className="form-control"
                      autoComplete="false"
                      value={banner.earlyNoOfDays}
                      onChange={(e) => {
                        setbanner((prev) => {
                          let data = { ...banner }
                          data.earlyNoOfDays = e.target.value;
                          return JSON.parse(JSON.stringify(data))
                        })
                      }}
                      
                    />
                    {errors.earlyNoOfDays && (
                      <span className="errorMessageText text-danger">
                        * {errors.earlyNoOfDays}
                      </span>
                    )}
                  </div>
                }

                {
                  selectoffertype && selectoffertype.value == "lastMinuteOffer" &&
                  <>
                    <div className="col-md-6">
                      <label
                        htmlFor="filter"
                        className="form-label font-weight-bold"
                      >
                        Number Of days
                      </label>
                      <input
                        type="number"
                        name="duration_value"
                        id="duration_value"
                        placeholder="Number Of days"
                        className="form-control"
                        autoComplete="false"
                        value={banner.lastMinuteValue}
                        onChange={(e) => {
                          setbanner(() => {
                            let updated = { ...banner };
                            updated.lastMinuteValue = e.target.value;
                            return JSON.parse(JSON.stringify(updated))
                          })
                        }}
                      />
                      <span>
                        * Maximum value will be 3 days
                      </span>
                      {errors.discountPercentage && (
                        <span className="errorMessageText text-danger">
                          {errors.discountPercentage}
                        </span>
                      )}
                    </div>
                    <div className="col-md-6">
                      <label
                        htmlFor="filter"
                        className="form-label font-weight-bold"
                      >
                        Select Unit
                      </label>
                      <select
                        name="duration_unit"
                        id="duration_unit"
                        className="form-control form-select"
                        onChange={(e) => {
                          setbanner(() => {
                            let data = { ...banner }
                            data.lastMinuteUnit = e.target.value;
                            return JSON.parse(JSON.stringify(data))
                          })
                        }}
                      >
                        <option value="" key={0}>
                          Select
                        </option>
                        {duration_units?.map((item, idx) => {
                          return (
                            <>
                              <option key={idx + 1} value={`${item}`}>
                                {item}
                              </option>
                            </>
                          );
                        })}
                      </select>
                    </div>
                  </>
                }
                <div className="col-md-6">
                  <div className="mb-3">
                    <label htmlFor="discountPercentage" className="form-label font-weight-bold">
                      Discount Percentage*
                    </label>
                    <input
                      type="number"
                      name="discountPercentage"
                      id="discountPercentage"
                      // placeholder="Your first name, e.g. Walter"
                      className="form-control"
                      autoComplete="false"
                      value={banner.discountPercentage}
                      onChange={(e) => handleChange(e)}
                    />
                  </div>
                  {errors.discountPercentage && (
                    <span className="errorMessageText text-danger">
                      {errors.discountPercentage}
                    </span>
                  )}
                </div>
              </div>

              <div className="col-md-12">
                <div className="mb-3">
                  <label htmlFor="description" className="form-label font-weight-bold">
                    Offer Description
                  </label>
                  <textarea
                    name="description"
                    id="description"
                    // placeholder="Your first name, e.g. Walter"
                    className="form-control"
                    autoComplete="false"
                    value={banner.description}
                    onChange={(e) => handleChange(e)}
                  />
                </div>
              </div>

              <button
                className="mt-2 btn btn-primary ml-auto d-table"
                type="submit"
                onClick={(e) => { }}
              >
                Submit
              </button>
            </div>
          </div>
        </div>
      </form>
      {/* 
        <Table
          striped
          tableTitle="All special_offers"
          data={tableData}
          modal={modal}
          setModal={setModal}
          modalToggleView={modalToggleView}
          renderModalView={renderModalView}
        /> */}
    </>
  );
}

import { useEffect, useState } from "react";
import { requestData } from "../services/api/apiHelpers";

function useCountries() {
  const [countries, setCountries] = useState([]);
  const [cities, setCities] = useState([]);
  const [loading,setLoading] = useState(false)
  const [activity, setActivity] = useState([])
  const [miniBlogData, setMiniBlogData] = useState([])

  const fetchCountries = async () => {
    let result = await requestData("/user/viewCountry", "GET");
    if (result && result.status) {
      setCountries(result.data);
    }
  };
  const fetchCities = async (countryId) => {
    setLoading(true)
    let result = await requestData(
      `/user/city-against-country/${countryId}`,
      "GET"
    );
    if (result && result.status) {
      setCities(result.data);
    }
    setLoading(false)

  };
  const fetchCitiesByCountryName = async (countryName) => {
    setLoading(true)
    let result = await requestData(
      `/user/city-against-countryName/${countryName}`,
      "GET"
    );
    if (result && result.status) {
      setCities(result.data);
    }
    setLoading(false)

  };
  const fetchActivityAgainstCity = async (cityId) => {
    setLoading(true)
    let result = await requestData(
      `/admin/getActivity-againstCity/${cityId}`
    )
    console.log("wedsdwe", result)
    if(result && result.status){
      setActivity(result.data)
    }
    setLoading(false)
  }

  const getMiniBlogs = async () => {
    setLoading(true)
    let res = await requestData("/admin/viewminiBlog", "GET")
    if(res && res.status){
      // console.log("oooooooopxdd", res?.data)
      setMiniBlogData(res?.data)
      setLoading(false)
    }
  }

  useEffect(() => {
    fetchCountries();
  }, []);
  return { countries, cities,fetchCities, fetchActivityAgainstCity,fetchCitiesByCountryName,activity,getMiniBlogs, miniBlogData, loading};
}

export default useCountries;

import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import { getAllBlogs } from "../../../redux/adminSlice/blogSlice";

import { getOnlyFullDate } from "../../../utils";

function BlogDetailedView() {
  const params = useParams();
  const dispatch = useDispatch();
  const { blogs } = useSelector((state) => state.blogsSlice);

  const blogData = blogs.find((item) => item?._id === params.id);

  useEffect(() => {
    dispatch(getAllBlogs());
  }, []);

  return (
    <div className="info-details">
      <div className="container-fluid">
        <div className="row">
          <div className="col-lg-12 d-flex">
            <div className="obser-top">
              <p>{blogData?.title}</p>
            </div>
          </div>
          <div className="col-lg-12">
            <div className="des-detail" style={{ flexWrap: "nowrap" }}>
              <div className="des-title">
                <div
                  dangerouslySetInnerHTML={{ __html: blogData?.description }}
                />
              </div>
            </div>
          </div>
        </div>

        <div className="mt-1">
          <div className="col-md-6 col-6">
            <div className="column">
              <div
                className="employeeDetails"
                style={{ padding: 20, display: "flex" }}
              >
                <div className="DetailsData mr-5">
                  <span>Blog Image</span>
                  <div className="task_img">
                    <img
                      src={blogData?.image}
                      className="img-fluid"
                      alt=""
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="row mt-3" style={{ padding: "2rem" }}>
          <div className="des-detail">
            <div className="col-lg-6 col-md-6 col-12">
              <div className="DetailsData">
                <span>Author Name</span>
                <h6>{blogData?.authorName}</h6>
              </div>
              <div className="DetailsData">
                <span>Blog Category</span>
                <h6>{blogData?.categoryName}</h6>
              </div>
            </div>
            <div className="col-lg-6 col-md-6 col-12">
              <div className="DetailsData">
                <span>Created On</span>
                <h6 className="">{getOnlyFullDate(blogData?.updatedOn)}</h6>
              </div>
            </div>
          </div>
        </div>

        <div className="mt-1">
          <div className="col-md-6 col-6">
            <div className="column">
              <div
                className="employeeDetails"
                style={{ padding: 20, display: "flex" }}
              >
                <div className="DetailsData mr-5">
                  <span>Author Image</span>
                  <div className="task_img">
                    <img
                      src={blogData?.authorImage}
                      className="img-fluid"
                      alt=""
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default BlogDetailedView;

import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { ApiHelperFunction } from '../../services/api/apiHelpers';

let initialState = {
  status: 'idle',
  sections: [],
  isLoading: false,
  isSuccess: false,
  isError: false,
};

export const getAllSections = createAsyncThunk('get_all_sections', async () => {
  let response = await ApiHelperFunction({
    urlPath: '/admin/view-section',
    method: 'GET',
  });
  if (response.status === 200) {
    return response.data;
  } else {
    console.log("Can't get data. Something went wrong");
  }
});

export const SectionSlice = createSlice({
  name: 'section_actions',
  initialState,
  reducers: {
    clearState: state => {
      state.isLoading = false;
      state.isSuccess = false;
      state.isError = false;
    },
  },
  extraReducers: builder => {
    builder
      .addCase(getAllSections.pending, state => {
        state.status = 'loading';
        state.isLoading = true;
      })
      .addCase(getAllSections.fulfilled, (state, { payload }) => {
        state.status = 'success';
        state.isSuccess = true;
        state.sections = payload?.data;
      })
      .addCase(getAllSections.rejected, state => {
        state.status = 'failed';
        state.isError = true;
        state.isSuccess = false;
      });
  },
});

export const { clearState } = SectionSlice.actions;

export default SectionSlice.reducer;

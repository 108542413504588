// Hooks
import React, { useState, useRef, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams, useNavigate } from "react-router-dom";

// Helpers
import { toast } from "react-toastify";

// Utilities
import {
  capitalizeFirstLetter,
  apiCallSuccess,
  apiCallError,
} from "../../../utils";

// API functions
import { fileUpload } from "../../../services/api/apiHelpers";
import { JSON_API } from "../../../services/api/jsonApiCallWithInterceptor";

// Redux actions
import { getAllSections } from "../../../redux/adminSlice/sectionSlice";
import { getAllActivityTypes } from "../../../redux/adminSlice/activitySlice";
import { getAllCategories } from "../../../redux/adminSlice/categorySlice";

// Components
import Layout from "../../../Layout";
import Table from "../../../Component/Table";
import Loader from "../../../Component/Loader";

const initialValues = {
  categoryName: "",
};

const createErrors = {
  categoryName: "",
  //   activityTypeId: "",
};
export const EditCategory = () => {
  // function AddCategory() {
  const dispatch = useDispatch();
  const params = useParams();
  const navigate = useNavigate();
  const [error, setError] = useState("");
  const [errors, seterrors] = useState(createErrors);
  const [modal, setModal] = useState(false);
  const [categoryEdit, setCategoryEdit] = useState(initialValues);
  const [isLoading, setisLoading] = useState(false);
  // Redux stores
  const { activity_types } = useSelector(
    (state) => state.adminActivityActionSlice
  );
  const { categories } = useSelector((state) => state.adminCategoryActionSlice);
  // console.log("categories", categories);

  // Component did mount
  useEffect(() => {
    dispatch(getAllActivityTypes());
    dispatch(getAllCategories());
    setCategoryEdit({
      ...categoryEdit,
      categoryName: categories.find((item) => item?._id === params.id)
        ?.categoryName,
    });
  }, []);

  const handleEditChange = (event) => {
    event.preventDefault();
    // console.log(event.target.name, event.target.value);
    let input = capitalizeFirstLetter(event);
    setCategoryEdit({
      ...categoryEdit,
      [event.target.name]: input,
    });
  };

  const handleEditValidation = () => {
    const { categoryName } = categoryEdit;

    if (categoryName === "") {
      seterrors({ ...errors, categoryName: "Category name is required" });

      return false;
    }

    return true;
  };

  const handleEditSubmit = async (event) => {
    event.preventDefault();
    console.log("Edit submit", categoryEdit);
    // let bodyData = { id: `${categories[itemIndex]._id}`, ...categoryEdit };

    if (handleEditValidation()) {
      try {
        setisLoading(true);
        let res = await JSON_API["adminEditCategory"]({
          id: params.id,
          ...categoryEdit,
        });

        if (res.isSuccess) {
          dispatch(getAllCategories());
          setModal(false);
          seterrors(createErrors);
          setCategoryEdit(initialValues);
          setisLoading(false);
          // apiCallSuccess(res.data);
          toast.success("Category edited successfully!");
          navigate("/manage-categories");
        } else {
          setisLoading(false);
          apiCallError(res?.error?.message, setError);
        }
      } catch (error) {
        setisLoading(false);
        apiCallError(error.message, setError);
      }
    }
  };

  return (
    <>
      {isLoading && <Loader />}
      <form className="" onSubmit={(e) => handleEditSubmit(e)}>
        <div component="div" className="TabsAnimation appear-done enter-done">
          <div className="main-card mb-3 card">
            <div className="card-body">
              <div className="card-title">Edit Category</div>
              <div className="col-md-12">
                <div className="mb-3">
                  <label htmlFor="categoryName" className="form-label">
                    Name*
                  </label>
                  <input
                    name="categoryName"
                    id="categoryName"
                    type="text"
                    className="form-control"
                    autoComplete="false"
                    value={categoryEdit.categoryName}
                    onChange={(e) => handleEditChange(e)}
                  />
                </div>
                {errors.categoryName && (
                  <span className="errorMessageText text-danger">
                    {errors.categoryName}
                  </span>
                )}
              </div>
              <div
                className="d-flex m-2"
                style={{ justifyContent: "flex-end" }}
              >
                <button
                  type="button"
                  className="mt-2 btn btn-link"
                  onClick={() => {
                    setModal(false);
                    setCategoryEdit(initialValues);
                    navigate("/manage-categories");
                  }}
                >
                  Cancel
                </button>
                <button type="submit" className="mt-2 btn btn-primary d-table">
                  Edit
                </button>
              </div>
            </div>
          </div>
        </div>
      </form>
    </>
  );
  //   }
};

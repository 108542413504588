// API url = BASE_URL + API_ENDPOINT_PATH + '/' + <ENDPOINT>

//  export const BASE_URL = 'http://127.0.0.1:8025/';
 export const BASE_URL = 'https://api.thingstodooo.com/'
 export const API_ENDPOINT_PATH = 'api/v1';

// API_NOTIFICATION_MESSAGES hadd .
export const API_NOTIFICATION_MESSAGES = {
  loading: {
    title: 'Loading...',
    message: 'Data is being loaded. Please wait',
  },
  success: {
    title: 'Success',
    message: 'Data successfully loaded',
  },
  responseFailure: {
    title: 'Error',
    message: 'An error occured while fetching response from server. Please try again.',
  },
  requestFailue: {
    title: 'Error',
    message: 'An error occurred while parsing request data.',
  },
  networkError: {
    title: 'Error',
    message: 'Unable to connect with server. Please check internet connectivity and try again.',
  },
};
export const SERVICE_URLS = {
  adminSignup: { url: '/admin/register', method: 'POST' },
  adminLogin: { url: '/admin/login', method: 'POST' },
  adminGetProfileDetails: { url: '/admin/get-profile', method: 'GET' },
  adminEditSelfProfile: { url: '/admin/update', method: 'POST' },
  adminUpdateProfileImg: { url: '/admin/updateImage', method: 'POST' },
  adminAddSubadmin: { url: '/admin/admin-add-subadmin', method: 'POST' },
  getAllMemberProfiles: { url: '/admin/get-all-member-profile', method: 'GET' },
  getAllcontuctIssues:{ url: '/admin/getAllcontuctIssues', method: 'GET' },
  bokunActivityFetch:{url:'/admin/fetch-bokun-activity', method: 'POST'},
  fetchParticipantPrice:{url:'/admin/fetch-bokun-Pricing', method: 'POST'},
  adminChangeStatus: {
    url: '/admin/setSubAdminActivityStatus',
    method: 'PUT',
    params: true
  },
  editSubadminInfo: {
    url: '/admin/update-member-profile',
    method: 'PUT',
    params: true,
  },
  adminDeleteSubadmin: {
    url: '/admin/deleteSubadminProfile',
    method: 'PUT',
    params: true,
  },
  adminAddMerchant: {
     url: '/admin/admin-add-merchant',
     method: 'POST' 
    },
  adminGetAllMerchants: {
    url: '/admin/get-all-merchant-profile',
    method: 'GET',
  },
  adminGetDraftMerchants: {
    url: '/admin/get-all-merchant-profile',
    method: 'GET',
  },
  adminChangeMerchantStatus: {
    url: '/admin/setMerchentActivityStatus',
    method: 'PUT',
    params: true
  },
  adminEditMerchant: {
    url: '/admin/update-merchant-profile',
    method: 'PUT',
    params: true,
  },
  adminDeleteMerchant: {
    url: '/admin/deleteMarchent',
    method: 'PUT',
    params: true,
  },
  adminGetAllDetailsChangeRequests: {
    url: '/admin/viewApprovalCompanyReq',
    method: 'GET'
  },
  adminApproveChangeRequest: {
    // url: '/admin/updateApprovalCompanyReq',
    // method: 'PUT',
    // params: true
    url: '/admin/account-update-request',
    method: 'POST',
    // params: true
  },
  adminGetAllNewMerchantRequests: {
    url: '/admin/upcoming-merchant-request',
    method: 'GET'
  },
  merchantApproval: {
    url: '/admin/approval-merchant-status',
    method: 'PUT',
    params: true
  },
  addCompanyDetails: {
    url: '/merchant/add-company-details',
    method: 'POST',
  },
  registerSubMerchant: {
    url: '/merchant/subMarchentRegister',
    method: 'POST',
  },
  editSubMerchant: {
    url: '/merchant/updateProfile',
    method: 'PUT',
    params: true,
  },
  deleteSubMerchant: {
    url: '/merchant/deleteSubMarchent',
    method: 'PUT',
    params: true,
  },
  adminAddActivityType: {
    url: '/admin/admin-add-activity-type',
    method: 'POST',
  },
  adminGetAllActivityTypes: {
    url: '/admin/admin-view-activity-type',
    method: 'GET',
  },
  adminEditActivityType: {
    url: '/admin/edit-activity-type',
    method: 'PUT',
    params: true,
  },
  adminDeleteActivityType: {
    url: '/admin/delete-activity-type',
    method: 'PUT',
    params: true,
  },
  adminAddActivitySite: { url: '/admin/addActivitySite', method: 'POST' },
  adminGetAllActivitySites: { url: '/admin/viewActivitySite', method: 'GET' },
  adminGetActivitySitesForACity: {
    url: '/admin/sitename-against-city',
    method: 'GET',
    params: true
  },
  adminEditActivitySite: {
    url: '/admin/editActivitySite',
    method: 'PUT',
    params: true,
  },
  adminDeleteActivitySite: {
    url: '/admin/deleteActivitySite',
    method: 'PUT',
    params: true,
  },
  addSiteDestination: { url: '/admin/add-destination', method: 'POST' },
  getAllSiteDestinations: {
    url: '/admin/view-destination',
    method: 'GET',
    params: true
  },
  addCountry: { url: '/admin/add-country', method: 'POST' },
  getAllCountries: { url: '/admin/view-country', method: 'GET' },
  editCountry: { url: '/admin/editCountry', method: 'PUT', params: true },
  deleteCountry: { url: '/admin/deleteCountry', method: 'PUT', params: true },
  adminAddBanner: { url: '/admin/add-banner', method: 'POST' },
  adminGetAllBanners: { url: '/admin/view-banner', method: 'GET' },
  adminEditBanner: { url: '/admin/edit-banner', method: 'PUT', params: true },
  adminDeleteBanner: {
    url: '/admin/delete-banner',
    method: 'PUT',
    params: true,
  },
  adminAddLanguage: { url: '/admin/admin-add-language', method: 'POST' },
  getAllLanguages: { url: '/admin/view-all-language', method: 'GET' },
  editLanguage: { url: '/admin/editLanguage', method: 'PUT', params: true },
  changeLangStatus: {
    url: '/admin/change-status',
    method: 'PUT',
    params: true,
  },
  setActivitytrandingStatus: {
    url: '/admin/setActivitytrandingStatus',
    method: 'PUT',
    params: true,
  },

  deleteLanguage: { url: '/admin/deleteLanguage', method: 'PUT', params: true },
  adminAddSection: { url: '/admin/add-section', method: 'POST' },
  adminGetAllSection: { url: '/admin/view-section', method: 'GET' },
  adminEditSection: {
    url: '/admin/edit-section',
    method: 'PUT',
    params: true,
  },

  adminAddCategory: { url: '/admin/add-category', method: 'POST' },
  adminGetAllCategories: { url: '/admin/view-category', method: 'GET' },
  adminEditCategory: {
    url: '/admin/edit-category',
    method: 'PUT',
    params: true,
  },
  adminDelteCategory: {
    url: '/admin/delete-category',
    method: 'PUT',
    params: true,
  },

  adminAddAbout: { url: '/admin/addAboutUs', method: 'POST' },
  adminGetAllAbouts: { url: '/admin/viewAboutUs', method: 'GET' },
  adminEditAbouts: {
    url: '/admin/editAbouUs',
    method: 'PUT',
    params: true,
  },
  adminDelteAbout: { url: '/admin/deleteAboutUs', method: 'PUT', params: true },

  adminDeleteSection: {
    url: '/admin/delete-section',
    method: 'PUT',
    params: true,
  },
  adminAddCity: { url: '/admin/addCity', method: 'POST' },
  adminGetAllCities: { url: '/admin/viewCity', method: 'GET' },
  adminGetCityByCountry: { url: '/admin/viewCountryAgainstCity', method: 'GET', params: true },
  adminEditCity: { url: '/admin/editCity', method: 'PUT', params: true },
  adminDeleteCity: { url: '/admin/deleteCity', method: 'PUT', params: true },
  adminAddPressRelease: { url: '/admin/addPress', method: 'POST' },
  adminGetAllPressReleases: { url: '/admin/viewPress', method: 'GET' },
  adminEditPress: { url: '/admin/editPress', method: 'PUT', params: true },
  adminDeletePress: { url: '/admin/deletePress', method: 'PUT', params: true },
  addBlogType: { url: '/admin/addtype', method: 'POST' },
  getAllBlogTypes: { url: '/admin/viewtype', method: 'GET' },
  editBlogType: { url: '/admin/editType', method: 'PUT', params: true },
  deleteBlogType: { url: '/admin/deleteType', method: 'DELETE', params: true },
  addBlogTypeCat: { url: '/admin/addBlogCategory', method: 'POST' },
  getAllBlogTypeCat: { url: '/admin/viewBlogCategory', method: 'GET' },
  deleteBlogTypeCat: { url: '/admin/delete-blog-category', method: 'DELETE', params: true },
  adminAddBlog: { url: '/admin/addBlog', method: 'POST' },
  adminGetAllBlogs: { url: '/admin/viewBlog', method: 'GET' },
  adminEditBlog: { url: '/admin/editBlog', method: 'PUT', params: true },
  adminDeleteBlog: { url: '/admin/deleteBlog', method: 'DELETE', params: true },
  adminAddCareer: { url: '/admin/addCareer', method: 'POST' },
  adminGetAllCareers: { url: '/admin/viewCareer', method: 'GET' },
  adminEditCareer: { url: '/admin/editCareer', method: 'PUT', params: true },
  adminDeleteCareer: {
    url: '/admin/deleteCareer',
    method: 'PUT',
    params: true,
  },
  adminAddLegalNotice: { url: '/admin/addLegalNotice', method: 'POST' },
  adminGetAllLegalNotices: { url: '/admin/viewLegalNotice', method: 'GET' },
  adminAddTerms: { url: '/admin/addTermsCondition', method: 'POST' },
  adminGetAllTerms: { url: '/admin/viewTermsCondition', method: 'GET' },
  adminEditTerms: {
    url: '/admin/editTermsCondition',
    method: 'PUT',
    params: true,
  },
  adminDeleteTerms: {
    url: '/admin/deleteTermsCondition',
    method: 'DELETE',
    params: true,
  },

  adminAddCookiesMarketing: {
    url: '/admin/addCookiesMarketing',
    method: 'POST',
  },
  adminGetAllCookiesMarketing: {
    url: '/admin/viewCookiesMarketing',
    method: 'GET',
  },
  adminEditCookiesMarketing: {
    url: '/admin/editCookiesMarketing',
    method: 'PUT',
    params: true,
  },
  adminDeleteCookiesMarketing: {
    url: '/admin/deleteCookiesMarketing',
    method: 'PUT',
    params: true,
  },
  editLegalNotice: {
    url: '/admin/editLegalNotice',
    method: 'PUT',
    params: true,
  },
  adminDeleteLegalNotice: {
    url: '/admin/deleteLegalNotice',
    method: 'PUT',
    params: true,
  },
  adminAddPrivacyPolicy: { url: '/admin/addPrivacyPolicy', method: 'POST' },
  getAllPrivacyPolicies: { url: '/admin/viewPrivacyPolicy', method: 'GET' },
  editPrivacyPolicy: {
    url: '/admin/editPrivacyPolicy',
    method: 'PUT',
    params: true,
  },
  adminDeletePrivacyPolicy: {
    url: '/admin/deletePrivacyPolicy',
    method: 'DELETE',
    params: true,
  },
  adminAddContact: { url: '/admin/addContact', method: 'POST' },
  adminGetAllContacts: { url: '/admin/viewContact', method: 'GET' },
  adminEditContact: { url: '/admin/editContact', method: 'PUT', params: true },
  adminDeleteContact: {
    url: '/admin/deleteContact',
    method: 'PUT',
    params: true,
  },
  addContactEmail: { url: "/admin/manage-email", method: 'POST' },
  getContactEmail: { url: '/admin/get-email', method: 'GET' },
  deleteContactEmail: { url: "/admin/delete-email", method: 'DELETE' },
  adminAddActivity: { url: '/admin/addActivityDetails', method: 'POST' },
  adminGetAllActivity: { url: '/admin/viewActivityDetails', method: 'GET' },
  adminEditActivity: { url: '/admin/editActivityDetails', method: 'PUT', params: true },
  adminSetActivityStatus: { url: '/admin/setActivityStatus', method: 'PUT', params: true },
  adminDeleteActivity: { url: '/admin/deleteActivityDetails', method: 'PUT', params: true },
  getAllMerchantActivityAdditions: {
    url: '/admin/viewMerchantApprovalActivity',
    method: 'GET'
  },
  approveMerchantAddedActivity: {
    url: '/admin/updateApprovalActivityReq',
    method: 'PUT',
    params: true
  },
  adminAddSpecialOffer: { url: '/admin/createSpecialOffer', method: 'POST' },
  adminGetAllSpecialOffers: { url: '/admin/viewSpecialOffer', method: 'GET' },
  adminDeleteSpecialOffer: {
    url: '/admin/deleteSpecialOffer',
    method: 'PUT',
    params: true,
  },
  adminAddTourModule: { url: '/admin/addTourModule', method: 'POST' },
  adminGetAllTourModules: { url: '/admin/viewTourModule', method: 'GET' },
  adminEditTourModule: { url: '/admin/editTourModule', method: 'PUT', params: true },
  adminDeleteTourModule: { url: '/admin/deleteTourModule', method: 'PUT', params: true },
  adminAddDigitalInfo: { url: '/admin/addInfoDigitalService', method: 'POST' },
  adminGetAllDigitalInfo: { url: '/admin/viewInfoDigitalService', method: 'GET' },
  adminEditDigitalInfo: { url: '/admin/editInfoDigitalService', method: 'PUT', params: true },
  adminAddSitemap: { url: '/admin/addSitemap', method: 'POST' },
  adminGetAllSitemaps: { url: '/admin/viewSitemap', method: 'GET' },
  adminEditSitemap: { url: '/admin/editSitemap', method: 'PUT', params: true },
  adminDeleteSitemap: { url: '/admin/deleteSitemap', method: 'PUT', params: true },
  adminAddParticipants: { url: '/admin/addParticipent', method: 'POST' },
  adminGetAllParticipants: { url: '/admin/viewParticipent', method: 'GET' },
  adminEditParticipants: { url: '/admin/editParticipent', method: 'PUT', params: true },
  adminDeleteParticipants: { url: '/admin/deleteParticipent', method: 'PUT', params: true },
  adminAssignActivity: { url: '/admin/addAssignAcitivity', method: 'POST' },
  adminGetAllAssignedActivities: { url: '/admin/viewAssignAcitivity', method: 'GET' },
  adminEditAssignedActivity: { url: '/admin/editAssignAcitivity', method: 'PUT', params: true },
  adminDeleteAssignedActivity: { url: '/admin/deleteAssignAcitivity', method: 'PUT', params: true },
  adminAddGift: { url: '/admin/addGift', method: 'POST' },
  adminGetAllGifts: { url: '/admin/viewGift', method: 'GET' },
  adminEditGift: { url: '/admin/editGift', method: 'PUT', params: true },
  adminDeleteGift: { url: '/admin/deleteGift', method: 'PUT', params: true },
  adminEditAboutUs: { url: 'admin/edit-banner', method: 'PUT', params: true },
  editAdminSpecialOffer: { url: '/admin/editSpecialOffer', method: 'PUT', params: true },
  editTopic: { url: '/admin/editUserContactTopic', method: 'PUT', params: true },
  deleteTopic: { url: '/admin/deleteContactUserTopic', method: 'PUT', params: true },
  editQuestion: { url: '/admin/editContactUserQuestion', method: 'PUT', params: true },
  deleteQuestion: { url: '/admin/deleteContactUserQuestion', method: 'PUT', params: true },
  createSubQuestion: { url: '/admin/createContactUserSubQuestion', method: 'POST' },
  editSubQuestion: { url: '/admin/editContactUserSubQuestion', method: 'PUT', params: true },
  deleteSubQuestion: { url: '/admin/deleteContactUserSubQuestion', method: 'PUT', params: true },
  addActivityAvailability: { url: '/admin/add-availability', method: 'POST' },
  getAvailabilityForAMonth: {
    url: '/admin/view-monthwise-availability',
    method: 'POST'
  },
  getActivityAvailabilityForADate: { url: '/admin/view-availability', method: 'POST' },
  editActivityAvailability: {
    url: '/admin/edit-availability',
    method: 'PUT',
    params: true
  },
  cancelAvailabilityForDates: { url: '/admin/date-wise-delete', method: 'PUT', params: true },
  viewCityZipCode: { url: '/admin/view-zipcode', method: 'POST' },
  addCityZipCode: { url: '/admin/add-zipcode', method: 'POST' },
  getAllBookings: { url: '/admin/view-booking-details', method: 'POST' },
  getSingleBooking:{url:'/admin/view-singleking-details',method:'GET', params: true},
  getMerchantInvoice: {
    url: '/admin/get-merchant-invoice',
    method: 'POST',
  },
  getOrderInvoices: {
    url: '/admin/billing-history',
    method: 'GET',
    params: true
  },
  addBookingStatusTag: { url: "/admin/add-payment-tag", method: "POST" },
  getAllBookingStatusTags: { url: "/admin/get-payment-tag", method: "GET" },
  editBookingStatusTag: {
    url: "/admin/update-payment-tag",
    method: 'PUT',
    params: true
  },
  deleteBookingStatusTag: {
    url: "/admin/delete-payment-tag",
    method: 'PUT',
    params: true
  },
  adminDeleteGiftqusstion: {
    url: "/admin/delete-gift-question",
    method: 'DELETE',
    params: true
  },
  adminEditGiftqusstion: {
    url: "/admin/update-gift-question",
    method: 'PUT',
    params: true
  },
  adminAddMerchantCommission: {
    url: '/admin/add-commission-percentage', method: 'POST'
  },
  adminManageCommission: {
    url: '/admin/view-commission-percentage', method: 'GET'
  },
  adminManageCommissionList: {
    url: '/admin/view-commission-percentage-list', method: 'GET'
  },
  editMerchantCommission: {
    url: '/admin/update-commission-percentage',
    method: 'PUT',
    params: true
  },
  deleteMerchantCommission: {
    url: "/admin/delete-commission-percentage",
    method: 'DELETE',
    params: true
  }
};

// Hooks
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

// Helpers
import { toast } from "react-toastify";

// Utils
import { apiCallSuccess, apiCallError } from "../../../utils/common-utils";

// API
import { JSON_API } from "../../../services/api/jsonApiCallWithInterceptor";

// Redux actions
import { getAllGifts } from "../../../redux/adminSlice/giftSlice";

// Components
import Layout from "../../../Layout";
import Table from "../../../Component/Table";
import { useNavigate, useParams } from "react-router-dom";

const EditGift = () => {
  // function AddLanguage() {
  const dispatch = useDispatch();
  const [error, setError] = useState("");
  const [modal, setModal] = useState(false);
  const [modalToggleView, setmodalToggleView] = useState(null);
  const [itemIndex, setitemIndex] = useState(0);
  const [newPrice, setnewPrice] = useState("");
  const [deleteId, setdeleteId] = useState("");
  const navigate = useNavigate();
  const params = useParams();
  const priceRegex = /^[0-9]+$/;

  // Redux stores
  const { gifts } = useSelector((state) => state.giftsSlice);
  console.log("gifts", gifts);
  const giftData = gifts.find((item) => item?._id === params.id);
  useEffect(() => {
    dispatch(getAllGifts());
    setnewPrice(giftData?.price);
  }, []);

  const openModal = () => {
    setModal(true);
  };

  const handleEditSubmit = async (event) => {
    event.preventDefault();

    if (!priceRegex.test(newPrice)) {
      toast.error("Please add a valid price in digits");
    } else {
      // console.log(newPrice);
      let check = gifts?.find((item) => item?.price == newPrice);
      // console.log("check", check);

      if (check) {
        toast.error("This amount has already been added.");
      } else {
        try {
          let res = await JSON_API["adminEditGift"]({
            id: params.id,
            price: newPrice,
          });

          if (res.isSuccess) {
            dispatch(getAllGifts());
            setModal(false);
            setnewPrice("");
            toast.success("Gift updated successfully ");
            navigate("/manage-gift");
          } else {
            apiCallError(res?.error?.message, setError);
          }
        } catch (error) {
          apiCallError(error.message, setError);
        }
      }
    }
  };

  const tableData = {
    columns: [
      {
        label: "SL",
        field: "sl",
        sort: "asc",
        width: 270,
      },
      {
        label: "Price",
        field: "price",
        sort: "asc",
        width: 150,
      },
      // {
      //   label: "Status",
      //   field: "status",
      //   sort: "asc",
      //   width: 150,
      // },
      {
        label: "Action",
        field: "action",
        sort: "asc",
        width: 150,
      },
    ],
    rows:
      gifts?.length > 0
        ? gifts.map((item, idx) => {
            return {
              sl: idx + 1,
              price: (
                <div className="widget-content p-0">
                  <div className="widget-content-wrapper">
                    <div className="widget-content-left flex2">
                      <div
                        className="widget-heading"
                        style={{ opacity: 1, textAlign: "center" }}
                      >
                        {item?.price}
                      </div>
                      {/* <div className="widget-subheading opacity-7">UI Designer</div> */}
                    </div>
                  </div>
                </div>
              ),
              // status: (
              //   <button
              //     className={`badge ${
              //       item?.status ? "bg-success" : "bg-danger"
              //     } border-0`}
              //   >
              //     {item?.status ? "Active" : "Deactivated"}
              //   </button>
              // ),
              action: (
                <div>
                  <button
                    className="me-2 btn-icon btn-icon-only btn btn-outline-danger"
                    onClick={() => {
                      openModal();
                      setmodalToggleView(1);
                      setdeleteId(gifts[idx]._id);
                    }}
                  >
                    <i className="fa-solid fa-trash-can" />
                  </button>
                  <button
                    className="me-2 btn-icon btn-icon-only btn btn-outline-success"
                    onClick={() => {
                      openModal();
                      setmodalToggleView(0);
                      setitemIndex(idx);
                      setnewPrice(gifts[idx]?.price);
                    }}
                  >
                    <i class="fa-regular fa-pen-to-square"></i>
                  </button>
                </div>
              ),
            };
          })
        : [],
  };

  return (
    <form className="">
      <div component="div" className="TabsAnimation appear-done enter-done">
        <div className="main-card mb-3 card">
          <div className="card-body">
            <div className="card-title">Edit Gift</div>
            <div className="col-md-12">
              <div className="mb-3">
                <label
                  htmlFor="price"
                  className="form-label"
                  style={{ margin: "0.5rem 0.5rem" }}
                >
                  Price
                </label>
                <input
                  name="price"
                  id="price"
                  type="number"
                  className="form-control"
                  autoComplete="false"
                  value={newPrice}
                  onChange={(e) => {
                    if (e.target.value < 0) {
                      e.target.value = "";
                    }
                    setnewPrice(e.target.value);
                  }}
                />
              </div>
            </div>
          </div>
          <div className="d-flex m-2">
            <button
              type="button"
              className="btn btn-link"
              onClick={() => {
                // setModal(false);
                // setnewLanguage("");
                navigate("/manage-gift");
              }}
            >
              Cancel
            </button>
            <button
              type="submit"
              className="mt-2 btn btn-primary  d-table"
              onClick={(e) => handleEditSubmit(e)}
            >
              Edit
            </button>
          </div>
        </div>
      </div>
    </form>
  );
};

export default EditGift;

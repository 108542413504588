import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { ApiHelperFunction } from '../../services/api/apiHelpers';


let initialState = {
    status: 'idle',
    isLoading: false,
    isSuccess: false,
    isError: false,
    bookings: [],
};

export const getAllBookings = createAsyncThunk('get_all_bookings', async (id) => {
    let response = await ApiHelperFunction({
        urlPath: `/merchant/viewMerchantInvoice/${id}`,
        method: 'GET',
    });
    if (response.status === 200) {
        return response.data;
    } else {
        console.log("Can't get data. Something went wrong");
    }
});

export const BookingSlice = createSlice({
    name: 'bookings_actions',
    initialState,
    reducers: {
        clearBookingState: state => {
            state.status = 'idle';
            state.isLoading = false;
            state.isSuccess = false;
            state.isError = false;
            state.bookings = [];
        },
    },
    extraReducers: builder => {
        builder
            .addCase(getAllBookings.pending, state => {
                state.status = 'loading';
                state.isLoading = true;
            })
            .addCase(getAllBookings.fulfilled, (state, { payload }) => {
                state.status = 'success';
                state.isSuccess = true;
                state.bookings = payload?.data;
            })
            .addCase(getAllBookings.rejected, state => {
                state.status = 'failed';
                state.isError = true;
                state.isSuccess = false;
            });
    },
});

export const { clearBookingState } = BookingSlice.actions;

export default BookingSlice.reducer;

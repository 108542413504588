import React from "react";
import { DateRangePicker } from "react-date-range";

export default function CalenderModal({ closeModal,today,handleDateChange,dateRangeArr,errors ,cssmodal ,maxDate}) {
    return (
        <>
            <div className="modal fade show" role="dialog" tabIndex={-1}>
                <div className="modal-dialog" role="document">
                    <button
                        className="btn-close"
                        style={{
                            position: "absolute",
                            top: 15,
                            right: 15,
                            zIndex: "1",
                            cursor: "pointer",
                        }}
                        onClick={() => {
                            closeModal(false);
                        }}
                    ></button>
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title">Choose Dates</h5>
                        </div>
                        <div className="modal-body">
                            <div className="col-md-12">
                                <div className="mb-3">
                                    <label
                                        className="form-label font-weight-bold"
                                        htmlFor="tourDate"
                                    >
                                        Date*
                                    </label>
                                    <DateRangePicker
                                        minDate={today}
                                        months={2} 
                                        maxDate={maxDate}
                                        direction="horizontal"
                                        initialFocusedRange={[0, 0]}
                                        onChange={(item) => {
                                            handleDateChange(item);
                                        }}
                                        ranges={dateRangeArr}
                                        
                                    />
                                </div>
                                {errors.tourDate && (
                                    <span className="errorMessageText text-danger">
                                        {errors.tourDate}
                                    </span>
                                )}
                            </div>
                        </div>
                        <div className="modal-footer">
                            <button
                                type="button"
                                className="btn btn-link"
                                onClick={() => {
                                    closeModal(false);
                                }}
                            >
                                Cancel
                            </button>
                            <button type="button" className="btn btn-primary"
                            onClick={() => {
                                closeModal(false);
                                cssmodal(false)
                            }}
                            >
                               Done
                            </button>{" "}
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

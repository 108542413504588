import React from "react";
import Loader from "../../../Component/Loader";
import { useState } from "react";
import Select from "react-select";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { requestData } from "../../../services/api/apiHelpers";
import toast from "react-hot-toast";
import { useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";

const AddMail = () => {
  const URLid = useParams();
  const id = URLid?.id;
  const navigate = useNavigate();
  const [isLoading, setisLoading] = useState(false);

  const allTypes = [
    {
      value: "Product Approve",
      label: "Product Approve",
    },
    {
      value: "Product Reject",
      label: "Product Reject",
    },
    {
      value: "Merchant Save As Draft",
      label: "Merchant Save As Draft",
    },
    {
      value: "Merchant Registration Successfull",
      label: "Merchant Registration Successfull",
    },
    {
      value: "Merchant Approve",
      label: "Merchant Approve",
    },
    {
      value: "Merchant Reject",
      label: "Merchant Reject",
    },
    {
      value: "User Booking Successfull",
      label: "User Booking Successfull",
    },
    {
      value: "User Booking Cancel",
      label: "User Booking Cancel",
    },
    {
      value: "Merchant Booking Successfull",
      label: "Merchant Booking Successfull",
    },
    {
      value: "Merchant Booking Cancel",
      label: "Merchant Booking Cancel",
    },
    {
      value: "SuperAdmin Get Mail When Merchant Cancel Booking",
      label: "SuperAdmin Get Mail When Merchant Cancel Booking",
    },
  ];

  const initial = {
    topic: "",
    description: {
      mailSubject: "",
      mailBody: "",
    },
  };

  const [mailData, setMailData] = useState(initial);

  const handleCKEditorChange = (event, editor) => {
    const data = editor.getData();
    setMailData((prev) => ({
      ...prev,
      description: {
        ...prev.description,
        mailBody: data,
      },
    }));
    console.log({ event, editor, data });
  };

  console.log("mailData", mailData);

  const handleSubmit = async (e) => {
    e.preventDefault();

    if(!mailData.topic){
      toast.error("Topic Name is required")
      return false
    }
    if(!mailData.description.mailSubject){
      toast.error("Mail Subject is required")
      return false
    }
    if(!mailData.description.mailBody){
      toast.error("Mail Body is required")
      return false
    }
    setisLoading(true);

    if (id) {
      try {
        const res = await requestData(
          `/admin/update-mail/${id}`,
          "PUT",
          mailData
        );
        if (res && res.status) {
          toast.success("Mail Updated successfully");
          setMailData(initial);
          navigate(-1);
        }
        setisLoading(false);
      } catch (error) {
        toast.error(error.message || "Server Error");
        setisLoading(false);
      }
    } else {
      try {
        const res = await requestData("/admin/add-mail", "POST", mailData);
        if (res && res.status) {
          toast.success("Mail Added successfully");
          setMailData(initial);
          setisLoading(false);
        }
        else{
          toast.error(res.message)
          setisLoading(false); 
        }
      } catch (error) {
        console.log("ssssxxxxs", error)
        toast.error(error?.message || "Server Error");
        setisLoading(false);
      }
    }
  };

  const getSingleMail = async (id) => {
    setisLoading(true);
    try {
      const res = await requestData(`/admin/get-mail/${id}`, "GET");
      console.log("dddddddd", res?.data);
      if (res && res.success) {
        setMailData((prev) => ({
          ...prev,
          topic: res?.data?.topic,
          description: {
            ...prev.description,
            mailSubject: res?.data?.description?.mailSubject,
            mailBody: res?.data?.description?.mailBody,
          },
        }));
        setisLoading(false);
      }
    } catch (error) {
      toast.error(error.message || "Server Error");
      setisLoading(false);
    }
  };

  useEffect(() => {
    if (id) getSingleMail(id);
  }, []);

  return (
    <>
      {isLoading && <Loader />}
      <form className="" onSubmit={(e) => handleSubmit(e)}>
        <div component="div" className="TabsAnimation appear-done enter-done">
          <div className="main-card mb-3 card">
            <div className="card-body">
              <div className="card-title">
                {id ? "Update The mail" : "Add All Topic Wise Mail"}
              </div>
              <div className="row">
                {/* topic */}
                <div className="col-md-12">
                  <div className="mb-3">
                    <label
                      htmlFor="topic"
                      className="form-label font-weight-bold"
                    >
                      Topic*
                    </label>

                    <Select
                      name="topic"
                      id="topic"
                      classNamePrefix="select"
                      options={allTypes?.map((item, idx) => ({
                        value: item?.value,
                        label: item?.label,
                      }))}
                      value={{
                        value: mailData?.topic,
                        label: mailData?.topic,
                      }}
                      onChange={(val) => {
                        setMailData((prev) => {
                          return {
                            ...prev,
                            topic: val?.value,
                          };
                        });
                      }}
                    />
                  </div>
                </div>
                {/* mail subject */}
                <div className="col-md-12">
                  <div className="mb-3">
                    <label
                      htmlFor="mailSubject"
                      className="form-label font-weight-bold"
                    >
                      Mail Subject
                    </label>

                    <input
                      type="text"
                      name="mailSubject"
                      id="mailSubject"
                      class="form-control"
                      aria-describedby="emailHelp"
                      placeholder="Enter Mail Subject"
                      onChange={(e) => {
                        const { value } = e.target;
                        setMailData((prev) => ({
                          ...prev,
                          description: {
                            ...prev.description,
                            mailSubject: value,
                          },
                        }));
                      }}
                      value={mailData?.description?.mailSubject}
                    />
                  </div>
                  {/* {errors.name && (
                        <span className="errorMessageText text-danger">
                          {errors.name}
                        </span>
                      )} */}
                </div>
                {/* mail body */}
                <div className="col-md-12">
                  <div className="mb-3">
                    <label htmlFor="topPriority" className="font-weight-bold">
                      Mail Body*
                    </label>
                    {mailData && (
                      <CKEditor
                        editor={ClassicEditor}
                        config={{
                          toolbar: {
                            items: [
                              "undo",
                              "redo",
                              "|",
                              "heading",
                              "fontfamily",
                              "|",
                              "fontfamily",
                              "fontsize",
                              "fontColor",
                              "fontBackgroundColor",
                              "|",
                              "bold",
                              "italic",
                              "strikethrough",
                              "subscript",
                              "superscript",
                              "code",
                              //  '-', // break point
                              "|",
                              "alignment",
                              "link",
                              "uploadImage",
                              "blockQuote",
                              "codeBlock",
                              "|",
                              "bulletedList",
                              "numberedList",
                              "todoList",
                              "outdent",
                              "indent",
                              "|",
                              "insertTable",
                            ],
                            //  shouldNotGroupWhenFull: true
                          },
                          placeholder: "Start typing here...",
                        }}
                        data={mailData?.description?.mailBody}
                        onChange={handleCKEditorChange}
                      />
                    )}
                  </div>
                  {/* {errors.topPriority && (
                        <span className="errorMessageText text-danger">
                          {errors.topPriority}
                        </span>
                      )} */}
                </div>
              </div>
              {id ? (
                <button
                  className="mt-2 btn btn-primary ml-auto d-table"
                  type="submit"
                  style={{ margin: "5px auto" }}
                >
                  Update
                </button>
              ) : (
                <button
                  className="mt-2 btn btn-primary ml-auto d-table"
                  type="submit"
                  style={{ margin: "5px auto" }}
                >
                  Submit
                </button>
              )}
            </div>
          </div>
        </div>
      </form>
    </>
  );
};

export default AddMail;

import HttpClientXml from '../../utils/HttpClientXml';

async function addfaqtype(data) {
  let endpoint = 'admin/add-type-FAQ';
  return HttpClientXml.post(endpoint, data);
}

async function getAllFaqType() {
  let endpoint = 'admin/view-FAQ-Types';
  return HttpClientXml.get(endpoint);
}

async function addfaq(data) {
  let endpoint = 'admin/add-help-FAQ';
  return HttpClientXml.post(endpoint, data);
}
async function getAllFaq() {
  let endpoint = 'admin/view-FAQ';
  return HttpClientXml.get(endpoint);
}

async function addcontacttype(data) {
  let endpoint = 'admin/createUserContactTopic';
  return HttpClientXml.post(endpoint, data);
}
async function getAllContactTopic() {
  let endpoint = 'admin/viewUserContactTopic';
  return HttpClientXml.get(endpoint);
}
async function addsubQuestion(data) {
  let endpoint = 'admin/add-ContactSubQuestion';
  return HttpClientXml.post(endpoint, data);
}
async function getAllTopics() {
  let endpoint = 'admin/viewUserContactTopic';
  return HttpClientXml.get(endpoint);
}
async function addQuestion(data) {
  let endpoint = 'admin/createUserContactQuestion';
  return HttpClientXml.post(endpoint, data);
}
async function getAllSubQuestions() {
  let endpoint = 'admin/viewContactUserSubQuestion';
  return HttpClientXml.get(endpoint);
}
async function getAllcounts() {
  let endpoint = 'admin/total-person-count';
  return HttpClientXml.get(endpoint);
}
async function addgiftquestions(data){
   return HttpClientXml.post(`admin/add-gift-question`,data)
}
async function updategiftquestions(data,id){
  return HttpClientXml.put(`admin/update-gift-question/${id}`,data)
}
async function viewgiftquestions(){
  return HttpClientXml.get(`admin/view-gift-question`)
}
async function deleteavailability(id){
  return HttpClientXml.deletemethod(`admin/delete-availability-single/${id}`)
}
async function deleteDestination(id){
  return HttpClientXml.deletemethod(`admin/delete-destination/${id}`)
}
async function updateDestination(id,data){
  return HttpClientXml.put(`admin/edit-destination/${id}`,data)
}

const homeService = {
  addfaqtype,
  getAllFaqType,
  addfaq,
  getAllFaq,
  addcontacttype,
  getAllContactTopic,
  addsubQuestion,
  getAllTopics,
  addQuestion,
  getAllSubQuestions,
  getAllcounts,
  addgiftquestions,
  viewgiftquestions,
  updategiftquestions,
  deleteavailability,
  deleteDestination,
  updateDestination
};
export default homeService;

import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";

export default function Notification() {
  const [newMerchantCount, setNewMerchantCount] = useState(null);
  const [productChangeCount, setProductChangeCount] = useState(null);

  const { merchant_additions } = useSelector(
    (state) => state.adminActivityActionSlice
  );

  useEffect(() => {
    let filterData = merchant_additions?.filter(
      (item) => item?.isApproval === false && !item?.rejectedStatus
    );
    setNewMerchantCount(filterData);

    let changeCountRequest = merchant_additions?.filter(
      (item) => item?.isEdited === true
    );
    setProductChangeCount(changeCountRequest);
  }, [merchant_additions]);

  const navigate = useNavigate();
  const newActivityAlert = () => {
    Swal.fire({
      title: "New activity added ",
      type: "success",
      text: "New activity is added by merchants, Please check them.",
    }).then((result) => {
      navigate("check-merchant-additions");
    });
  };

  const activityChangeAlert = () => {
    Swal.fire({
      title: "Activity changed request ",
      type: "success",
      text: "New activity is added by merchants, Please check them.",
    }).then((result) => {
      navigate("product-change-requests");
    });
  };

  const HandleClick = () => {
    Swal.fire({
      title: "Activity changed request ",
      type: "success",
      text: "Some product is awating for your approval, Please check them.",
    }).then((result) => {
      navigate("manage-admin");
    });
  };

  const HandleClick1 = () => {
    Swal.fire({
      title: "Are you sure?",
      text: "User will have Admin Privileges",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes!",
      cancelButtonText: "No",
    });
  };
  const HandleClick12 = () => {
    Swal.fire({
      icon: "error",
      title: "Oops...",
      text: "Something went wrong!",
      footer: "<a href>Why do I have this issue?</a>",
    });
  };
  const HandleClicktop = () => {
    Swal.fire({
      position: "top-end",
      icon: "success",
      title: "Your work has been saved",
      showConfirmButton: false,
      timer: 1500,
    });
  };
  const HandleClickAutoclose = () => {
    let timerInterval;
    Swal.fire({
      title: "Auto close alert!",
      html: "I will close in <b></b> milliseconds.",
      timer: 1000,
      timerProgressBar: true,
      onBeforeOpen: () => {
        Swal.showLoading();
        timerInterval = setInterval(() => {
          const content = Swal.getContent();
          if (content) {
            const b = content.querySelector("b");
            if (b) {
              b.textContent = Swal.getTimerLeft();
            }
          }
        }, 100);
      },
      onClose: () => {
        clearInterval(timerInterval);
      },
    }).then((result) => {
      if (result.dismiss === Swal.DismissReason.timer) {
      }
    });
  };

  useEffect(() => {
    if (newMerchantCount?.length > 0) {
      newActivityAlert();
    } else if (productChangeCount?.length > 0) {
      activityChangeAlert();
    }
  }, []);

  return (
    <div>
      <div class="row" className="hdr d-none">
        <div class="col-sm-12 btn btn-info d-none">SweetAlert2 In React</div>
      </div>
      <div style={{ paddingTop: "10px" }} className="hdr d-none">
        <button class="btn btn-info btn" onClick={HandleClick}>
          Success
        </button>
        <button class="btn btn-success btn" onClick={HandleClick1}>
          Confirm
        </button>
        <button class="btn btn-primary btn" onClick={HandleClick12}>
          Confirm Box
        </button>
        <button class="btn btn-primary btn" onClick={HandleClicktop}>
          Top Side
        </button>
        <button class="btn btn-primary btn" onClick={HandleClickAutoclose}>
          Auto Close
        </button>
      </div>
    </div>
  );
}

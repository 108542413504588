import React, { useState, useEffect, useRef, useReducer } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { Country } from "country-state-city";
import { capitalizeFirstLetter, getOnlyFullDate } from "../../../utils";
import { JSON_API } from "../../../services/api/jsonApiCallWithInterceptor";
import {
  requestData,
  fileUpload,
  ApiHelperFunction,
} from "../../../services/api/apiHelpers";
import { getAllLanguages } from "../../../redux/adminSlice/languageSlice";
import DatePicker from "react-date-picker";
import Loader from "../../../Component/Loader";
import Select from "react-select";

import "react-date-picker/dist/DatePicker.css";
import "react-calendar/dist/Calendar.css";
import "./styles.css";
import "react-phone-number-input/style.css";
import PhoneInput from "../../../constants/PhoneInput";

import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";
import currenciesJson from "../../../constants/currencies.json";
import useCountries from "../../../Hooks/useCountries";

let initialValues = {
  firstName: "",
  lastName: "",
  email: "",
  password: "",
  langId: "",
  mobile: "",
  city: "",
  country: "",
  zipId: "",
  zip: "",
  image: "",
  commisionPercentage: "",
  marBankAccNo: "",
  marBankIfscCode: "",
  marBankName: "",
  marAccHolderName: "",
  legalStatus: "",
  companyName: "",
  legalCompanyName: "",
  companyMobile: "",
  directorName: "",
  individualName: "",
  companyDob: "",
  companyStreetAddress: "",
  postalCode: "",
  compCity: "",
  compState: "",
  compStateCode: "",
  compCountry: "",
  compCountryCode: "",
  licenseNumber: "",
  registrationNumber: "",
  description: "",
  socialLink: "",
  logo: "",
  insurancePolicy: "",
  compamyRegistrationImage: "",
  saveAsDraft: false,
  isApproval: true,
  rejectedStatus: false,
  payeeType: "",
  accountType: "",
  branchName: "",
  bankAcctCurrency: "",
  swiftCode: "",
  bankCode: "",
  branchCode: "",
  countryId: "",
  cityId: "",
  phonecode: "",
  phonecodecompany: "",

  isthirdperty: null,
  thirdpertyId: undefined,
  AccesKey: "",
  secretKey: ""
};

let createErrors = {
  firstName: "",
  lastName: "",
  email: "",
  password: "",
  langId: "",
  mobile: "",
  city: "",
  country: "",
  countryId: "",
  cityId: "",
  zip: "",
  image: "",
  commisionPercentage: "",
  marBankAccNo: "",
  marBankIfscCode: "",
  marBankName: "",
  marAccHolderName: "",
  legalStatus: "",
  companyName: "",
  legalCompanyName: "",
  companyMobile: "",
  directorName: "",
  individualName: "",
  companyDob: "",
  companyStreetAddress: "",
  postalCode: "",
  compCity: "",
  compState: "",
  compCountry: "",
  licenseNumber: "",
  registrationNumber: "",
  description: "",
  socialLink: "",
  logo: "",
  insurancePolicy: "",
  compamyRegistrationImage: "",
  payeeType: "",
  accountType: "",

  phonecode: "",
  phonecodecompany: "",
};

var imageReg = /[\/.](gif|jpg|jpeg|tiff|png|webp)$/i;
var emailReg =
  /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
var bankAccountRegex = /^[A-Za-z0-9]{10}$/;
var numRegXp = /^[0-9]+$/;

const worldCities = require("../../../constants/worldcities.json");
let currencies = Object.values(currenciesJson);

export default function AddMarchent() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [img, setimg] = useState("");
  const [citiesForCountry, setcitiesForCountry] = useState([]);
  const [zipDropdownOne, setzipDropdownOne] = useState(false);
  const [zipDropdownTwo, setzipDropdownTwo] = useState("");
  const [zipcodesForCity, setzipcodesForCity] = useState([]);
  const [logo, setlogo] = useState("");
  const [insurancePolicy, setinsurancePolicy] = useState("");
  const [compRegCert, setcompRegCert] = useState("");
  const [merchant, setmerchant] = useState(initialValues);
  const imgInputRef = useRef(null);
  const firstScroll = useRef(null);
  const [error, setError] = useState("");
  const [emailSugg, setEmailSugg] = useState(null);
  const [errors, seterrors] = useState(createErrors);
  const [isLoading, setisLoading] = useState(false);
  const [uploadedImg, setuploadedImg] = useState("");
  const [uploadedLogo, setuploadedLogo] = useState("");
  const [upInsurancePolicy, setupInsurancePolicy] = useState("");
  const [uploadedcompRegCert, setuploadedcompRegCert] = useState("");
  const [filterEmails, setFilterEmails] = useState([]);
  const { countries, cities, fetchCities, loading } = useCountries();
  const companyCityCountry = useCountries()

  const { languages } = useSelector((state) => state.languageSlice);
  const [thirdPartyData, setThirdPartyData] = useState([])

  const [formProgress, setProgress] = useState(0);
  useEffect(() => {
    dispatch(getAllLanguages());
    getEmailSuggesion();
    getAllThirdParty()
  }, []);

  const calcProgress = () => {
    let calc = 0;
    setProgress(0);
    if (merchant?.country != "") {
      calc += 5;
    }
    if (merchant?.city != "") {
      calc += 5;
    }
    if (merchant?.zip != "") {
      calc += 5;
    }
    if (merchant?.companyName != "") {
      calc += 5;
    }
    if (merchant?.email != "") {
      calc += 5;
    }
    if (merchant?.password != "") {
      calc += 5;
    }
    if (merchant?.firstName != "") {
      calc += 5;
    }
    if (merchant?.lastName != "") {
      calc += 5;
    }
    if (merchant?.mobile != "") {
      calc += 5;
    }
    if (merchant?.commisionPercentage != "") {
      calc += 5;
    }
    if (merchant?.marBankName != "") {
      calc += 5;
    }
    if (merchant?.marAccHolderName != "") {
      calc += 5;
    }
    if (merchant?.marBankAccNo != "") {
      calc += 5;
    }
    if (merchant?.setPhoneCodeCompany != "") {
      calc += 5;
    }
    if (merchant?.legalStatus != "") {
      calc += 5;
    }
    if (merchant?.legalCompanyName != "") {
      calc += 5;
    }
    if (merchant?.compCountry != "") {
      calc += 5;
    }
    if (merchant?.compCity != "") {
      calc += 5;
    }
    if (merchant?.companyStreetAddress != "") {
      calc += 5;
    }
    if (merchant?.postalCode != "") {
      calc += 5;
    }
    if (merchant?.licenseNumber != "") {
      calc += 5;
    }
    if (merchant?.registrationNumber != "") {
      calc += 5;
    }

    setProgress(calc);
    return calc;
  };
  const getEmailSuggesion = async () => {
    let response = await ApiHelperFunction({
      urlPath: "/admin/merchant-random-email",
      method: "GET",
    });

    if (response && response?.status === 200) {
      setEmailSugg(response?.data.data);
    }
  };

  const getCityAgainstCountry = (countryName) => {
    setcitiesForCountry(
      worldCities.filter((item) => item.country === countryName)
    );
  };

  const getZipcodesForCity = async (cityName) => {
    let res = await requestData("/admin/view-zipcode", "POST", { cityName });

    if (res && res.status) {
      setzipcodesForCity(res.data);
      return res.data;
    }
  };

  const handleChange = (event) => {
    if (event.target.name === "email") {
      setmerchant({
        ...merchant,
        [event?.target?.name]: event.target.value,
      });
      if (event.target.value !== "") {
        let emails = filterEmail(event.target.value);
        if (emails?.length > 5) {
          setFilterEmails(emails.slice(0, 5));
        } else {
          setFilterEmails(emails);
        }
      }
    } else if (
      event?.target?.name === "mobile" ||
      event?.target?.name === "zip" ||
      event?.target?.name === "commisionPercentage" ||
      event?.target?.name === "companyMobile" ||
      event?.target?.name === "postalCode"
    ) {
      if (event.target.value < 0) {
        event.target.value = "";
        return;
      }
      let inputValue = event.target.value.replace(/\D+/g, "");
      setmerchant({ ...merchant, [event.target.name]: inputValue });
    }

    let textInput = event.target.value;

    setmerchant({
      ...merchant,
      [event?.target?.name]: textInput,
    });
  };

  const filterEmail = (address) => {
    let emails = emailSugg?.filter((item, index) => {
      return item.includes(address);
    });
    return emails;
  };

  const handleDateChange = (date) => {
    setmerchant({
      ...merchant,
      companyDob: getOnlyFullDate(date),
    });
  };

  const handleImage = async (event, errors) => {
    if (imageReg.test(event.target.files[0]?.type)) {
      if (event.target.files[0].size <= 2560000) {
        errors[event.target.name] = "";

        if (event.target.name === "image") {
          setuploadedImg(URL.createObjectURL(event.target.files[0]));
        } else if (event.target.name === "logo") {
          setuploadedLogo(URL.createObjectURL(event.target.files[0]));
        } else if (event.target.name === "insurancePolicy") {
          setupInsurancePolicy(URL.createObjectURL(event.target.files[0]));
        } else if (event.target.name === "compamyRegistrationImage") {
          setuploadedcompRegCert(URL.createObjectURL(event.target.files[0]));
        }

        const DATA = new FormData();
        DATA.append("image", event.target.files[0]);
        let imgUpRes = await fileUpload("/admin/uploadMerimage", "POST", DATA);
        if (imgUpRes && imgUpRes.status) {
          setmerchant((prev) => ({
            ...prev,
            [event.target.name]: imgUpRes.url,
          }));
        } else {
          toast.error(imgUpRes.message);
        }
      } else {
        errors[event.target.name] = "Image should not exceed more the 2.5MB";
        if (event.target.name === "image") {
          setuploadedImg("");
        } else if (event.target.name === "logo") {
          setuploadedLogo("");
        } else if (event.target.name === "insurancePolicy") {
          setupInsurancePolicy("");
        } else {
          setuploadedcompRegCert("");
        }
      }
    } else {
      errors[event.target.name] = "File is not an image";
      if (event.target.name === "image") {
        setuploadedImg("");
      } else if (event.target.name === "logo") {
        setuploadedLogo("");
      } else if (event.target.name === "insurancePolicy") {
        setupInsurancePolicy("");
      } else {
        setuploadedcompRegCert("");
      }
    }
  };
  const scrollToErrorElement = (id) => {
    const errorElement = document.getElementById(id);
    if (errorElement) {
      errorElement.scrollIntoView({
        block: "center",
      });
    }
  };

  const handleValidation = () => {
    const {
      firstName,
      lastName,
      email,
      password,
      langId,
      mobile,
      commisionPercentage,
      country,
      countryId,
      city,
      cityId,
      zip,
      marBankAccNo,
      marBankIfscCode,
      marBankName,
      marAccHolderName,
      legalStatus,
      companyName,
      legalCompanyName,
      companyMobile,
      directorName,
      individualName,
      companyDob,
      companyStreetAddress,
      postalCode,
      compCountry,
      compCity,
      licenseNumber,
      registrationNumber,
      description,
      socialLink,
      payeeType,
      accountType,
      phonecode,
      phonecodecompany,
    } = merchant;

    if (countryId === "") {
      setSelectedIndex(0);
      scrollToErrorElement("country");
      seterrors({
        ...createErrors,
        countryId: "Merchant country name is required.",
      });

      return false;
    }
    if (cityId === "") {
      setSelectedIndex(0);
      scrollToErrorElement("city");
      seterrors({
        ...createErrors,
        cityId: "Merchant city name is required.",
      });

      return false;
    }
    if (zip === "") {
      setSelectedIndex(0);
      scrollToErrorElement("zip");
      seterrors({
        ...createErrors,
        zip: "Merchant address zip is required.",
      });

      return false;
    }
    if (country === "India" && isNaN(zip)) {
      setSelectedIndex(0);
      scrollToErrorElement("country");
      seterrors({
        ...createErrors,
        zip: "Please enter only numerics",
      });

      return false;
    }
    if (companyName === "") {
      setSelectedIndex(0);
      scrollToErrorElement("companyName");
      seterrors({
        ...createErrors,
        companyName: "Company name is required.",
      });

      return false;
    }
    if (email === "") {
      setSelectedIndex(0);
      scrollToErrorElement("email");
      seterrors({ ...createErrors, email: "Email is required." });

      return false;
    }
    if (!emailReg.test(email)) {
      setSelectedIndex(0);
      scrollToErrorElement("email");
      seterrors({
        ...createErrors,
        email: "Please enter correct email format.",
      });

      return false;
    }
    if (password === "") {
      setSelectedIndex(0);
      scrollToErrorElement("password");
      seterrors({
        ...createErrors,
        password: "Password is required.",
      });

      return false;
    }
    if (password.length < 8) {
      setSelectedIndex(0);
      scrollToErrorElement("password");
      seterrors({
        ...createErrors,
        password: "Please enter minimum 8 characters.",
      });

      return false;
    }
    if (firstName === "") {
      setSelectedIndex(0);
      scrollToErrorElement("firstName");
      seterrors({
        ...createErrors,
        firstName: "First name is required.",
      });

      return false;
    }

    if (lastName === "") {
      setSelectedIndex(0);
      scrollToErrorElement("lastName");
      seterrors({
        ...createErrors,
        lastName: "Last name is required.",
      });

      return false;
    }
    if (langId === "") {
      setSelectedIndex(0);
      scrollToErrorElement("langId");
      seterrors({
        ...createErrors,
        langId: "Language is required.",
      });

      return false;
    }
    if (!phonecode) {
      setSelectedIndex(0);
      scrollToErrorElement("phonecode");
      seterrors({
        ...createErrors,
        phonecode: "phoneccode is required",
      });

      return false;
    }
    if (mobile === "") {
      setSelectedIndex(0);
      scrollToErrorElement("mobile");
      seterrors({
        ...createErrors,
        mobile: "Merchant mobile number is required.",
      });

      return false;
    }
    if (mobile.length > 15) {
      setSelectedIndex(0);
      scrollToErrorElement("mobile");
      seterrors({
        ...createErrors,
        mobile: "Please enter atmost 15 digit mobile number.",
      });

      return false;
    }
    if (isNaN(mobile)) {
      setSelectedIndex(0);
      scrollToErrorElement("mobile");
      seterrors({
        ...createErrors,
        mobile: "Please enter only numerics",
      });

      return false;
    }
    if (commisionPercentage === "") {
      setSelectedIndex(0);
      scrollToErrorElement("commisionPercentage");
      seterrors({
        ...createErrors,
        commisionPercentage: "Commission percentage is required.",
      });

      return false;
    }
    if (isNaN(commisionPercentage)) {
      setSelectedIndex(0);
      scrollToErrorElement("commisionPercentage");
      seterrors({
        ...createErrors,
        commisionPercentage: "Please enter only numerics",
      });

      return false;
    }
    // if (payeeType === "") {
    //   setSelectedIndex(1);
    //   scrollToErrorElement("payee_type");
    //   seterrors({ ...createErrors, payeeType: "Payee type is required." });

    //   return false;
    // }
    // if (accountType === "") {
    //   setSelectedIndex(1);
    //   scrollToErrorElement("account_type");
    //   seterrors({ ...createErrors, accountType: "Account type is required." });

    //   return false;
    // }
    // if (marBankName === "") {
    //   setSelectedIndex(1);
    //   scrollToErrorElement("marBankName");
    //   seterrors({
    //     ...createErrors,
    //     marBankName: "Merchant bank name is required.",
    //   });

    //   return false;
    // }
    // if (marAccHolderName === "") {
    //   setSelectedIndex(1);
    //   scrollToErrorElement("marAccHolderName");
    //   seterrors({
    //     ...createErrors,
    //     marAccHolderName: "Merchant bank account holder name is required.",
    //   });

    //   return false;
    // }
    // if (marBankAccNo === "") {
    //   setSelectedIndex(1);
    //   scrollToErrorElement("marBankAccNo");
    //   seterrors({
    //     ...createErrors,
    //     marBankAccNo: "Merchant bank account number is required.",
    //   });

    //   return false;
    // }
    // if (!marBankIfscCode) {
    //   setSelectedIndex(1);
    //   seterrors({
    //     ...createErrors,
    //     marBankIfscCode: "Bank Branch Address is Required",
    //   });

    //   return false;
    // }
    if (legalStatus === "") {
      setSelectedIndex(2);
      scrollToErrorElement("legalStatus");
      seterrors({
        ...createErrors,
        legalStatus: "Company legal status is required.",
      });

      return false;
    }
    if (legalCompanyName === "") {
      setSelectedIndex(2);
      scrollToErrorElement("legalCompanyName");
      seterrors({
        ...createErrors,
        legalCompanyName: "Company legal name is required.",
      });

      return false;
    }
    if (compCountry === "") {
      setSelectedIndex(2);
      scrollToErrorElement("compCountry");
      seterrors({
        ...createErrors,
        compCountry: "Company country name is required.",
      });
      return false;
    }
    if (compCity === "") {
      setSelectedIndex(2);
      scrollToErrorElement("compCity");
      seterrors({
        ...createErrors,
        compCity: "Company city name is required.",
      });

      return false;
    }

    if (companyStreetAddress === "") {
      setSelectedIndex(2);
      scrollToErrorElement("companyStreetAddress");
      seterrors({
        ...createErrors,
        companyStreetAddress: "Company address street is required.",
      });

      return false;
    }
    if (postalCode === "") {
      setSelectedIndex(2);
      scrollToErrorElement("postalCode");
      seterrors({
        ...createErrors,
        postalCode: "Company address zip is required.",
      });

      return false;
    }
    if (compCountry == "India" && isNaN(postalCode)) {
      setSelectedIndex(2);
      scrollToErrorElement("postalCode");
      seterrors({
        ...createErrors,
        postalCode: "Please enter only numerics",
      });

      return false;
    }
    if (!phonecodecompany) {
      setSelectedIndex(2);
      scrollToErrorElement("phonecodecompany");
      seterrors({
        ...createErrors,
        phonecodecompany: "Company phone code is required.",
      });
      return false;
    }
    if (companyMobile === "") {
      setSelectedIndex(2);
      scrollToErrorElement("companyMobile");
      seterrors({
        ...createErrors,
        companyMobile: "Company mobile number is required.",
      });
      return false;
    }

    if (companyMobile.length > 15) {
      setSelectedIndex(2);
      scrollToErrorElement("companyMobile");
      seterrors({
        ...createErrors,
        companyMobile: "Please enter atmost 15 digit mobile number.",
      });

      return false;
    }
    if (isNaN(companyMobile)) {
      scrollToErrorElement("companyMobile");
      seterrors({
        ...createErrors,
        companyMobile: "Please enter only numerics",
      });

      return false;
    }
    if (licenseNumber === "") {
      setSelectedIndex(2);
      scrollToErrorElement("licenseNumber");
      seterrors({
        ...createErrors,
        licenseNumber: "Company license is required.",
      });

      return false;
    }
    if (registrationNumber === "") {
      setSelectedIndex(2);
      scrollToErrorElement("registrationNumber");
      seterrors({
        ...createErrors,
        registrationNumber: "Company registration is required.",
      });

      return false;
    }

    return true;
  };
  const handleSubmit = async (event) => {
    event.preventDefault();
    if (handleValidation()) {
      try {
        setisLoading(true);
        let payload = {
          ...merchant,
          countryId: merchant.countryId === "" ? undefined : merchant.countryId,
          cityId: merchant.cityId === "" ? undefined : merchant.cityId,
        };     
        let res1 = await requestData("/admin/admin-add-merchant", "POST" , payload )
        let cityZipCheck = await getZipcodesForCity(merchant.city);
        if (
          cityZipCheck?.filter((item) => item?.zipCode === merchant.zip)
            ?.length === 0
        ) {
          let res2 = await JSON_API["addCityZipCode"]({
            cityName: merchant.city,
            countryName: merchant.country,
            zipCode: merchant.zip,
          });
        }
        let compCityCheck = await getZipcodesForCity(merchant.compCity);
        if (
          compCityCheck?.filter((item) => item?.zipCode === merchant.postalCode)
            ?.length === 0
        ) {
          let res3 = await JSON_API["addCityZipCode"]({
            cityName: merchant.compCity,
            countryName: merchant.compCountry,
            zipCode: merchant.postalCode,
          });
        }

        if (res1.status) {
         
          if (imgInputRef.current) {
            imgInputRef.current.value = "";
          }
          setuploadedImg("");
          setupInsurancePolicy("");
          setuploadedLogo("");
          setuploadedcompRegCert("");
          setzipDropdownOne(false);
          setzipDropdownTwo(false);
          setmerchant(initialValues);
          seterrors(createErrors);
          MerchantApiCallSuccess(res1.data);
        } else {
          MerchantApiCallError(res1?.message);
        }
        setisLoading(false);
      } catch (error) {
        setisLoading(false);
        MerchantApiCallError(error.message);
      }
    }
  };

  const MerchantApiCallSuccess = (data) => {
    toast.success("Merchant added successfully");
  };

  const MerchantApiCallError = (errorMsg) => {
    toast.error(errorMsg);
    setError(errorMsg);
  };

  const handleDraftSave = async (event) => {
    event.preventDefault();

    const { email, countryId, cityId } = merchant;
    if (!countryId) {
      setSelectedIndex(0);
      scrollToErrorElement("countryId");
      seterrors({
        ...createErrors,
        countryId: "Merchant Country Name is required.",
      });
      return false;
    }
    if (!cityId) {
      setSelectedIndex(0);
      scrollToErrorElement("cityId");
      seterrors({ ...createErrors, cityId: "Merchant City Name is required." });
      return false;
    }

    if (email === "") {
      setSelectedIndex(0);
      scrollToErrorElement("email");
      seterrors({ ...createErrors, email: "Merchant Email is required." });
      return false;
    } else if (!emailReg.test(email)) {
      setSelectedIndex(0);
      scrollToErrorElement("email");
      seterrors({
        ...createErrors,
        email: "Please enter correct email format.",
      });
      return false;
    } else {
      let bodyData = {
        ...merchant,
        saveAsDraft: true,
      };

      if (merchant.langId === "") {
        delete bodyData.langId;
      }
      if (merchant.legalStatus === "") {
        delete bodyData.legalStatus;
      }
      setisLoading(true);
      let res = await JSON_API["adminAddMerchant"](bodyData);

      if (res.isSuccess) {
        setisLoading(false);
        setmerchant(initialValues);
        seterrors(createErrors);
        toast.success("Saved As Draft!");
        navigate("/manage-merchant-drafts");
      } else {
        setisLoading(false);
      }
    }
  };

  const getAllThirdParty = async () => {
    try {
      setisLoading(true)
      let res = await requestData("/admin/viewThirdPerty", "GET");
      if (res) {
        setThirdPartyData(res.data)
        setisLoading(false)
      }
    } catch (error) {
      toast.error(error.message)
      setisLoading(false)
    }
  }

  const [selectedIndex, setSelectedIndex] = useState(0);
  useEffect(() => {
    setisLoading(loading);
  }, [loading]);

  return (
    <>
      {isLoading && <Loader />}
      <form className="" autoComplete="off">
        <div component="div" className="TabsAnimation appear-done enter-done">
          <div className="main-card mb-3 card">
            <div className="card-body">
              <div className="card-title">Add Merchant : {formProgress}%</div>
              <div className="row">
                <div className="col-md-12">
                  <progress
                    id="progress-bar"
                    value={formProgress}
                    max={100}
                    style={{ width: "100%" }}
                  >
                    {formProgress}%
                  </progress>
                </div>
              </div>

              <div className="row" style={{ padding: "1rem" }}>
                <div className="col-md-12">
                  <Tabs
                    selectedIndex={selectedIndex}
                    onSelect={(index) => setSelectedIndex(index)}
                  >
                    <TabList>
                      <Tab id="merchantDetails">Merchant Details</Tab>
                      <Tab id="bankDetails">Bank Details</Tab>
                      <Tab id="companyDetails">Company Details</Tab>
                    </TabList>

                    <TabPanel>
                      <div className="row" style={{ padding: "1rem" }}>
                        <div className="col-md-4">
                          <div className="mb-3" id="top">
                            <label className="form-label" htmlFor="country">
                              Country{" "}
                              <span style={{ fontSize: "20px", color: "red" }}>
                                *
                              </span>
                            </label>

                            <Select
                              isSearchable={true}
                              name="country"
                              id="country"
                              classNamePrefix="select"
                              ref={firstScroll}
                              options={countries?.map((item, idx) => {
                                return {
                                  value: item._id,
                                  label: item?.name,
                                };
                              })}
                              value={{
                                value: merchant?.country,
                                label: merchant?.country,
                              }}
                              onChange={async (val) => {
                                calcProgress();
                                getCityAgainstCountry(val?.value);

                                fetchCities(val.value);
                                setmerchant((prev) => {
                                  return {
                                    ...prev,
                                    country: val?.label,
                                    countryId: val.value,
                                    city: "",
                                    zip: "",
                                  };
                                });
                              }}
                            />
                          </div>
                          {errors.countryId && (
                            <span className="errorMessageText text-danger">
                              {errors.countryId}
                            </span>
                          )}
                        </div>
                        <div className="col-md-6">
                          <div className="mb-3">
                            <label htmlFor="city" className="form-label">
                              City{" "}
                              <span style={{ fontSize: "20px", color: "red" }}>
                                *
                              </span>
                            </label>
                            <Select
                              isSearchable={true}
                              name="city"
                              id="city"
                              classNamePrefix="select"
                              options={cities?.map((item, idx) => ({
                                value: item?._id,
                                label: item?.cityName,
                              }))}
                              value={{
                                value: merchant?.city,
                                label: merchant?.city,
                              }}
                              onChange={(val) => {
                                calcProgress();
                                getZipcodesForCity(val?.value);
                                setmerchant((prev) => {
                                  return {
                                    ...prev,
                                    city: val?.label,
                                    cityId: val.value,
                                    zip: "",
                                  };
                                });
                              }}
                            />
                          </div>
                          {errors.cityId && (
                            <span className="errorMessageText text-danger">
                              {errors.cityId}
                            </span>
                          )}
                        </div>
                        <div className="col-md-2">
                          <div className="mb-3">
                            <label htmlFor="zip" className="form-label">
                              Zip/Postcode{" "}
                              <span style={{ fontSize: "20px", color: "red" }}>
                                *
                              </span>
                            </label>
                            <input
                              name="zip"
                              id="zip_input"
                              type="text"
                              className="form-control"
                              value={merchant.zip}
                              onChange={(e) => {
                                calcProgress();
                                setmerchant((prev) => {
                                  let update = JSON.parse(JSON.stringify(prev));
                                  update.zip = e.target.value;
                                  return update;
                                });
                              }}
                              onClick={() => setzipDropdownOne(true)}
                              required={true}
                            />
                          </div>
                          {errors.zip && (
                            <span className="errorMessageText text-danger">
                              {errors.zip}
                            </span>
                          )}
                        </div>
                        <div className="col-md-12">
                          <div className="mb-3">
                            <label className="form-label" htmlFor="companyName">
                              Company Name{" "}
                              <span style={{ fontSize: "20px", color: "red" }}>
                                *
                              </span>
                            </label>
                            <input
                              type="text"
                              name="companyName"
                              className="form-control"
                              id="companyName"
                              aria-describedby="emailHelp"
                              autoComplete="off"
                              onChange={(e) => {
                                handleChange(e);
                                calcProgress();
                              }}
                              value={merchant.companyName}
                              required
                            />
                          </div>
                          {errors.companyName && (
                            <span className="errorMessageText text-danger">
                              {errors.companyName}
                            </span>
                          )}
                        </div>
                        <div className="col-md-6">
                          <div className="mb-3">
                            <label htmlFor="email" className="form-label">
                              Email{" "}
                              <span style={{ fontSize: "20px", color: "red" }}>
                                *
                              </span>
                            </label>
                            <input
                              autoComplete="off"
                              name="email"
                              id="email"
                              placeholder="E.g.,waltersmith@gmail.com"
                              type="email"
                              className="form-control"
                              value={merchant.email}
                              onChange={(e) => {
                                handleChange(e);
                                calcProgress();
                              }}
                              required={true}
                            />
                          </div>
                          {errors.email && (
                            <span className="errorMessageText text-danger">
                              {errors.email}
                            </span>
                          )}
                          {filterEmails?.length > 0 && (
                            <div className="admin-email-suggesion">
                              <div style={{ color: "sandybrown" }}>
                                Email suggestions:
                              </div>
                              {filterEmails.map((item, i) => (
                                <p
                                  key={i}
                                  onClick={() => {
                                    setmerchant((prev) => {
                                      return { ...prev, email: item };
                                    });
                                    setFilterEmails([]);
                                  }}
                                >
                                  {item}
                                </p>
                              ))}
                            </div>
                          )}
                        </div>
                        <div className="col-md-6">
                          <div className="mb-3">
                            <label htmlFor="password" className="form-label">
                              Password{" "}
                              <span style={{ fontSize: "20px", color: "red" }}>
                                *
                              </span>
                            </label>
                            <input
                              name="password"
                              id="password"
                              type="password"
                              className="form-control"
                              value={merchant.password}
                              onChange={(e) => {
                                handleChange(e);
                                calcProgress();
                              }}
                              required={true}
                            />
                          </div>
                          {errors.password && (
                            <span className="errorMessageText text-danger">
                              {errors.password}
                            </span>
                          )}
                        </div>
                        <div className="col-md-6">
                          <div className="mb-3">
                            <label htmlFor="firstName" className="form-label">
                              First Name{" "}
                              <span style={{ fontSize: "20px", color: "red" }}>
                                *
                              </span>
                            </label>

                            <input
                              name="firstName"
                              id="firstName"
                              placeholder="Your first name, e.g. Walter"
                              type="text"
                              className="form-control"
                              value={merchant.firstName}
                              onChange={(e) => {
                                handleChange(e);
                                calcProgress();
                              }}
                              required={true}
                            />
                          </div>
                          {errors.firstName && (
                            <span className="errorMessageText text-danger">
                              {errors.firstName}
                            </span>
                          )}
                        </div>
                        <div className="col-md-6">
                          <div className="mb-3">
                            <label htmlFor="lastName" className="form-label">
                              Last Name{" "}
                              <span style={{ fontSize: "20px", color: "red" }}>
                                *
                              </span>
                            </label>
                            <input
                              name="lastName"
                              id="lastName"
                              placeholder="Your last name, e.g. Smith"
                              type="text"
                              className="form-control"
                              value={merchant.lastName}
                              onChange={(e) => {
                                handleChange(e);
                                calcProgress();
                              }}
                              required={true}
                            />
                          </div>
                          {errors.lastName && (
                            <span className="errorMessageText text-danger">
                              {errors.lastName}
                            </span>
                          )}
                        </div>
                        <div className="col-md-6">
                          <div className="mb-3">
                            <label htmlFor="langId" className="form-label">
                              Language{" "}
                              <span style={{ fontSize: "20px", color: "red" }}>
                                *
                              </span>
                            </label>
                            <select
                              name="langId"
                              id="langId"
                              className="form-control form-select"
                              value={merchant.langId}
                              onChange={(e) => handleChange(e)}
                              required={true}
                            >
                              <option
                                value=""
                                disabled={true}
                                selected={true}
                                key={0}
                              >
                                Select
                              </option>
                              {languages.map((item, idx) => {
                                return (
                                  <>
                                    <option
                                      key={idx + 1}
                                      value={`${item?._id}`}
                                    >
                                      {item?.name}
                                    </option>
                                  </>
                                );
                              })}
                            </select>
                          </div>
                          {errors.langId && (
                            <span className="errorMessageText text-danger">
                              {errors.langId}
                            </span>
                          )}
                        </div>
                        <div className="col-md-6">
                          <div className="mb-3">
                            <label htmlFor="mobile" className="form-label">
                              Mobile{" "}
                              <span style={{ fontSize: "20px", color: "red" }}>
                                *
                              </span>
                            </label>
                            <div className="form-control">
                              <PhoneInput
                                value={merchant.mobile}
                                onChange={(e) => {
                                  setmerchant((prev) => ({
                                    ...prev,
                                    mobile: e,
                                  }));
                                }}
                                countryCode={
                                  merchant.phonecode ? merchant.phonecode : "+0"
                                }
                                onCountryChange={(val) =>
                                  setmerchant((prev) => ({
                                    ...prev,
                                    phonecode: val,
                                  }))
                                }
                              />
                            </div>
                          </div>
                          {errors.phonecode && (
                            <span className="errorMessageText text-danger">
                              {errors.phonecode}
                            </span>
                          )}
                          {errors.mobile && (
                            <span className="errorMessageText text-danger">
                              {errors.mobile}
                            </span>
                          )}
                        </div>

                        <div className="col-md-3">
                          <div className="mb-3 d-flex flex-col" style={{ gap: "1rem" }}>
                            <label
                              htmlFor="isthirdperty"
                              className="form-label"
                              style={{
                                marginLeft: "15px",
                                fontWeight: "bold",
                              }}
                            >
                              Have any third party platforms ?{" "}
                              {/* <span style={{ fontSize: "20px", color: "red" }}>
                                *
                              </span> */}
                            </label>

                            <div className="d-flex ml-2">
                              <div className="form-check">
                                <input
                                  className="form-check-input"
                                  type="radio"
                                  name="isthirdperty"
                                  id="payee_type_1"
                                  onChange={() =>
                                    setmerchant((prev) => ({
                                      ...prev,
                                      isthirdperty: true,
                                    }))
                                  }
                                  value={true}
                                  checked={merchant?.isthirdperty === true}
                                />
                                <label className="form-check-label" htmlFor="payee_type_1">
                                  Yes
                                </label>
                              </div>

                              <div className="form-check ml-8">
                                <input
                                  className="form-check-input"
                                  type="radio"
                                  name="isthirdperty"
                                  id="payee_type_2"
                                  onChange={() =>
                                    setmerchant((prev) => ({
                                      ...prev,
                                      isthirdperty: false,
                                      thirdpertyId: undefined,
                                      AccesKey: "",
                                      secretKey: ""
                                    }))
                                  }
                                  value={false}
                                  checked={merchant?.isthirdperty === false}
                                />
                                <label className="form-check-label" htmlFor="payee_type_2">
                                  No
                                </label>
                              </div>

                            </div>
                          </div>
                          {/* {errors.langId && (
                            <span className="errorMessageText text-danger">
                              {errors.langId}
                            </span>
                          )} */}
                        </div>
                        <div className="col-md-3" style={{ display: merchant.isthirdperty ? "block" : "none" }}>
                          <div className="mb-3">
                            <label htmlFor="langId" className="form-label">
                              Third Party Platforms{" "}
                              <span style={{ fontSize: "20px", color: "red" }}>
                                *
                              </span>
                            </label>
                            <select
                              name="thirdpertyId"
                              id="thirdpertyId"
                              className="form-control form-select"
                              value={merchant.thirdpertyId}
                              onChange={(e) => handleChange(e)}
                              required={true}
                            >
                              <option
                                value=""
                                disabled={true}
                                selected={true}
                                key={0}
                              >
                                Select
                              </option>
                              {thirdPartyData?.map((item, idx) => {
                                return (
                                  <>
                                    <option
                                      key={idx + 1}
                                      value={`${item?._id}`}
                                    >
                                      {item?.name}
                                    </option>
                                  </>
                                );
                              })}
                            </select>
                          </div>
                          {errors.thirdpertyId && (
                            <span className="errorMessageText text-danger">
                              {errors.thirdpertyId}
                            </span>
                          )}
                        </div>
                        <div className="col-md-3" style={{ display: merchant.isthirdperty && merchant.thirdpertyId ? "block" : "none" }}>
                          <div className="mb-3">
                            <label
                              htmlFor="AccesKey"
                              className="form-label"
                            >
                              Access key{" "}
                              <span style={{ fontSize: "20px", color: "red" }}>
                                *
                              </span>
                            </label>
                            <input
                              name="AccesKey"
                              id="AccesKey"
                              type="text"
                              value={merchant.AccesKey}
                              className="form-control"
                              onChange={(e) => {
                                setmerchant((prev) => ({
                                  ...prev,
                                  AccesKey: e.target.value
                                }))
                              }}
                              required={true}
                            />
                            {errors.AccesKey && (
                              <span className="errorMessageText text-danger">
                                {errors.AccesKey}
                              </span>
                            )}
                          </div>
                        </div>
                        <div className="col-md-3" style={{ display: merchant.isthirdperty && merchant.thirdpertyId ? "block" : "none" }}>
                          <div className="mb-3">
                            <label
                              htmlFor="secretKey"
                              className="form-label"
                            >
                              Secret Key{" "}
                              <span style={{ fontSize: "20px", color: "red" }}>
                                *
                              </span>
                            </label>
                            <input
                              name="secretKey"
                              id="secretKey"
                              type="text"
                              value={merchant.secretKey}
                              className="form-control"
                              onChange={(e) => {
                                setmerchant((prev) => ({
                                  ...prev,
                                  secretKey: e.target.value
                                }))
                              }}
                              required={true}
                            />
                            {errors.secretKey && (
                              <span className="errorMessageText text-danger">
                                {errors.secretKey}
                              </span>
                            )}
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="mb-3">
                            <label
                              htmlFor="commisionPercentage"
                              className="form-label"
                            >
                              Commission Percentage{" "}
                              <span style={{ fontSize: "20px", color: "red" }}>
                                *
                              </span>
                            </label>
                            <input
                              name="commisionPercentage"
                              id="commisionPercentage"
                              type="number"
                              value={merchant.commisionPercentage}
                              className="form-control"
                              onChange={(e) => {
                                calcProgress();
                                setmerchant((prev) => {
                                  let update = JSON.parse(JSON.stringify(prev));
                                  update.commisionPercentage =
                                    e.target.value.replace(/[^\w\s]/gi, "");
                                  return update;
                                });
                              }}
                              required={true}
                            />
                            {errors.commisionPercentage && (
                              <span className="errorMessageText text-danger">
                                {errors.commisionPercentage}
                              </span>
                            )}
                          </div>
                        </div>
                        <div className="col-md-6 d-none" id="img">
                          <div className="mb-3">
                            <label htmlFor="image" className="form-label">
                              Image
                            </label>
                            <input
                              name="image"
                              id="image"
                              type="file"
                              ref={imgInputRef}
                              className="form-control"
                              onChange={(e) => {
                                setimg(e.target.files[0]);
                                handleImage(e, errors);
                              }}
                              required={true}
                              accept="image/*"
                            />
                            {uploadedImg.length > 0 && (
                              <img
                                alt=""
                                style={{ height: "100px", width: "100px" }}
                                src={uploadedImg}
                              />
                            )}
                            {errors.image && (
                              <span className="errorMessageText text-danger">
                                {errors.image}
                              </span>
                            )}
                          </div>
                        </div>
                      </div>

                      <div className="row" style={{ padding: "1rem" }}>
                        <div className="col-md-12">
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "flex-end",
                            }}
                          >
                            <button
                              className="mt-2 btn btn-secondary d-table"
                              type="submit"
                              onClick={(e) => handleDraftSave(e)}
                              style={{ marginRight: "10px" }}
                            >
                              Save As Draft
                            </button>
                            <button
                              className="mt-2 btn btn-primary ml-auto d-table d-none"
                              type="submit"
                              onClick={(e) => { }}
                            >
                              Next
                            </button>
                          </div>
                        </div>
                      </div>
                    </TabPanel>
                    <TabPanel>
                      <div className="row" style={{ padding: "1rem" }}>
                        <div className="col-md-12" id="payee_type">
                          <div className="mb-3 d-flex" style={{ gap: "1rem" }}>
                            <label
                              htmlFor="payeeType"
                              className="form-label"
                              style={{
                                marginLeft: "15px",
                                fontWeight: "bold",
                              }}
                            >
                              Payee Type{" "}
                            </label>

                            <div className="form-check">
                              <input
                                className="form-check-input"
                                type="radio"
                                name="payeeType"
                                id="payee_type_1"
                                onChange={(e) => {
                                  calcProgress();
                                  handleChange(e);
                                }}
                                value="person"
                                checked={merchant?.payeeType === "person"}
                              />
                              <label
                                className="form-check-label"
                                htmlFor="payee_type_1"
                              >
                                Person
                              </label>
                            </div>

                            <div className="form-check">
                              <input
                                className="form-check-input"
                                type="radio"
                                name="payeeType"
                                id="payee_type_2"
                                onChange={(e) => handleChange(e)}
                                value="organization"
                                checked={merchant?.payeeType === "organization"}
                              />
                              <label
                                className="form-check-label"
                                htmlFor="payee_type_2"
                              >
                                Organization
                              </label>
                            </div>
                          </div>
                          {errors.payeeType && (
                            <span className="errorMessageText text-danger">
                              {errors.payeeType}
                            </span>
                          )}
                        </div>
                        <div className="col-md-12" id="account_type">
                          <div className="mb-3 d-flex" style={{ gap: "1rem" }}>
                            <label
                              htmlFor="accountType"
                              className="form-label"
                              style={{
                                marginLeft: "15px",
                                fontWeight: "bold",
                              }}
                            >
                              Account Type{" "}
                            </label>
                            <div className="form-check">
                              <input
                                className="form-check-input"
                                type="radio"
                                name="accountType"
                                id={`account_type_1`}
                                onChange={(e) => {
                                  calcProgress();
                                  handleChange(e);
                                }}
                                value="current"
                                checked={merchant?.accountType === "current"}
                              />
                              <label
                                className="form-check-label"
                                htmlFor={`account_type_1`}
                              >
                                Current
                              </label>
                            </div>
                            <div className="form-check">
                              <input
                                className="form-check-input"
                                type="radio"
                                name="accountType"
                                id="account_type_2"
                                onChange={(e) => handleChange(e)}
                                value="savings"
                                checked={merchant?.accountType === "savings"}
                              />
                              <label
                                className="form-check-label"
                                htmlFor="account_type_2"
                              >
                                Savings
                              </label>
                            </div>
                            <div className="form-check">
                              <input
                                className="form-check-input"
                                type="radio"
                                name="accountType"
                                id="account_type_3"
                                onChange={(e) => handleChange(e)}
                                value="others"
                                checked={merchant?.accountType === "others"}
                              />
                              <label
                                className="form-check-label"
                                htmlFor="account_type_3"
                              >
                                Others
                              </label>
                            </div>
                          </div>
                          {errors.accountType && (
                            <span className="errorMessageText text-danger">
                              {errors.accountType}
                            </span>
                          )}
                        </div>
                        <div className="col-md-6">
                          <div className="mb-3">
                            <label htmlFor="marBankName" className="form-label">
                              Bank Name : (E.g HSBC){" "}
                            </label>
                            <input
                              name="marBankName"
                              id="marBankName"
                              type="text"
                              value={merchant.marBankName}
                              className="form-control"
                              onChange={(e) => {
                                handleChange(e);
                                calcProgress();
                              }}
                              required={true}
                            />
                            {errors.marBankName && (
                              <span className="errorMessageText text-danger">
                                {errors.marBankName}
                              </span>
                            )}
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="mb-3">
                            <label htmlFor="bankCode" className="form-label">
                              IBAN ( E.g. FR76 3000 4028 3798 7654 3210 943)
                            </label>
                            <input
                              name="bankCode"
                              id="bankCode"
                              type="text"
                              value={merchant.bankCode}
                              className="form-control"
                              onChange={(e) => handleChange(e)}
                            />
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="mb-3">
                            <label htmlFor="branchName" className="form-label">
                              Branch name and address: (HSBC, Seapoint, 15 Ocean
                              Drive, 8574 )
                            </label>
                            <input
                              name="branchName"
                              id="branchName"
                              type="text"
                              value={merchant.branchName}
                              className="form-control"
                              onChange={(e) => handleChange(e)}
                            />
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="mb-3">
                            <label htmlFor="branchCode" className="form-label">
                              Branch Code
                            </label>
                            <input
                              name="branchCode"
                              id="branchCode"
                              type="text"
                              value={merchant.branchCode}
                              className="form-control"
                              onChange={(e) => handleChange(e)}
                            />
                          </div>
                        </div>
                        <div className="col-md-6" id="top3">
                          <div className="mb-3">
                            <label
                              htmlFor="marAccHolderName"
                              className="form-label"
                            >
                              Account holder name/Recipient name
                            </label>
                            <input
                              name="marAccHolderName"
                              id="marAccHolderName"
                              type="text"
                              value={merchant.marAccHolderName}
                              className="form-control"
                              onChange={(e) => {
                                handleChange(e);
                                calcProgress();
                              }}
                              required={true}
                            />
                            {errors.marAccHolderName && (
                              <span className="errorMessageText text-danger">
                                {errors.marAccHolderName}
                              </span>
                            )}
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="mb-3">
                            <label
                              htmlFor="marBankAccNo"
                              className="form-label"
                            >
                              Bank Account Number
                            </label>
                            <input
                              name="marBankAccNo"
                              id="marBankAccNo"
                              type="text"
                              value={merchant.marBankAccNo}
                              className="form-control"
                              onChange={(e) => {
                                handleChange(e);
                                calcProgress();
                              }}
                              required={true}
                            />
                          </div>
                          {errors.marBankAccNo && (
                            <span className="errorMessageText text-danger">
                              {errors.marBankAccNo}
                            </span>
                          )}
                        </div>
                        <div className="col-md-6">
                          <div className="mb-3">
                            <label
                              htmlFor="marBankIfscCode"
                              className="form-label"
                            >
                              Bank Branch Address
                            </label>
                            <input
                              name="marBankIfscCode"
                              id="marBankIfscCode"
                              type="text"
                              value={merchant.marBankIfscCode}
                              className="form-control"
                              onChange={(e) => handleChange(e)}
                            />
                          </div>
                          {errors.marBankIfscCode && (
                            <span className="errorMessageText text-danger">
                              {errors.marBankIfscCode}
                            </span>
                          )}
                        </div>
                        <div className="col-md-6">
                          <div className="mb-3">
                            <label
                              htmlFor="bankAcctCurrency"
                              className="form-label"
                            >
                              Bank Account Currency
                            </label>
                            <Select
                              name="bankAcctCurrency"
                              id="bankAcctCurrency"
                              classNamePrefix="select"
                              options={currencies?.map((item, idx) => ({
                                value: item?.name,
                                label: item?.name + " (" + item?.code + ")",
                              }))}
                              value={{
                                value: merchant.bankAcctCurrency,
                                label: merchant.bankAcctCurrency,
                              }}
                              onChange={(val) => {
                                setmerchant({
                                  ...merchant,
                                  bankAcctCurrency: val?.value,
                                });
                              }}
                            />
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="mb-3">
                            <label htmlFor="swiftCode" className="form-label">
                              Swift Code/BIC
                            </label>
                            <input
                              name="swiftCode"
                              id="swiftCode"
                              type="text"
                              value={merchant.swiftCode}
                              className="form-control"
                              onChange={(e) => handleChange(e)}
                            />
                          </div>
                        </div>
                      </div>

                      <div className="row" style={{ padding: "1rem" }}>
                        <div className="col-md-12">
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "flex-end",
                            }}
                          >
                            <button
                              className="mt-2 btn btn-secondary d-table"
                              type="submit"
                              onClick={(e) => handleDraftSave(e)}
                              style={{ marginRight: "10px" }}
                            >
                              Save As Draft
                            </button>

                            <button
                              className="mt-2 btn btn-primary ml-auto d-table d-none"
                              type="submit"
                              onClick={(e) => {
                              }}
                            >
                              Next
                            </button>
                          </div>
                        </div>
                      </div>
                    </TabPanel>
                    <TabPanel>
                      <div className="row" style={{ padding: "1rem" }}>
                        <div className="col-md-6" id="top4">
                          <div className="mb-3">
                            <label
                              className="font-weight-bold"
                              htmlFor="legalStatus"
                            >
                              Legal Status{" "}
                              <span style={{ fontSize: "20px", color: "red" }}>
                                *
                              </span>
                            </label>
                            <select
                              className="form-select"
                              id="legalStatus"
                              name="legalStatus"
                              onChange={(e) => {
                                calcProgress();
                                handleChange(e);
                              }}
                              value={merchant.legalStatus}
                            >
                              <option value="" disabled={true} selected={true}>
                                Select legal status
                              </option>
                              <option value="registered_company">
                                Registered Company
                              </option>
                              <option value="individual_traders">
                                Individual Trader
                              </option>
                            </select>
                          </div>
                          {errors.legalStatus && (
                            <span className="errorMessageText text-danger">
                              {errors.legalStatus}
                            </span>
                          )}
                        </div>
                        <div className="col-md-6">
                          <div className="mb-3">
                            <label
                              className="font-weight-bold"
                              htmlFor="legalCompanyName"
                            >
                              Legal Company Name{" "}
                              <span style={{ fontSize: "20px", color: "red" }}>
                                *
                              </span>
                            </label>
                            <input
                              type="text"
                              name="legalCompanyName"
                              className="form-control"
                              id="legalCompanyName"
                              aria-describedby="emailHelp"
                              autoComplete="off"
                              onChange={(e) => {
                                calcProgress();
                                handleChange(e);
                              }}
                              value={merchant.legalCompanyName}
                              required
                            />
                          </div>
                          {errors.legalCompanyName && (
                            <span className="errorMessageText text-danger">
                              {errors.legalCompanyName}
                            </span>
                          )}
                        </div>

                        {merchant.legalStatus === "registered_company" ? (
                          <div className="col-md-12">
                            <div className="mb-3">
                              <label
                                className="font-weight-bold"
                                htmlFor="directorName"
                              >
                                Company Director Name
                              </label>
                              <input
                                type="text"
                                name="directorName"
                                className="form-control"
                                id="directorName"
                                aria-describedby="emailHelp"
                                autoComplete="off"
                                onChange={(e) => handleChange(e)}
                                value={merchant.directorName}
                                required
                              />
                            </div>
                            {errors.directorName && (
                              <span className="errorMessageText text-danger">
                                {errors.directorName}
                              </span>
                            )}
                          </div>
                        ) : merchant.legalStatus === "individual_traders" ? (
                          <div className="row" style={{ padding: "1rem" }}>
                            <div className="col-md-6">
                              <div className="mb-3">
                                <label
                                  className="font-weight-bold"
                                  htmlFor="individualName"
                                >
                                  Trading as : (E.g Billy's Tours)
                                </label>
                                <input
                                  type="text"
                                  name="individualName"
                                  className="form-control"
                                  id="individualName"
                                  aria-describedby="emailHelp"
                                  autoComplete="off"
                                  onChange={(e) => handleChange(e)}
                                  value={merchant.individualName}
                                  required
                                />
                              </div>
                              {errors.individualName && (
                                <span className="errorMessageText text-danger">
                                  {errors.individualName}
                                </span>
                              )}
                            </div>
                            <div className="col-md-6">
                              <div className="mb-3">
                                <label
                                  className="font-weight-bold"
                                  htmlFor="companyDob"
                                >
                                  Trading since
                                </label>
                                <DatePicker
                                  calendarIcon={null}
                                  className="form-control"
                                  clearIcon={null}
                                  value={merchant.companyDob}
                                  format="dd/MM/y"
                                  shouldCloseCalendar={({ reason }) =>
                                    reason === "select" ||
                                    reason === "outsideAction"
                                  }
                                  onChange={handleDateChange}
                                />
                              </div>
                              {errors.companyDob && (
                                <span className="errorMessageText text-danger">
                                  {errors.companyDob}
                                </span>
                              )}
                            </div>
                          </div>
                        ) : (
                          ""
                        )}
                      </div>

                      <div
                        className="row"
                        style={{ padding: "1rem" }}
                        id="top5"
                      >
                        <div className="col-md-6">
                          <div className="mb-3">
                            <label
                              className="font-weight-bold"
                              htmlFor="compCountry"
                            >
                              Country of registration{" "}
                              <span style={{ fontSize: "20px", color: "red" }}>
                                *
                              </span>
                            </label>
                            <Select
                              name="compCountry"
                              id="compCountry"
                              classNamePrefix="select"
                              options={companyCityCountry.countries.map(
                                (item, idx) => ({
                                  value: item?.name,
                                  label: item?.name,
                                })
                              )}
                              value={{
                                value: merchant?.compCountry,
                                label: merchant?.compCountry,
                              }}
                              onChange={(val) => {
                                calcProgress();
                                companyCityCountry.fetchCitiesByCountryName(val?.value);
                                setmerchant((prev) => {
                                  return {
                                    ...prev,
                                    compCountryCode: val?.value,
                                    compCountry: val?.label,
                                    compCity: "",
                                    postalCode: "",
                                  };
                                });
                              }}
                            />
                          </div>
                          {errors.compCountry && (
                            <span className="errorMessageText text-danger">
                              {errors.compCountry}
                            </span>
                          )}
                        </div>
                        <div className="col-md-6">
                          <div className="mb-3">
                            <label
                              className="font-weight-bold"
                              htmlFor="compCity"
                            >
                              Company City{" "}
                              <span style={{ fontSize: "20px", color: "red" }}>
                                *
                              </span>
                            </label>
                            <Select
                              isSearchable={true}
                              name="compCity"
                              id="compCity"
                              classNamePrefix="select"
                              options={companyCityCountry.cities?.map((item, idx) => ({
                                value: item?.cityName,
                                label: item?.cityName,
                              }))}
                              value={{
                                value: merchant?.compCity,
                                label: merchant?.compCity,
                              }}
                              onChange={(val) => {
                                calcProgress();
                                getZipcodesForCity(val?.value);
                                setmerchant((prev) => {
                                  return {
                                    ...prev,
                                    compCity: val?.value,
                                    postalCode: "",
                                  };
                                });
                              }}
                            />
                          </div>
                          {errors.compCity && (
                            <span className="errorMessageText text-danger">
                              {errors.compCity}
                            </span>
                          )}
                        </div>
                        <div className="col-md-6">
                          <div className="mb-3">
                            <label
                              className="font-weight-bold"
                              htmlFor="companyStreetAddress"
                            >
                              Company Street Address{" "}
                              <span style={{ fontSize: "20px", color: "red" }}>
                                *
                              </span>
                            </label>
                            <input
                              type="text"
                              name="companyStreetAddress"
                              className="form-control"
                              id="companyStreetAddress"
                              aria-describedby="emailHelp"
                              autoComplete="off"
                              onChange={(e) => {
                                calcProgress();
                                handleChange(e);
                              }}
                              value={merchant.companyStreetAddress}
                              required
                            />
                          </div>
                          {errors.companyStreetAddress && (
                            <span className="errorMessageText text-danger">
                              {errors.companyStreetAddress}
                            </span>
                          )}
                        </div>
                        <div className="col-md-6">
                          <div className="mb-3">
                            <label
                              className="font-weight-bold"
                              htmlFor="postalCode"
                            >
                              Company Zip/Postcode{" "}
                              <span style={{ fontSize: "20px", color: "red" }}>
                                *
                              </span>
                            </label>
                            <input
                              type="text"
                              name="postalCode"
                              className="form-control"
                              id="postalCode"
                              aria-describedby="emailHelp"
                              autoComplete="off"
                              min={0}
                              onChange={(e) => {
                                calcProgress();
                                handleChange(e);
                              }}
                              onClick={() => setzipDropdownTwo(true)}
                              value={merchant.postalCode}
                              required
                            />
                            {zipDropdownTwo && (
                              <Select
                                name="postalCode"
                                id="comp_zip_select"
                                classNamePrefix="select"
                                options={zipcodesForCity?.map((item, idx) => ({
                                  value: item?.zipCode,
                                  label: item?.zipCode,
                                }))}
                                value={{
                                  value: merchant?.postalCode,
                                  label: merchant?.postalCode,
                                }}
                                onChange={(val) => {
                                  setmerchant((prev) => {
                                    return {
                                      ...prev,
                                      postalCode: val?.value,
                                    };
                                  });
                                }}
                              />
                            )}
                          </div>
                          {errors.postalCode && (
                            <span className="errorMessageText text-danger">
                              {errors.postalCode}
                            </span>
                          )}
                        </div>
                        <div className="col-md-6">
                          <div className="mb-3">
                            <label
                              className="font-weight-bold"
                              htmlFor="companyMobile"
                            >
                              Company Contact Number{" "}
                              <span style={{ fontSize: "20px", color: "red" }}>
                                *
                              </span>
                            </label>

                            <div className="form-control">
                              <PhoneInput
                                value={merchant.companyMobile}
                                onChange={(e) => {
                                  setmerchant((prev) => ({
                                    ...prev,
                                    companyMobile: e,
                                  }));
                                }}
                                countryCode={
                                  merchant.phonecodecompany
                                    ? merchant.phonecodecompany
                                    : "+0"
                                }
                                onCountryChange={(val) =>
                                  setmerchant((prev) => ({
                                    ...prev,
                                    phonecodecompany: val,
                                  }))
                                }
                              />
                            </div>
                          </div>
                          {errors.phonecodecompany && (
                            <span className="errorMessageText text-danger">
                              {errors.phonecodecompany}
                            </span>
                          )}
                          {errors.companyMobile && (
                            <span className="errorMessageText text-danger">
                              {errors.companyMobile}
                            </span>
                          )}
                        </div>
                        <div className="col-md-6">
                          <div className="mb-3">
                            <label
                              className="font-weight-bold"
                              htmlFor="licenseNumber"
                            >
                              License number/Official authorisation number{" "}
                              <span style={{ fontSize: "20px", color: "red" }}>
                                *
                              </span>
                            </label>
                            <input
                              type="text"
                              name="licenseNumber"
                              className="form-control"
                              id="licenseNumber"
                              aria-describedby="emailHelp"
                              autoComplete="off"
                              onChange={(e) => {
                                calcProgress();
                                handleChange(e);
                              }}
                              value={merchant.licenseNumber}
                              required
                            />
                          </div>
                          {errors.licenseNumber && (
                            <span className="errorMessageText text-danger">
                              {errors.licenseNumber}
                            </span>
                          )}
                        </div>
                        <div className="col-md-6">
                          <div className="mb-3">
                            <label
                              className="font-weight-bold"
                              htmlFor="registrationNumber"
                            >
                              Company registration number{" "}
                              <span style={{ fontSize: "20px", color: "red" }}>
                                *
                              </span>
                            </label>
                            <input
                              type="number"
                              name="registrationNumber"
                              className="form-control"
                              id="registrationNumber"
                              aria-describedby="emailHelp"
                              autoComplete="off"
                              onChange={(e) => {
                                calcProgress();
                                handleChange(e);
                              }}
                              value={merchant.registrationNumber}
                              required
                            />
                          </div>
                          {errors.registrationNumber && (
                            <span className="errorMessageText text-danger">
                              {errors.registrationNumber}
                            </span>
                          )}
                        </div>
                        <div className="col-md-12">
                          <div className="mb-3">
                            <label
                              className="font-weight-bold"
                              htmlFor="description"
                            >
                              Company profile (Short description of your
                              companies provided activities)
                            </label>
                            <textarea
                              name="description"
                              className="form-control"
                              id="description"
                              aria-describedby="emailHelp"
                              autoComplete="off"
                              onChange={(e) => {
                                handleChange(e);
                              }}
                              value={merchant.description}
                              required
                            />
                          </div>
                          {errors.description && (
                            <span className="errorMessageText text-danger">
                              {errors.description}
                            </span>
                          )}
                        </div>
                        <div className="col-md-6" id="top5">
                          <div className="mb-3">
                            <label
                              className="font-weight-bold"
                              htmlFor="socialLink"
                            >
                              Social Link
                            </label>
                            <input
                              type="text"
                              name="socialLink"
                              className="form-control"
                              id="socialLink"
                              aria-describedby="emailHelp"
                              autoComplete="off"
                              onChange={(e) => handleChange(e)}
                              value={merchant.socialLink}
                              required
                            />
                          </div>
                          {errors.socialLink && (
                            <span className="errorMessageText text-danger">
                              {errors.socialLink}
                            </span>
                          )}
                        </div>
                        <div className="col-md-6">
                          <label className="font-weight-bold" htmlFor="logo">
                            Company Logo
                          </label>
                          <input
                            name="logo"
                            id="logo"
                            type="file"
                            ref={imgInputRef}
                            className="form-control"
                            onChange={(e) => {
                              setlogo(e.target.files[0]);
                              handleImage(e, errors);
                            }}
                            required
                            accept="image/*"
                          />
                          {uploadedLogo.length > 0 && (
                            <img
                              style={{ height: "100px", width: "100px" }}
                              src={uploadedLogo}
                            />
                          )}
                          {errors.logo && (
                            <span className="errorMessageText text-danger">
                              {errors.logo}
                            </span>
                          )}
                        </div>
                        <div className="col-md-6">
                          <div className="mb-3">
                            <label
                              className="font-weight-bold"
                              htmlFor="insurancePolicy"
                            >
                              Insurance Policy
                            </label>
                            <input
                              type="file"
                              name="insurancePolicy"
                              className="form-control"
                              id="insurancePolicy"
                              aria-describedby="emailHelp"
                              autoComplete="off"
                              onChange={(e) => {
                                setinsurancePolicy(e.target.files[0]);
                                handleImage(e, errors);
                              }}
                              required
                              accept="image/*"
                            />
                          </div>
                          {upInsurancePolicy.length > 0 && (
                            <img
                              style={{ height: "100px", width: "100px" }}
                              src={upInsurancePolicy}
                            />
                          )}
                          {errors.insurancePolicy && (
                            <span className="errorMessageText text-danger">
                              {errors.insurancePolicy}
                            </span>
                          )}
                        </div>
                        <div className="col-md-6">
                          <label
                            className="font-weight-bold"
                            htmlFor="compamyRegistrationImage"
                          >
                            Company Registration Certificate
                          </label>
                          <input
                            name="compamyRegistrationImage"
                            id="compamyRegistrationImage"
                            type="file"
                            ref={imgInputRef}
                            className="form-control"
                            onChange={(e) => {
                              setcompRegCert(e.target.files[0]);
                              handleImage(e, errors);
                            }}
                            required
                            accept="image/*"
                          />
                          {uploadedcompRegCert.length > 0 && (
                            <img
                              style={{ height: "100px", width: "100px" }}
                              src={uploadedcompRegCert}
                            />
                          )}
                          {errors.compamyRegistrationImage && (
                            <span className="errorMessageText text-danger">
                              {errors.compamyRegistrationImage}
                            </span>
                          )}
                        </div>
                      </div>

                      <div className="row" style={{ padding: "1rem" }}>
                        <div className="col-md-12">
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "flex-end",
                            }}
                          >
                            <button
                              className="mt-2 btn btn-secondary d-table"
                              type="submit"
                              onClick={(e) => handleDraftSave(e)}
                              style={{ marginRight: "10px" }}
                            >
                              Save As Draft
                            </button>
                            <button
                              className="mt-2 btn btn-primary ml-auto d-table"
                              type="submit"
                              onClick={(e) => handleSubmit(e)}
                            >
                              Submit
                            </button>
                          </div>
                        </div>
                      </div>
                    </TabPanel>
                  </Tabs>
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
    </>
  );
}

import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import { ApiHelperFunction } from "../../services/api/apiHelpers";

let initialState = {
  status: "idle",
  user: {},
  user_details: null,
  isLoading: false,
  isSuccess: false,
  isError: false,
};

export const signup = createAsyncThunk("admin_signup", async (data) => {
  let response = await ApiHelperFunction({
    urlPath: "/admin/register",
    method: "POST",
    data: JSON.stringify(data),
  });

  if (response.status === 200) {
    localStorage.setItem("user_email", response.data.data.email);
    localStorage.setItem("Authorization", response.data.data.token);
    localStorage.setItem("userType", "Admin");
    toast.success("Registered successfully!");
    return response.data;
  } else {
    toast.error("Something went wrong.");

    return data.rejectedWithValue();
  }
});

export const signin = createAsyncThunk("admin_login", async (data) => {
  let response = await ApiHelperFunction({
    urlPath: "/admin/login",
    method: "POST",
    data: JSON.stringify(data),
  });

  if (response.status === 200) {
    localStorage.setItem("Authorization", response.data.data.token);
    localStorage.setItem("userType", "Admin");
    toast.success("Login successful!");
    return response.data;
  } else {
    toast.error("Wrong email or password");

    return data.rejectedWithValue();
  }
});

export const AuthSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    clearState: (state) => {
      state.isLoading = false;
      state.isSuccess = false;
      state.isError = false;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(signup.pending, (state) => {
        state.status = "loading";
        state.isLoading = true;
      })
      .addCase(signup.fulfilled, (state, { payload }) => {
        state.status = "success";
        state.isSuccess = true;
        state.user = payload.data;
      })
      .addCase(signup.rejected, (state) => {
        state.status = "failed";
        state.isError = true;
        state.isSuccess = false;
      })
      .addCase(signin.pending, (state) => {
        state.status = "loading";
        state.isLoading = true;
      })
      .addCase(signin.fulfilled, (state, { payload }) => {
        state.status = "success";
        state.isSuccess = true;
        state.user_details = payload.data;
      })
      .addCase(signin.rejected, (state) => {
        state.status = "failed";
        state.isError = true;
        state.isSuccess = false;
      });
  },
});

export const { clearState } = AuthSlice.actions;

export default AuthSlice.reducer;

import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import { ApiHelperFunction } from "../../services/api/apiHelpers";

let initialState = {
  status: "idle",
  blog_types: [],
  blog_type_categories: [],
  blogs: [],
  isLoading: false,
  isSuccess: false,
  isError: false,
};

export const getAllBlogTypes = createAsyncThunk('get_all_blog_types', async () => {
  let response = await ApiHelperFunction({
    urlPath: "/admin/viewtype",
    method: "GET",
  });
 
  if (response.status === 200) {
    return response?.data;
  } else {
    toast.error("Can't get data. Something went wrong");
  }
})

export const getAllBlogTypeCategories = createAsyncThunk('get_all_blog_type_categories', async () => {
  let response = await ApiHelperFunction({
    urlPath: "/admin/viewBlogCategory",
    method: "GET",
  });
  if (response.status === 200) {
    return response?.data;
  } else {
    toast.error("Can't get data. Something went wrong");
  }
})

export const getAllBlogs = createAsyncThunk(
  "get_all_blogs",
  async () => {
    let response = await ApiHelperFunction({
      urlPath: "/admin/viewBlog",
      method: "GET",
    });
    if (response.status === 200) {
      return response?.data;
    } else {
      toast.error("Can't get data. Something went wrong");
    }
  }
);

export const BlogSlice = createSlice({
  name: "blog_actions",
  initialState,
  reducers: {
    clearState: (state) => {
      state.isLoading = false;
      state.isSuccess = false;
      state.isError = false;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAllBlogs.pending, (state) => {
        state.status = "loading";
        state.isLoading = true;
      })
      .addCase(getAllBlogs.fulfilled, (state, { payload }) => {
        state.status = "success";
        state.isSuccess = true;
        state.blogs = payload?.data;
      })
      .addCase(getAllBlogs.rejected, (state) => {
        state.status = "failed";
        state.isError = true;
        state.isSuccess = false;
      })
      .addCase(getAllBlogTypes.pending, (state) => {
        state.status = "loading";
        state.isLoading = true;
      })
      .addCase(getAllBlogTypes.fulfilled, (state, { payload }) => {
        state.status = "success";
        state.isSuccess = true;
        state.blog_types = payload?.data;
      })
      .addCase(getAllBlogTypes.rejected, (state) => {
        state.status = "failed";
        state.isError = true;
        state.isSuccess = false;
      })
      .addCase(getAllBlogTypeCategories.pending, (state) => {
        state.status = "loading";
        state.isLoading = true;
      })
      .addCase(getAllBlogTypeCategories.fulfilled, (state, { payload }) => {
        state.status = "success";
        state.isSuccess = true;
        state.blog_type_categories = payload?.data;
      })
      .addCase(getAllBlogTypeCategories.rejected, (state) => {
        state.status = "failed";
        state.isError = true;
        state.isSuccess = false;
      });
  },
});

export const { clearState } = BlogSlice.actions;

export default BlogSlice.reducer;

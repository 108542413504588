// Hooks
import React, { useState, useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";

// Helpers
import { toast } from "react-toastify";

// Utils
import { apiCallSuccess, apiCallError } from "../../../utils/common-utils";

// API
import { JSON_API } from "../../../services/api/jsonApiCallWithInterceptor";

// Redux actions
import { getAllNewMerchantRequests } from "../../../redux/adminSlice/merchantActionsSlice";

// Components
import Layout from "../../../Layout";
import Table from "../../../Component/Table";

function NewMerchantRequests() {
  const dispatch = useDispatch();
  const [error, setError] = useState("");
  const [searchText, setsearchText] = useState("");
  const [modal, setModal] = useState(false);
  const [modalToggleView, setmodalToggleView] = useState(null);
  const [itemIndex, setitemIndex] = useState(0);
  const [deleteId, setdeleteId] = useState("");
  // Redux stores
  const { new_merchant_requests } = useSelector(
    (state) => state.adminMerchantActionSlice
  );
  console.log("new_merchant_requests", new_merchant_requests);
  console.log("new_merchant_requests length", new_merchant_requests.length);

  // Component did mount
  useEffect(() => {
    dispatch(getAllNewMerchantRequests());
  }, []);

  const openModal = () => {
    setModal(true);
  };

  const deleteLanguage = async (id) => {
    try {
      let res = await JSON_API["adminDeleteGift"]({ id: id });
      // console.log("Res new marchant", res);

      if (res.isSuccess) {
        setModal(false);
        apiCallSuccess(res.data);
      } else {
        apiCallError(res?.error?.message, setError);
      }
    } catch (error) {
      apiCallError(error.message, setError);
    }
  };

  const filterData = () => {
    if (searchText !== "") {
      return new_merchant_requests.filter((item) =>
        JSON.stringify(item).toLowerCase().includes(searchText.toLowerCase())
      );
    }
    return new_merchant_requests;
  };

  const tableData = useMemo(() => {
    return {
    columns: [
      {
        label: "SL",
        field: "sl",
        sort: "asc",
        width: 100,
      },
      {
        label: "Merchant Name",
        field: "merchant_name",
        sort: "asc",
        width: 150,
      },
      {
        label: "City",
        field: "merchant_company",
        sort: "asc",
        width: 150,
      },
      // {
      //   label: "Status",
      //   field: "status",
      //   sort: "asc",
      //   width: 150,
      // },
      {
        label: "View Details",
        field: "detail_view",
        sort: "asc",
        width: 150,
      },
    ],
    rows:
      filterData()?.length > 0
        ? filterData().map((item, idx) => {
          console.log('item new request',item)
            return {
              sl: idx + 1,
              merchant_name: (
                <div className="widget-content p-0">
                  <div className="widget-content-wrapper">
                    <div className="widget-content-left flex2">
                      <div
                        className="widget-heading"
                        style={{ opacity: 1, textAlign: "center" }}
                      >
                        {item?.firstName?.charAt(0)?.toUpperCase() +
                          item?.firstName?.slice(1) +
                          " " +
                          item?.lastName?.charAt(0)?.toUpperCase() +
                          item?.lastName?.slice(1)}
                      </div>
                      {/* <div className="widget-subheading opacity-7">UI Designer</div> */}
                    </div>
                  </div>
                </div>
              ),
              merchant_company: (
                <div className="widget-content p-0">
                  <div className="widget-content-wrapper">
                    <div className="widget-content-left flex2">
                      <div
                        className="widget-heading"
                        style={{ opacity: 1, textAlign: "center" }}
                      >
                        {item?.city}
                      </div>
                      {/* <div className="widget-subheading opacity-7">UI Designer</div> */}
                    </div>
                  </div>
                </div>
              ),
              // status: (
              //   <button
              //     className={`badge ${
              //       item?.status ? "bg-success" : "bg-danger"
              //     } border-0`}
              //   >
              //     {item?.status ? "Active" : "Deactivated"}
              //   </button>
              // ),
              detail_view: (
                <div>
                  {/* <button
                    className="me-2 btn-icon btn-icon-only btn btn-outline-danger"
                    onClick={() => {
                      openModal();
                      setmodalToggleView(1);
                      setdeleteId(gifts[idx]._id);
                    }}
                  >
                    <i className="fa-solid fa-trash-can" />
                  </button>
                  <button
                    className="me-2 btn-icon btn-icon-only btn btn-outline-success"
                    onClick={() => {
                      openModal();
                      setmodalToggleView(0);
                      setitemIndex(idx);
                    }}
                  >
                    <i class="fa-regular fa-pen-to-square"></i>
                  </button> */}
                  <Link
                    to={`/new-merchant-detail-view/${item?._id}`}
                    state={item}
                  >
                    <button className="me-2 btn-icon btn-icon-only btn btn-outline-primary">
                      <i class="fa fa-info-circle" aria-hidden="true"></i>
                    </button>
                  </Link>
                </div>
              ),
            };
          })
        : [],
  };
},[new_merchant_requests, searchText]);

  const renderModalView = (index) => {
    switch (index) {
      case 0:
        return (
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">Change Price</h5>
            </div>
            <form className="">
              <div className="modal-body">
                <div className="col-md-12">
                  <div className="mb-3">
                    <label
                      htmlFor="price"
                      className="form-label"
                      style={{ margin: "0.5rem 0.5rem" }}
                    >
                      Price
                    </label>
                    <input
                      name="price"
                      id="price"
                      type="number"
                      className="form-control"
                      autoComplete="false"
                      // value={}
                      onChange={(e) => {}}
                    />
                  </div>
                </div>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-link"
                  onClick={() => {
                    setModal(false);
                    // setnewLanguage("");
                  }}
                >
                  Cancel
                </button>
                <button
                  type="submit"
                  className="btn btn-primary"
                  onClick={(e) => {}}
                >
                  Edit
                </button>
              </div>
            </form>
          </div>
        );
      case 1:
        return (
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">Delete Info</h5>
            </div>
            <div className="modal-body">
              <div className="deleteModalContainer p-5">
                <i className="ri-close-circle-line deleteIcon"></i>
                <h3>Are You Sure?</h3>
                <span>Do You Want To Delete This Information?</span>
                <div className="deleteModalNote">
                  <span>*Note : This data will be permanently deleted</span>
                </div>
              </div>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-link"
                onClick={() => {
                  setModal(false);
                }}
              >
                Cancel
              </button>
              <button
                type="button"
                className="btn btn-primary"
                onClick={() => {
                  deleteLanguage(deleteId);
                }}
              >
                Delete
              </button>
            </div>
          </div>
        );
      default:
        return null;
    }
  };

  return (
    <Table
      striped
      tableTitle="All New Merchant Requests "
      data={tableData}
      modal={modal}
      setModal={setModal}
      modalToggleView={modalToggleView}
      renderModalView={renderModalView}
      onSearch={(val) => setsearchText(val)}
    />
  );
}

export default NewMerchantRequests;

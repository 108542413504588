// Hooks
import React, { useState, useRef, useEffect } from "react";
import { apiCallError } from "../../../../utils/common-utils";
import { toast } from "react-toastify";
import { JSON_API } from "../../../../services/api/jsonApiCallWithInterceptor";
import Loader from "../../../../Component/Loader";
import useImgUploader from "../../../../Hooks/use-img-uploader";
import { capitalizeFirstLetter } from "../../../../utils";
import { useDispatch, useSelector } from "react-redux";
import { getAllcountres } from "../../../../redux/adminSlice/countrySlice";
import Select from "react-select";
import { getCitiesOfACountry } from "../../../../redux/adminSlice/citySlice";

let initialValues = {
  countryId: "",
  countryName: "",
  cityId: "",
  cityName: "",
  siteName: "",
  image: "",
};

let createErrors = {
  countryId: "",
  cityId: "",
  siteName: "",
  picture: "",
};

const config = {
  url: "/admin/uploadActivitySiteImage",
  propertyName: "data",
};

function AddAndManageActivitySite() {
  const dispatch = useDispatch();
  const fileInputRef = useRef(null);
  const [city, setcity] = useState(initialValues);
  const [errors, seterrors] = useState(createErrors);
  const [isLoadings, setisLoadings] = useState(false);
  const { Img, setImg, imageHandler, imgComp, imgLoading } =
    useImgUploader(config);
  const { countres } = useSelector((state) => state.countrySlice);
  const { cities_of_a_country, isLoading } = useSelector((state) => state.citySlice);

  useEffect(() => {
    dispatch(getAllcountres());
  }, [dispatch]);

  const handleChange = (event) => {
    event.preventDefault();
    let input = capitalizeFirstLetter(event);
    setcity({ ...city, [event.target.name]: input });
  };

  const handleValidation = () => {
    const { countryId, cityId, siteName } = city;

    if (countryId === "") {
      seterrors({ ...errors, countryId: "Country is required" });

      return false;
    }
    if (cityId === "") {
      seterrors({ ...errors, cityId: "City is required" });

      return false;
    }
    if (siteName === "") {
      seterrors({ ...errors, siteName: "Site name is required" });

      return false;
    }

    return true;
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (isLoadings || imgLoading) return;

    if (handleValidation()) {
      if (Img === "") return toast.error("Picture is required");
      try {
        setisLoadings(true);
        let res = await JSON_API["adminAddActivitySite"]({
          ...city,
          image: Img,
        });

        console.log("wswswsw", res)

        if (res.isSuccess) {
          seterrors(createErrors);
          setImg("");
          setcity(initialValues);
          if (fileInputRef.current) {
            fileInputRef.current.value = "";
          }

          setisLoadings(false);
          toast.success(res.data.message);
        } else {
          toast.error(res.error.error)
          setisLoadings(false);
        }
      } catch (error) {
        console.log("catcjhsss", error)

        toast.error(error.error)
        setisLoadings(false);
      }
      setisLoadings(false);
    }
  };

  //   useEffect(() => {
  // setisLoadings(isLoading)
  //   }, [isLoading] )

  return (
    <>
      {isLoadings && <Loader />}
      <form className="" onSubmit={(e) => handleSubmit(e)}>
        <div component="div" className="TabsAnimation appear-done enter-done">
          <div className="main-card mb-3 card">
            <div className="card-body">
              <div className="card-title">Add Activity Site</div>
              <div className="row" style={{ margin: "0.1rem" }}>
                <div className="col-md-6">
                  <div className="mb-3">
                    <label
                      htmlFor="countryId"
                      className="form-label font-weight-bold"
                    >
                      Country*
                    </label>
                    <Select
                      name="countryId"
                      id="countryId"
                      classNamePrefix="select"
                      options={countres?.map((item, idx) => ({
                        value: item?._id,
                        label: item?.name,
                      }))}
                      // isMulti
                      value={{
                        value: city.countryId,
                        label: city.countryName,
                      }}
                      onChange={(val) => {
                        setcity((prev) => {
                          return {
                            ...prev,
                            countryId: val?.value,
                            countryName: val?.label,
                            cityId: "",
                            cityName: "",
                          };
                        });
                        dispatch(getCitiesOfACountry(val?.value));
                        setisLoadings(isLoading)
                      }}
                    />
                  </div>
                  {errors.countryId && (
                    <span className="errorMessageText text-danger">
                      {errors.countryId}
                    </span>
                  )}
                </div>
                <div className="col-md-6">
                  <div className="mb-3">
                    <label
                      htmlFor="cityId"
                      className="form-label font-weight-bold"
                    >
                      City*
                    </label>
                    <Select
                      name="cityId"
                      id="cityId"
                      classNamePrefix="select"
                      options={cities_of_a_country?.map((item, idx) => ({
                        value: item?._id,
                        label: item?.cityName,
                      }))}
                      // isMulti
                      value={{
                        value: city.cityId,
                        label: city.cityName,
                      }}
                      onChange={(val) => {
                        setcity((prev) => {
                          return {
                            ...prev,
                            cityId: val?.value,
                            cityName: val?.label,
                          };
                        });
                      }}
                    />
                  </div>
                  {errors.cityId && (
                    <span className="errorMessageText text-danger">
                      {errors.cityId}
                    </span>
                  )}
                </div>
                <div className="col-md-6">
                  <div className="mb-3">
                    <label
                      htmlFor="siteName"
                      className="form-label font-weight-bold"
                    >
                      Site Name*
                    </label>
                    <input
                      name="siteName"
                      id="siteName"
                      // placeholder="Your first name, e.g. Walter"
                      type="text"
                      className="form-control"
                      autoComplete="false"
                      value={city.siteName}
                      onChange={(e) => handleChange(e)}
                    />
                  </div>
                  {errors.siteName && (
                    <span className="errorMessageText text-danger">
                      {errors.siteName}
                    </span>
                  )}
                </div>
                <div className="col-md-6">
                  <div className="mb-3">
                    <label
                      htmlFor="picture"
                      className="form-label font-weight-bold"
                    >
                      Image*
                    </label>
                    <input
                      name="picture"
                      id="picture"
                      type="file"
                      accept={"image/*"}
                      ref={fileInputRef}
                      className="form-control"
                      onChange={imageHandler}
                    />
                  </div>
                  {imgComp}
                </div>
              </div>

              <button
                className="mt-2 btn btn-primary ml-auto d-table"
                type="submit"
                onClick={() => { }}
              >
                Submit
              </button>
            </div>
          </div>
        </div>
      </form>
    </>
  );
}

export default AddAndManageActivitySite;

// Hooks
import React, { useState, useEffect } from "react";
import { useNavigate, Link, NavLink } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import $ from "jquery";
import { reactLocalStorage } from "reactjs-localstorage";
import axios from "axios";
import { toast } from "react-toastify";
// Redux actions
import { getAllMerchants } from "../../redux/adminSlice/merchantActionsSlice";
import { loginStatusClearState } from "../../redux/authSlice/adminProfileSlice";
import { subadminDataClearState } from "../../redux/adminSlice/subadminActionsSlice";
import { merchantDataClearState } from "../../redux/adminSlice/merchantActionsSlice";
import {
  activityActionsClearState,
  getAllMerchantAdditions,
} from "../../redux/adminSlice/activitySlice";
import { clearBookingState } from "../../redux/adminSlice/bookingSlice";
import { clearPrivacyState } from "../../redux/adminSlice/privacyPolicySlice";
import { clearTermsState } from "../../redux/adminSlice/termsAndConSlice";
import { clearCityState } from "../../redux/adminSlice/citySlice";
import { merchantCommissionDataClearState } from "../../redux/adminSlice/merchantCommissionSlice";

import logo from "../../assets/utils/images/logo-inverse2.png";

import { BASE_URL, API_ENDPOINT_PATH } from "../../constants/config";

const API_URL = BASE_URL + API_ENDPOINT_PATH;

export default function Header() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [allMerchantData, setallMerchantData] = useState([]);
  const [accountModal, setAccountModal] = useState(false);
  const [profileData, setprofileData] = useState(null);
  const [newMerchantCount, setNewMerchantCount] = useState(null);
  const [productChangeCount, setProductChangeCount] = useState(null);

  const merchantType = "new";
  const { details_change_requests_new } = useSelector(
    (state) => state.adminMerchantActionSlice
  );
  const { merchants } = useSelector((state) => state.adminMerchantActionSlice);

  const { merchant_additions } = useSelector(
    (state) => state.adminActivityActionSlice
  );

  useEffect(() => {
    let filterData = merchant_additions?.filter(
      (item) => item?.isApproval === false && !item?.rejectedStatus
    );
    setNewMerchantCount(filterData);

    let changeCountRequest = merchant_additions?.filter(
      (item) => item?.isEdited === true
    );
    setProductChangeCount(changeCountRequest);
  }, [merchant_additions]);

  useEffect(() => {
    if (
      localStorage.getItem("Authorization") &&
      localStorage.getItem("userType")
    ) {
      dispatch(getAllMerchants());
      dispatch(getAllMerchantAdditions());
    }
  }, []);

  useEffect(() => {
    if (merchants?.length > 0) {
      setallMerchantData(
        merchants.filter((item) => {
          if (merchantType === "new") {
            if (item?.newSupplier && !item?.saveAsDraft) {
              return true;
            } else {
              return false;
            }
          } else {
            if (item?.isApproval) {
              return true;
            } else {
              return false;
            }
          }
        })
      );
    }
  }, [merchants, merchantType]);

  useEffect(() => {
    if (
      localStorage.getItem("Authorization") &&
      localStorage.getItem("userType")
    ) {
      const getProfile = async () => {
        const url = `${API_URL}/admin/get-profile`;
        let response = null;

        await axios
          .get(url, {
            headers: {
              "Content-Type": "application/json",
              Authorization: localStorage.getItem("Authorization"),
              userType: "Admin",
            },
          })
          .then((res) => {
            response = res;
            localStorage.setItem("userdata", JSON.stringify(res.data.data));
          })
          .catch((err) => {
            response = err;
          });
        return response?.data?.data;
      };
      getProfile().then((data) => setprofileData(data));
    }
  }, []);

  const hankclickModal = () => {
    setAccountModal(!accountModal);
  };

  const drawyer = () => {
    $(".app-sidebar").toggleClass("closed-sidebar");
    $(".app-main__outer").toggleClass("test");
    $(".app-footer__inner").toggleClass("test3");
  };
  const drawyerMob = () => {
    $(".app-sidebar").toggleClass("sidebarmob");
    $(".sidebar-mobile-overlay").toggleClass("sidebar-mobile-open");
    $(".BurgerBox").toggleClass("BurgerActive");
  };

  const logout = () => {
    // localStorage.clear();
    reactLocalStorage.clear();
    dispatch(loginStatusClearState());
    dispatch(subadminDataClearState());
    dispatch(merchantDataClearState());
    dispatch(activityActionsClearState());
    dispatch(clearBookingState());
    dispatch(clearPrivacyState());
    dispatch(clearTermsState());
    dispatch(clearCityState());
    dispatch(merchantCommissionDataClearState());
    toast.success("Log out successfully.");
    navigate("/login");
  };

  let profileImg = profileData?.image ? profileData?.image : "logo192.png";

  const overlayHandler = (e) => {
    if (!document.querySelector("#parentOverlay").contains(e.target)) {
      setAccountModal(false);
    }
  };
  useEffect(() => {
    document.addEventListener("mousedown", overlayHandler);
    return () => document.removeEventListener("mousedown", overlayHandler);
  });
  return (
    <>
      <div className="app-header bg-strong-bliss header-text-light header-shadow appear-done enter-done">
        <div className="app-header__logo">
          <div className="logo-src">
            <img src={logo} className="img-fluid" alt="logo" />
          </div>
          <div className="header__pane ms-auto">
            <div>
              <button
                className="jsx-2200192874 BurgerSlider Burger"
                onClick={drawyer}
              >
                <div className="jsx-2200192874 BurgerBox">
                  <div className="jsx-2200192874 BurgerInner" />
                </div>
              </button>
            </div>
          </div>
        </div>
        <div className="app-header__mobile-menu">
          <div>
            <button
              className="jsx-3928250682 BurgerSlider Burger"
              onClick={drawyerMob}
            >
              <div className="jsx-3928250682 BurgerBox">
                <div className="jsx-3928250682 BurgerInner" />
              </div>
            </button>
          </div>
        </div>

        <div className="app-header__content">
          <div className="app-header-left">
            {/* ------------------------ */}
            <button className="m-3" title="New Merchant Request .!">
              <NavLink to="/manage-merchant/new" className="metismenu-link">
                {/* <i class="fa fa-handshake-o" aria-hidden="true"><sup  > <div className="supelement prelative"> <div className="absolutep">{allMerchantData?.length}  </div> </div> </sup>  </i> */}

                <i class="fa fa-handshake-o" aria-hidden="true">
                  {" "}
                  <sup style={{ display: !allMerchantData ? "none" : "" }}>
                    {" "}
                    <div className="supelement prelative">
                      {" "}
                      <div className="absolutep">
                        {allMerchantData?.length}{" "}
                      </div>{" "}
                    </div>{" "}
                  </sup>{" "}
                </i>
              </NavLink>
            </button>
            {/* ------------------------ */}
            <button className="m-3" title="New Merchant Request .!">
              <NavLink
                to="/merchant-details-change-requests"
                className="metismenu-link"
              >
                <i class="fa fa-chain-broken" aria-hidden="true">
                  {" "}
                  <sup>
                    {" "}
                    <div className="supelement prelative">
                      {" "}
                      <div className="absolutep">
                        {details_change_requests_new?.length}{" "}
                      </div>{" "}
                    </div>{" "}
                  </sup>{" "}
                </i>
              </NavLink>
            </button>
            {/* ------------------------ */}
            <button className="m-3" title="Merchant Product Request .!">
              <NavLink
                to="/check-merchant-additions"
                className="metismenu-link"
              >
                {/* <i class="fa fa-shopping-cart" aria-hidden="true"> <sup style={{ display: (!localStorage.getItem('newActivityCnt')) ? 'none' : '' }}> <div className="supelement prelative"> <div className="absolutep">{localStorage.getItem('newActivityCnt')}  </div> </div> </sup> </i> */}

                <i class="fa fa-shopping-cart" aria-hidden="true">
                  {" "}
                  <sup style={{ display: !newMerchantCount ? "none" : "" }}>
                    {" "}
                    <div className="supelement prelative">
                      {" "}
                      <div className="absolutep">
                        {newMerchantCount?.length}{" "}
                      </div>{" "}
                    </div>{" "}
                  </sup>{" "}
                </i>
              </NavLink>
            </button>
            {/* ------------------------ */}
            <button className="m-3" title="Merchant Product Change Request .!">
              <NavLink to="/product-change-requests" className="metismenu-link">
                {/* <i class="fa fa-window-restore" aria-hidden="true"> <sup style={{ display: (!localStorage.getItem('merchantActivityChangeCnt')) ? 'none' : '' }}> <div className="supelement prelative"> <div className="absolutep">{localStorage.getItem('merchantActivityChangeCnt')}</div> </div> </sup>   </i> */}

                <i class="fa fa-window-restore" aria-hidden="true">
                  {" "}
                  <sup style={{ display: !productChangeCount ? "none" : "" }}>
                    {" "}
                    <div className="supelement prelative">
                      {" "}
                      <div className="absolutep">
                        {productChangeCount?.length}
                      </div>{" "}
                    </div>{" "}
                  </sup>{" "}
                </i>
              </NavLink>
            </button>
            <button
              className="m-3"
              title="Refresh"
              onClick={() => {
                alert("refresh ");
              }}
            >
              <i class="fa fa-refresh" aria-hidden="true">
                {" "}
              </i>{" "}
            </button>
          </div>

          <div className="app-header-right">
            <div className="header-btn-lg pe-0">
              <div className="widget-content p-0">
                <div className="widget-content-wrapper">
                  <div className="widget-content-left">
                    <div className="btn-group" id="parentOverlay">
                      <button
                        type="button"
                        aria-haspopup="true"
                        aria-expanded="false"
                        className="p-0 btn btn-link"
                        onClick={hankclickModal}
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <img
                          width={42}
                          className="rounded-circle"
                          src={profileImg}
                          alt=""
                        />
                        <svg
                          aria-hidden="true"
                          focusable="false"
                          data-prefix="fas"
                          data-icon="angle-down"
                          className="svg-inline--fa fa-angle-down opacity-8 ml-2"
                          role="img"
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 448 512"
                          style={{ height: "1em" }}
                        >
                          <path
                            fill="currentColor"
                            d="M201.4 342.6c12.5 12.5 32.8 12.5 45.3 0l160-160c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L224 274.7 86.6 137.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l160 160z"
                          />
                        </svg>
                      </button>
                      {accountModal && (
                        <div
                          className="account"
                          style={{
                            position: "absolute",
                            right: "-7rem",
                            top: "-10px",
                          }}
                        >
                          <div
                            tabIndex={-1}
                            role="menu"
                            aria-hidden="false"
                            className="rm-pointers dropdown-menu-lg dropdown-menu dropdown-menu-end show"
                            data-popper-placement="bottom-end"
                            style={{
                              position: "absolute",
                              inset: "20px 38px auto auto",
                              transform: "translate(0px, 44px)",
                            }}
                          >
                            <ul className="nav flex-column">
                              <li className="nav-item">
                                <Link
                                  className="nav-link"
                                  to="/profile"
                                  state={profileData}
                                >
                                  Edit Profile
                                </Link>
                              </li>
                              <hr style={{ margin: "5px" }} />
                              {/* <li className="nav-item-header nav-item">My Account</li> */}

                              <li
                                className="nav-item"
                                style={{ marginLeft: "auto" }}
                              >
                                {/* <a href="#" className="nav-link"> */}
                                <div
                                  className="ms-auto badge bg-warning"
                                  onClick={logout}
                                  style={{ cursor: "pointer" }}
                                >
                                  Logout
                                </div>
                                {/* </a> */}
                              </li>
                            </ul>
                          </div>
                        </div>
                      )}
                      {/* <div
                        tabIndex={-1}
                        role="menu"
                        aria-hidden="true"
                        className="rm-pointers dropdown-menu-lg dropdown-menu dropdown-menu-end">
                        <ul className="nav flex-column">
                          <li className="nav-item-header nav-item">Activity</li>
                          <li className="nav-item">
                            <a href="#" className="nav-link">
                              Chat<div className="ms-auto badge bg-pill bg-info">8</div>
                            </a>
                          </li>
                          <li className="nav-item">
                            <a href="#" className="nav-link">
                              Recover Password
                            </a>
                          </li>
                          <li className="nav-item-header nav-item">My Account</li>
                          <li className="nav-item">
                            <a href="#" className="nav-link">
                              Settings
                              <div className="ms-auto badge bg-success">New</div>
                            </a>
                          </li>
                          <li className="nav-item">
                            <a href="#" className="nav-link">
                              Messages
                              <div className="ms-auto badge bg-warning">512</div>
                            </a>
                          </li>
                          <li className="nav-item">
                            <a href="#" className="nav-link">
                              Logs
                            </a>
                          </li>
                        </ul>
                      </div> */}
                    </div>
                  </div>
                  <div className="widget-content-left  ms-3 header-user-info">
                    <div className="widget-heading">
                      {profileData?.firstName && profileData?.lastName
                        ? profileData?.firstName?.charAt(0)?.toUpperCase() +
                          profileData?.firstName?.slice(1) +
                          " " +
                          profileData?.lastName?.charAt(0)?.toUpperCase() +
                          profileData?.lastName?.slice(1)
                        : "Admin"}
                    </div>
                    {/* <div className="widget-subheading">VP People Manager</div> */}
                  </div>
                  {/* <div className="widget-content-right header-user-info ms-3">
                    <button
                      type="button"
                      id="Tooltip-1"
                      className="btn-shadow p-1 btn btn-info btn-sm"
                    >
                    <i class="fa-regular fa-calendar"></i>
                    </button>
                  </div> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

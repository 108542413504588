// Hooks
import React, { useState, useRef, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

// Utilities
import {
  apiCallSuccess,
  apiCallError,
  capitalizeFirstLetter,
} from "../../../utils";

// API functions
import { JSON_API } from "../../../services/api/jsonApiCallWithInterceptor";

// Redux actions
import { getAllcountres } from "../../../redux/adminSlice/countrySlice";

// Components
import Layout from "../../../Layout";
import Select from "react-select";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

const initialValues = {
  continent: "",
  countryId: "",
};

const createErrors = {
  continent: "",
  countryId: "",
  //   activityTypeId: "",
};

function AddInfo() {
  const dispatch = useDispatch();
  const [category, setsection] = useState(initialValues);
  const [error, setError] = useState("");
  const [errors, seterrors] = useState(createErrors);
  const navigate=useNavigate();

  // Redux stores
  const { countres } = useSelector((state) => state.countrySlice);

  useEffect(() => {
    dispatch(getAllcountres());
  }, []);

  const handleChange = (event) => {
    event.preventDefault();
    let input;
    if (event.target.name !== "countryId") {
      input = capitalizeFirstLetter(event);
    } else {
      input = event.target.value;
    }
    // console.log(event.target.name, event.target.value);
    setsection({ ...category, [event.target.name]: input });
  };

  const handleValidation = () => {
    const { continent, countryId } = category;

    if (continent === "") {
      seterrors({ ...createErrors, continent: "Continent is required" });

      return false;
    }
    if (countryId === "") {
      seterrors({ ...createErrors, countryId: "Country is required" });

      return false;
    }

    return true;
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    // console.log("submitting");
    if (handleValidation()) {
      try {
        let res = await JSON_API["adminAddSitemap"](category);

        if (res.isSuccess) {
          seterrors(createErrors);
          setsection(initialValues);
          toast.success(' Sitemap added successfully ')
          navigate('/manage-sitemap')
          
        } else {
          apiCallError(res?.error?.message, setError);
        }
      } catch (error) {
        apiCallError(error.message, setError);
      }
    }
  };

  return (
    <form className="" onSubmit={(e) => handleSubmit(e)}>
      <div component="div" className="TabsAnimation appear-done enter-done">
        <div className="main-card mb-3 card">
          <div className="card-title">Add Sitemap</div>
          {/* <div className="row"> */}
          <div className="col-md-12">
            <div className="mb-3">
              <label htmlFor="continent" className="form-label">
                Continent
              </label>
              <input
                name="continent"
                id="continent"
                type="text"
                className="form-control"
                autoComplete="false"
                value={category.continent}
                onChange={(e) => handleChange(e)}
              />
            </div>
            {errors.continent && (
              <span className="errorMessageText text-danger">
                {errors.continent}
              </span>
            )}
          </div>
          <div className="col-md-12">
            <div className="mb-3">
              <label htmlFor="countryId" className="form-label">
                Country
              </label>
              {/* <select
                name="countryId"
                id="countryId"
                className="form-control form-select"
                onChange={handleChange}
              >
                <option value="" key={0}>
                  Select
                </option>
                {countres.map((item, idx) => {
                  return (
                    <>
                      <option key={idx + 1} value={`${item?._id}`}>
                        {item?.name}
                      </option>
                    </>
                  );
                })}
              </select> */}
              <Select
                name="countryId"
                id="countryId"
                classNamePrefix="select"
                // ref={inputRef}
                options={countres.map((item, idx) => ({
                  value: item?._id,
                  label: item?.name,
                }))}
                onChange={(val) => {
                  setsection({ ...category, countryId: val?.value });
                  // console.log("country",category.countryId);
                }}
              />
            </div>
            {errors.desc && (
              <span className="errorMessageText text-danger">
                {errors.desc}
              </span>
            )}
          </div>

          {/* </div> */}

          <button
            className="mt-2 btn btn-primary ml-auto d-table"
            type="submit"
            style={{ margin: "5px" }}
          >
            Add
          </button>
        </div>
      </div>
    </form>
  );
}

export default AddInfo;

import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

// Redux actions
import { getAllBookings } from "../../../redux/adminSlice/bookingSlice";
import { getAllMerchants } from "../../../redux/adminSlice/merchantActionsSlice";

// API functions
import { requestData } from "../../../services/api/apiHelpers";

// Components
import Loader from "../../../Component/Loader";
import Select from "react-select";

import { BASE_URL } from "../../../constants/config";
import Bookinginvoice from "./BookingInvoice";
import { toast } from "react-toastify";

let initialValues = {
  orderId: "",
  activityTitle: "",
  merchantId: "",
  merchantName: "",
};

let createErrors = {
  orderId: "",
  merchantId: "",
};

function OrderInvoice() {
  const dispatch = useDispatch();
  const [isLoading, setisLoading] = useState(false);
  const [merchant, setmerchant] = useState(initialValues);
  const [errors, seterrors] = useState(createErrors);
  const [invoiceData, setinvoiceData] = useState([]);
  const { merchants } = useSelector((state) => state.adminMerchantActionSlice);


  useEffect(() => {
    dispatch(getAllBookings());
    dispatch(getAllMerchants());
  }, []);



  const handleValidation = () => {
    const { merchantId } = merchant;
    if (merchantId === "") {
      seterrors({ ...createErrors, merchantId: "A merchant is required" });

      return false;
    }

    return true;
  };

  // const handleSubmit = async (event) => {
  //   event.preventDefault();

  //   // console.log(merchant);
  //   if (handleValidation()) {
  //     try {
  //       setisLoading(true);
  //       let res = await requestData(
  //         `/admin/billing-history/${merchant.merchantId}`,
  //         "GET"
  //       );
  //       console.log(res);
  //       if (res && res.status) {
  //         setinvoiceData(res?.data);
  //       }

  //       // setinvoiceData(res?.error?.message);

  //       setisLoading(false);
  //     } catch (error) {
  //       setisLoading(false);
  //       console.log(error.message);
  //     }
  //   }
  // };

  function getMonthName(monthNumber) {
    const months = [
        "January", "February", "March", "April", "May", "June",
        "July", "August", "September", "October", "November", "December"
    ];

    if (monthNumber >= 1 && monthNumber <= 12) {
        return months[monthNumber - 1];
    } else {
        return "Invalid month number";
    }
}

  const fetchPdf=async(item)=>{
  
    let data = {
      monthEndDate: item.createdOn,
      type: "pdf"
    };
  let token = localStorage.getItem("Authorization");
      setisLoading(true)
      try {
        const response = await fetch(`${BASE_URL}api/v1/admin/viewMonthwisePDF/${item.marchantId}`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            "authorization":token,
            "userType":"Admin"
          },
          body: JSON.stringify(data),
        });
        if(response && response.status){
          setisLoading(false)
        }
         // Extract the blob data from the response
         const blob = await response.blob();
  
         // Create a URL for the blob object
         const blobUrl = URL.createObjectURL(blob);
  
         // Create a temporary anchor element
         const a = document.createElement('a');
         a.href = blobUrl;
         
         // Set the download attribute with a filename
         a.download = 'downloaded-file'; // You can specify a file name here
         
         // Append the anchor to the body (necessary for Firefox)
         document.body.appendChild(a);
  
         // Trigger a click event on the anchor
         a.click();
  
         // Remove the anchor from the document
         document.body.removeChild(a);
  
         // Revoke the object URL to free up memory
         URL.revokeObjectURL(blobUrl);

        if (!response.ok) {
          throw new Error('Failed to fetch PDF');
        }
  
        const pdfBlob = await response.blob();
        const pdfUrl = URL.createObjectURL(pdfBlob);
  
        const embedElement = document.createElement('embed');
        embedElement.setAttribute('src', pdfUrl);
        embedElement.setAttribute('type', 'application/pdf');
        embedElement.setAttribute('width', '100%');
        embedElement.setAttribute('height', '600px');
  
        const pdfContainer = document.getElementById('pdf-container');
        pdfContainer.appendChild(embedElement);
      
      } catch (error) {
        toast.error('Error fetching PDF:', error);
      }
    


  }

  const tableData = {
    columns: [
      {
        label: "Month/Year",
        field: "month_year",
        sort: "asc",
        width: 150,
      },
      {
        label: "Invoice",
        field: "link",
        sort: "asc",
        width: 150,
      },
    ],
    rows:
      invoiceData?.length > 0
        ? invoiceData?.map((item, index) => {
        
            return {
              month_year: (
                <div className="widget-content p-0">
                  <div className="widget-content-wrapper">
                    <div className="widget-content-left flex2">
                      <div
                        className="widget-heading"
                        style={{ opacity: 1, textAlign: "center" }}
                      >
                        {getMonthName(Number(item?.createdMonth)) + ", " + item?.createdYear}
                      </div>
                    </div>
                  </div>
                </div>
              ),
              link: (
                <div className="widget-content p-0">
                  <div className="widget-content-wrapper">
                    <div className="widget-content-left flex2">
                      <div
                        className="widget-heading"
                        style={{ opacity: 1, textAlign: "center" }}
                        onClick={()=>{
                          fetchPdf(item)
                        }}
                      >
                       <a href="#">Link</a>
                      </div>
                    </div>
                  </div>
                </div>
              ),
            };
          })
        : [],
  };

  return (
    <>
      {isLoading && <Loader />}
      <form className="">
        <div component="div" className="TabsAnimation appear-done enter-done">
          <div className="main-card mb-3 card">
            <div className="card-body">
              <div className="card-title">Activity Bookings</div>
              <div className="row" style={{ padding: "1rem" }}>
                <div className="col-md-12">
                  <div className="mb-3">
                    <label
                      htmlFor="merchantId"
                      className="form-label font-weight-bold"
                    >
                      Merchants*
                    </label>
                    <Select
                      name="merchantId"
                      id="merchantId"
                      classNamePrefix="select"
                      options={merchants?.map((item, idx) => ({
                        value: item?._id,
                        label:
                          item?.firstName?.charAt(0)?.toUpperCase() +
                          item?.firstName?.slice(1)?.toLowerCase() +
                          " " +
                          item?.lastName?.charAt(0)?.toUpperCase() +
                          item?.lastName?.slice(1)?.toLowerCase(),
                      }))}
                      value={{
                        value: merchant.merchantId,
                        label: merchant.merchantName,
                      }}
                      onChange={(val) => {
                        setmerchant((prev) => {
                          return {
                            ...prev,
                            merchantId: val?.value,
                            merchantName: val?.label,
                          };
                        });
                      }}
                    />
                  </div>
                  {errors.merchantId && (
                    <span className="errorMessageText text-danger">
                      {errors.merchantId}
                    </span>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>



     {merchant?.merchantId &&<Bookinginvoice marchantId={merchant?.merchantId}/>  } 
    </>
  );
}

export default OrderInvoice;

import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import { ApiHelperFunction } from "../../services/api/apiHelpers";

let initialState = {
  status: "idle",
  gifts: [],
  isLoading: false,
  isSuccess: false,
  isError: false,
};

export const getAllGifts = createAsyncThunk(
  "get_all_gifts",
  async () => {
    let response = await ApiHelperFunction({
      urlPath: "/admin/viewGift",
      method: "GET",
    });
    if (response.status === 200) {
      return response.data;
    } else {
      toast.error("Can't get data. Something went wrong");
    }
  }
);

export const GiftsSlice = createSlice({
  name: "gifts",
  initialState,
  reducers: {
    clearState: (state) => {
      state.isLoading = false;
      state.isSuccess = false;
      state.isError = false;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAllGifts.pending, (state) => {
        state.status = "loading";
        state.isLoading = true;
      })
      .addCase(getAllGifts.fulfilled, (state, { payload }) => {
        if(payload !== undefined){
          console.log('Payload data found ',payload);
          state.status = "success";
          state.isSuccess = true;
          state.gifts = payload.data;
        }
        else{
          console.log('Payload data is undefined for gifts');
        }

      })
      .addCase(getAllGifts.rejected, (state) => {
        state.status = "failed";
        state.isError = true;
        state.isSuccess = false;
      });
  },
});

export const { clearState } = GiftsSlice.actions;

export default GiftsSlice.reducer;

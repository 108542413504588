import React, { useState, useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { JSON_API } from "../../../../services/api/jsonApiCallWithInterceptor";
import { getAllActivityTypes } from "../../../../redux/adminSlice/activitySlice";
import Table from "../../../../Component/Table";
import Loader from "../../../../Component/Loader";

function ManageActivityType() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [searchText, setsearchText] = useState("");
  const [modal, setModal] = useState(false);
  const [modalToggleView, setmodalToggleView] = useState(null);
  const [deleteId, setdeleteId] = useState("");
  const [isLoading, setisLoading] = useState(false);


  let userdata  = localStorage.getItem('userdata');
  userdata  = JSON.parse(userdata);
  let assignAccess = userdata?.assignAccess;
  let userType     = userdata?.userType;



  // Redux stores
  const { activity_types } = useSelector(
    (state) => state.adminActivityActionSlice
  );

  useEffect(() => {
    dispatch(getAllActivityTypes());
  }, [dispatch]);

  const openModal = () => {
    setModal(true);
  };

  const deleteActivity = async (id) => {
    try {
      setisLoading(true);
      let res = await JSON_API["adminDeleteActivityType"]({ id: id });
      if (res.isSuccess) {
        dispatch(getAllActivityTypes());
        setModal(false);
        setisLoading(false);
        ActivityApiCallSuccess(res.data);
      } else {
        setisLoading(false);
        ActivityApiCallError(res?.error?.message);
      }
    } catch (error) {
      setisLoading(false);
      ActivityApiCallError(error.message);
    }
  };

  const ActivityApiCallSuccess = (data) => {
    toast.success("Activity type deleted successfully!");
  };

  const ActivityApiCallError = (errorMsg) => {
    toast.error(errorMsg);
  };

  const filterData = () => {
    if (searchText !== "") {
      return activity_types.filter((item) =>
        JSON.stringify(item).toLowerCase().includes(searchText.toLowerCase())
      );
    }
    return activity_types;
  };

  const tableData = useMemo(() => {
    return {
      columns: [
        {
          label: "SL",
          field: "sl",
          sort: "asc",
          width: 270,
        },
        {
          label: "Name",
          field: "name",
          sort: "asc",
          width: 150,
        },

        {
          label: "Logo",
          field: "logo",
          sort: "asc",
          width: 200,
        },
        {
          label: "Action",
          field: "action",
          sort: "asc",
          width: 150,
        },
      ],
      rows:
        filterData()?.length > 0
          ? filterData().map((item, idx) => {
              return {
                sl: idx + 1,
                name: (
                  <div className="widget-content p-0">
                    <div className="widget-content-wrapper">
                      <div className="widget-content-left flex2">
                        <div
                          className="zwidget-heading"
                          style={{
                            opacity: 1,
                            margin: " 0 auto",
                            textAlign: "center",
                          }}
                        >
                          {item?.name}
                        </div>
                      </div>
                    </div>
                  </div>
                ),

                logo: (
                  <div className="widget-content p-0">
                    <div className="widget-content-wrapper">
                      <img
                        src={`${item.logo}`}
                        alt=""
                        className="img-fluid"
                        style={{
                          height: "70px",
                          width: "120px",
                          borderRadius: "3px",
                          margin: " 0 auto",
                        }}
                      />
                    </div>
                  </div>
                ),
                action: (
                  <div>
                    {(userType ==="admin" || (userType ==="subadmin" && assignAccess.includes('delete'))) && (
                    <button
                      className="me-2 btn-icon btn-icon-only btn btn-outline-danger"
                      onClick={() => {
                        openModal();
                        setmodalToggleView(0);
                        setdeleteId(activity_types[idx]._id);
                      }}
                    >
                      <i className="fa-solid fa-trash-can" />
                    </button>
                    )}
                    {(userType ==="admin" || (userType ==="subadmin" && assignAccess.includes('edit'))) && (
                    <button
                      className="me-2 btn-icon btn-icon-only btn btn-outline-success"
                      onClick={() => {
                        navigate(`/edit-activity-type/${item?._id}`);
                      }}
                    >
                      <i class="fa-regular fa-pen-to-square"></i>
                    </button>
                    )}
                  </div>
                ),
              };
            })
          : [],
    };
  }, [activity_types, searchText]);

  const renderModalView = (index) => {
    switch (index) {
      case 0:
        return (
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">Delete Info</h5>
            </div>
            <div className="modal-body">
              <div className="deleteModalContainer p-5">
                <i className="ri-close-circle-line deleteIcon"></i>
                <h3>Are You Sure?</h3>
                <span>Do You Want To Delete This Information?</span>
                <div className="deleteModalNote">
                  <span>*Note : This data will be permanently deleted</span>
                </div>
              </div>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-link"
                onClick={() => {
                  setModal(false);
                }}
              >
                Cancel
              </button>
              <button
                type="button"
                className="btn btn-primary"
                onClick={() => {
                  deleteActivity(deleteId);
                }}
              >
                Delete
              </button>
            </div>
          </div>
        );
      default:
        return null;
    }
  };

  return (
    <>
      {isLoading && <Loader />}
      <Table
        striped
        tableTitle="All Activity Types"
        data={tableData}
        modal={modal}
        setModal={setModal}
        modalToggleView={modalToggleView}
        renderModalView={renderModalView}
        onSearch={(val) => setsearchText(val)}
      />
    </>
  );
}

export default ManageActivityType;

import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import { ApiHelperFunction } from "../../services/api/apiHelpers";

let initialState = {
  status: "idle",
  participants: [],
  isLoading: false,
  isSuccess: false,
  isError: false,
};

export const getAllActivityParticipants = createAsyncThunk(
  "get_all_participants",
  async () => {
    let response = await ApiHelperFunction({
      urlPath: "/admin/view-participant-type",
      method: "GET",
    });
    if (response.status === 200) {
      return response.data;
    } else {
      toast.error("Can't get data. Something went wrong");
    }
  }
);

export const ActivityPartcipantsSlice = createSlice({
  name: "participants",
  initialState,
  reducers: {
    clearState: (state) => {
      state.isLoading = false;
      state.isSuccess = false;
      state.isError = false;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAllActivityParticipants.pending, (state) => {
        state.status = "loading";
        state.isLoading = true;
      })
      .addCase(getAllActivityParticipants.fulfilled, (state, { payload }) => {
        state.status = "success";
        state.isSuccess = true;
        state.participants = payload.data;
      })
      .addCase(getAllActivityParticipants.rejected, (state) => {
        state.status = "failed";
        state.isError = true;
        state.isSuccess = false;
      });
  },
});

export const { clearState } = ActivityPartcipantsSlice.actions;

export default ActivityPartcipantsSlice.reducer;

// Hooks
import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";

// Utils
import {
    capitalizeFirstLetter,
    apiCallSuccess,
    apiCallError,
} from "../../../utils";

// Helpers
import { toast } from "react-toastify";

// API
import { fileUpload } from "../../../services/api/apiHelpers";
import { JSON_API } from "../../../services/api/jsonApiCallWithInterceptor";

// Redux actions
import { getAllCareers } from "../../../redux/adminSlice/careerSlice";

import Loader from "../../../Component/Loader";
import { useNavigate, useParams } from "react-router-dom";

let initialValues = {
    category: "",
    subCategory: "",
    description: "",
};

let createErrors = {
    category: "",
    subCategory: "",
    description: "",
};
const EditCarrier = () => {
    const dispatch = useDispatch();
    const fileInputRef = useRef(null);
    const [Img, setImg] = useState(null);
    const [error, setError] = useState("");
    const [errors, seterrors] = useState(createErrors);
    const [isLoading, setisLoading] = useState(false);
    const [modal, setModal] = useState(false);
    const [pressEdit, setpressEdit] = useState(initialValues);
    const { careers } = useSelector((state) => state.careerSlice);
    const navigate = useNavigate();
    const params = useParams();

    const carrierData = careers.find((item) => item?._id === params.id);
    useEffect(() => {
        dispatch(getAllCareers());
        setpressEdit({
            ...pressEdit,
            category: carrierData.category,
            subCategory: carrierData.subCategory,
            description: carrierData.description,
        });
    }, []);

    const handleEditChange = (event) => {
        event.preventDefault();
        let input;

        if (event.target.name !== "description") {
            input = capitalizeFirstLetter(event);
        } else {
            input = event.target.value;
        }

        setpressEdit({ ...pressEdit, [event.target.name]: input });
    };

    const handleEditValidation = () => {
        const { category, subCategory, description } = pressEdit;

        if (category === "") {
            seterrors({ ...errors, category: "Topic is required" });

            return false;
        }
        if (subCategory === "") {
            seterrors({ ...errors, subCategory: "Subtopic is required" });

            return false;
        }
        if (description === "") {
            seterrors({ ...errors, description: "Description is required" });

            return false;
        }
        return true;
    };

    const handleEditSubmit = async (event) => {
        event.preventDefault();
        let bodyData = { id: `${params.id}`, ...pressEdit };

        if (Img === null) {
            if (handleEditValidation()) {
                try {
                    let res = await JSON_API["adminEditCareer"](bodyData);

                    if (res.isSuccess) {
                        dispatch(getAllCareers());
                        setModal(false);
                        seterrors(createErrors);
                        setpressEdit(initialValues);
                        apiCallSuccess(res.data);
                        navigate('/manage-careers')
                    } else {
                        apiCallError(res?.error?.message, setError);
                    }
                } catch (error) {
                    apiCallError(error.message, setError);
                }
            }
        } else {
            if (Img.type === "image/png" || Img.type === "image/jpeg") {
                if (handleEditValidation()) {
                    const DATA = new FormData();
                    DATA.append("image", Img);
                    setisLoading(true);
                    let imgUpRes = await fileUpload("/admin/careerImage", "POST", DATA);
                    if (imgUpRes && imgUpRes.status) {
                        try {
                            let res = await JSON_API["adminEditCareer"]({
                                ...bodyData,
                                image: imgUpRes.url,
                            });

                            if (res.isSuccess) {
                                dispatch(getAllCareers());
                                setModal(false);
                                seterrors(createErrors);
                                setImg(null);
                                setpressEdit(initialValues);
                                setisLoading(false);
                                apiCallSuccess(res.data);
                            } else {
                                apiCallError(res?.error?.message, setError);
                            }
                        } catch (error) {
                            apiCallError(error.message, setError);
                        }
                    } else {
                        toast.error("Something went wrong!");
                    }
                }
            } else {
                toast.error("Picture is not a .png or .jpg type");
            }
        }
    };

    return (
        <>
            {isLoading && <Loader />}

            <form
                className=""
                onSubmit={(e) => handleEditSubmit(e)}
                encType="multipart/form-data"
            >
                <div component="div" className="TabsAnimation appear-done enter-done">
                    <div className="main-card mb-3 card">
                        <div className="card-body">
                            <div className="card-title">Edit Careers</div>
                            <div className="row" style={{ margin: "0.1rem" }}>
                                <div className="col-md-6">
                                    <div className="mb-3">
                                        <label htmlFor="category" className="form-label">
                                            Category*
                                        </label>
                                        <input
                                            name="category"
                                            id="category"
                                            type="text"
                                            className="form-control"
                                            autoComplete="false"
                                            value={pressEdit.category}
                                            onChange={(e) => handleEditChange(e)}
                                        />
                                    </div>
                                    {errors.category && (
                                        <span className="errorMessageText text-danger">
                                            {errors.category}
                                        </span>
                                    )}
                                </div>
                                <div className="col-md-6">
                                    <div className="mb-3">
                                        <label htmlFor="subCategory" className="form-label">
                                            Subcategory*
                                        </label>
                                        <input
                                            name="subCategory"
                                            id="subCategory"
                                            type="text"
                                            className="form-control"
                                            autoComplete="false"
                                            value={pressEdit.subCategory}
                                            onChange={(e) => handleEditChange(e)}
                                        />
                                    </div>
                                    {errors.subCategory && (
                                        <span className="errorMessageText text-danger">
                                            {errors.subCategory}
                                        </span>
                                    )}
                                </div>
                            </div>

                            <div className="col-md-12">
                                <div className="mb-3">
                                    <label htmlFor="description" className="form-label">
                                        Description*
                                    </label>
                                    <textarea
                                        name="description"
                                        id="description"
                                        className="form-control"
                                        autoComplete="false"
                                        value={pressEdit.description}
                                        onChange={(e) => handleEditChange(e)}
                                    />
                                </div>
                                {errors.bannerTitle && (
                                    <span className="errorMessageText text-danger">
                                        {errors.bannerTitle}
                                    </span>
                                )}
                            </div>
                            <div className="col-md-12">
                                <div className="mb-3">
                                    <label htmlFor="picture" className="form-label">
                                        Picture*
                                    </label>
                                    <input
                                        name="picture"
                                        id="picture"
                                        type="file"
                                        ref={fileInputRef}
                                        className="form-control"
                                        onChange={(e) => setImg(e.target.files[0])}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="d-flex m-2">
                            <button
                                type="button"
                                className="btn btn-link"
                                onClick={() => {
                                    navigate('/manage-careers')
                                }}
                            >
                                Cancel
                            </button>
                            <button type="submit" className="mt-2 btn btn-primary  d-table">
                                Edit
                            </button>
                        </div>
                    </div>
                </div>
            </form>


        </>
    );
    //   }
};

export default EditCarrier;

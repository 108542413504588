// Hooks
import React, { useState, useEffect, useMemo } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

// Utils
import { apiCallSuccess, apiCallError } from "../../../utils/common-utils";

// API
import { JSON_API } from "../../../services/api/jsonApiCallWithInterceptor";

// Redux actions
import { getAllCities } from "../../../redux/adminSlice/citySlice";

// Components
import Table from "../../../Component/Table";
import Loader from "../../../Component/Loader";
import Select from "react-dropdown-select";
import useCountries from "../../../Hooks/useCountries";

let initialValues = {
  countryId: "",
  countryName: "",
  cityId: "",
  cityName: "",
  siteName: "",
  image: "",
};

const ManageCities = () => {
  // function AddCities() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [searchText, setsearchText] = useState("");
  const [error, setError] = useState("");
  const [isLoading, setisLoading] = useState(false);
  const [modal, setModal] = useState(false);
  const [modalToggleView, setmodalToggleView] = useState(null);
  const [deleteId, setdeleteId] = useState("");
  const [city, setcity] = useState(initialValues);
  const { countries, fetchCities, cities, loading } = useCountries();
  const [countryId, setCountryId] = useState("");

  let userdata = localStorage.getItem("userdata");
  userdata = JSON.parse(userdata);
  let assignAccess = userdata?.assignAccess;
  let userType = userdata?.userType;

  // Redux stores

  // Component did mount

  const openModal = () => {
    setModal(true);
  };

  const handleSearch = (event) => {
    setsearchText(event);
  };

  const deleteCity = async (id) => {
    try {
      setisLoading(true);
      let res = await JSON_API["adminDeleteCity"]({ id: id });
      // console.log("Res", res);

      if (res.isSuccess) {
        fetchCities(countryId);
        // dispatch(getAllCities());
        setModal(false);
        setisLoading(false);
        apiCallSuccess(res.data);
      } else {
        setisLoading(false);
        apiCallError(res?.error?.message, setError);
      }
    } catch (error) {
      setisLoading(false);
      apiCallError(error.message, setError);
    }
  };

  const filterData = useMemo(() => {
    if (searchText !== "") {
      return cities?.filter((item) =>
        JSON.stringify(item)?.toLowerCase()?.includes(searchText.toLowerCase())
      );
    }

    return cities;
  }, [cities, searchText]);

  const tableData = {
    columns: [
      {
        label: "SL",
        field: "sl",
        sort: "asc",
        width: 50,
      },
      // {
      //   label: "Country",
      //   field: "country_name",
      //   sort: "asc",
      //   width: 100,
      // },
      {
        label: "City",
        field: "city_name",
        sort: "asc",
        width: 100,
      },
      {
        label: "Picture",
        field: "picture",
        sort: "asc",
        width: 100,
        height: 100,
      },
      {
        label: "Action",
        field: "action",
        sort: "asc",
        width: 150,
      },
    ],
    rows:
      filterData?.length > 0
        ? filterData?.map((item, idx) => {
            return {
              sl: idx + 1,
              city_name: (
                <div className="widget-content p-0">
                  <div className="widget-content-wrapper">
                    <div
                      className="widget-content-left flex2"
                      style={{ textAlign: "center" }}
                    >
                      <div className="zwidget-heading" style={{ opacity: 1 }}>
                        {item.cityName}
                      </div>
                    </div>
                  </div>
                </div>
              ),
              // country_name: (
              //   <div className="widget-content p-0">
              //     <div className="widget-content-wrapper">
              //       <div
              //         className="widget-content-left flex2"
              //         style={{ textAlign: "center" }}
              //       >
              //         <div className="zwidget-heading" style={{ opacity: 1 }}>
              //           {item?.countryDetails?.name
              //             ? item?.countryDetails?.name
              //             : "NA"}
              //         </div>
              //       </div>
              //     </div>
              //   </div>
              // ),
              picture: (
                <div
                  className="widget-content p-0"
                  key={idx}
                  style={{ textAlign: "center" }}
                >
                  <div className="widget-content-wrapper">
                    <img
                      src={`${item.picture}`}
                      alt=""
                      style={{
                        height: "70px",
                        width: "120px",
                        marginLeft: "auto",
                        marginRight: "auto",
                      }}
                      className="img-fluid"
                    />
                  </div>
                </div>
              ),
              action: (
                <div>
                  {(userType == "admin" ||
                    (userType == "subadmin" &&
                      assignAccess.includes("delete"))) && (
                    <button
                      className="me-2 btn-icon btn-icon-only btn btn-outline-danger"
                      onClick={() => {
                        openModal();
                        setmodalToggleView(0);
                        setdeleteId(item._id);
                      }}
                    >
                      <i className="fa-solid fa-trash-can" />
                    </button>
                  )}

                  {(userType == "admin" ||
                    (userType == "subadmin" &&
                      assignAccess.includes("edit"))) && (
                    <button
                      className="me-2 btn-icon btn-icon-only btn btn-outline-success"
                      onClick={() => navigate(`/edit-city/${item?._id}`)}
                    >
                      <i class="fa-regular fa-pen-to-square"></i>
                    </button>
                  )}
                </div>
              ),
            };
          })
        : [],
  };

  const renderModalView = (index) => {
    switch (index) {
      case 0:
        return (
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">Delete City</h5>
            </div>
            <div className="modal-body">
              <div className="deleteModalContainer p-5">
                <i className="ri-close-circle-line deleteIcon"></i>
                <h3>Are You Sure?</h3>
                <span>Do You Want To Delete This Information?</span>
                <div className="deleteModalNote">
                  <span>*Note : This data will be permanently deleted</span>
                </div>
              </div>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-link"
                onClick={() => {
                  setModal(false);
                }}
              >
                Cancel
              </button>
              <button
                type="button"
                className="btn btn-primary"
                onClick={() => {
                  deleteCity(deleteId);
                }}
              >
                Delete
              </button>
            </div>
          </div>
        );
      default:
        return null;
    }
  };

  return (
    <>
      {loading && <Loader />}

      <div className="row" style={{ margin: "0.1rem" }}>
        <div className="col-md-6">
          <div className="mb-3">
            <label htmlFor="countryId" className="form-label font-weight-bold">
              Country*
            </label>
            <Select
              name="countryId"
              id="countryId"
              classNamePrefix="select"
              options={countries?.map((item, idx) => ({
                value: item?._id,
                label: item?.name,
              }))}
              value={{
                value: city.countryId,
                label: city.countryName,
              }}
              onChange={(val) => {
                if (val && val[0]) {
                  fetchCities(val[0]?.value);
                  setCountryId(val[0]?.value);
                }
              }}
            />
          </div>
        </div>
      </div>
      <Table
        striped
        tableTitle="All Cities"
        data={tableData}
        modal={modal}
        setModal={setModal}
        modalToggleView={modalToggleView}
        renderModalView={renderModalView}
        onSearch={handleSearch}
      />
    </>
  );
  //   }
};

export default ManageCities;

import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import { ApiHelperFunction } from "../../services/api/apiHelpers";

let initialState = {
  status: "idle",
  giftquestions: [],
  isLoading: false,
  isSuccess: false,
  isError: false,
};

export const getAllGiftsquestions = createAsyncThunk(
  "get_all_gift_questions",
  async () => {
    let response = await ApiHelperFunction({
      urlPath: "/admin/view-gift-question",
      method: "GET",
    });
    if (response.status === 200) {
      return response.data;
    } else {
      toast.error("Can't get data. Something went wrong");
    }
  }
);

export const giftQuestionSlice = createSlice({
  name: "giftquestions",
  initialState,
  reducers: {
    clearState: (state) => {
      state.isLoading = false;
      state.isSuccess = false;
      state.isError = false;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAllGiftsquestions.pending, (state) => {
        state.status = "loading";
        state.isLoading = true;
      })
      .addCase(getAllGiftsquestions.fulfilled, (state, { payload }) => {
        state.status = "success";
        state.isSuccess = true;
        state.giftquestions = payload.data;
      })
      .addCase(getAllGiftsquestions.rejected, (state) => {
        state.status = "failed";
        state.isError = true;
        state.isSuccess = false;
      });
  },
});

export const { clearState } = giftQuestionSlice.actions;

export default giftQuestionSlice.reducer;

import React from "react";
import phoneCodes from "../lib/phonecodes.json";
import { useMemo } from "react";

function PhoneInput({
  onCountryChange = () => {},
  onChange = () => {},
  value,
  countryCode,
  disabled,
}) {
  phoneCodes.sort((a, b) => {
    const dialCodeA = parseInt(a.dial_code.replace("+", ""));
    const dialCodeB = parseInt(b.dial_code.replace("+", ""));

    if (dialCodeA < dialCodeB) {
      return -1;
    } else if (dialCodeA > dialCodeB) {
      return 1;
    } else {
      return 0;
    }
  });

  const uniqueCountryCodes = [];
  let previousDialCode = null;

  for (const country of phoneCodes) {
    const currentDialCode = parseInt(country.dial_code);

    if (currentDialCode !== previousDialCode) {
      uniqueCountryCodes.push(country);
      previousDialCode = currentDialCode;
    }
  }

  const imageSrc = useMemo(() => {
    let country = uniqueCountryCodes.find(
      (item) => item.dial_code === countryCode
    )?.code;
    if (country)
      return `https://flagcdn.com/32x24/${country.toLowerCase()}.png`;
    return "https://w7.pngwing.com/pngs/313/776/png-transparent-iphone-telephone-call-computer-icons-call-volume-call-electronics-text-hand.png";
  }, [countryCode]);

  return (
    <div className="phonenb_tr_d">
      <img
        style={!countryCode ? { height: "24px", width: "50px" } : {}}
        src={imageSrc}
        alt="phoneInput"
      />

      <select
        value={countryCode}
        onChange={(e) => onCountryChange(e.target.value)}
        disabled={disabled}
      >
        {phoneCodes.map((item) => {
          return (
            <option key={item.dial_code} value={item.dial_code}>
              {" "}
              {item.dial_code}
            </option>
          );
        })}
      </select>
      <input
        type="number"
        value={value}
        onChange={(e) => onChange(e.target.value)}
        disabled={disabled}
      />
    </div>
  );
}

export default PhoneInput;

// Hooks
import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";

// Utils
import {
  capitalizeFirstLetter,
  apiCallSuccess,
  apiCallError,
} from "../../../utils";

// Helpers
import { toast } from "react-toastify";
import moment from "moment";

// API
import { fileUpload } from "../../../services/api/apiHelpers";
import { JSON_API } from "../../../services/api/jsonApiCallWithInterceptor";

// Redux actions
import { getAllSpecialOffers } from "../../../redux/adminSlice/specialOfferSlice";
import {
  getAllActivityTypes,
  getAllActivities,
} from "../../../redux/adminSlice/activitySlice";
import { getAllcountres } from "../../../redux/adminSlice/countrySlice";

// Components
import Layout from "../../../Layout";
import Table from "../../../Component/Table";
import Loader from "../../../Component/Loader";
// import DateTimePicker from "react-datetime-picker";
import Datetime from "react-datetime";
import Select from "react-select";
import { Link, useNavigate, useParams } from "react-router-dom";

// import "react-datetime-picker/dist/DateTimePicker.css";
import "react-calendar/dist/Calendar.css";
import "react-datetime/css/react-datetime.css";

let initialValues = {
  id: "",
  specialOfferName: "",
  duration_value: 0,
  duration_unit: "",
  activityDetailsId: [],
  offerType: "",
  discountPercentage: 0,
  description: "",
  startDate: "",
};

const createErrors = {
  specialOfferName: "",
  duration_value: "",
  duration_unit: "",
  activityDetailsId: "",
  offerType: "",
  discountPercentage: "",
  description: "",
};

const duration_units = ["minutes", "hours", "days"];

const EditOffer = () => {
  // export default function AddSpecialOffers() {
  const dispatch = useDispatch();
  const datetimeInputRef = useRef(null);
  const [selectedDateTime, setSelectedDateTime] = useState(null);
  const [banner, setbanner] = useState(initialValues);
  const [error, setError] = useState("");
  const [errors, seterrors] = useState(createErrors);
  const [isLoading, setisLoading] = useState(false);
  const [itemIndex, setitemIndex] = useState("");
  const navigate = useNavigate();
  const params = useParams();
  const [openDate, setOpendate] = useState(false);


  // const activities = [
  //   {
  //     id: "648a3d93dc7e775e9661ffb8",
  //     name: "From Paris: Versailles Skip-the-Line Tour & Gardens Access",
  //   },
  //   {
  //     id: "648a3daedc7e775e9661ffc4",
  //     name: "NYC: MoMA Before Hours Tour with Art Expert",
  //   },
  // ];

  // Redux stores
  const { special_offers } = useSelector((state) => state.specialOfferSlice);
  const { activities } = useSelector((state) => state.adminActivityActionSlice);

  const offerData = special_offers.find((item) => item?._id === params.id);
  // console.log("special_offer", offerData);
  useEffect(() => {
    dispatch(getAllSpecialOffers());
    dispatch(getAllActivities());
    setbanner({
      id: offerData._id,
      specialOfferName: offerData.specialOfferName,
      duration_value: offerData.duration.value,
      duration_unit: offerData.duration.unit,
      activityDetailsId: offerData.activityDetailsId,
      offerType: offerData.offerType,
      discountPercentage: offerData.discountPercentage,
      description: offerData.description,
      // startDate: new Date(offerData.startDate).toUTCString(),
    });
    /** ------ To set the startDate to the appropriate value format for "DateTime" component ----- */
    const createMomentObj = () => {
      const dateString = offerData?.startDate;
      const momentObj = moment(dateString);

      // Set the properties as specified
      momentObj._isAMomentObject = true;
      momentObj._strict = true;
      momentObj._isUTC = false;
      momentObj._pf = {
        empty: false,
        unusedTokens: [],
        unusedInput: [],
        overflow: -2,
        charsLeftOver: 0,
        nullInput: false,
        invalidEra: null,
        invalidMonth: null,
        invalidFormat: false,
        userInvalidated: false,
        iso: false,
        parsedDateParts: [],
        era: null,
        meridiem: null,
        rfc2822: false,
        weekdayMismatch: false,
      };
      momentObj._locale = {
        _calendar: {
          sameDay: "[Today at] LT",
          nextDay: "[Tomorrow at] LT",
          nextWeek: "dddd [at] LT",
          lastDay: "[Yesterday at] LT",
          lastWeek: "[Last] dddd [at] LT",
          sameElse: "L",
        },
        _longDateFormat: {
          LTS: "h:mm:ss A",
          LT: "h:mm A",
          L: "MM/DD/YYYY",
          LL: "MMMM D, YYYY",
          LLL: "MMMM D, YYYY h:mm A",
          LLLL: "dddd, MMMM D, YYYY h:mm A",
        },
        _invalidDate: "Invalid date",
        _dayOfMonthOrdinalParse: {},
        _relativeTime: {
          future: "in %s",
          past: "%s ago",
          s: "a few seconds",
          ss: "%d seconds",
          m: "a minute",
          mm: "%d minutes",
          h: "an hour",
          hh: "%d hours",
          d: "a day",
          dd: "%d days",
          w: "a week",
          ww: "%d weeks",
          M: "a month",
          MM: "%d months",
          y: "a year",
          yy: "%d years",
        },
        _months: [
          "January",
          "February",
          "March",
          "April",
          "May",
          "June",
          "July",
          "August",
          "September",
          "October",
          "November",
          "December",
        ],
        // ... (Other properties)
      };

      momentObj._d = new Date(dateString);
      momentObj._isValid = true;

      setSelectedDateTime(new Date(momentObj));
    };
    /** -----------------------------------------------------------------------------------------  */

    createMomentObj();
  }, []);

  const handleDateTimeChange = (date) => {
    // console.log("date", date, moment(date).format('MMMM Do YYYY, h:mm:ss a'));
    // console.log("time", time);
    setSelectedDateTime(moment(date).format());
  };

  const handleChange = (event) => {
    event.preventDefault();
    // console.log(event.target.name, event.target.value);
    let input;

    if (
      event.target.name === "description" ||
      event.target.name === "duration_unit"
    ) {
      input = event.target.value;
    } else {
      input = capitalizeFirstLetter(event);
    }

    setbanner({ ...banner, [event.target.name]: input });
  };

  const handleValidation = () => {
    const {
      specialOfferName,
      duration_value,
      duration_unit,
      offerType,
      discountPercentage,
    } = banner;

    if (specialOfferName === "") {
      seterrors({
        ...errors,
        specialOfferName: "Special offer name is required",
      });

      return false;
    }
    if (duration_value === 0 && duration_unit === "") {
      seterrors({ ...errors, duration_value: "Offer duration is required" });

      return false;
    }
    // if (banner.activityDetailsId.length === 0) {
    //   seterrors({
    //     ...errors,
    //     activityDetailsId: "Please select atleast 1 activity",
    //   });
    // }
    if (offerType === "") {
      seterrors({
        ...errors,
        offerType: "Offer description description is required",
      });

      return false;
    }
    if (discountPercentage === 0) {
      seterrors({
        ...errors,
        discountPercentage: "Discount percentage is required",
      });

      return false;
    }

    return true;
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    console.log(selectedDateTime.toString());
    let bodyData = {
      id: special_offers[itemIndex]?._id,
      ...banner,
      startDate: selectedDateTime,
      duration: { value: banner.duration_value, unit: banner.duration_unit },
    };

    console.log(bodyData);
    if (handleValidation()) {
      try {
        setisLoading(true);
        let res = await JSON_API["editAdminSpecialOffer"](bodyData);

        if (res.isSuccess) {
          dispatch(getAllSpecialOffers());
          seterrors(createErrors);
          setSelectedDateTime(null);
          if (datetimeInputRef.current) {
            datetimeInputRef.current.value = "";
          }
          setbanner(initialValues);
          setisLoading(false);
          toast.success("Special offer updated successfully ");
          navigate("/manage-special-offers");
        } else {
          setisLoading(false);
          apiCallError(res?.error?.message, setError);
        }
      } catch (error) {
        setisLoading(false);
        apiCallError(error.message, setError);
      }
    }
  };

  return (
    <>
      {isLoading && <Loader />}

      <form className="" onSubmit={handleSubmit}>
        <div component="div" className="TabsAnimation appear-done enter-done">
          <div className="main-card mb-3 card">
            <div className="card-body">
              <div className="card-title">Edit Details</div>
              <div className="row" style={{ margin: "0.1rem" }}>
                <div className="col-md-6">
                  <div className="mb-3">
                    <label htmlFor="specialOfferName" className="form-label">
                      Special Offer Name*
                    </label>
                    <input
                      type="text"
                      name="specialOfferName"
                      id="specialOfferName"
                      // placeholder="Your first name, e.g. Walter"
                      className="form-control"
                      autoComplete="false"
                      value={banner.specialOfferName}
                      onChange={(e) => handleChange(e)}
                    />
                  </div>
                  {errors.specialOfferName && (
                    <span className="errorMessageText text-danger">
                      {errors.specialOfferName}
                    </span>
                  )}
                </div>
                <div className="col-md-6">
                <div className="mb-3">
                    <label className="form-label">Offer Starts At*</label>
                    <div className="row">
                      <div className="col-md-6">
                        <input
                          type="text"
                          name="duration_value"
                          id="duration_value"
                          // placeholder="Your first name, e.g. Walter"
                          className="form-control"
                          autoComplete="false"
                          onFocus={() => setOpendate(true)}
                          value={selectedDateTime}
                        />
                      </div>
                      <div className="col-md-6">
                        <button
                        type="button"
                          className="btn btn-warning"
                          onClick={() => setOpendate(!openDate)}
                        >
                          Select
                        </button>
                      </div>
                    </div>

                    {openDate && (
                      <Datetime
                        // renderInput={renderInput}
                        // utc={true}
                        // value={selectedDateTime ? selectedDateTime : ''}
                        ref={datetimeInputRef}
                        closeOnSelect
                        input={false}
                        // open={true}
                        onChange={(date) => {
                          handleDateTimeChange(date);
                        }}
                        // timeFormat={false}
                        onClose={() => setOpendate(false)}
                      />
                    )}
                  </div>
                </div>
                <div className="col-md-6">
                  <div>
                    <label htmlFor="duration_value" className="form-label">
                      Valid for*
                    </label>
                  </div>
                  <div className="" style={{ display: "flex" }}>
                    <div className="col-md-3" style={{ marginLeft: "-15px" }}>
                      <div className="mb-3">
                        <input
                          type="number"
                          name="duration_value"
                          id="duration_value"
                          // placeholder="Your first name, e.g. Walter"
                          className="form-control"
                          autoComplete="false"
                          value={banner.duration_value}
                          onChange={(e) => handleChange(e)}
                        />
                      </div>
                      {errors.duration_value && (
                        <span className="errorMessageText text-danger">
                          {errors.duration_value}
                        </span>
                      )}
                    </div>
                    <div className="col-md-3">
                      <div className="mb-3">
                        <select
                          name="duration_unit"
                          id="duration_unit"
                          className="form-control form-select"
                          onChange={(e) => handleChange(e)}
                          value={banner.duration_unit}
                        >
                          <option value="" key={0}>
                            Select
                          </option>
                          {duration_units?.map((item, idx) => {
                            return (
                              <>
                                <option key={idx + 1} value={`${item}`}>
                                  {item}
                                </option>
                              </>
                            );
                          })}
                        </select>
                      </div>
                      {errors.duration_value && errors.duration_unit && (
                        <span className="errorMessageText text-danger">
                          {errors.duration_value}
                        </span>
                      )}
                    </div>
                  </div>
                </div>
                {/* <div className="col-md-6">
                  <div className="mb-3">
                    <label htmlFor="activityDetailsId" className="form-label">
                      Activity*
                    </label>
                    <Select
                      name="activityDetailsId"
                      id="activityDetailsId"
                      classNamePrefix="select"
                      options={activities.map((item, idx) => ({
                        value: item?._id,
                        label: item?.activityTitle,
                      }))}
                      value={banner.activityDetailsId.map((item) => {
                        let find = activities.find((it) => it._id === item);
                        // console.log("offers for activities", find);
                        return {
                          value: find?._id,
                          label: find?.activityTitle,
                        };
                      })}
                      isMulti
                      onChange={(val) => {
                        setbanner((prev) => {
                          return {
                            ...prev,
                            activityDetailsId: val.map((item) => item.value),
                          };
                        });
                      }}
                    />
                  </div>
                  {errors.activityDetailsId && (
                    <span className="errorMessageText text-danger">
                      {errors.activityDetailsId}
                    </span>
                  )}
                </div> */}
                <div className="col-md-6">
                  <div className="mb-3">
                    <label htmlFor="offerType" className="form-label">
                      Offer Type*
                    </label>
                    <input
                      type="text"
                      name="offerType"
                      id="offerType"
                      // placeholder="Your first name, e.g. Walter"
                      className="form-control"
                      autoComplete="false"
                      value={banner.offerType}
                      onChange={(e) => handleChange(e)}
                    />
                  </div>
                  {errors.offerType && (
                    <span className="errorMessageText text-danger">
                      {errors.offerType}
                    </span>
                  )}
                </div>
                <div className="col-md-6">
                  <div className="mb-3">
                    <label htmlFor="discountPercentage" className="form-label">
                      Discount Percentage*
                    </label>
                    <input
                      type="number"
                      name="discountPercentage"
                      id="discountPercentage"
                      // placeholder="Your first name, e.g. Walter"
                      className="form-control"
                      autoComplete="false"
                      value={banner.discountPercentage}
                      onChange={(e) => handleChange(e)}
                    />
                  </div>
                  {errors.discountPercentage && (
                    <span className="errorMessageText text-danger">
                      {errors.discountPercentage}
                    </span>
                  )}
                </div>
              </div>

              <div className="col-md-12">
                <div className="mb-3">
                  <label htmlFor="description" className="form-label">
                    Offer Description
                  </label>
                  <textarea
                    name="description"
                    id="description"
                    // placeholder="Your first name, e.g. Walter"
                    className="form-control"
                    autoComplete="false"
                    value={banner.description}
                    onChange={(e) => handleChange(e)}
                  />
                </div>
                {errors.description && (
                  <span className="errorMessageText text-danger">
                    {errors.description}
                  </span>
                )}
              </div>
            </div>
            <div className="d-flex m-2">
              <button
                type="button"
                className="btn btn-link"
                onClick={() => {
                  // setModal(false);
                  navigate("/manage-special-offers");
                }}
              >
                Cancel
              </button>
              <button type="submit" className="mt-2 btn btn-primary  d-table">
                Edit
              </button>
            </div>
          </div>
        </div>
      </form>
    </>
  );
  //   }
};

export default EditOffer;

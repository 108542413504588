import React, { useState, useEffect, useRef } from "react";
import Table from "../../../Component/Table";
import { toast } from "react-toastify";
import { JSON_API } from "../../../services/api/jsonApiCallWithInterceptor";
import Loader from "../../../Component/Loader";
import HomeService from "../../../services/api/HomeService";
import { fileUpload } from "../../../services/api/apiHelpers";
import { useDispatch, useSelector } from "react-redux";
import { getAllContacts } from "../../../redux/adminSlice/contactsSlice";
import { capitalizeFirstLetter } from "../../../utils";
import { useNavigate, useParams } from "react-router-dom";

const INITIAL_IDS = { topicId: "", questionId: "", id: "" };
const INITIAL_SUB_QUESTIONS = { subDesc: "", subItem: [{ item: "" }] };

const EditSubq = () => {
    const [allSubQuestions, allSetSubQuestions] = useState([]);
    const [ids, setIds] = useState({ ...INITIAL_IDS });
    const [subQuestions, setSubQuestions] = useState(
        JSON.parse(JSON.stringify(INITIAL_SUB_QUESTIONS))
    );
    const dispatch = useDispatch();
    const [deleteId, setDeleteId] = useState("");
    const [modalToggleView, setmodalToggleView] = useState(null);
    const [modal, setModal] = useState(false);
    const [uploadedImg, setUploadedImg] = useState("");
    const [imgLoading, setImgLoading] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const imageRef = useRef();
    const navigate = useNavigate();
    const params = useParams();


    const { contacts } = useSelector((state) => state.contactsSlice);
    // console.log('contacts', contacts);

    useEffect(() => {
        dispatch(getAllContacts());
        fetchSubQuestions();
    }, [dispatch]);

    useEffect(() => {
        if (allSubQuestions.length) {
            const subqData = allSubQuestions.find((item) => item?._id === params.id);
            setIds({
                topicId: subqData?.topicId,
                questionId: subqData?.questionId,
                id: subqData?._id,
            });
            setUploadedImg(subqData?.image);
            // setmodalToggleView(0);
            const update = subqData?.subQuestion?.subItem.map((ele) => ({
                item: ele,
            }));
            setSubQuestions({
                subDesc: subqData?.subQuestion?.subDesc,
                subItem: update,
            });
        }
    }, [allSubQuestions])

    const fetchSubQuestions = async () => {
        try {
            const res = await HomeService.getAllSubQuestions();
            if (res && res?.status) {
                console.log("SUB", res.data);
                allSetSubQuestions(res?.data);
            }
        } catch (error) {
            console.log("FETCHING SUB QUESTIONS FAILED", error);
        }
    };

    // @ Submit Handler
    const submitHandler = async (e) => {
        e.preventDefault();
        setIsLoading(true);
        try {
            let res = await JSON_API["editSubQuestion"]({
                ...ids,
                subQuestion: {
                    subDesc: subQuestions.subDesc,
                    subItem: [...subQuestions.subItem.map((ele) => ele.item)],
                },
                image: uploadedImg,
            });
            if (res.isSuccess) {
                fetchSubQuestions();
                toast.success("Sub Question updated successfully");
                setModal(false);
                navigate('/manage-subquestion');
            } else toast.error(res?.message || "Something went wrong");
        } catch (error) {
            toast.error(error?.message || "Something went wrong");
        }
        setIsLoading(false);
    };

    // ~ Delete Handler
    const deleteHandler = async (id) => {
        setIsLoading(true);
        try {
            let res = await JSON_API["deleteSubQuestion"]({ id });
            if (res.isSuccess) {
                fetchSubQuestions();
                toast.success("Sub Question deleted successfully");
                setModal(false);
            } else toast.error(res?.message || "Something went wrong");
        } catch (error) {
            toast.error(error?.message || "Something went wrong");
        }
        setIsLoading(false);
    };

    const tableData = {
        columns: [
            {
                label: "SL",
                field: "sl",
                sort: "asc",
                width: 50,
            },
            {
                label: "Topic",
                field: "topic",
                sort: "asc",
                width: 100,
            },

            {
                label: "Question",
                field: "question",
                sort: "asc",
                width: 100,
            },
            {
                label: "Sub Question",
                field: "subquestion",
                sort: "asc",
                width: 100,
            },
            {
                label: "Action",
                field: "action",
                sort: "asc",
                width: 150,
            },
        ],
        rows:
            contacts?.length > 0
                ? allSubQuestions.map((item, idx) => {
                    return {
                        sl: idx + 1,
                        topic: (
                            <div className="widget-content p-0">
                                <div className="widget-content-wrapper">
                                    <div className="widget-content-left flex2">
                                        <div className="widget-heading" style={{ opacity: 0.75 }}>
                                            {item?.topic}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ),

                        question: (
                            <div className="widget-content p-0">
                                <div className="widget-content-wrapper">
                                    <div className="widget-content-left flex2">
                                        <div className="widget-heading" style={{ opacity: 0.75 }}>
                                            {item?.questions}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ),
                        subquestion: (
                            <div className="widget-content p-0">
                                <div className="widget-content-wrapper">
                                    <div className="widget-content-left flex2">
                                        <div className="widget-heading" style={{ opacity: 0.75 }}>
                                            {item?.subQuestion.subDesc}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ),
                        action: (
                            <div>
                                <button
                                    className="me-2 btn-icon btn-icon-only btn btn-outline-danger"
                                    onClick={() => {
                                        setDeleteId(item?._id);
                                        setmodalToggleView(1);
                                        setModal(true);
                                    }}
                                >
                                    <i className="fa-solid fa-trash-can" />
                                </button>
                                <button
                                    className="me-2 btn-icon btn-icon-only btn btn-outline-success"
                                    onClick={() => {
                                        setIds({
                                            topicId: item?.topicId,
                                            questionId: item?.questionId,
                                            id: item?._id,
                                        });
                                        setUploadedImg(item?.image);
                                        setmodalToggleView(0);
                                        const update = item?.subQuestion?.subItem.map((ele) => ({
                                            item: ele,
                                        }));
                                        setSubQuestions({
                                            subDesc: item?.subQuestion?.subDesc,
                                            subItem: update,
                                        });
                                        setModal(true);
                                    }}
                                >
                                    <i class="fa-regular fa-pen-to-square"></i>
                                </button>
                            </div>
                        ),
                    };
                })
                : [],
    };

    const uploadImg = async (picture) => {
        let imgData = new FormData();
        imgData.append("image", picture);
        try {
            setImgLoading(true);
            let imgRes = await fileUpload("/admin/aboutUsImage", "POST", imgData);
            if (imgRes && imgRes.url) {
                setImgLoading(false);
                return imgRes;
            } else {
                setImgLoading(false);
                return null;
            }
        } catch (error) {
            setImgLoading(false);
            return null;
        }
    };

    const changeHandler = async (e, i) => {
        if (e.target.name === "image") {
            if (e.target.files.length === 0) return;
            if (imgLoading) return;

            const i = Array.from(e.target.files)[0];
            const img = await uploadImg(i);
            if (img?.url !== "") {
                setUploadedImg(img.url);
            } else {
                console.log("ERROR", img);
                return toast.error("Error occurred while uploading image, Try Again!");
            }

            return;
        }

        if (e.target.name === "questionId") {
            const item = contacts?.find((item) => item?._id === e.target.value);
            let update = {
                topicId: item?.topicId,
                questionId: item?._id,
            };
            setIds(update);
            return;
        }

        let input;

        if (e.target.name === "subItem") {
            input = e.target.value;
        } else {
            input = capitalizeFirstLetter(e);
        }

        if (e.target.name === "subItem") {
            setSubQuestions((prev) => {
                let update = JSON.parse(JSON.stringify(prev));
                console.log(update.subItem[i]);
                update.subItem[i].item = input;
                return { ...update };
            });

            return;
        }

        setSubQuestions((prev) => {
            let update = JSON.parse(JSON.stringify(prev));
            update[e.target.name] = input;
            return { ...update };
        });
    };

    return (
        <>
            {isLoading && <Loader />}
            <form className="" onSubmit={submitHandler}>
                <div
                    component="div"
                    className="TabsAnimation appear-done enter-done"
                >
                    <div className="main-card card">
                        <div
                            className="card-body text-primary font-weight-bold"
                            style={{ fontSize: "16px" }}
                        >
                            Update Sub-Question
                        </div>
                        <div className="col-md-12">
                            <div className="mb-3">
                                <label
                                    htmlFor="countryId"
                                    className="form-label font-weight-bold"
                                >
                                    Question
                                </label>
                                <select
                                    name="questionId"
                                    id="countryId"
                                    className="form-control form-select"
                                    onChange={changeHandler}
                                    value={ids.questionId}
                                    required={true}
                                    disabled={true}
                                >
                                    <option value="" disabled={true} selected={true}>
                                        Select a question
                                    </option>
                                    {contacts?.map((item, i) => {
                                        return (
                                            <option value={item?._id} key={i}>
                                                {item?.questions}
                                            </option>
                                        );
                                    })}
                                </select>
                            </div>
                        </div>
                        <div className="col-md-12">
                            <div className="mb-3">
                                <label className="form-label font-weight-bold">
                                    Sub Question
                                </label>
                                <input
                                    name="subDesc"
                                    id="Questions"
                                    type="text"
                                    className="form-control"
                                    autoComplete="false"
                                    placeholder="Enter sub question"
                                    required={true}
                                    value={subQuestions.subDesc}
                                    onChange={changeHandler}
                                />
                            </div>
                        </div>
                        {subQuestions?.subItem?.map((item, i) => (
                            <>
                                <div className="col-md-12">
                                    <label
                                        className="form-label font-weight-bold"
                                        htmlFor="Description"
                                    >
                                        Description
                                    </label>
                                    <div className="input-group mb-3 ">
                                        <input
                                            name="subItem"
                                            id="Description"
                                            type="text"
                                            className="form-control"
                                            aria-describedby="button-addon2"
                                            autoComplete="false"
                                            placeholder="Enter description"
                                            value={subQuestions.subItem[i].item}
                                            onChange={(e) => changeHandler(e, i)}
                                        />
                                        {i !== 0 && (
                                            <button
                                                className="btn btn-outline-secondary"
                                                type="button"
                                                id="button-addon2"
                                                onClick={() => {
                                                    setSubQuestions((prev) => {
                                                        let update = JSON.parse(
                                                            JSON.stringify(prev)
                                                        );
                                                        update.subItem.splice(i, 1);
                                                        return { ...update };
                                                    });
                                                }}
                                            >
                                                <i class="fa-solid fa-minus"></i>
                                            </button>
                                        )}
                                        {subQuestions?.subItem?.length - 1 === i && (
                                            <button
                                                className="btn btn-outline-secondary"
                                                type="button"
                                                id="button-addon2"
                                                onClick={() => {
                                                    setSubQuestions((prev) => {
                                                        let update = JSON.parse(
                                                            JSON.stringify(prev)
                                                        );
                                                        update.subItem.push({ item: "" });
                                                        return { ...update };
                                                    });
                                                }}
                                            >
                                                <i class="fa-solid fa-plus"></i>
                                            </button>
                                        )}
                                    </div>
                                </div>
                            </>
                        ))}
                        <div className="col-md-12">
                            <div className="mb-3">
                                <label
                                    className="form-label font-weight-bold"
                                    htmlFor="image"
                                >
                                    Upload Image
                                </label>

                                <input
                                    name="image"
                                    type="file"
                                    onChange={changeHandler}
                                    ref={imageRef}
                                    className="form-control"
                                    id="image"
                                    aria-describedby="inputGroupFileAddon04"
                                    aria-label="Upload"
                                />
                                <p className="text-primary mt-2">
                                    {imgLoading && "Uploading please wait..."}
                                </p>
                                {uploadedImg !== "" && (
                                    <img
                                        src={uploadedImg}
                                        alt=""
                                        height={"100px"}
                                        width={"100px"}
                                    />
                                )}
                            </div>
                        </div>

                        <button
                            className="mt-2 btn btn-primary  d-table font-weight-bold"
                            type="submit"
                            style={{ margin: "5px", fontSize: "14px" }}
                        >
                            Submit
                        </button>
                    </div>
                </div>
            </form>

        </>
    );
    //   }
};

export default EditSubq;

// Hooks
import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from 'react-router-dom';

// Utils
import { apiCallError } from "../../../../utils/common-utils";

// Helpers
import { toast } from "react-toastify";

// API
import { JSON_API } from "../../../../services/api/jsonApiCallWithInterceptor";

// Redux actions
import { getAllBlogTypes } from "../../../../redux/adminSlice/blogSlice";

// Components
import Loader from "../../../../Component/Loader";
import Select from "react-select";

let initialValues = {
  name: "",
  typeId: "",
  typeIdName: ""
};

let createErrors = {
  name: "",
  typeId: "",
};

function AddTypeCategory() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const inputRef = useRef(null);
  const [activity, setactivity] = useState(initialValues);
  const [error, setError] = useState("");
  const [errors, seterrors] = useState(createErrors);
  const [isLoading, setisLoading] = useState(false);
  // Redux stores
  const { blog_types } = useSelector((state) => state.blogsSlice);

  useEffect(() => {
    dispatch(getAllBlogTypes());
  }, []);

  const handleChange = (event) => {
    event.preventDefault();
    setactivity({
      ...activity,
      [event.target.name]: event.target.value,
    });
  };

  const handleValidation = () => {
    const { name, typeId } = activity;

    if (typeId === "") {
      seterrors({
        ...createErrors,
        typeId: "Blog type is required.",
      });

      return false;
    }

    if (name === "") {
      seterrors({
        ...createErrors,
        name: "Blog type category name is required.",
      });

      return false;
    }

    return true;
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (handleValidation()) {
      try {
        setisLoading(true);
        let res = await JSON_API["addBlogTypeCat"](activity);

        if (res.isSuccess) {
          seterrors(createErrors);
          setactivity(initialValues);
          if (inputRef.current) {
            inputRef.current.value = "";
          }
          setisLoading(false);
          toast.success('Blog type category added successfully ')
          navigate('/manage-blog-type-category');
        } else {
          setisLoading(false);
          apiCallError(res?.error?.message, setError);
        }
      } catch (error) {
        setisLoading(false);
        apiCallError(error.message, setError);
      }
    } else {
      window.scrollTo({
        top: 0,
        left: 0,
        behavior: "smooth",
      });
    }
  };

  return (
    <>
      {isLoading && <Loader />}

      <form className="" onSubmit={(e) => handleSubmit(e)}>
        <div component="div" className="TabsAnimation appear-done enter-done">
          <div className="main-card mb-3 card">
            <div className="card-body">
              <div className="card-title">Add Blog Type Category</div>
              <div className="col-md-12">
                <div className="mb-3">
                  <label htmlFor="typeId" className="form-label">
                    Blog Type*
                  </label>
                  <Select
                    name="typeId"
                    id="typeId"
                    classNamePrefix="select"
                    ref={inputRef}
                    options={blog_types.map((item, idx) => ({
                      value: item?._id,
                      label: item?.name,
                    }))}
                    onChange={(val) => {
                      setactivity((prev) => {
                        return {
                          ...prev,
                          typeId: val?.value,
                          typeIdName: val?.label,
                        };
                      });
                    }}
                  />
                </div>
                {errors.typeId && (
                  <span className="errorMessageText text-danger">
                    {errors.typeId}
                  </span>
                )}
              </div>
              <div className="col-md-12">
                <div className="mb-3">
                  <label htmlFor="name" className="form-label">
                    Category Name*
                  </label>
                  <input
                    name="name"
                    id="name"
                    type="text"
                    className="form-control"
                    autoComplete="false"
                    value={activity.name}
                    onChange={(e) => handleChange(e)}
                  />
                </div>
                {errors.name && (
                  <span className="errorMessageText text-danger">
                    {errors.name}
                  </span>
                )}
              </div>

              <button
                className="mt-2 btn btn-primary ml-auto d-table"
                type="submit"
                onClick={() => {}}
              >
                Submit
              </button>
            </div>
          </div>
        </div>
      </form>
    </>
  );
}

export default AddTypeCategory;

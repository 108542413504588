import React, { useEffect, useState } from 'react'
import Loader from '../../../Component/Loader';
import { requestData } from '../../../services/api/apiHelpers';
import { toast } from 'react-toastify';
import { useNavigate, useParams } from 'react-router-dom';

const AddThirdPartyPlatform = () => {
    const navigate = useNavigate()
    const URLid = useParams();
    const id = URLid?.id;
    const [platformName, setPlatformName] = useState("")
    const [isLoading, setisLoading] = useState(false)

    const handleSubmit = async (e) => {
        e.preventDefault();

        const data = {
            name: platformName
        }
        if (id) {
            try {
                setisLoading(true)
                const res = await requestData(`/admin/update-thirdperty/${id}`, "PUT", data);
                if (res && res.status) {
                    setisLoading(false)
                    toast.success(res.message)
                    navigate("/manage_trird_party_platform")
                }
                else {
                    setisLoading(false)
                    toast.error(res?.message)
                }
            } catch (error) {
                toast.error(error.message)
                setisLoading(false)
            }
        }
        else {
            try {
                const res = await requestData("/admin/add-thirdperty-name", "POST", data);
                console.log("res", res)
                if (res && res.status) {
                    toast.success("Third Party Platform added Successfully")
                    navigate("/manage_trird_party_platform")
                }
                else {
                    setisLoading(false)
                    toast.error(res?.message)
                }
            } catch (error) {
                toast.error(error.message)
            }
        }
    }

    const getAllThirdParty = async () => {
        try {
            setisLoading(true)
            let res = await requestData("/admin/viewThirdPerty", "GET");
            console.log("wewew", res)
            if (res) {
                setisLoading(false)
                const data = res.data.filter((item) => item._id === id)
                setPlatformName(data[0].name)
                console.log("data", data[0].name)
            }
        } catch (error) {
            toast.error(error.message)
            setisLoading(false)
        }
    }
    useEffect(() => {
        if (id) getAllThirdParty()
    }, [id])

    return (
        <>
            {isLoading && <Loader />}
            <form className="" onSubmit={(e) => handleSubmit(e)}>
                <div component="div" className="TabsAnimation appear-done enter-done">
                    <div className="main-card mb-3 card">
                        <div className="card-body">
                            <div className="card-title">
                                {id ? "Update Third Party Platform" : "Add Third Party Platform"}
                            </div>
                            <div className="row">
                                <div className="col-md-6">
                                    <div className="mb-3">
                                        <label
                                            htmlFor="platformName"
                                            className="form-label font-weight-bold"
                                        >
                                            Third Party Platform Name
                                        </label>

                                        <input
                                            type="text"
                                            name="platformName"
                                            id="platformName"
                                            class="form-control"
                                            aria-describedby="thirdpartyplatform"
                                            placeholder="Enter Third Party Platform name"
                                            onChange={(e) => setPlatformName(e.target.value)}
                                            value={platformName}
                                        />
                                    </div>
                                </div>
                            </div>
                            {id ? (
                                <button
                                    className="mt-2 btn btn-primary ml-auto d-table"
                                    type="submit"
                                    style={{ margin: "5px auto" }}
                                >
                                    Update
                                </button>
                            ) : (
                                <button
                                    className="mt-2 btn btn-primary ml-auto d-table"
                                    type="submit"
                                    style={{ margin: "5px auto" }}
                                >
                                    Submit
                                </button>
                            )}
                        </div>
                    </div>
                </div>
            </form>
        </>
    )
}

export default AddThirdPartyPlatform

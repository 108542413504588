// Hooks
import React, { useState, useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";

// Helpers
import { toast } from "react-toastify";

import { requestData } from "../../../services/api/apiHelpers";


// Components
import Table from "../../../Component/Table";
import Loader from "../../../Component/Loader";



const ManageMail = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const params = useParams();
  
    const [searchText, setsearchText] = useState("");
    const [error, setError] = useState("");
    const [allMerchantData, setallMerchantData] = useState([]);
    const [modal, setModal] = useState(false);
    const [modalToggleView, setmodalToggleView] = useState(null);
    const [deleteId, setdeleteId] = useState("");
    const [isLoading, setisLoading] = useState(false);
    const [mailData, setMailData] = useState([])

    const getAllMails = async () => {
    setisLoading(true);
    try {
      const res = await requestData("/admin/get-mails", "GET");
      console.log("ffffffff", mailData)
      setMailData(res?.data)
      setisLoading(false);
    } catch (error) {
      toast.error(error.message || "Server Error");
      setisLoading(false);
    }
    }

    useEffect(() => {
        getAllMails()
    }, [])

    const filterData = () => {
        if (searchText !== "") {
          return mailData.filter((item) =>
            JSON.stringify(item).toLowerCase().includes(searchText.toLowerCase())
          );
        }
    
        return mailData;
      };

    const tableData = useMemo(() => {
        return {
          columns: [
            {
              label: "SL",
              field: "sl",
              sort: "asc",
              width: 270,
            },
            {
              label: "Topic",
              field: "topic",
              sort: "asc",
              width: 150,
            },
            {
              label: "Mail Subject",
              field: "mailSubject",
              sort: "asc",
              width: 150,
            },
            {
              label: "Action",
              field: "action",
              sort: "asc",
              width: 150,
            },
          ],
          rows:
            filterData()?.length > 0
              ? filterData().map((item, idx) => {
                  return {
                    sl: idx + 1,
                    topic: (
                      <div className="widget-content p-0">
                        <div className="widget-content-wrapper">
                          <div className="widget-content-left me-3">
                            {item?.topic}
                          </div>
                          <div className="widget-content-left flex2">
                          </div>
                        </div>
                      </div>
                    ),
                    mailSubject: (
                      <div className="widget-content p-0">
                        <div className="widget-content-wrapper">
                          <div className="widget-content-left flex2">
                            {item?.description?.mailSubject}
                          </div>
                        </div>
                      </div>
                    ),
                    action: (
                      <div>
                      <button
                          title="Mail Review"
                          className={"me-2 btn-icon btn-icon-only btn btn-outline-primary"}
                          onClick={() => {
                            navigate(`/add-mail/${item?._id}`);
                          }}
                        >
                          <i class="fa-regular fa fa-eye"></i>
                        </button>
                      </div>
                    ),
                  };
                })
              : [],
        };
      }, [mailData, searchText]);

      const renderModalView = (index) => {
        switch (index) {
          case 0:
            return (
              <div className="modal-content">
                <div className="modal-header">
                  <h5 className="modal-title">Delete merchant</h5>
                </div>
                <div className="modal-body">
                  <div className="deleteModalContainer p-5">
                    <i className="ri-close-circle-line deleteIcon"></i>
                    <h3>Are You Sure?</h3>
                    <span>Do You Want To Delete This Information?</span>
                    <div className="deleteModalNote">
                      <span>*Note : This data will be permanently deleted</span>
                    </div>
                  </div>
                </div>
                <div className="modal-footer">
                  <button
                    type="button"
                    className="btn btn-link"
                    onClick={() => {
                      setModal(false);
                    }}
                  >
                    Cancel
                  </button>
                  <button
                    type="button"
                    className="btn btn-primary"
                    // onClick={() => {
                    //   // console.log(deleteId);
                    //   deleteMerchant(deleteId);
                    // }}
                  >
                    Delete
                  </button>
                </div>
              </div>
            );
          default:
            return null;
        }
      };

  return (
    <>
    {isLoading && <Loader />}

    <Table
      striped
      tableTitle="All Mails Details"
      data={tableData}
      modal={modal}
      setModal={setModal}
      modalToggleView={modalToggleView}
      renderModalView={renderModalView}
      onSearch={(val) => setsearchText(val)}
    />
  </>
  )
}

export default ManageMail

// Hooks
import React, { useState, useEffect } from "react";

// Helpers
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import toast from "react-hot-toast";

// API functions
import { JSON_API } from "../../../services/api/jsonApiCallWithInterceptor";

// Components
import Loader from "../../../Component/Loader";
import { CKEditor } from "@ckeditor/ckeditor5-react";

export default function AddTermsandCond() {
  const [content, setcontent] = useState("");
  const [isLoading, setisLoading] = useState(false);

  // Component did mount
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (content === "") {
      return toast.error("Terms and conditions details is required.")
    } else {
      try {
        setisLoading(true);
        let res = await JSON_API['adminAddTerms']({ topic: content });

        if (res.isSuccess) {
          setcontent('');
          toast.success("Terms & Conditions added successfully ");
        } else {
          toast.error(res?.error?.message);
        }

        setisLoading(false)
      } catch (error) {
        setisLoading(true);
        console.log("Failed to add terms and conditions info due to ", error.message);
      }
    }
  };

  return (
    <>
      {isLoading && <Loader />}
      <form action="" onSubmit={handleSubmit}>
        <div component="div" className="TabsAnimation appear-done enter-done">
          <div className="main-card mb-3 card">
            <div className="card-body">
              <div className="card-title">Add Terms And Conditions</div>
              <div className="col-md-12">
                <div className="mb-3">
                  <label htmlFor="Faq" className="form-label font-weight-bold">
                    Details*
                  </label>
                  <CKEditor
                    className="editor-container"
                    editor={ClassicEditor}
                    data={content}
                    onReady={(editor) => {
                      // You can store the "editor" and use when it is needed.
                      console.log("Editor is ready to use!", editor);
                    }}
                    onChange={(event, editor) => {
                      const data = editor.getData();
                      console.log({ event, editor, data });
                      setcontent(data);
                    }}
                    onBlur={(event, editor) => {
                      console.log("Blur.", editor);
                    }}
                    onFocus={(event, editor) => {
                      console.log("Focus.", editor);
                    }}
                  />
                </div>
              </div>

              <button
                className="mt-2 btn btn-primary ml-auto d-table"
                type="submit"
                style={{ margin: "5px" }}
              >
                Add
              </button>
            </div>
          </div>
        </div>
      </form>
    </>
  );
}

import moment from "moment";
import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { requestData } from "../../../services/api/apiHelpers";

const ViewMerchantReviewAndRating = () => {
    console.log('---ViewMerchantReviewAndRating-------');
    const params = useParams();
    let navigate = useNavigate();
    const [tourList, setTourList]               = useState([]);
    const [merchantDetails, setMerchantDetails] = useState([]);

    const merchantData = async() =>{
        let res = await requestData(
                        `/admin/get-all-merchant-profile`,
                        "GET"
                    );
        
        if(res?.status && res?.data){
            let result = res.data.filter(val=> val._id == params.id);
            console.log('-: merchantDetails :-',result);
            if(result.length){
                setMerchantDetails(result[0]);
            }
            else{
                alert('Merchant Not Found!');
                return navigate("/manage-merchant");
            }
        }
    } 

    const merchantTourData = async() => {
        let res = await requestData(
            `/admin/merchantActivityList/`+params.id,
            "GET"
        );

        console.log(res);
        if(res?.status && res?.data){
            setTourList(res?.data);
        }
    }




    useEffect(()=>{
        merchantData();
        merchantTourData();
    }, []);



    let merchantReview  = 0;
    let totalAvgReview  = 0;
    let totalTourCnt    = 0;
    tourList.forEach(val=>{
        if(val.reviewAvgRating){
            totalTourCnt++;
            totalAvgReview += val.reviewAvgRating;
        }

        merchantReview = parseFloat(totalAvgReview/totalTourCnt).toFixed(2);;
    });



    return (
    <div component="div" className="TabsAnimation appear-done enter-done">
        <div className="main-card mb-3 card">
            <div className="card-body">
                <div className="card-title"></div>
                    <div class="row mt-3">
                        <div class="col-lg-4 col-md-4 col-12">
                        <div class="DetailsData">
                        <span>Company Name</span>
                            <h6>{merchantDetails?.companyName}</h6>
                        </div>
                        </div>

                        <div class="col-lg-4 col-md-4 col-12">
                        <div class="DetailsData">
                            <span>Company Country</span>
                            <h6>{merchantDetails?.compCountry}</h6>
                        </div>
                        </div>
                        <div class="col-lg-4 col-md-4 col-12">
                        <div class="DetailsData">
                        <span>Company City</span>
                            <h6>{merchantDetails?.compCity}</h6>
                        </div>
                        </div>

                        <div class="col-lg-4 col-md-4 col-12">
                        <div class="DetailsData">
                            <span>Merchant Name</span>
                            <h6>{merchantDetails?.firstName} {merchantDetails?.lastName}</h6>
                        </div>
                        </div>
                        <div class="col-lg-4 col-md-4 col-12">                  
                        <div class="DetailsData">
                            <span>Merchant Email</span>
                            <h6><a href={'mailto:'+merchantDetails?.email}>{merchantDetails?.email}</a></h6>
                        </div>
                        </div>
                        <div class="col-lg-4 col-md-4 col-12">
                        <div class="DetailsData">
                            <span>Contact No.</span>
                            <h6>{merchantDetails?.companyMobile} / {merchantDetails?.companyMobile}</h6>
                        </div>
                        </div>
                    </div>

                    <div class="row mt-3">
                        <div class="col mainInnerRatingPart">
                            <div class="reviewSecondPart">
                                <p class="reviewSmallHeads reviewpart1" style={{ fontWeight: "bold" }}>
                                Merchant Review : {merchantReview} <br /> <hr />
                                </p>
                                <div className="heightscrl">
                                {tourList?.length === 0 ? (
                                    <div
                                    style={{
                                        height: "30px",
                                        width: "100%",
                                        backgroundColor: "#7ad97a",
                                        textAlign: "center",
                                    }}
                                    >
                                    <p style={{ fontSize: "18px" }}>
                                        There is no tour
                                    </p>
                                    </div>
                                ) : (
                                    ""
                                )}
                                <table className="table table-striped dataTable" style={{width:"100%"}}>
                                    <thead>
                                    <tr>
                                        <th>SL</th>
                                        <th>Tour Code</th>
                                        <th>Tour Title</th>
                                        <th>Status</th>
                                        <th>Total Review</th>
                                        <th>Avg. Ratting</th>
                                        <th>Overall Ratting</th>
                                        <th>Action</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {tourList?.length > 0 &&
                                    tourList?.map((item, key) => {
                                    return (
                                        <tr>
                                            <td>{key+1}</td>
                                            <td>{item.productCode}</td>
                                            <td>{item.activityTitle}</td>
                                            <td>
                                                <div class="form-check form-switch">
                                                    <input
                                                        class="form-check-input"
                                                        type="checkbox"
                                                        role="switch"
                                                        checked={item.status}
                                                    />
                                                </div>
                                            </td>
                                            <td>{item?.reviews.length}</td>
                                            <td>{item?.reviewAvgRating}</td>
                                            <td>
                                                <div class="reviewProgressBarPartParent">
                                                    <div class="reviewProgressBarPart">
                                                        <div class="progress myProgress">
                                                            <div
                                                                class="progress-bar progressBarPart"
                                                                role="progressbar"
                                                                style={{ width: `${(item?.reviewAvgRating / 5) * 100}%` }}
                                                                ></div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </td>
                                            <td>
                                                <button
                                                    title="Activity Review Details"
                                                    className="me-2 btn-icon btn-icon-only btn btn-outline-primary"
                                                    onClick={() => {
                                                        navigate(`/viewReviewAndRating/${item?._id}`);
                                                    }}
                                                    >
                                                    <i class="fa-regular fa fa-eye"></i>
                                                </button>
                                            </td>
                                        </tr>
                                    );
                                    })}
                                    </tbody>
                                </table>
                                
                                </div>
                            </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
  );
};

export default ViewMerchantReviewAndRating;

// Hooks
import React, { useEffect, useState } from "react";

// Helpers
import { toast } from "react-toastify";

// Utils
import { apiCallError } from "../../../utils";

// API
import { JSON_API } from "../../../services/api/jsonApiCallWithInterceptor";

// Components
import Loader from "../../../Component/Loader";

function AddContactEmail() {
  const [isLoading, setisLoading] = useState(false);
  const [isEdit, setisEdit] = useState(false);
  const [email, setemail] = useState("");
  const [error, setError] = useState("");


const getEmail=async()=>{
  const res= await JSON_API["getContactEmail"]({})
  if(res.isSuccess){
  setemail(res.data.data[0].email)}

}
useEffect(()=>{
  getEmail()
},[])


  const handleSubmit = async (event) => {
    event.preventDefault();
    console.log("languageCode", email);
    if (email === "") {
      toast.error("Please add an email");
    } else {
      // console.log(language);
      try {
        setisLoading(true);
        let res = await JSON_API["addContactEmail"]({ email: email });
        // console.log("this response", res);

        if (res.isSuccess) {
          setemail("");
          getEmail()
          // apiCallSuccess(res.data);
          toast.success("Email added successfully!");
        } else {
          apiCallError(res?.error?.message, setError);
        }
        setisLoading(false);
      } catch (error) {
        setisLoading(false);
        apiCallError(error.message, setError);
      }
    }
  };
  
  // console.log("edit info", newLanguage);

  return (
    <>
      {isLoading && <Loader />}
      <form className="">
        <div component="div" className="TabsAnimation appear-done enter-done">
          <div className="main-card mb-3 card">
            <div className="card-body">
              <div className="card-title">Add Contact Email</div>
              <div className="col-md-12">
                <div
                  className="mb-3"
                  style={{ display: "flex", paddingTop: "5px" }}
                >
                  <label
                    htmlFor="language"
                    className="form-label"
                    style={{ margin: "0.5rem 0.5rem" }}
                  >
                    Email*
                  </label>
                  {/* <ReactLanguageSelect
                  className="border border-2 rounded"
                  names={'both'}
                  // languages={["English", "French", "German", "Italian", "Spanish"]}
                  onSelect={(lang) => setlanguage(lang)}
                  placeholder="Select Language"
                  searchable={true}
                  searchPlaceholder="Search for a language"
                /> */}
                  <input
                    name="language"
                    id="language"
                    // placeholder="Your first name, e.g. Walter"
                    type="text"
                    className="form-control"
                    autoComplete="false"
                    disabled={!isEdit}
                    value={email}
                    onChange={(e) => setemail(e.target.value)}
                  />
                  {/* {errors.name && (
                            <span className="errorMessageText">{errors.name}</span>
                            )} */}
                </div>
              </div>
{
!isEdit?<button
className="mt-2 btn btn-primary ml-auto d-table"
type="submit"
onClick={(e)=>{
  e.preventDefault()
  setisEdit(true)
}}
>
Edit

</button>:
 <button
 className="mt-2 btn btn-primary ml-auto d-table"
 type="submit"
 onClick={(e) => handleSubmit(e)}
 
>
Submit
 
</button>


}
             
            </div>
          </div>
        </div>
      </form>
    </>
  );
}

export default AddContactEmail;

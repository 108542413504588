import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';
import { ApiHelperFunction } from '../../services/api/apiHelpers';

let initialState = {
  status: 'idle',
  subMerchants: [],
  isLoading: false,
  isSuccess: false,
  isError: false,
};

export const getAccounts = createAsyncThunk('get_accounts', async () => {
  let response = await ApiHelperFunction({
    urlPath: '/merchant/viewSubMarchent',
    method: 'GET',
  });

  if (response.status === 200) {
    return response.data;
  } else {
    toast.error("Can't get data. Something went wrong");
  }
});

export const merchantAccountSlice = createSlice({
  name: 'submerchant_actions',
  initialState,
  reducers: {
    clearState: state => {
      state.isLoading = false;
      state.isSuccess = false;
      state.isError = false;
    },
  },
  extraReducers: builder => {
    builder
      .addCase(getAccounts.pending, state => {
        state.status = 'loading';
        state.isLoading = true;
      })
      .addCase(getAccounts.fulfilled, (state, { payload }) => {
        state.status = 'success';
        state.isSuccess = true;
        state.subMerchants = payload?.data;
      })
      .addCase(getAccounts.rejected, state => {
        state.status = 'failed';
        state.isError = true;
        state.isSuccess = false;
      });
  },
});

export const { clearState } = merchantAccountSlice.actions;

export default merchantAccountSlice.reducer;

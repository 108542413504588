import React, { useEffect, useMemo, useState } from 'react'
import Table from '../../../Component/Table';
import Loader from '../../../Component/Loader';
import { useNavigate } from 'react-router-dom';
import { requestData } from '../../../services/api/apiHelpers';
import { toast } from 'react-toastify';
import Swal from "sweetalert2";

const ManageThirsPartyplatform = () => {
  const navigate = useNavigate();
  const [searchText, setsearchText] = useState("");
  const [modal, setModal] = useState(false);
  const [modalToggleView, setmodalToggleView] = useState(null);
  const [isLoading, setisLoading] = useState(false);
  const [thirdPartyData, setThirdPartyData] = useState([])
  console.log("thirdPartyData", thirdPartyData)

  const getAllThirdParty = async () => {
    try {
      setisLoading(true)
      let res = await requestData("/admin/viewThirdPerty", "GET");
      console.log("wewew", res)
      if (res) {
        setThirdPartyData(res.data)
        setisLoading(false)
      }
    } catch (error) {
      toast.error(error.message)
      setisLoading(false)
    }
  }

  useEffect(() => {
    getAllThirdParty()
  }, [])

  const filterData = () => {
    if (searchText !== "") {
      return thirdPartyData.filter((item) =>
        JSON.stringify(item).toLowerCase().includes(searchText.toLowerCase())
      );
    }

    return thirdPartyData;
  };

  const tableData = useMemo(() => {
    return {
      columns: [
        {
          label: "SL",
          field: "sl",
          sort: "asc",
          width: 270,
        },
        {
          label: "Third Party Platform",
          field: "topic",
          sort: "asc",
          width: 150,
        },
        {
          label: "Action",
          field: "action",
          sort: "asc",
          width: 150,
        },
      ],
      rows:
        filterData()?.length > 0
          ? filterData().map((item, idx) => {
            return {
              sl: idx + 1,
              topic: (
                <div className="widget-content p-0">
                  <div className="widget-content-wrapper">
                    <div className="widget-content-left flex2">
                      <div
                        className="zwidget-heading"
                        style={{ opacity: 1, textAlign: "center" }}
                      >
                        {item?.name}
                      </div>
                      {/* <div className="widget-subheading opacity-7">UI Designer</div> */}
                    </div>
                  </div>
                </div>
              ),
              action: (
                <div>
                  <button
                    title="Edit Activity"
                    className="me-2 btn-icon btn-icon-only btn btn-outline-success"
                    onClick={() => {
                      navigate(`/add_trird_party_platform/${item?._id}`);
                    }}
                  >
                    <i className="fa-regular fa-pen-to-square"></i>
                  </button>
                  <button
                    title="Delete Activity"
                    className="me-2 btn-icon btn-icon-only btn btn-outline-danger"
                    onClick={() => {
                      deleteActivity(item?._id)
                    }}
                  >
                    <i className="fa-solid fa-trash-can" />
                  </button>
                </div>
              ),
            };
          })
          : [],
    };
  }, [thirdPartyData, searchText]);

  const deleteActivity = async (id) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          setisLoading(true);
          let res = await requestData(`/admin/delete-thirdperty/${id}`, "DELETE")
          if (res && res.status) {
            getAllThirdParty()
            setModal(false);
            setisLoading(false);
            Swal.fire({
              title: "Deleted!",
              text: "Activity has been deleted.",
              icon: "success",
            });
          } else {
            setisLoading(false);
            toast.error(res?.error?.message);
          }
        } catch (error) {
          setisLoading(false);
          toast.error(error?.message);
        }
      }
    });
    return;
  };

  return (
    <>
      {isLoading && <Loader />}

      <Table
        striped
        tableTitle="All Third Party Platforms"
        data={tableData}
        modal={modal}
        setModal={setModal}
        onSearch={(val) => setsearchText(val)}
      />
    </>
  )
}

export default ManageThirsPartyplatform

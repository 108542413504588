import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import HomeService from "../../../services/api/HomeService";
import { toast } from "react-hot-toast";
import { capitalizeFirstLetter } from '../../../utils';
import Select from "react-select";

const user_types = ["user", "merchant"]

function AddFaq() {
    const navigate = useNavigate();
    const [typeOfUser, settypeOfUser] = useState(null);
    const [content, setContent] = useState('')
    const [faqTitle, setFaqTitle] = useState([])
    const [faqId, setFaqid] = useState("")
    const [questions, setQuestions] = useState('')
    // const onBlur () 
    useEffect(() => {
        getAllfaqType()
    }, [])

    const getAllfaqType = () => {
        HomeService.getAllFaqType()
            .then((result) => {
                // console.log("result", result);
                if (result && result.status) {
                    setFaqTitle(result.data);
                }
            })
            .catch((error) => console.log("error", error))

    }
    const handlesubmit = (event) => {
        if (typeOfUser == "" || faqId == "" || questions == "" || content == "") {
            toast.error('Fill all fields')
        }
        event.preventDefault();
        let data = {
            question: questions,
            FAQTypeId: faqId,
            answer: content,
            userType: typeOfUser
        }
        console.log(data)
        HomeService.addfaq(data)
            .then((res) => {
                if (res && res.status) {
                    toast.success(' FAQ added successfully');
                    setContent('')
                    setQuestions('')
                    navigate('/manage-faq');
                } else {
                    toast.error('Something wrong please try after some time')
                }
            })
            .catch((err) => {
                console.log(err)
            })
    }
    return (

        <form className=""
            onSubmit={(e) => handlesubmit(e)}
        >
            <div component="div" className="TabsAnimation appear-done enter-done">
                <div className="main-card mb-3 card">
                    <div className="card-body">FAQ</div>
                    <div className="row" style={{ margin: '0.1rem' }}>
                        <div className="col-md-6">
                            <div className="mb-3">
                                <label htmlFor="userType" className="form-label">
                                    User Type*
                                </label>
                                <select
                                    name="userType"
                                    id="userType"
                                    className="form-control form-select"
                                    value={typeOfUser}
                                    onChange={(e) => settypeOfUser(e.target.value)}
                                >
                                    <option value="" key={0}>Select</option>
                                    {user_types.map((it, idx) => {
                                        return (
                                            <option value={it} key={idx+1}>
                                                {it.charAt(0).toUpperCase() + it.slice(1)}
                                            </option>
                                        )
                                    })}
                                </select>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="mb-3">
                                <label htmlFor="countryId" className="form-label">
                                    Faq Title*
                                </label>
                                {/* <select
                                name="countryId"
                                id="countryId"
                                className="form-control form-select"
                                onChange={(e) => setFaqid(e.target.value)}
                            >
                                <option value="" key={0}>
                                    Select
                                </option>
                                {
                                    faqTitle.map((it, ind) => {
                                        return (
                                            <option value={it._id} key={ind}>
                                                {it.name}
                                            </option>
                                        )
                                    })
                                }
                            </select> */}
                                <Select
                                    name="countryId"
                                    id="countryId"
                                    classNamePrefix="select"
                                    // ref={inputRef}
                                    options={faqTitle.map((item, idx) => ({
                                        value: item?._id,
                                        label: item?.name,
                                    }))}
                                    onChange={(val) => {
                                        setFaqid(val?.value);
                                        // console.log("country",faqId);
                                    }}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="col-md-12">
                        <div className="mb-3">
                            <label className="form-label">
                                Add Questions*
                            </label>
                            <input
                                name="Questions"
                                id="Questions"
                                type="text"
                                className="form-control"
                                autoComplete="false"
                                value={questions}
                                onChange={(e) => setQuestions(capitalizeFirstLetter(e))}
                            />
                        </div>
                    </div>
                    <div className="col-md-12">
                        <div className="mb-3">
                            <label htmlFor="Faq" className="form-label">
                                Add Answer*
                            </label>
                            <CKEditor

                                editor={ClassicEditor}
                                data={content}
                                onReady={editor => {
                                    // You can store the "editor" and use when it is needed.
                                    console.log('Editor is ready to use!', editor);
                                }}
                                onChange={(event, editor) => {
                                    const data = editor.getData();
                                    console.log({ event, editor, data });
                                    setContent(data);
                                }}
                                onBlur={(event, editor) => {
                                    console.log('Blur.', editor);
                                }}
                                onFocus={(event, editor) => {
                                    console.log('Focus.', editor);
                                }}
                                className="editor-container"
                            />
                        </div>
                    </div>
                    <button
                        className="mt-2 btn btn-primary ml-auto d-table"
                        type="submit"
                        style={{ margin: "5px" }}
                    >
                        Add
                    </button>
                </div>
            </div>
        </form>

    )
}

export default AddFaq;
import { useState } from "react";
import Layout from "../../../Layout";
import { JSON_API } from "../../../services/api/jsonApiCallWithInterceptor";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";

const INITIAL = {
  firstName: "",
  lastName: "",
  email: "",
  password: "",
  designation: "subMerchant",
  position: "",
  mobile: "",
  acessLogin: "no",
  emailNotification: [],
};
const INITIAL_CHECKBOX = {
  productQuality: false,
  bookings: false,
  customerQuestions: false,
  accounting: false,
  reviews: false,
};

const validator = ({ firstName, lastName, mobile, password }) => {
  if (firstName?.trim().length < 2) {
    toast.error("Length of firstname cannot be less than 2 characters");
    return false;
  }

  if (lastName?.trim().length < 2) {
    toast.error("Length of lastname cannot be less than 2 characters");
    return false;
  }
  if (password?.length < 9) {
    toast.error("Length of password cannot be less than 9 characters");
    return false;
  }
  if (mobile?.length < 6) {
    toast.error("Length of mobile number cannot be less than 6 characters");
    return false;
  }

  return true;
};

const AddProfile = () => {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [subMerchant, setSubMerchant] = useState({ ...INITIAL });
  const [enableRadio, setEnableRadio] = useState(false);
  const [enableCheckboxes, setEnableCheckboxes] = useState(false);
  const [checkboxVal, setCheckboxDefaultVal] = useState({
    ...INITIAL_CHECKBOX,
  });

  // ! Submit Handler
  const submitHandler = async (e) => {
    e.preventDefault();
    if (isLoading) return;

    const validation = validator(subMerchant);
    if (!validation) return;

    try {
      setIsLoading(true);
      let response = await JSON_API["registerSubMerchant"]({ ...subMerchant });

      console.log("RESPONSE", response);
      if (response?.isSuccess) {
        console.log("success", response);
        toast.success(response?.data?.message || "Action successfull");
        setCheckboxDefaultVal({ ...INITIAL_CHECKBOX });
        setEnableRadio(false);
        setEnableCheckboxes(false);
        setSubMerchant({ ...INITIAL });
        setIsLoading(false);
        navigate("/account-management");
      } else {
        console.log("error", response);
        setIsLoading(false);
        toast.error(response?.error?.message);
      }
    } catch (error) {
      setIsLoading(false);
      console.log("ERROR IN CREATING SUB MERCHANT", error);
      toast.error(error?.message);
    }
  };

  // ! Register change
  const changeHandler = (e) => {
    console.log("e", e.target.value);

    setSubMerchant((oldState) => {
      oldState[e.target.name] = e.target.value;
      return { ...oldState };
    });
  };

  // ~ ChangeHandler for email notifications
  const notificationHandler = (e) => {
    setCheckboxDefaultVal((oldState) => {
      oldState[e.target.name] = e.target.checked;
      return { ...oldState };
    });

    if (e.target.checked) {
      setSubMerchant((prev) => {
        return {
          ...prev,
          emailNotification: [
            ...prev.emailNotification,
            {
              title: e.target.name,
              status: e.target.checked,
              desc: e.target.getAttribute("data-desc"),
            },
          ],
        };
      });
      return;
    }

    if (!e.target.checked) {
      console.log(e.target.checked);
      setSubMerchant((oldState) => {
        let update = [...oldState.emailNotification].filter(
          (item) => item.title !== e.target.name
        );
        oldState.emailNotification = update;
        return { ...oldState };
      });
    }
  };

  return (
    <div className="main-card mb-3 card p-4">
      <h4 className="text-primary pb-4">Create Profile</h4>
      <form autoComplete="off" onSubmit={submitHandler}>
        <div className="row">
          <div className="form-group col-md-6 col-12">
            <label className="font-weight-bold" htmlFor="exampleInputEmail1">
              First name
            </label>
            <input
              type="text"
              name="firstName"
              className="form-control"
              id="exampleInputEmail12"
              aria-describedby="emailHelp"
              autoComplete="off"
              onChange={changeHandler}
              value={subMerchant?.firstName}
              required={true}
            />
          </div>
          <div className="form-group col-md-6 col-12">
            <label className="font-weight-bold" htmlFor="exampleInputPassword1">
              Last name
            </label>
            <input
              type="text"
              name="lastName"
              className="form-control"
              id="exampleInputPassword14"
              autoComplete="off"
              onChange={changeHandler}
              value={subMerchant?.lastName}
              required={true}
            />
          </div>
          <div className="form-group col-md-6 col-12">
            <label className="font-weight-bold" htmlFor="exampleInputPassword1">
              Email ID
            </label>
            <input
              type="email"
              name="email"
              className="form-control"
              id="exampleInputPassword16"
              autoComplete="off"
              onChange={changeHandler}
              value={subMerchant?.email}
              required={true}
            />
          </div>

          <div className="form-group col-md-6 col-12">
            <label
              className="font-weight-bold"
              htmlFor="exampleInputPassword1667"
            >
              Password
            </label>
            <input
              type="password"
              name="password"
              className="form-control"
              id="exampleInputPassword1667"
              autoComplete="off"
              onChange={changeHandler}
              value={subMerchant?.password}
              required={true}
            />
          </div>

          <div className="form-group col-md-6 col-12">
            <label className="font-weight-bold" htmlFor="exampleInputPasswor">
              Position
            </label>
            <input
              type="text"
              name="position"
              className="form-control"
              id="exampleInputPasswor"
              autoComplete="off"
              onChange={changeHandler}
              value={subMerchant?.position}
              required={true}
            />
          </div>
          <div className="form-group col-md-6 col-12">
            <label className="font-weight-bold" htmlFor="mobile">
              Mobile Number
            </label>
            <input
              type="number"
              name="mobile"
              className="form-control"
              id="mobile"
              autoComplete="off"
              onChange={changeHandler}
              value={subMerchant?.mobile}
              required={true}
            />
          </div>

          <div
            className="form-check form-switch mb-2 "
            style={{ paddingLeft: "13px", display: "flex", gap: "5px" }}
          >
            <label
              className="form-check-label font-weight-bold"
              htmlFor="flexSwitchCheckDefault1"
            >
              Login Access
            </label>
            <input
              onClick={(e) => {
                setEnableRadio(e.target.checked);
                setSubMerchant((prev) => {
                  return {
                    ...prev,
                    acessLogin: "no",
                  };
                });
              }}
              type="checkbox"
              role="switch"
              id="flexSwitchCheckDefault1"
              checked={enableRadio}
            />
          </div>
          {enableRadio && (
            <div className="mb-3 fade-in">
              <div
                style={{ display: "flex", gap: "5px", alignItems: "center" }}
              >
                <input
                  style={{
                    display: "flex",
                    alignSelf: "self-start",
                    marginTop: "4px",
                  }}
                  onChange={changeHandler}
                  type="radio"
                  id="marchentAdminstrative"
                  name="acessLogin"
                  checked={subMerchant?.acessLogin === "marchentAdminstrative"}
                  value="marchentAdminstrative"
                />
                <label className="mb-0" htmlFor="marchentAdminstrative">
                  <b>Merchant Adminstrative</b>
                  <br />
                  <span>Has full access Can invite users and edit</span>
                </label>
              </div>
              <div
                style={{ display: "flex", gap: "5px", alignItems: "center" }}
              >
                <input
                  style={{
                    display: "flex",
                    alignSelf: "self-start",
                    marginTop: "4px",
                  }}
                  onChange={changeHandler}
                  type="radio"
                  id="finance"
                  name="acessLogin"
                  checked={subMerchant?.acessLogin === "finance"}
                  value="finance"
                />
                <label className="mb-0" htmlFor="finance">
                  <b>Finance</b>
                  <br />
                  <span>Can access financial info only</span>
                </label>
              </div>
              <div style={{ display: "flex", gap: "5px" }}>
                <input
                  style={{
                    display: "flex",
                    alignSelf: "self-start",
                    marginTop: "4px",
                  }}
                  onChange={changeHandler}
                  type="radio"
                  id="operations"
                  name="acessLogin"
                  checked={subMerchant?.acessLogin === "operations"}
                  value="operations"
                />
                <label className="mb-0" htmlFor="operations">
                  <b>Operations</b>
                  <br />
                  <span>Can create and manage products</span>
                  <br />
                  <span>Can request changes to products</span>
                  <br />
                  <span>Can view, approve, and cancel bookings</span>
                  <br />
                  <span>Cannot access financial info</span>
                </label>
              </div>
              <div
                style={{ display: "flex", gap: "5px", alignItems: "center" }}
              >
                <input
                  style={{
                    display: "flex",
                    alignSelf: "self-start",
                    marginTop: "4px",
                  }}
                  onChange={changeHandler}
                  type="radio"
                  id="guides"
                  name="acessLogin"
                  value="guides"
                  checked={subMerchant?.acessLogin === "guides"}
                />
                <label className="mb-0" htmlFor="guides">
                  <b>Guides</b>
                  <br />
                  <span>Can view, approve, and cancel bookings</span>
                  <br />
                  <span>Cannot access financial info</span>
                </label>
              </div>
            </div>
          )}
          <div
            className="form-check form-switch myFormChk mb-2"
            style={{
              paddingLeft: "13px",
              display: "flex",
              gap: "5px",
              alignItems: "center",
            }}
          >
            <label
              className="form-check-label font-weight-bold"
              style={{ display: "block" }}
              htmlFor="flexSwitchCheckDefault"
            >
              Receive email notifications
            </label>
            <input
              onChange={(e) => {
                setEnableCheckboxes(e.target.checked);
                setSubMerchant((oldState) => {
                  oldState.emailNotification = [];
                  return { ...oldState };
                });
                setCheckboxDefaultVal({ ...INITIAL_CHECKBOX });
              }}
              className="f"
              style={{ display: "block" }}
              type="checkbox"
              role="switch"
              id="flexSwitchCheckDefault"
              checked={enableCheckboxes}
            />
          </div>
          {enableCheckboxes && (
            <div className="fade-in">
              <div
                style={{ display: "flex", gap: "5px", alignItems: "center" }}
              >
                <input
                  style={{
                    display: "flex",
                    alignSelf: "self-start",
                    marginTop: "4px",
                  }}
                  type="checkbox"
                  id="customerQuestions"
                  name="customerQuestions"
                  data-desc="Receive emails from customers or about customer questions"
                  onChange={notificationHandler}
                  checked={checkboxVal.customerQuestions}
                />
                <label
                  htmlFor="customerQuestions"
                  className="mb-0"
                  style={{ display: "flex", flexDirection: "column" }}
                >
                  <b>Customer Questions </b>
                  Receive emails from customers or about customer questions
                </label>
              </div>
              <div
                style={{ display: "flex", gap: "5px", alignItems: "center" }}
              >
                <input
                  style={{
                    display: "flex",
                    alignSelf: "self-start",
                    marginTop: "4px",
                  }}
                  type="checkbox"
                  id="bookings"
                  name="bookings"
                  data-desc="Receive emails such as new bookings, confirmations pending, and cancellations"
                  onChange={notificationHandler}
                  checked={checkboxVal.bookings}
                />
                <label
                  className="mb-0"
                  htmlFor="bookings"
                  style={{ display: "flex", flexDirection: "column" }}
                >
                  <b>Bookings </b>
                  Receive emails such as new bookings, confirmations pending,
                  and cancellations
                </label>
              </div>
              <div
                style={{ display: "flex", gap: "5px", alignItems: "center" }}
              >
                <input
                  style={{
                    display: "flex",
                    alignSelf: "self-start",
                    marginTop: "4px",
                  }}
                  type="checkbox"
                  id="productQuality"
                  name="productQuality"
                  data-desc="Receive emails about insights and optimization, plus quality checks for new products and edits"
                  onChange={notificationHandler}
                  checked={checkboxVal.productQuality}
                />
                <label
                  className="mb-0"
                  htmlFor="productQuality"
                  style={{ display: "flex", flexDirection: "column" }}
                >
                  <b>Product Quality </b>
                  Receive emails about insights and optimization, plus quality
                  checks for new products and edits
                </label>
              </div>
              <div
                style={{ display: "flex", gap: "5px", alignItems: "center" }}
              >
                <input
                  style={{
                    display: "flex",
                    alignSelf: "self-start",
                    marginTop: "4px",
                  }}
                  type="checkbox"
                  id="accounting"
                  name="accounting"
                  data-desc="Receive emails about payments and billing"
                  onChange={notificationHandler}
                  checked={checkboxVal.accounting}
                />
                <label
                  className="mb-0"
                  htmlFor="accounting"
                  style={{ display: "flex", flexDirection: "column" }}
                >
                  <b>Accounting </b>
                  Receive emails about payments and billing
                </label>
              </div>
              <div
                style={{ display: "flex", gap: "5px", alignItems: "center" }}
              >
                <input
                  style={{
                    display: "flex",
                    alignSelf: "self-start",
                    marginTop: "4px",
                  }}
                  type="checkbox"
                  id="reviews"
                  name="reviews"
                  data-desc="Receive emails about payments and billing"
                  onChange={notificationHandler}
                  checked={checkboxVal.reviews}
                />
                <label
                  className="mb-0"
                  htmlFor="reviews"
                  style={{ display: "flex", flexDirection: "column" }}
                >
                  <b>Reviews </b>
                  Receive emails about customer reviews
                </label>
              </div>
            </div>
          )}
        </div>
        <div style={{ display: "flex", flexDirection: "row-reverse" }}>
          <button className="btn btn-primary btn-lg font-weight-bold mt-4">
            SUBMIT
          </button>

          <button
            className="btn btn-secondary btn-lg font-weight-bold mt-4 mr-3"
            onClick={(e) => {
              e.preventDefault();
              setCheckboxDefaultVal({ ...INITIAL_CHECKBOX });
              setEnableRadio(false);
              setEnableCheckboxes(false);
              setSubMerchant({ ...INITIAL });
            }}
          >
            CLEAR
          </button>
        </div>
      </form>
    </div>
  );
};

export default AddProfile;

import React, { useEffect } from "react";
import Layout from "../../../Layout";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getAllTerms } from "../../../redux/adminSlice/termsAndConSlice";

export default function TermsDetailView() {
  const dispatch = useDispatch();
  const { id } = useParams();
  const { terms } = useSelector((state) => state.termsConditionSlice);
  console.log("terms", terms);
  const termsDetail = JSON.parse(
    JSON.stringify(terms.find((item) => item._id === id))
  );

  useEffect(() => {
    dispatch(getAllTerms());
  }, [dispatch]);

  return (
    <>
      <div className="info-details">
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-12">
              <div className="des-detail" style={{ flexWrap: "nowrap" }}>
                <div className="des-title">
                  <h3 className="text-primary mb-4">Terms And Conditions Details</h3>

                  <div dangerouslySetInnerHTML={{ __html: termsDetail.topic }} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

import React, { useState } from "react";
import Loader from "../../../Component/Loader";
import { capitalizeFirstLetter } from "../../../utils";
import { toast } from "react-hot-toast";
import { requestData } from "../../../services/api/apiHelpers";

function AddParticipants() {
  const [parType, setparType] = useState({ type: "", ageRange: "" });
  const [isLoading, setisLoading] = useState(false);

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!parType.type) {
      toast.error("Please provide participant type name");
      return;
    }
    if (!parType.ageRange) {
      toast.error("Please provide  participant age range");
      return;
    } else {
      setisLoading(true);
      let res = await requestData(
        "/admin/add-participant-type",
        "POST",
        parType
      );

      if (res && res.status) {
        setisLoading(false);
        toast.success("Participant type add successfully.");
        setparType({ type: "", ageRange: "" });
      } else {
        setisLoading(false);
        toast.error(res?.message);
      }
    }
  };

  return (
    <>
      {isLoading && <Loader />}
      <form action="" onSubmit={handleSubmit}>
        <div component="div" className="TabsAnimation appear-done enter-done">
          <div className="main-card mb-3 card">
            <div className="card-body">
              <div className="card-title">ADD PARTICIPANT TYPE</div>
              <div className="row">
                <div className="col-md-12">
                  <div className="mb-3">
                    <label htmlFor="type" className="form-label">
                      Type{" "}
                      <span
                        style={{
                          fontSize: "18px",
                          fontWeight: "bold",
                          color: "red",
                        }}
                      >
                        *
                      </span>
                    </label>
                    <input
                      name="type"
                      id="type"
                      type="text"
                      className="form-control"
                      autoComplete="false"
                      value={parType.type}
                      onChange={(e) => {
                        setparType((prevState) => ({
                          ...prevState,
                          [e.target.name]: capitalizeFirstLetter(e),
                        }));
                      }}
                    />
                  </div>
                  <div className="mb-3">
                    <label htmlFor="type" className="form-label">
                      Age Range{" "}
                      <span
                        style={{
                          fontSize: "18px",
                          fontWeight: "bold",
                          color: "red",
                        }}
                      >
                        *
                      </span>
                    </label>
                    <input
                      name="ageRange"
                      id="ageRange"
                      type="text"
                      className="form-control"
                      autoComplete="false"
                      value={parType.ageRange}
                      onChange={(e) => {
                        setparType((prevState) => ({
                          ...prevState,
                          [e.target.name]: e.target.value,
                        }));
                      }}
                    />
                  </div>
                </div>
              </div>

              <button
                className="mt-2 btn btn-primary ml-auto d-table"
                type="submit"
                style={{ margin: "5px" }}
              >
                Add
              </button>
            </div>
          </div>
        </div>
      </form>
    </>
  );
}

export default AddParticipants;

// Hooks
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

// Components
import { Formik } from "formik";
import Loader from "../../../Component/Loader";

// Helpers
import * as Yup from "yup";
import { toast } from "react-toastify";

// Utils
import { apiCallSuccess, apiCallError } from "../../../utils/common-utils";

// API functions
import { JSON_API } from "../../../services/api/jsonApiCallWithInterceptor";

// Redux actions
import { getAllLegalNotices } from "../../../redux/adminSlice/legalNoticeSlice";
import { useNavigate, useParams } from "react-router-dom";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";

const mobileRegex =
  /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/; // /^[0-9]{15}$/;
const vatRegex = /^[A-Za-z0-9 ]+$/;

const EditLegalNotice = () => {

  const [content, setcontent] = useState("");
  const [isLoading, setisLoading] = useState(false);

  // export default function AddLegalNotice() {
  const dispatch = useDispatch();
  const [key, setkey] = useState(Math.random());
  const [error, setError] = useState("");
  const navigate = useNavigate();
  const params = useParams();

  // Redux stores
  const { legal_notices } = useSelector((state) => state.legalNoticeSlice);

  const legalData = legal_notices.find((item) => item?._id === params.id);
  console.log("legalData", legalData);
  const [editObject, seteditObject] = useState(legalData);
  useEffect(() => {
    window.scrollTo(0, 0);
    dispatch(getAllLegalNotices());
    setcontent(legalData.legalNotice)
    // seteditObject({
    //   managmentPersonName: legalData?.managmentPersonName,
    //   contactInformation: legalData?.contactInformation,
    //   comapnyRegistration: legalData?.comapnyRegistration,
    //   VATRegistrationNo: legalData?.VATRegistrationNo,
    //   address: legalData?.address,
    //   id: legalData?._id,
    // });
  }, [dispatch]);

  const legalNoticeEditSchema = Yup.object().shape({
    managmentPersonName: Yup.string().required(
      "Contact person name is required."
    ),
    contactInformation: Yup.string()
      .required("Contact number is required.")
      .matches(mobileRegex, {
        message: "Please enter valid number.",
        excludeEmptyString: false,
      })
      .min(10, "Please enter a mobile number between 10 to 15 digits")
      .max(15, "Please enter a mobile number between 10 to 15 digits"),
    comapnyRegistration: Yup.string().required(
      "Company registration number is required."
    ),
    VATRegistrationNo: Yup.string()
      .required("VAT registration number is required.")
      .matches(vatRegex, {
        message: "Please enter valid VAT Registration number.",
        excludeEmptyString: false,
      })
      .test(
        "len",
        "Please enter a 15 digit VAT number",
        (val) => val.length === 15
      ),
    address: Yup.string().required("Company address is required."),
  });

  const editLegalNoticeInfo = async () => {
    let bodyData = {legalNotice:content };
    // console.log(bodyData);

    try {
      setisLoading(true);
      let res = await JSON_API["editLegalNotice"]({
        id: params.id,
        ...bodyData,
      });

      if (res.isSuccess) {
        setkey(Math.random());
        console.log("clicked");
        dispatch(getAllLegalNotices());
        seteditObject(null);
   
        // apiCallSuccess(res.data);
        toast.success("Legal Notice edited successfully");
        navigate("/manage-legal-notices");
      } else {
        apiCallError(res?.error?.message, setError);
      }
      setisLoading(false);
    } catch (error) {
      setisLoading(false);
      apiCallError(error.message, setError);
    }
  };

  return (
    <>
      {isLoading && <Loader />}
      {/* <Formik
        key={key}
        initialValues={editObject}
        validationSchema={legalNoticeEditSchema}
        validateOnChange={false}
        validateOnBlur={false}
        onSubmit={(values, { resetForm }) => {
          editLegalNoticeInfo(values, resetForm);
        }}
      >
        {({ handleChange, handleSubmit, setFieldValue, values, errors }) => ( */}
          <>
            <div
              component="div"
              className="TabsAnimation appear-done enter-done"
            >
              <div className="main-card mb-3 card">
                <div className="card-body">
                  <div className="card-title">Edit Legal Notice</div>
                  <div className="row" style={{ margin: "0.1rem" }}>
                    <div className="col-md-12">
                      <div className="mb-3">
                        <label
                          htmlFor="managmentPersonName"
                          className="form-label"
                        >
                          Full name of the person
                        </label>
                 
                      </div>
                      <div>        <CKEditor
                    className="editor-container"
                    editor={ClassicEditor}
                    data={content}
                    onReady={(editor) => {
                      // You can store the "editor" and use when it is needed.
                      console.log("Editor is ready to use!", editor);
                    }}
                    onChange={(event, editor) => {
                      const data = editor.getData();
                      console.log({ event, editor, data });
                      setcontent(data);
                    }}
                    onBlur={(event, editor) => {
                      console.log("Blur.", editor);
                    }}
                    onFocus={(event, editor) => {
                      console.log("Focus.", editor);
                    }}
                  /></div>
                   
                    </div>
                 
               
                  </div>

            
                </div>
                <div className="d-flex m-2">
                  <button
                    type="button"
                    className="btn btn-link"
                    onClick={() => {
                      // setModal(false);
                      // seteditObject(null);
                      navigate("/manage-legal-notices");
                    }}
                  >
                    Cancel
                  </button>
                  <button
                    type="submit"
                    className="mt-2 btn btn-primary  d-table"
                    onClick={(e) => editLegalNoticeInfo()}
                  >
                    Edit
                  </button>
                </div>
              </div>
            </div>
          </>
        {/* )}
      </Formik> */}
    </>
  );
  //   }
};

export default EditLegalNotice;

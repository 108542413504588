// Hooks
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

// Helpers
import { toast } from "react-toastify";
import { addDays } from "date-fns";

// Utils
import { apiCallError, getDatesBetween } from "../../../utils";

// API
import { JSON_API } from "../../../services/api/jsonApiCallWithInterceptor";

// Redux actions
import { getAllActivities } from "../../../redux/adminSlice/activitySlice";

// Components
import Loader from "../../../Component/Loader";
import Select from "react-select";
import { DateRangePicker } from "react-date-range";

import "react-date-range/dist/styles.css"; // main style file
import "react-date-range/dist/theme/default.css"; // theme css file
import "./styles.css";

let initDatesValue = {
  selection1: {
    startDate: new Date(),
    endDate: addDays(new Date(), 0),
    key: "selection1",
  },
  selection2: {
    startDate: new Date(),
    endDate: addDays(new Date(), 0),
    key: "selection2",
  },
  selection3: {
    startDate: new Date(),
    endDate: addDays(new Date(), 0),
    key: "selection3",
  },
  selection4: {
    startDate: new Date(),
    endDate: addDays(new Date(), 0),
    key: "selection4",
  },
  selection5: {
    startDate: new Date(),
    endDate: addDays(new Date(), 0),
    key: "selection5",
    autoFocus: false,
  },
};

const createErrors = {
  activityDetailsId: "",
  dateRange: "",
};

function CancelAvailability() {
  const dispatch = useDispatch();
  const [isLoading, setisLoading] = useState(false);
  const [activityDetails, setactivityDetails] = useState({
    id: "",
    title: "",
  });
  const [dates, setdates] = useState(initDatesValue);
  const today = new Date();
  today.setHours(0, 0, 0, 0); // Set time to beginning of the day
  const [errors, seterrors] = useState(createErrors);
  const [error, setError] = useState("");
  // Redux stores
  const { activities } = useSelector((state) => state.adminActivityActionSlice);

  const initDateArr = [
    {
      startDate: new Date(),
      endDate: addDays(new Date(), 0),
      // key: "selection1",
    },
  ]
  const [dateRangeArr, setDateRangeArr] = useState(initDateArr);
  const [isFirstDateSelect, setIsFirstDateSelect] = useState(false);


  useEffect(() => {
    dispatch(getAllActivities());
  }, []);

  const handleDateChange = (selectedDates) => {
    setdates((prev) => {
      let update = { ...prev };

      return {
        ...update,
        ...selectedDates,
      };
    });

    // new 
    setIsFirstDateSelect(true);
    let arr = [];
    arr.push(selectedDates);
    if (isFirstDateSelect) {
      setDateRangeArr(prev => ([...prev, ...Object.values(selectedDates)]));
    } else {
      setDateRangeArr(prev => ([...Object.values(selectedDates)]));
    }
  };

  const handleValidation = () => {
    if (activityDetails.id === "") {
      seterrors({
        ...createErrors,
        activityDetailsId: "Please provide an activity.",
      });

      return false;
    }

    return true;
  };

  const getTourDates = (arr) => {
    let dateArrRange = arr;
    let datesArr = [];
    for (let i = 0; i < dateArrRange.length; i++) {
      let allDates = getDatesBetween(
        dateArrRange[i]?.startDate,
        dateArrRange[i]?.endDate
      );
      allDates.forEach((item) => {
        datesArr.push(item);
      });
    }
    return datesArr;
  }

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (handleValidation()) {
      let selectedDateRanges = Object.entries(dates).map((item) => item[1]);
      let datesArr = [];
      for (let i = 0; i < selectedDateRanges.length; i++) {
        let allDates = getDatesBetween(
          selectedDateRanges[i].startDate,
          selectedDateRanges[i].endDate
        );
        allDates.forEach((item) => {
          datesArr.push(item);
        });
      }
      let bodyData = {
        id: activityDetails.id,
        dateRange: getTourDates(dateRangeArr),
      };

      try {
        setisLoading(true);
        let res = await JSON_API["cancelAvailabilityForDates"](bodyData);

        if (res.isSuccess) {
          toast.success(
            "Activity for the selected dates has been cancelled successfully."
          );
          setactivityDetails({
            id: "",
            title: "",
          });
          setdates(initDatesValue);
        } else {
          apiCallError(res?.error?.message, setError);
        }
        setisLoading(false);
      } catch (error) {
        setisLoading(false);
        apiCallError(error.message, setError);
      }
    }
  };

  return (
    <>
      {isLoading && <Loader />}
      <form className="">
        <div
          component="div"
          className="TabsAnimation appear-done enter-done table-hover"
        >
          <div className="main-card mb-3 card">
            <div className="card-body">
              <div className="card-title">Cancel Activity Availability</div>
              <div className="row">
                <div className="col-md-6">
                  <div className="mb-3">
                    <label
                      htmlFor="activityDetailsId"
                      className="form-label font-weight-bold"
                    >
                      Activity*
                    </label>
                    <Select
                      name="activityDetailsId"
                      id="activityDetailsId"
                      classNamePrefix="select"
                      options={activities?.map((item) => ({
                        value: item?._id,
                        label: item?.activityTitle,
                      }))}
                      // isMulti
                      value={{
                        value: activityDetails.id,
                        label: activityDetails.title,
                      }}
                      onChange={(val) => {
                        setactivityDetails((prev) => {
                          return {
                            ...prev,
                            id: val.value,
                            title: val.label,
                          };
                        });
                      }}
                    />
                  </div>
                  {errors.activityDetailsId && (
                    <span className="errorMessageText text-danger">
                      {errors.activityDetailsId}
                    </span>
                  )}
                </div>
                <div className="col-md-6">
                  <div className="mb-3">
                    <label
                      className="form-label font-weight-bold"
                      htmlFor="tourDate"
                    >
                      Date*
                    </label>
                    <DateRangePicker
                      minDate={today}
                      months={2}
                      direction="horizontal"
                      initialFocusedRange={[0, 0]}
                      onChange={(item) => {
                        // while (count < 5) {
                        //   setcount((prev) => {
                        //     let update = prev;

                        //     return update + 1;
                        //   });
                        // }
                        handleDateChange(item);
                      }}
                      // rangeColors={[
                      //   "#3d91ff",
                      //   "#3ecf8e",
                      //   "#fed14c",
                      //   "#008080",
                      //   "#FF00FF",
                      // ]}
                      // disabledDates={[new Date()]}
                      // ranges={[
                      //   dates.selection1,
                      //   dates.selection2,
                      //   dates.selection3,
                      //   dates.selection4,
                      //   dates.selection5,
                      // ]}
                      ranges={dateRangeArr}
                    />
                  </div>
                  {errors.dateRange && (
                    <span className="errorMessageText text-danger">
                      {errors.dateRange}
                    </span>
                  )}
                </div>
              </div>

              <button
                className="mt-2 btn btn-primary ml-auto d-table"
                type="submit"
                onClick={(e) => handleSubmit(e)}
              // style={{ margin: "5px auto" }}
              >
                Submit
              </button>
            </div>
          </div>
        </div>
      </form>
    </>
  );
}

export default CancelAvailability;

//import liraries
import {
  get,
  limitToLast,
  off,
  onChildAdded,
  onChildChanged,
  onValue,
  push,
  query,
  ref,
  set,
  update,
} from 'firebase/database';
import React, { Component, useEffect, useRef, useState } from 'react';
import { db } from '../../../utils/FirebaseConfig';
import moment from 'moment';

const MerchantChat = () => {
  const [allChatList, setAllChatList] = useState([]);
  const [selectedChat, setSelectedChat] = useState(null);
  const [allMsg, setAllMsg] = useState([]);
  const [isShowChat, setIsShowChat] = useState(false);
  const [msg, setMsg] = useState('');

  useEffect(() => {
    getChatList();
  }, []);

  const getChatList = () => {
    get(ref(db, `/chats`)).then(snapshot => {
      if (snapshot.exists()) {
        setAllChatList(Object.values(snapshot.val()));
        fbChatListListener();
      } else {
        fbChatListListener();
      }
    });
  };

  const fbChatListListener = () => {
    onChildChanged(ref(db, `/chats`), snapshot => {
      if (snapshot.exists()) {
        let data = snapshot.val();
        setAllChatList(state => {
          let index = state.findIndex(it => it.id == data.id);
          if (index >= 0) {
            state[index] = data;
            return state;
          }
          return state;
        });
      }
    });
  };

  const onSelectChat = (index, item) => {
    if (selectedChat != null) off(ref(db, `/chats/${selectedChat.id}/messages`), 'child_added');

    let data = {
      name: item.name,
      image: item.image,
      id: item.id,
    };
    setSelectedChat(data);
    getChatData(item.id);
    setIsShowChat(true);
  };

  const getChatData = userId => {
    get(ref(db, `/chats/${userId}/messages`)).then(snapshot => {
      // console.log("snapshot", snapshot.val())
      if (snapshot.exists()) {
        setAllMsg(Object.values(snapshot.val()));
        chatListener(userId);
        let chatBody = document.getElementById('chat_body');
        chatBody.scrollTo(0, chatBody.scrollHeight);
      } else {
        chatListener(userId);
      }
    });
  };

  const chatListener = userId => {
    onChildAdded(query(ref(db, `/chats/${userId}/messages`), limitToLast(1)), snapshot => {
      update(ref(db, `/chats/${userId}`), { unseen: false });
      // console.log("snapshot", snapshot.val())
      setAllMsg(state => {
        if (snapshot.exists()) {
          let data = snapshot.val();
          let index = state.findIndex(it => it.id == data.id);
          if (index == -1) {
            return [...state, data];
          }
        }
        return state;
      });
      let chatBody = document.getElementById('chat_body');
      chatBody.scrollTo(0, chatBody.scrollHeight);
    });
  };

  const sendMessage = () => {
    if (msg == '') return;

    let chatRef = push(ref(db, `/chats/${selectedChat?.id}/messages`));
    let data = {
      sendBy: 'admin',
      message: msg,
      roomId: selectedChat?.id,
      id: chatRef.key,
      sendTime: moment().format(),
    };

    set(chatRef, data)
      .then(() => {
        update(ref(db, `/chats/${selectedChat?.id}`), {
          lastMsg: data.message,
          lastMsgTime: data.sendTime,
        });
        let chatBody = document.getElementById('chat_body');
        chatBody.scrollTo(0, chatBody.scrollHeight);
        setMsg('');
      })
      .catch(error => {
        console.log('error', error);
      });
  };

  const sorteChatList = () => {
    return allChatList.sort(function (a, b) {
      return b.lastMsgTime < a.lastMsgTime ? -1 : b.lastMsgTime > a.lastMsgTime ? 1 : 0;
    });
  };

  return (
    <div>
      <div className="main-card mb-3 card">
        <div className="card-body chat-body d-flex">
          <div
            className="d-flex"
            style={{
              height: '78vh',
              width: '100%',
            }}>
            <div className="chatlist-container">
              {sorteChatList().map((item, index) => {
                return (
                  <div
                    className={`chatlist-box d-flex ${item.hover ? 'chatlist-selected' : ''}`}
                    key={index}
                    onMouseOver={() => {
                      setAllChatList(state => {
                        state[index].hover = true;
                        return JSON.parse(JSON.stringify(state));
                      });
                    }}
                    onMouseOut={() => {
                      setAllChatList(state => {
                        state[index].hover = false;
                        return JSON.parse(JSON.stringify(state));
                      });
                    }}
                    onClick={() => onSelectChat(index, item)}>
                    <img className="rounded-circle" src={item.image} alt="" />

                    <div className="name-panel d-flex flex-column justify-content-center">
                      <h4 className={`${item.unseen ? 'unseen-name' : ''}`}>{item.name}</h4>
                      <h5 className={`${item.unseen ? 'unseen-msg' : ''}`}>
                        {item.lastMsg.slice(0, 33)}
                        {item.lastMsg.length > 33 && '...'}{' '}
                      </h5>
                    </div>
                  </div>
                );
              })}
            </div>

            {isShowChat ? (
              <div className="chat-container d-flex flex-column">
                <div className="chat-header">
                  <img className="rounded-circle" src={selectedChat?.image} alt="" />

                  <div className="name-panel d-flex flex-column justify-content-center">
                    <h4>{selectedChat?.name}</h4>
                  </div>
                </div>

                <div className="chat-body" id="chat_body">
                  {allMsg.map((item, index) => {
                    return (
                      <div
                        style={{ overflowWrap: 'anywhere' }}
                        className={`d-flex chat-message-container 
                                                            ${
                                                              item.sendBy !== 'merchant'
                                                                ? 'justify-content-end pl-5'
                                                                : 'pr-5'
                                                            }`}
                        key={index}>
                        <div className={`chat-message ${item.sendBy !== 'merchant' ? 'own-message' : ''}`}>
                          <h5>{item.message}</h5>
                        </div>
                      </div>
                    );
                  })}
                </div>

                <div className="chat-message-box">
                  <input
                    placeholder="Write a message"
                    onChange={e => setMsg(e.nativeEvent.target.value)}
                    value={msg}
                    onKeyDown={e => {
                      if (e.key == 'Enter') {
                        sendMessage();
                      }
                    }}
                  />

                  <i
                    className="fa fa-paper-plane ml-3 mr-1"
                    style={{
                      cursor: 'pointer',
                    }}
                    onClick={sendMessage}
                  />
                </div>
              </div>
            ) : null}
          </div>
        </div>
      </div>
    </div>
  );
};

export default MerchantChat;

import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import { ApiHelperFunction } from "../../services/api/apiHelpers";

let initialState = {
  status: "idle",
  press_releases: [],
  isLoading: false,
  isSuccess: false,
  isError: false,
};

export const getAllPressReleases = createAsyncThunk(
  "get_all_press_releases",
  async () => {
    let response = await ApiHelperFunction({
      urlPath: "/admin/viewPress",
      method: "GET",
    });
    if (response.status === 200) {
      return response.data;
    } else {
      toast.error("Can't get data. Something went wrong");
    }
  }
);

export const PressSlice = createSlice({
  name: "press_actions",
  initialState,
  reducers: {
    clearState: (state) => {
      state.isLoading = false;
      state.isSuccess = false;
      state.isError = false;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAllPressReleases.pending, (state) => {
        state.status = "loading";
        state.isLoading = true;
      })
      .addCase(getAllPressReleases.fulfilled, (state, { payload }) => {
        state.status = "success";
        state.isSuccess = true;
        state.press_releases = payload.data;
      })
      .addCase(getAllPressReleases.rejected, (state) => {
        state.status = "failed";
        state.isError = true;
        state.isSuccess = false;
      });
  },
});

export const { clearState } = PressSlice.actions;

export default PressSlice.reducer;

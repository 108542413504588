export function formatTimeToAMPM(timeString) {
    if(!timeString){
        return "0:0 AM";
    }
    
    const [hourString, minute] = timeString.split(":");
    const hour = +hourString % 24;
    return (hour % 12 || 12) + ":" + minute + (hour < 12 ? " AM" : " PM");
}

export const convertDurationTime = (duration_value, duration_unit) => {
    let totalMinutes = 0;


    if (duration_unit === 'minutes') {
      totalMinutes = duration_value;
    } else if (duration_unit === 'hours') {
      totalMinutes = duration_value * 60; 
    } else if (duration_unit === 'days') {
      totalMinutes = duration_value * 24 * 60; 
    }

    if (totalMinutes >= 24 * 60) {
   
      const days = Math.floor(totalMinutes / (24 * 60));
      const remainingMinutes = totalMinutes % (24 * 60);

      if (remainingMinutes >= 60) {
        const hours = Math.floor(remainingMinutes / 60);
        return { duration_value: days + hours / 24, duration_unit: 'days' }; 
      } else {
        return { duration_value: days, duration_unit: 'days' }; // Return just days
      }
    } else if (totalMinutes >= 60) {
      // Between 1 hour and 24 hours
      const hours = Math.floor(totalMinutes / 60);
      const remainingMinutes = totalMinutes % 60;

      if (remainingMinutes > 0) {
        return { duration_value: hours + remainingMinutes / 60, duration_unit: 'hours' };
      } else {
        return { duration_value: hours, duration_unit: 'hours' }; // Exact hours
      }
    } else {
      // Less than 60 minutes
      return { duration_value: totalMinutes, duration_unit: 'minutes' };
    }
  };
  
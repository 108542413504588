import React from "react";
import classnames from "classnames";

const Label = ({ error, className, children, ...props }) => {
  return (
    <label className="" {...props}>
      {children}
    </label>
  );
};
const InputFeedback = ({ error }) =>
  error ? <div className="invalid-feedback">{error}</div> : null;

const TextInput = ({
  type,
  id,
  label,
  error,
  value,
  onChange,
  className,
  ...props
}) => {
  const classes = classnames(
    "",
    {
      "animated shake error": !!error,
    },
    className
  );
  return (
    <div className={classes}>
      <Label htmlFor={id} error={error}>
        {label}
      </Label>
      <input
        id={id}
        className={error ? "form-control is-invalid" : `form-control`}
        type={type}
        value={value}
        onChange={onChange}
        {...props}
      />
      <InputFeedback error={error} />
    </div>
  );
};

export default TextInput;

// Hooks
import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";

// Helpers

// Utils
import {
  capitalizeFirstLetter,
  apiCallError,
} from "../../../utils";

// API
import { JSON_API } from "../../../services/api/jsonApiCallWithInterceptor";

// Redux actions
import { getAllActivitySites } from "../../../redux/adminSlice/activitySlice";

// Components
import Loader from "../../../Component/Loader";
import Select from "react-select";
import { toast } from "react-toastify";

let initialValues = {
  name: "",
  siteId: "",
  siteName: "",
  topPriority: 0,
};

let createErrors = {
  name: "",
  siteId: "",
  topPriority: "",
};

function AddDestination() {
  const dispatch = useDispatch();
  const inputRef = useRef(null);
  const [city, setcity] = useState(initialValues);
  const [error, setError] = useState("");
  const [errors, seterrors] = useState(createErrors);
  const [isLoading, setisLoading] = useState(false);

  const { activity_sites } = useSelector(
    (state) => state.adminActivityActionSlice
  );

  useEffect(() => {
    dispatch(getAllActivitySites());
  }, []);

  const handleChange = (event) => {
    let input;

    if (event.target.name === "siteId") {
      input = event.target.value;
    } else if (event.target.name === "topPriority") {
      input = Number(event.target.value);
    } else {
      input = capitalizeFirstLetter(event);
    }
    setcity({ ...city, [event.target.name]: input });
  };

  const handleValidation = () => {
    const { name, siteId, topPriority } = city;

    if (siteId === "") {
      seterrors({ ...createErrors, siteId: "Destination site is required" });

      return false;
    }
    if (name === "") {
      seterrors({ ...createErrors, name: "Destination name is required" });

      return false;
    }
    if (topPriority === 0) {
      seterrors({ ...createErrors, topPriority: "Please provide a priority number."});

      return false;
    }

    return true;
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (handleValidation()) {
      if (isLoading) return;
      try {
        setisLoading(true);
        let res = await JSON_API["addSiteDestination"](city);

        if (res.isSuccess) {
          seterrors(createErrors);
          setcity(initialValues);
          toast.success("Site Destination Added Successfully!")
        } else {
          apiCallError(res?.error?.message, setError);
        }

        setisLoading(false);
      } catch (error) {
        setisLoading(false);
        apiCallError(error.message, setError);
      }
    }
  };

  return (
    <>
      {isLoading && <Loader />}
      <form
        className=""
        onSubmit={(e) => handleSubmit(e)}
        encType="multipart/form-data"
      >
        <div component="div" className="TabsAnimation appear-done enter-done">
          <div className="main-card mb-3 card">
            <div className="card-body">
              <div className="card-title">Add Site Destination</div>
              <div className="row">
                <div className="col-md-6">
                  <div className="mb-3">
                    <label htmlFor="siteId" className="form-label">
                      Activity Site*
                    </label>
                    <Select
                      name="siteId"
                      id="siteId"
                      classNamePrefix="select"
                      ref={inputRef}
                      options={activity_sites.map((item, idx) => ({
                        value: item?._id,
                        label: item?.siteName,
                      }))}
                      value={{
                        value: city?.siteId,
                        label: city?.siteName,
                      }}
                      onChange={(val) => {
                        setcity((prev) => {
                          return {
                            ...prev,
                            siteId: val?.value,
                            siteName: val?.label,
                          };
                        });
                      }}
                    />
                  </div>
                  {errors.siteId && (
                    <span className="errorMessageText text-danger">
                      {errors.siteId}
                    </span>
                  )}
                </div>
                <div className="col-md-6">
                  <div className="mb-3">
                    <label htmlFor="name" className="form-label">
                      Destination Name*
                    </label>
                    <input
                      name="name"
                      id="name"
                      type="text"
                      className="form-control"
                      autoComplete="false"
                      value={city.name}
                      onChange={(e) => handleChange(e)}
                    />
                  </div>
                  {errors.name && (
                    <span className="errorMessageText text-danger">
                      {errors.name}
                    </span>
                  )}
                </div>
                <div className="col-md-6">
                  <div className="mb-3">
                    <label htmlFor="topPriority" className="form-label">
                      Priority*
                    </label>
                    <input
                      name="topPriority"
                      id="topPriority"
                      type="number"
                      min={0}
                      className="form-control"
                      value={city.topPriority}
                      onChange={handleChange}
                    />
                  </div>
                  {errors.topPriority && (
                    <span className="errorMessageText text-danger">
                      {errors.topPriority}
                    </span>
                  )}
                </div>
              </div>

              <button
                className="mt-2 btn btn-primary ml-auto d-table"
                type="submit"
              >
                Submit
              </button>
            </div>
          </div>
        </div>
      </form>
    </>
  );
}

export default AddDestination;

import React, { useState, useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";

// Redux actions
import { getAllMerchants } from "../../../redux/adminSlice/merchantActionsSlice";
import { getAllMerchantAdditions } from "../../../redux/adminSlice/activitySlice";

// Components
import Table from "../../../Component/Table";
import Loader from "../../../Component/Loader";
import Modal from "../../../Component/Modal/Modal";

function CheckMerchantAdditions() {
  const dispatch = useDispatch();
  const [modal, setmodal] = useState(false);
  const [modalToggleView, setmodalToggleView] = useState(null);
  const [searchText, setsearchText] = useState("");
  const [isLoading, setisLoading] = useState(false);
  const [itemIndex, setitemIndex] = useState(null);
  const [manageProductData, setManageProductData] = useState(null)
  // Redux stores
  const { merchants } = useSelector((state) => state.adminMerchantActionSlice);
  const { merchant_additions } = useSelector(
    (state) => state.adminActivityActionSlice
  );

  useEffect(() => {
    dispatch(getAllMerchants());
    dispatch(getAllMerchantAdditions());
  }, []);

  useEffect(() => {
    let filterData = merchant_additions?.filter((item) => item?.isApproval === false && !item?.rejectedStatus)
    setManageProductData(filterData)
  }, [merchant_additions])



  const filterData = useMemo(() => {
    if (manageProductData?.length && searchText != "") {
      let updated = manageProductData.filter((item) =>
        JSON.stringify(item).toLowerCase().includes(searchText.toLowerCase())
      );
      return updated;
    }

    return manageProductData;
  }, [manageProductData, searchText]);


  const tableData = {
    columns: [
      {
        label: "SL",
        field: "sl",
        sort: "asc",
        width: 50,
      },
      {
        label: "Merchant Code",
        field: "merchant_code",
        sort: "asc",
        width: 100,
      },
      {
        label: "Activity Code",
        field: "activity_code",
        sort: "asc",
        width: 100,
      },
      {
        label: "Merchant Company Name",
        field: "merchant_name",
        sort: "asc",
        width: 100,
      },
      {
        label: "Activity Name",
        field: "activity_name",
        sort: "asc",
        width: 100,
      },
      {
        label: "Approval Status",
        field: "approval_status",
        sort: "asc",
        width: 100,
      },
      {
        label: "Remarks",
        field: "remarks",
        sort: "asc",
        width: 100,
      },
      {
        label: "Detail View",
        field: "detail_view",
        sort: "asc",
        width: 100,
      },
    ],
    rows:
      filterData?.length > 0
        ? filterData.map((item, idx) => {
          return {
            sl: idx + 1,
            merchant_code: (
              <div className="widget-content p-0">
                <div className="widget-content-wrapper">
                  <div className="widget-content-left flex2">
                    {item.productCode.substring(0, 5)}
                  </div>
                </div>
              </div>
            ),
            activity_code: (
              <div className="widget-content p-0">
                <div className="widget-content-wrapper">
                  <div className="widget-content-left flex2">
                    {item.productCode}
                  </div>
                </div>
              </div>
            ),
            merchant_name: (
              <div className="widget-content p-0">
                <div className="widget-content-wrapper">
                  <div className="widget-content-left flex2">
                    <div
                      className="zwidget-heading"
                      style={{ opaactivity: 1, textAlign: "center" }}
                    >
                      {merchants
                        .find((ele) => ele._id == item.addedByid)
                        ?.legalCompanyName?.charAt(0)
                        .toUpperCase() +
                        merchants
                          .find((ele) => ele._id == item.addedByid)
                          ?.legalCompanyName?.slice(1) 
                        }
                    </div>
                  </div>
                </div>
              </div>
            ),
            activity_name: (
              <div className="widget-content p-0">
                <div className="widget-content-wrapper">
                  <div className="widget-content-left flex2">
                    <div
                      className="zwidget-heading"
                      style={{ opaactivity: 1, textAlign: "center" }}
                    >
                      {item.activityTitle.substring(0, 40)}
                    </div>
                  </div>
                </div>
              </div>
            ),
            approval_status: (
              <div className="widget-content p-0" key={idx}>
                <div className="widget-content-wrapper">
                  <div className="widget-content-left flex2">
                    <div
                      className="zwidget-heading"
                      style={{ textAlign: "center" }}
                    >
                      {!item?.isApproval && !item?.rejectedStatus ? (
                        <div className="btn btn-danger">Not Approved</div>
                      ) : !item?.isApproval && item?.rejectedStatus ? (
                        <div className="btn btn-danger">Rejected</div>
                      ) : (
                        <div className="btn btn-success">Approved</div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            ),
            remarks: (
              <div className="widget-content p-0" key={idx}>
                <div className="widget-content-wrapper">
                  <div className="widget-content-left flex2">
                    <div
                      className="widget-heading"
                      style={{ opaactivity: 1, textAlign: "center" }}
                    >
                      <button
                        onClick={() => {
                          setitemIndex(idx);
                          setmodal(true);
                          setmodalToggleView(0);
                        }}
                        style={{
                          fontSize: "x-small",
                          textDecoration: "underline",
                          color: "blueviolet",
                          textDecorationColor: "blueviolet",
                        }}
                      >
                        Check Full Remarks
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            ),
            detail_view: (
              <div>
                <Link to={`/new-activity-details/${item._id}`} state={item}>
                  <button className="me-2 btn-icon btn-icon-only btn btn-outline-primary">
                    <i class="fa-regular fa fa-eye"></i>
                  </button>
                </Link>
              </div>
            ),
          };
        })
        : [],
  };

  const renderModalView = (index) => {
    switch (index) {
      case 0:
        return (
          <div className="modal-content" style={{ width: "400px" }}>
            <div className="modal-header">
              <h5 className="modal-title">FULL REMARKS</h5>
            </div>
            <div className="modal-body">
              <div className="col-md-12">
                <div className="mb-3">
                  {merchant_additions[itemIndex]?.remarks}
                </div>
              </div>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-link"
                onClick={() => {
                  setmodal(false);
                }}
              >
                CLOSE
              </button>
            </div>
          </div>
        );

      default:
        break;
    }
  };

  return (
    <>
      {isLoading && <Loader />}
      {modal && (
        <Modal
          modalToggleView={modalToggleView}
          renderModalView={renderModalView}
          closeModal={setmodal}
        />
      )}
      <Table
        striped
        tableTitle="Merchant Activity Additions"
        data={tableData}
        searching={true}
        onSearch={(val) => setsearchText(val)}
      // modal={modal}
      // setModal={setModal}
      // modalToggleView={modalToggleView}
      // renderModalView={renderModalView}
      />
    </>
  );
}

export default CheckMerchantAdditions;

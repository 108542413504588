// Hooks
import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

// Utils
import { apiCallError } from "../../../utils";

// Helpers
import { toast } from "react-toastify";

// API
import { JSON_API } from "../../../services/api/jsonApiCallWithInterceptor";

// Redux actions
import { getAllCookies } from "../../../redux/adminSlice/cookiesSlice";

// Components
import Table from "../../../Component/Table";
import Loader from "../../../Component/Loader";

const ManageCookies = () => {
  // function AddCookies() {
  const dispatch = useDispatch();
  const [error, setError] = useState("");
  const [isLoading, setisLoading] = useState(false);
  const [modal, setModal] = useState(false);
  const [modalToggleView, setmodalToggleView] = useState(null);
  const [deleteId, setdeleteId] = useState("");
  const navigate = useNavigate();
  // Redux stores
  const { cookies } = useSelector((state) => state.cookiesMarketingSlice);

  // Component did mount
  useEffect(() => {
    dispatch(getAllCookies());
  }, []);

  const openModal = () => {
    setModal(true);
  };

  const deletePress = async (id) => {
    try {
      let res = await JSON_API["adminDeleteCookiesMarketing"]({ id: id });
      // console.log("Res", res);

      if (res.isSuccess) {
        dispatch(getAllCookies());
        setModal(false);
        // apiCallSuccess(res.data);
        toast.success("Deleted Successfully");
      } else {
        apiCallError(res?.error?.message, setError);
      }
    } catch (error) {
      apiCallError(error.message, setError);
    }
  };

  const tableData = {
    columns: [
      {
        label: "SL",
        field: "sl",
        sort: "asc",
        width: 50,
      },
      //   {
      //     label: "Picture",
      //     field: "picture",
      //     sort: "asc",
      //     width: 100,
      //     height: 100,
      //   },
      {
        label: "Description",
        field: "title",
        sort: "asc",
        width: 100,
      },

      {
        label: "Action",
        field: "action",
        sort: "asc",
        width: 150,
      },
    ],
    rows:
      cookies?.length > 0
        ? cookies.map((item, idx) => {
            return {
              sl: idx + 1,
              //   picture: (
              //     <div className="widget-content p-0" key={idx}>
              //       <div className="widget-content-wrapper">
              //         <img src={`${item.image}`} alt="" className="img-fluid" />
              //       </div>
              //     </div>
              //   ),
              title: (
                <div className="widget-content p-0">
                  <div className="widget-content-wrapper">
                    <div className="widget-content-left flex2">
                      <div className="widget-heading" style={{ opacity: 1 }}    dangerouslySetInnerHTML={{ __html: item.description.slice(0,50) }}>
                        {/* {item.title} */}
                      </div>
                      {/* <div className="widget-subheading opacity-7">UI Designer</div> */}
                    </div>
                  </div>
                </div>
              ),

              action: (
                <div>
                  <button
                    className="me-2 btn-icon btn-icon-only btn btn-outline-danger"
                    onClick={() => {
                      openModal();
                      setmodalToggleView(0);
                      setdeleteId(cookies[idx]?._id);
                    }}
                  >
                    <i className="fa-solid fa-trash-can" />
                  </button>
                  <button
                    className="me-2 btn-icon btn-icon-only btn btn-outline-success"
                    onClick={() => {
                      // openModal();
                      // setmodalToggleView(0);
                      // setitemIndex(idx);
                      // setpressEdit({
                      //   ...pressEdit,
                      //   title: cookies[idx].title,
                      //   subTopic: cookies[idx].subTopic,
                      //   description: cookies[idx].description,
                      // });
                      // console.log("edit", editObject);
                      navigate(`/edit-cookies/${item._id}`);
                    }}
                  >
                    <i class="fa-regular fa-pen-to-square"></i>
                  </button>
                </div>
              ),
            };
          })
        : [],
  };

  const renderModalView = (index) => {
    switch (index) {
      case 0:
        return (
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">Delete</h5>
            </div>
            <div className="modal-body">
              <div className="deleteModalContainer p-5">
                <i className="ri-close-circle-line deleteIcon"></i>
                <h3>Are You Sure?</h3>
                <span>Do you want to delete this?</span>
                <div className="deleteModalNote">
                  <span>*Note : This data will be permanently deleted</span>
                </div>
              </div>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-link"
                onClick={() => {
                  setModal(false);
                }}
              >
                Cancel
              </button>
              <button
                type="button"
                className="btn btn-primary"
                onClick={() => {
                  deletePress(deleteId);
                }}
              >
                Delete
              </button>
            </div>
          </div>
        );
      default:
        return null;
    }
  };

  return (
    <>
      {isLoading && <Loader />}
      <Table
        striped
        tableTitle="All Cookies Marketing"
        data={tableData}
        modal={modal}
        setModal={setModal}
        modalToggleView={modalToggleView}
        renderModalView={renderModalView}
      />
    </>
  );
  //   }
};

export default ManageCookies;

import { useState, useEffect, useRef } from "react";
import { toast } from "react-toastify";
import Loader from "../../../Component/Loader";
import { getAllContacts } from "../../../redux/adminSlice/contactsSlice";
import { useDispatch, useSelector } from "react-redux";
import { fileUpload } from "../../../services/api/apiHelpers";
import { JSON_API } from "../../../services/api/jsonApiCallWithInterceptor";
import { capitalizeFirstLetter } from "../../../utils";
import Select from "react-select";

const INITIAL_IDS = { topicId: "", questionId: "" };
const INITIAL_SUB_QUESTIONS = { subDesc: "", subItem: [{ item: "" }] };

function AddSubq() {
  const [ids, setIds] = useState({ ...INITIAL_IDS });
  const [subQuestions, setSubQuestions] = useState(
    JSON.parse(JSON.stringify(INITIAL_SUB_QUESTIONS))
  );
  const [uploadedImg, setUploadedImg] = useState("");
  const [imgLoading, setImgLoading] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();
  const imageRef = useRef();
  const { contacts } = useSelector((state) => state.contactsSlice);
  console.log("contact", contacts);

  useEffect(() => {
    dispatch(getAllContacts());
  }, [dispatch]);

  const uploadImg = async (picture) => {
    let imgData = new FormData();
    imgData.append("image", picture);
    try {
      setImgLoading(true);
      let imgRes = await fileUpload("/admin/aboutUsImage", "POST", imgData);
      if (imgRes && imgRes.url) {
        setImgLoading(false);
        return imgRes;
      } else {
        setImgLoading(false);
        return null;
      }
    } catch (error) {
      setImgLoading(false);
      return null;
    }
  };

  const submitHandler = async (e) => {
    e.preventDefault();
    if (isLoading) return;

    setIsLoading(true);

    try {
      let res = await JSON_API["createSubQuestion"]({
        ...ids,
        subQuestion: {
          subDesc: subQuestions.subDesc,
          subItem: [...subQuestions.subItem.map((ele) => ele.item)],
        },
        image: uploadedImg,
      });

      if (res.isSuccess) {
        setIds(INITIAL_IDS);
        setSubQuestions(INITIAL_SUB_QUESTIONS);
        setUploadedImg("");
        imageRef.current.value = "";
        toast.success(res?.message || "Sub question added successfully");
      } else {
        toast.error(res?.message || "Something went wrong ");
      }
    } catch (error) {}
    setIsLoading(false);
  };

  const changeHandler = async (e, i) => {
    if (e.target.name === "image") {
      if (e.target.files.length === 0) return;
      if (imgLoading) return;

      const i = Array.from(e.target.files)[0];
      const img = await uploadImg(i);
      if (img?.url !== "") {
        setUploadedImg(img.url);
      } else {
        console.log("ERROR", img);
        return toast.error("Error occurred while uploading image, Try Again!");
      }

      return;
    }

    if (e.target.name === "questionId") {
      const item = contacts?.find((item) => item?._id === e.target.value);
      let update = {
        topicId: item?.topicId,
        questionId: item?._id,
      };
      setIds(update);
      return;
    }

    let input;

    if (e.target.name === "subItem") {
      input = e.target.value;
    } else {
      input = capitalizeFirstLetter(e);
    }

    if (e.target.name === "subItem") {
      setSubQuestions((prev) => {
        let update = JSON.parse(JSON.stringify(prev));
        console.log(update.subItem[i]);
        update.subItem[i].item = input;
        return { ...update };
      });

      return;
    }

    setSubQuestions((prev) => {
      let update = JSON.parse(JSON.stringify(prev));
      update[e.target.name] = input;
      return { ...update };
    });
  };

  return (
    <>
      {isLoading && <Loader />}
      <form className="" onSubmit={submitHandler}>
        <div component="div" className="TabsAnimation appear-done enter-done">
          <div className="main-card mb-3 card">
            <div
              className="card-body text-primary font-weight-bold"
              style={{ fontSize: "16px" }}
            >
              Create Sub-Question
            </div>
            <div className="col-md-12">
              <div className="mb-3">
                <label
                  htmlFor="countryId"
                  className="form-label font-weight-bold"
                >
                  Questions
                </label>
                {/* <select
                  name="questionId"
                  id="countryId"
                  className="form-control form-select"
                  onChange={changeHandler}
                  value={ids.questionId}
                  required={true}
                >
                  <option value="" disabled={true} selected={true}>
                    Select a question
                  </option>
                  {contacts?.map((item, i) => {
                    return (
                      <option value={item?._id} key={i}>
                        {item?.questions}
                      </option>
                    );
                  })}
                </select> */}
                <Select
                  name="questionId"
                  id="countryId"
                  classNamePrefix="select"
                  // ref={inputRef}
                  options={contacts.map((item, idx) => ({
                    value: item?._id,
                    label: item?.questions,
                  }))}
                  onChange={(val) => {
                    const item = contacts?.find(
                      (item) => item?._id === val?.value
                    );
                    let update = {
                      topicId: item?.topicId,
                      questionId: item?._id,
                    };
                    setIds(update);
                  }}
                />
              </div>
            </div>
            <div className="col-md-12">
              <div className="mb-3">
                <label className="form-label font-weight-bold">
                  Sub Question
                </label>
                <input
                  name="subDesc"
                  id="Questions"
                  type="text"
                  className="form-control"
                  autoComplete="false"
                  placeholder="Enter sub question"
                  required={true}
                  value={subQuestions.subDesc}
                  onChange={changeHandler}
                />
              </div>
            </div>
            {subQuestions?.subItem?.map((item, i) => (
              <>
                <div className="col-md-12">
                  <label
                    className="form-label font-weight-bold"
                    htmlFor="Description"
                  >
                    Description
                  </label>
                  <div className="input-group mb-3">
                    <input
                      name="subItem"
                      id="Description"
                      type="text"
                      className="form-control"
                      aria-describedby="button-addon2"
                      autoComplete="false"
                      placeholder="Enter description"
                      value={subQuestions.subItem[i].item}
                      onChange={(e) => changeHandler(e, i)}
                    />
                    {i !== 0 && (
                      <button
                        className="btn btn-outline-secondary"
                        type="button"
                        id="button-addon2"
                        onClick={() => {
                          setSubQuestions((prev) => {
                            let update = JSON.parse(JSON.stringify(prev));
                            update.subItem.splice(i, 1);
                            return { ...update };
                          });
                        }}
                      >
                        <i class="fa-solid fa-minus"></i>
                      </button>
                    )}
                    {subQuestions?.subItem?.length - 1 === i && (
                      <button
                        className="btn btn-outline-secondary"
                        type="button"
                        id="button-addon2"
                        onClick={() => {
                          setSubQuestions((prev) => {
                            let update = JSON.parse(JSON.stringify(prev));
                            update.subItem.push({ item: "" });
                            return { ...update };
                          });
                        }}
                      >
                        <i class="fa-solid fa-plus"></i>
                      </button>
                    )}
                  </div>
                </div>
              </>
            ))}
            <div className="col-md-12">
              <div className="mb-3">
                <label className="form-label font-weight-bold" htmlFor="image">
                  Upload Image
                </label>

                <input
                  name="image"
                  type="file"
                  onChange={changeHandler}
                  ref={imageRef}
                  className="form-control"
                  id="image"
                  aria-describedby="inputGroupFileAddon04"
                  aria-label="Upload"
                  accept="image/*"
                />
                <p className="text-primary mt-2">
                  {imgLoading && "Uploading please wait..."}
                </p>
                {uploadedImg !== "" && (
                  <img
                    src={uploadedImg}
                    alt=""
                    height={"100px"}
                    width={"100px"}
                  />
                )}
              </div>
            </div>

            <button
              className="mt-2 btn btn-primary ml-auto d-table font-weight-bold"
              type="submit"
              style={{ margin: "5px", fontSize: "14px" }}
            >
              Submit
            </button>
          </div>
        </div>
      </form>
    </>
  );
}

export default AddSubq;

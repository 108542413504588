import React, { useState, useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";

import { toast } from "react-toastify";
import { apiCallError } from "../../../utils/common-utils";

import Table from "../../../Component/Table";
import { requestData } from "../../../services/api/apiHelpers";
import SelectInput from "../../../Component/SelectInput";
import "./stuff.css";
import { getAllMerchants } from "../../../redux/adminSlice/merchantActionsSlice";
const ManageAdditionalStuff = () => {
  const dispatch = useDispatch();
  const [searchText, setsearchText] = useState("");
  const [error, setError] = useState("");
  const [isLoading, setisLoading] = useState(false);
  const { merchants } = useSelector((state) => state.adminMerchantActionSlice);

  const [flag, setFlag] = useState(false);
  const [stuffId, setStuffId] = useState("");
  const [addStuff, setaddStuff] = useState({
    stuffName: "",
    stuffprice: "",
    addedBy: "Merchant",
    addedById: "",
  });

  const setStuff = (item) => {
    setStuffId(item?._id);
    setFlag(true);
    setaddStuff((prev) => ({
      ...prev,
      stuffName: item?.stuffName,
      stuffprice: item?.stuffprice,
      addedBy: "Merchant",
      addedById: item?.addedById,
    }));
  };

  const [selectedMerchant, setSelectedMerchaant] = useState("");

  const [getallStuff, setgetallStuff] = useState("");
  const handlechangeStuff = (event) => {
    setaddStuff({ ...addStuff, [event.target.name]: event.target.value });
  };
 
  useEffect(() => {
    dispatch(getAllMerchants());
  }, []);

  const submitStuff = async (e) => {
    e.preventDefault();
    if (flag) {
      try {
        setisLoading(true);
        let res = await requestData(`/merchant/stuff-edit/${stuffId}`, "PUT", addStuff);
        if (res.status) {
          setisLoading(false);
          toast.success("Updated Stuff successfully");
          
          setaddStuff({
            stuffName: "",
            stuffprice: "",
            addedBy: "Merchant",
            addedById: "",
          })
          setFlag(false)
          setStuffId("")
          getAllStuff(addStuff?.addedById);
        } else {
          setisLoading(false);
          apiCallError(res?.error?.message, setError);
        }
      } catch (error) {
        setisLoading(false);
        apiCallError(error.message, setError);
      }
    } else {
      try {
        setisLoading(true);
        let res = await requestData(`/merchant/create-stuff`, "POST", addStuff);
        if (res.status) {
          setisLoading(false);
          toast.success("Added Stuff successfully");
          setaddStuff({
            stuffName: "",
            stuffprice: "",
            addedBy: "Merchant",
            addedById: "",
          })
        } else {
          setisLoading(false);
          apiCallError(res?.error?.message, setError);
        }
      } catch (error) {
        setisLoading(false);
        apiCallError(error.message, setError);
      }
    }
  };

  const getAllStuff = async (merchantId) => {
    try {
      setisLoading(true);
      let res = await requestData(`/merchant/getAll-stuff`, "POST", {
        addedById: merchantId,
      });
      if (res.status) {
        setisLoading(false);
        setgetallStuff(res.data);
      } else {
        setisLoading(false);
        apiCallError(res?.error?.message, setError);
      }
    } catch (error) {
      setisLoading(false);
      apiCallError(error.message, setError);
    }
  };
  const handleDelete = async (id) => {
    try {
      setisLoading(true);
      let res = await requestData(`/merchant/delete-stuff`, "POST", { id });
      if (res.status) {
        setisLoading(false);
        toast.success("deleted successfully");
      } else {
        setisLoading(false);
        apiCallError(res?.error?.message, setError);
      }
    } catch (error) {
      setisLoading(false);
      apiCallError(error.message, setError);
    }
  };
  const filterRows = () => {
    if (searchText !== "") {
      return getallStuff?.filter((item) =>
        JSON.stringify(item).toLowerCase().includes(searchText.toLowerCase())
      );
    }
    return getallStuff;
  };
  const tableData = useMemo(() => {
    return {
      columns: [
        {
          label: "SL",
          field: "sl",
          sort: "asc",
          width: 270,
        },
        {
          label: "stuff Name",
          field: "Stuff_name",
          sort: "asc",
          width: 150,
        },
        {
          label: "Stuff Price",
          field: "stuff_price",
          sort: "asc",
          width: 150,
        },
        {
          label: "Action",
          field: "action",
          sort: "asc",
          width: 150,
        },
      ],
      rows:
        filterRows()?.length > 0
          ? filterRows()?.map((item, idx) => {
              return {
                sl: idx + 1,

                Stuff_name: (
                  <div className="widget-content p-0">
                    <div className="widget-content-wrapper">
                      <div
                        className="widget-content-left flex2"
                        style={{ textAlign: "center" }}
                      >
                        {item.stuffName}
                      </div>
                    </div>
                  </div>
                ),
                stuff_price: (
                  <div className="widget-content p-0">
                    <div className="widget-content-wrapper">
                      <div className="widget-content-left flex2">
                        <div
                          className="widget-heading"
                          style={{ textAlign: "center" }}
                        >
                          {item?.stuffprice}
                        </div>
                      </div>
                    </div>
                  </div>
                ),
                action: (
                  <div>
                    <button
                      className="me-2 btn-icon btn-icon-only btn btn-outline-success"
                      onClick={() => {
                        setStuff(item);
                      }}
                    >
                      <i class="fa-regular fa-pen-to-square"></i>
                    </button>
                    <button
                      className="me-2 btn-icon btn-icon-only btn btn-outline-danger"
                      onClick={(e) => handleDelete(item?._id)}
                    >
                      <i className="fa-solid fa-trash-can" />
                    </button>
                  </div>
                ),
              };
            })
          : [],
    };
  }, [searchText, getallStuff]);

  return (
    <>
      <div className="main-card mb-3 card table-hover kmnn">
        <h2> {stuffId ? "Update" : "Add"} Your Stuff for Products</h2>
        <div>
          <div className="row addstuf">
            <SelectInput
              className="col-md-12"
              label="Assign To Merchant"
              options={merchants}
              labelKey={["firstName", "lastName", "marchentCode"]}
              valueKey="_id"
              value={addStuff.addedById}
              onChange={(val) => {
                setaddStuff((prev) => ({ ...prev, addedById: val._id }));
              }}
            />
            <div className="col-md-4">
              <div className="mb-3">
                <label
                  htmlFor="specialOfferName"
                  className="form-label"
                  style={{ fontWeight: "bold" }}
                >
                  Stuff Name{" "}
                  <span
                    style={{
                      color: "red",
                      fontSize: "23px",
                      fontWeight: "bold",
                    }}
                  >
                    {" "}
                    *
                  </span>
                </label>
                <input
                  type="text"
                  name="stuffName"
                  id="stuffName"
                  className="form-control"
                  autoComplete="false"
                  value={addStuff?.stuffName}
                  onChange={handlechangeStuff}
                />
              </div>
            </div>
            <div className="col-md-4">
              <div className="mb-3">
                <label
                  htmlFor="specialOfferName"
                  className="form-label"
                  style={{ fontWeight: "bold" }}
                  onChange={handlechangeStuff}
                >
                  Stuff Price{" "}
                  <span
                    style={{
                      color: "red",
                      fontSize: "23px",
                      fontWeight: "bold",
                    }}
                  >
                    {" "}
                    *
                  </span>
                </label>
                <input
                  type="number"
                  name="stuffprice"
                  id="stuffprice"
                  className="form-control"
                  autoComplete="false"
                  value={addStuff?.stuffprice}
                  onChange={handlechangeStuff}
                />
              </div>
            </div>

            <div className="">
              <button
                type="button"
                className="btn btn-success"
                onClick={submitStuff}
              >
                {stuffId ? "Update" : "Add"} Stuff
              </button>
            </div>
          </div>
        </div>
      </div>

      <div className="card">
        <div className="card-body">
          <SelectInput
            className="col-md-12"
            label="Select to view Merchant Stuffs"
            options={merchants}
            labelKey={["firstName", "lastName", "marchentCode"]}
            valueKey="_id"
            value={selectedMerchant}
            onChange={(val) => {
              setSelectedMerchaant(val._id);
              getAllStuff(val._id);
            }}
          />
          <Table
            striped
            data={tableData}
            onSearch={(val) => setsearchText(val)}
            tabflag={true}
          />
        </div>
      </div>
    </>
  );
};

export default ManageAdditionalStuff;

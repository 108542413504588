// Hooks
import React, { useState, useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

// Utils
import { apiCallSuccess, apiCallError } from "../../../utils/common-utils";

// API
import { JSON_API } from "../../../services/api/jsonApiCallWithInterceptor";

// Redux actions
import { getAllcountres } from "../../../redux/adminSlice/countrySlice";

// Components
import Table from "../../../Component/Table";
import Loader from "../../../Component/Loader";
import { toast } from "react-toastify";

const ManageCountry = () => {
  // function AddCountry() {
  const dispatch = useDispatch();
  const [searchText, setsearchText] = useState("");
  const [error, setError] = useState("");
  const [modal, setModal] = useState(false);
  const [modalToggleView, setmodalToggleView] = useState(null);
  const [deleteId, setdeleteId] = useState("");
  const [isLoading, setisLoading] = useState(false);
  const navigate = useNavigate();
  // Redux stores
  const { countres } = useSelector((state) => state.countrySlice);
  console.log("countres", countres);

  let userdata  = localStorage.getItem('userdata');
  userdata  = JSON.parse(userdata);
  let assignAccess = userdata?.assignAccess;
  let userType     = userdata?.userType;

  // Component did mount
  useEffect(() => {
    dispatch(getAllcountres());
  }, []);

  const openModal = () => {
    setModal(true);
  };

  const deleteCountry = async (id) => {
    try {
      setisLoading(true);
      let res = await JSON_API["deleteCountry"]({ id: id });
      // console.log("Res", res);

      if (res.isSuccess) {
        dispatch(getAllcountres());
        setModal(false);
        // apiCallSuccess(res.data);
        toast.success("Deleted successfully.");
      } else {
        apiCallError(res?.error?.message, setError);
      }
    } catch (error) {
      apiCallError(error.message, setError);
    }
    setisLoading(false);
  };

  const filterData = () => {
    if (searchText !== "") {
      return countres.filter((item) =>
        JSON.stringify(item).toLowerCase().includes(searchText.toLowerCase())
      );
    }

    return countres;
  };

  const tableData = useMemo(() => {
    return {
      columns: [
        {
          label: "SL",
          field: "sl",
          sort: "asc",
          width: 270,
        },
        {
          label: "Country Name",
          field: "name",
          sort: "asc",
          width: 150,
        },
        {
          label: "Image",
          field: "image",
          width: 150,
        },
        {
          label: "Action",
          field: "action",
          sort: "asc",
          width: 150,
        },
      ],
      rows:
        filterData()?.length > 0
          ? filterData()?.map((item, idx) => {
              return {
                sl: idx + 1,
                name: (
                  <div className="widget-content p-0">
                    <div className="widget-content-wrapper">
                      <div className="widget-content-left flex2">
                        <div
                          className="zwidget-heading"
                          style={{ opacity: 1, textAlign: "center" }}
                        >
                          {item?.name}
                        </div>
                        {/* <div className="widget-subheading opacity-7">UI Designer</div> */}
                      </div>
                    </div>
                  </div>
                ),
                image: (
                  <div
                    className="widget-content p-0"
                    key={idx}
                    style={{ textAlign: "center" }}
                  >
                    <div className="widget-content-wrapper">
                      <img
                        src={`${item?.image}`}
                        alt=""
                        style={{
                          height: "70px",
                          width: "120px",
                          marginLeft: "auto",
                          marginRight: "auto",
                        }}
                        className="img-fluid"
                      />
                    </div>
                  </div>
                ),
                action: (
                  <div>
                    {(userType == "admin" || (userType == "subadmin" && assignAccess.includes('delete'))) && (
                    <button
                      className="me-2 btn-icon btn-icon-only btn btn-outline-danger"
                      onClick={() => {
                        openModal();
                        setmodalToggleView(0);
                        // console.log("Delete id", countres[idx]._id);
                        setdeleteId(countres[idx]._id);
                      }}
                    >
                      <i className="fa-solid fa-trash-can" />
                    </button>
                    )}

                    {(userType == "admin" || (userType == "subadmin" && assignAccess.includes('edit'))) && (
                    <button
                      className="me-2 btn-icon btn-icon-only btn btn-outline-success"
                      onClick={() => {
                        // openModal();
                        navigate(`/edit-country/${item?._id}`);
                      }}
                    >
                      <i class="fa-regular fa-pen-to-square"></i>
                    </button>
                    )}
                  </div>
                ),
              };
            })
          : [],
    };
  }, [countres, searchText]);

  const renderModalView = (index) => {
    switch (index) {
      case 0:
        return (
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">Delete Country</h5>
            </div>
            <div className="modal-body">
              <div className="deleteModalContainer p-5">
                <i className="ri-close-circle-line deleteIcon"></i>
                <h3>Are You Sure?</h3>
                <span>Do You Want To Delete This Information?</span>
                <div className="deleteModalNote">
                  <span>*Note : This data will be permanently deleted</span>
                </div>
              </div>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-link"
                onClick={() => {
                  setModal(false);
                }}
              >
                Cancel
              </button>
              <button
                type="button"
                className="btn btn-primary"
                onClick={() => {
                  deleteCountry(deleteId);
                }}
              >
                Delete
              </button>
            </div>
          </div>
        );
      default:
        return null;
    }
  };

  return (
    <>
      {isLoading && <Loader />}
      <Table
        striped
        tableTitle="All Countries"
        data={tableData}
        modal={modal}
        setModal={setModal}
        modalToggleView={modalToggleView}
        renderModalView={renderModalView}
        onSearch={(val) => setsearchText(val)}
      />
    </>
  );
  //   }
};

export default ManageCountry;

import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

// Helpers
import { toast } from "react-toastify";

// Utils
import { getOnlyFullDate } from "../../../utils";
import moment from "moment";

// API
import { requestData } from "../../../services/api/apiHelpers";

// Redux actions
import { getAllMerchants } from "../../../redux/adminSlice/merchantActionsSlice";
import { getAllCategories } from "../../../redux/adminSlice/categorySlice";
import {
  getAllActivityTypes,
  getAllActivitySites,
  getAllSitesForACity,
} from "../../../redux/adminSlice/activitySlice";
import { getAllcountres } from "../../../redux/adminSlice/countrySlice";

// Components
import Modal from "../../../Component/Modal/Modal";
import Loader from "../../../Component/Loader";
import Select from "react-select";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";
import TextInput from "../../../Component/TextInput";
import FileSelect from "../../../Component/FileSelect";
import useActivityMethods from "../../../Hooks/useActivityMethods";
import useCountries from "../../../Hooks/useCountries";
import ShowEditProduct from "../../../modals/ShowEditProduct";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";

let currencies = [];
for (const [key, value] of Object.entries(
  require("../../../constants/currencies.json")
)) {
  currencies.push(value);
}
const personType = [
  { label: "Adult", value: "adult" },
  { label: "Children", value: "children" },
];
const duration_units = ["hours", "days", "minutes"];

function ProductChangeDetails() {
  const params = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [isLoading, setisLoading] = useState(false);
  const [modal, setmodal] = useState(false);
  const [modalToggleView, setmodalToggleView] = useState(0);
  const [citiesForCountry, setcitiesForCountry] = useState([]);
  const [siteDestinations, setsiteDestinations] = useState([]);
  const { languages } = useSelector((state) => state.languageSlice);
  const [tourActivityData, settourActivityData] = useState([
    {
      term: "",
      description: "",
    },
  ]);
  const [experienceDetails, setExperienceDetails] = useState([
    {
      title: "Highlights",
      desc: [""],
    },
    {
      title: "Full description",
      desc: [""],
    },
    {
      title: "Includes",
      desc: [""],
    },
    {
      title: "Not suitable for",
      desc: [""],
    },
  ]);
  const [meetingPointData, setmeetingPointData] = useState([
    {
      desc: "",
      link: "",
    },
  ]);
  const [importentInfo, setImportentInfo] = useState([
    {
      title: "",
      description: [""],
    },
  ]);
  const [tourCoordinates, settourCoordinates] = useState([
    {
      location: "",
      lat: "",
      lng: "",
    },
  ]);
  const [participantType, setparticipantType] = useState([
    {
      pertype: "",
      age: "",
      price: 0,
      frontRowSeatPrice: 0,
    },
  ]);
  const [importantQuestion, setImportantQuestion] = useState([
    {
      question: "",
      answer: "",
    },
  ]);

  const [thirdPartyReviewrating, setThirdPartyReviewRating] = useState([
    {
      travelAdvisorname: "",
      onlineTravelAdvisorId: undefined,
      NumberOfStar: null,
      noOfView: null
    }
  ])
  const [travelAgenciesData, setTravelAgenciesData] = useState([])
  const [filterAgencyData, setFilterAgencyData] = useState([])

  const [activityStatus, setactivityStatus] = useState({ remarks: "" });
  const [errors, seterrors] = useState({ remarks: "" });
  const [tempData, settempData] = useState(null);
  const [selectedItems, setSelectedItems] = useState([]);
  const [updateModel, setupdateModal] = useState(false);
  const [iamFrom, setiamFrom] = useState("");

  // Redux stores
  const { merchants } = useSelector((state) => state.adminMerchantActionSlice);
  const { categories } = useSelector((state) => state.adminCategoryActionSlice);
  const { merchant_additions, activity_types, sites_of_a_city } = useSelector(
    (state) => state.adminActivityActionSlice
  );
  const { countres } = useSelector((state) => state.countrySlice);
  const { profile_data } = useSelector((state) => state.adminProfileSlice);

  // Data to edit
  const activity = merchant_additions?.find((item) => item?._id === params.id);
  const [thirdPartyData, setThirdPartyData] = useState([])
  const [currencyName, setCurrencyName] = useState(activity?.currency?.name);
  const { fetchTourModulesAgainstMerchant, loadings } =
    useActivityMethods();
  const { cities, fetchCities, loading } = useCountries();

  const filterTravelAgency = () => {
    const removeTravelAgency = travelAgenciesData?.filter((item) => {
      return !thirdPartyReviewrating.some((travel) => item._id === travel.onlineTravelAdvisorId);
    });
    setFilterAgencyData(removeTravelAgency);
  }

  const getAllTravelAgencies = async () => {
    try {
      setisLoading(true)
      let res = await requestData("/admin/view-online-Travel-advisor", "GET");
      if (res) {
        setTravelAgenciesData(res.data)
        setisLoading(false)
      }
    } catch (error) {
      toast.error(error.message)
      setisLoading(false)
    }
  }
  const getAllThirdParty = async () => {
    try {
      setisLoading(true)
      let res = await requestData("/admin/viewThirdPerty", "GET");
      if (res) {
        setThirdPartyData(res.data)
        setisLoading(false)
      }
    } catch (error) {
      toast.error(error.message)
      setisLoading(false)
    }
  }

  useEffect(() => {
    filterTravelAgency();
  }, [travelAgenciesData]);

  useEffect(() => {
    getAllTravelAgencies();
    getAllThirdParty();
    dispatch(getAllMerchants());
    dispatch(getAllActivityTypes());
    dispatch(getAllCategories());
    dispatch(getAllcountres());
    getCitiesByCountry(activity?.countryId);
    dispatch(getAllActivitySites());
    dispatch(getAllSitesForACity(activity?.cityId));
    getSiteDestinations(activity?.activitySiteId);
    settempData(activity?.tempActivityData);
    setparticipantType(
      activity?.participentType?.length > 0
        ? activity?.participentType?.map((ele) => {
          return {
            pertype: ele?.pertype,
            age: ele?.age,
            price: ele?.price,
            frontRowSeatPrice: ele?.frontRowSeatPrice,
          };
        })
        : (state) => {
          return state;
        }
    );
    setExperienceDetails(
      activity?.information?.map((ele) => {
        return {
          title: ele?.title,
          desc: ele?.desc,
        };
      })
    );
    setImportentInfo(
      activity?.importentInfo?.map((ele) => {
        return {
          title: ele?.title,
          description: ele?.description,
        };
      })
    );
    settourCoordinates(
      activity?.activityCoordinates?.map((ele) => {
        return {
          location: ele?.location,
          lat: ele?.lat,
          lng: ele?.lng,
        };
      })
    );
    settourActivityData(
      activity?.tourActivity?.map((item) => {
        return {
          term: item?.term,
          description: item?.description,
        };
      })
    );
    setmeetingPointData(
      activity?.meetingPoint?.map((ele) => {
        return {
          desc: ele?.desc,
          link: ele?.link,
        };
      })
    );
    if (
      activity?.importantQuestionAboutThisTour &&
      activity?.importantQuestionAboutThisTour.length > 0
    ) {
      setImportantQuestion(activity?.importantQuestionAboutThisTour);
    }
    setSelectedItems(activity?.TourAdditionalStuff);
    if (activity?.addedByid) getAllStuff(activity.addedByid);

    window.scrollTo({
      top: 100,
      left: 100,
      behavior: "smooth",
    });
  }, []);

  const [stuff, setstuff] = useState("");
  const handleCheckboxChange = (event, item) => {
    const { checked } = event.target;
    if (checked) {
      setSelectedItems([...selectedItems, item]);
    } else {
      setSelectedItems(
        selectedItems.filter((selectedItem) => selectedItem._id !== item._id)
      );
    }
  };

  useEffect(() => {
    fetchTourModulesAgainstMerchant({
      cityId: activity?.cityId,
      merchantId: profile_data?._id,
    });
    fetchCities(activity?.countryId);
  }, []);

  useEffect(() => {
    setisLoading(loading);
  }, [loading, loadings]);

  const handleChange = (event) => {
    setactivityStatus({
      ...activityStatus,
      [event.target.name]: event.target.value,
    });
  };

  const getCitiesByCountry = async (countryId) => {
    if (!countryId)
      return;
    try {
      let res = await requestData(
        `/admin/viewCountryAgainstCity/${countryId}`,
        "GET"
      );
      if (res.status) {
        setcitiesForCountry(res?.data);
      } else {
        toast.error(res?.error?.message);
      }
    } catch (error) {
      toast.error(error?.message);
    }
  };

  const getSiteDestinations = async (siteId) => {
    try {
      let res = await requestData(`/admin/view-destination/${siteId}`, "GET");

      if (res.status) {
        setsiteDestinations(res?.data);
      } else {
        toast.error(res?.error?.message);
      }
    } catch (error) {
      toast.error(error?.message);
    }
  };

  const openModal = () => {
    setmodal(true);
  };

  const approveSubmission = async (event) => {
    event.preventDefault();

    if (activityStatus.remarks === "") {
      seterrors({ ...errors, remarks: "A remark is required." });
    } else {
      try {
        setisLoading(true);
        let res = await requestData(
          `/admin/updateApprovalActivityReq/${params.id}`,
          "PUT",
          {
            activityDetailsId: `${params.id}`,
            ...activityStatus,
            rejectedStatus: false,
            updatedStatus: true,
            isApproval: true,
            visibleStatus: true,
            isEdited: false,
          }
        );

        if (res && res.status) {
          setactivityStatus({ remarks: "" });
          setisLoading(false);
          setmodal(false);
          navigate("/product-change-requests");
          toast.success("Product change successfully");
        } else {
          setisLoading(false);
          toast.error(res?.error?.message);
        }
      } catch (error) {
        toast.error(error.message);
      }
    }
  };

  const rejectSubmission = async (event) => {
    if (activityStatus.remarks === "") {
      seterrors({ ...errors, remarks: "A remark is required." });
    } else {
      try {
        setisLoading(true);
        let res = await requestData(
          `/admin/updateApprovalActivityReq/${params.id}`,
          "PUT",
          {
            activityDetailsId: `${params.id}`,
            ...activityStatus,
            rejectedStatus: true,
            updatedStatus: true,
            isApproval: false,
            visibleStatus: false,
          }
        );

        if (res && res.status) {
          setactivityStatus({ remarks: "" });
          setisLoading(false);
          setmodal(false);
          navigate("/check-merchant-reject-product");
        } else {
          setisLoading(false);
          toast.error(res?.error?.message);
        }
      } catch (error) {
        toast.error(error?.message);
      }
    }
  };

  const getAllStuff = async (merchantId) => {
    try {
      let res = await requestData(`/merchant/getAll-stuff`, "POST", {
        addedById: merchantId,
      });
      if (res && res?.status) {
        setstuff(res.data);
      } else toast.error(res?.error?.message);
    } catch (error) {
      toast.error(error?.message);
    }
  };

  const renderModalView = (index) => {
    switch (index) {
      case 0:
        return (
          <div className="modal-content" style={{ width: "400px" }}>
            <div className="modal-header">
              <h5 className="modal-title">APPROVAL REMARKS</h5>
            </div>
            <div className="modal-body">
              <div className="col-md-12">
                <div className="mb-3">
                  <label htmlFor="remarks" className="form-label">
                    Remarks*
                  </label>
                  <textarea
                    name="remarks"
                    id="remarks"
                    className="form-control"
                    autoComplete="false"
                    value={activityStatus.remarks}
                    onChange={(e) => handleChange(e)}
                  />
                </div>
                {errors.remarks && (
                  <span className="errorMessageText text-danger">
                    {errors.remarks}
                  </span>
                )}
              </div>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-link"
                onClick={() => {
                  setmodal(false);
                }}
              >
                CLOSE
              </button>
              <button
                type="submit"
                className="btn btn-link btn-primary"
                onClick={(e) => {
                  approveSubmission(e);
                }}
              >
                SUBMIT
              </button>
            </div>
          </div>
        );
      case 1:
        return (
          <div className="modal-content" style={{ width: "400px" }}>
            <div className="modal-header">
              <h5 className="modal-title">REJECTION REMARKS</h5>
            </div>
            <div className="modal-body">
              <div className="col-md-12">
                <div className="mb-3">
                  <label htmlFor="remarks" className="form-label">
                    Remarks*
                  </label>
                  <textarea
                    name="remarks"
                    id="remarks"
                    className="form-control"
                    autoComplete="false"
                    value={activityStatus.remarks}
                    onChange={(e) => handleChange(e)}
                  />
                </div>
                {errors.remarks && (
                  <span className="errorMessageText text-danger">
                    {errors.remarks}
                  </span>
                )}
              </div>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-link"
                onClick={() => {
                  setmodal(false);
                }}
              >
                CLOSE
              </button>
              <button
                type="submit"
                className="btn btn-link btn-warning"
                onClick={(e) => {
                  rejectSubmission(e);
                }}
              >
                REJECT
              </button>
            </div>
          </div>
        );
      default:
        break;
    }
  };

  return (
    <>
      {isLoading && <Loader />}
      {modal && (
        <Modal
          modalToggleView={modalToggleView}
          renderModalView={renderModalView}
          closeModal={setmodal}
        />
      )}
      <div component="div" className="TabsAnimation appear-done enter-done">
        <div className="main-card mb-3 card">
          <div className="card-body">
            <div className="card-title">Activity Details </div>
            <div className="row" style={{ margin: "0.1rem" }}>
              <div className="col-md-12">
                <Tabs>
                  <TabList>
                    <Tab>Tour Details</Tab>
                    <Tab>Participant Type*</Tab>
                    <Tab>Activity Details</Tab>
                    <Tab>Experience</Tab>
                    <Tab>Meeting Point</Tab>
                    <Tab>Important Info</Tab>
                    <Tab>Tour Places</Tab>
                    <Tab>Additional Stuff</Tab>
                    <Tab>Important To Know About This Tour</Tab>
                    <Tab>Third Party Revire Rating</Tab>
                  </TabList>

                  <TabPanel>
                    <div className="row" style={{ margin: "0.1rem" }}>
                      <div className="col-md-6">
                        <div className="mb-3">
                          <label htmlFor="addedByid" className="form-label">
                            Assigned To Merchant*
                          </label>
                          <select
                            disabled
                            name="addedByid"
                            id="addedByid"
                            className="form-control form-select"
                            value={activity?.addedByid}
                          >
                            <option value="" key={0}>
                              Select
                            </option>
                            {merchants?.map((item, idx) => (
                              <option value={item?._id} key={idx + 1}>
                                {item?.firstName?.charAt(0).toUpperCase() +
                                  item?.firstName?.slice(1) +
                                  " " +
                                  item?.lastName?.charAt(0).toUpperCase() +
                                  item?.lastName?.slice(1)}
                              </option>
                            ))}
                          </select>
                        </div>
                      </div>

                      <div className="col-md-6">
                        <label
                          htmlFor=""
                          className="form-label"
                          style={{
                            fontSize: "15px",
                          }}
                        >
                          Third Party Id
                        </label>
                        <select
                          name="thirdPartyId"
                          id="thirdPartyId"
                          className="form-control form-select"
                          value={activity.thirdPartyId}
                          disabled
                        >
                          <option value="" key={0}>
                            Select
                          </option>
                          {thirdPartyData?.map((item, idx) => {
                            return (
                              <>
                                <option
                                  key={idx + 1}
                                  value={`${item?._id}`}
                                >
                                  {item?.name}
                                </option>
                              </>
                            );
                          })}
                        </select>
                      </div>
                      <div className="col-md-6">
                        <div className="mb-3">
                          <label htmlFor="activityTitle" className="form-label">
                            Product Title*
                          </label>
                          <input
                            disabled
                            name="activityTitle"
                            id="activityTitle"
                            type="text"
                            className="form-control"
                            autoComplete="false"
                            value={activity?.activityTitle}
                          />
                        </div>
                        <div style={{ backgroundColor: "yellow" }}>
                          {activity?.activityTitle !==
                            tempData?.activityTitle ? (
                            <p>{tempData?.activityTitle}</p>
                          ) : (
                            ""
                          )}
                        </div>
                        {errors.activityTitle && (
                          <span className="errorMessageText text-danger">
                            {errors.activityTitle}
                          </span>
                        )}
                      </div>
                      <div className="col-md-6">
                        <div className="mb-3">
                          <label htmlFor="productCode" className="form-label">
                            Product Code*
                          </label>
                          <input
                            disabled
                            name="productCode"
                            id="productCode"
                            type="text"
                            className="form-control"
                            autoComplete="false"
                            value={activity?.productCode}
                          />
                        </div>
                      </div>

                      <div className="col-md-12">
                        <div className="mb-3">
                          <label htmlFor="description" className="form-label">
                            Description*
                          </label>
                          <p style={{ fontSize: "14px" }}>
                            This is the customer’s first introduction to your
                            activity. Describe your activity in 2 to 3 sentences
                            including all the main selling points of the
                            activity (This is not the main description so keep
                            it short and aim to attract attention)
                          </p>
                          <CKEditor
                            className="editor-container"
                            editor={ClassicEditor}
                            data={activity.description}
                          />
                        </div>
                        <div style={{ backgroundColor: "yellow" }}>
                          {activity?.description !== tempData?.description ? (
                            <p>{tempData?.description}</p>
                          ) : (
                            ""
                          )}
                        </div>
                        {errors.description && (
                          <span className="errorMessageText text-danger">
                            {errors.description}
                          </span>
                        )}
                      </div>
                      <div className="col-md-6">
                        <div className="mb-3">
                          <label htmlFor="categoryId" className="form-label">
                            Category*
                          </label>
                          <select
                            disabled
                            name="categoryId"
                            id="categoryId"
                            className="form-control form-select"
                            value={activity?.categoryId}
                            onChange={(e) => handleChange(e)}
                          >
                            <option value="" key={0}>
                              Select
                            </option>
                            {categories?.map((item, idx) => {
                              return (
                                <>
                                  <option key={idx + 1} value={`${item?._id}`}>
                                    {item?.categoryName}
                                  </option>
                                </>
                              );
                            })}
                          </select>
                        </div>
                        <div style={{ backgroundColor: "yellow" }}>
                          {activity?.categoryId !== tempData?.categoryId ? (
                            <p>{tempData?.categoryId}</p>
                          ) : (
                            ""
                          )}
                        </div>
                        {errors.categoryId && (
                          <span className="errorMessageText text-danger">
                            {errors.categoryId}
                          </span>
                        )}
                      </div>

                      <div className="col-md-6">
                        <div className="mb-3">
                          <label
                            htmlFor="activityTypesId"
                            className="form-label"
                          >
                            {/* Activity Type* */}
                            Top activities
                          </label>
                          <select
                            disabled
                            name="activityTypesId"
                            id="activityTypesId"
                            className="form-control form-select"
                            value={activity?.activityTypesId}
                            onChange={(e) => handleChange(e)}
                          >
                            <option value="" key={0}>
                              Select
                            </option>
                            {activity_types?.map((item, idx) => {
                              return (
                                <>
                                  <option key={idx + 1} value={`${item?._id}`}>
                                    {item?.name}
                                  </option>
                                </>
                              );
                            })}
                          </select>
                        </div>
                        <div style={{ backgroundColor: "yellow" }}>
                          {activity?.activityTypesId !==
                            tempData?.activityTypesId ? (
                            <>
                              <p>{tempData?.activitytypeName}</p>
                            </>
                          ) : (
                            ""
                          )}
                        </div>
                        {errors.activityTypesId && (
                          <span className="errorMessageText text-danger">
                            {errors.activityTypesId}
                          </span>
                        )}
                      </div>
                      <div className="col-md-6">
                        <div className="mb-3">
                          <label htmlFor="countryId" className="form-label">
                            Country*
                          </label>
                          <select
                            disabled
                            name="countryId"
                            id="countryId"
                            className="form-control form-select"
                            value={activity?.countryId}
                            onChange={(e) => {
                              handleChange(e);
                              fetchCities(activity?.countryId);
                            }}
                          >
                            <option value="" key={0}>
                              Select
                            </option>
                            {countres?.map((item, idx) => {
                              return (
                                <>
                                  <option key={idx + 1} value={`${item?._id}`}>
                                    {item?.name}
                                  </option>
                                </>
                              );
                            })}
                          </select>
                        </div>
                        <div style={{ backgroundColor: "yellow" }}>
                          {activity?.countryName !== tempData?.countryName ? (
                            <p>{tempData?.countryName}</p>
                          ) : (
                            ""
                          )}
                        </div>
                        {errors.countryId && (
                          <span className="errorMessageText text-danger">
                            {errors.countryId}
                          </span>
                        )}
                      </div>
                      <div className="col-md-6">
                        <div className="mb-3">
                          <label htmlFor="cityId" className="form-label">
                            City*
                          </label>
                          <select
                            disabled
                            name="cityId"
                            id="cityId"
                            className="form-control form-select"
                            value={activity?.cityId}
                            onChange={(e) => handleChange(e)}
                          >
                            <option value="" key={0}>
                              Select
                            </option>
                            {cities?.map((item, idx) => {
                              return (
                                <>
                                  <option key={idx + 1} value={`${item?._id}`}>
                                    {item?.cityName}
                                  </option>
                                </>
                              );
                            })}
                          </select>
                        </div>
                        <div style={{ backgroundColor: "yellow" }}>
                          {activity?.cityName !== tempData?.cityName ? (
                            <p>{tempData?.cityName}</p>
                          ) : (
                            ""
                          )}
                        </div>
                        {errors.cityId && (
                          <span className="errorMessageText text-danger">
                            {errors.cityId}
                          </span>
                        )}
                      </div>
                      <div className="col-md-6">
                        <div className="mb-3">
                          <label
                            htmlFor="activitySiteId"
                            className="form-label"
                          >
                            {/* Activity Site */}
                            Activity location: (Select the area where your
                            activity takes place or start from. If no suitable
                            selection is available contact us to add it to our
                            system)
                          </label>
                          <select
                            disabled
                            name="activitySiteId"
                            id="activitySiteId"
                            className="form-control form-select"
                            value={activity?.activitySiteId}
                            onChange={(e) => handleChange(e)}
                          >
                            <option value="" key={0}>
                              Select
                            </option>
                            {sites_of_a_city?.map((item, idx) => {
                              return (
                                <>
                                  <option key={idx + 1} value={`${item?._id}`}>
                                    {item?.siteName}
                                  </option>
                                </>
                              );
                            })}
                          </select>
                        </div>
                        <div style={{ backgroundColor: "yellow" }}>
                          {activity?.activitySiteName !==
                            tempData?.activitySiteName ? (
                            <p>{tempData?.activitySiteName}</p>
                          ) : (
                            ""
                          )}
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="mb-3">
                          <label htmlFor="destination" className="form-label">
                            {/* Destination */}
                            Activities main destination <br />
                            (If nothing suitable please contact us directly to
                            add a suitable option)
                          </label>
                          <select
                            disabled
                            name="destination"
                            id="destination"
                            className="form-control form-select"
                            value={activity?.destination}
                            onChange={(e) => handleChange(e)}
                          >
                            <option value="" key={0}>
                              Select
                            </option>
                            {siteDestinations?.map((item, idx) => {
                              return (
                                <>
                                  <option key={idx + 1} value={`${item?._id}`}>
                                    {item?.name}
                                  </option>
                                </>
                              );
                            })}
                          </select>
                        </div>
                        <div style={{ backgroundColor: "yellow" }}>
                          {activity?.destinationName !==
                            tempData?.destinationName ? (
                            <p>{tempData?.destinationName}</p>
                          ) : (
                            ""
                          )}
                        </div>
                      </div>
                      {/* Tour Module* */}
                      <div className="col-md-4">
                        <div className="mb-3">
                          <label htmlFor="tourModuleId" className="form-label">
                            {/* Tour Module* */}
                            Tour Activity/Module (Select the option that best
                            describe your type of activity or create your own
                            from the merchant sidebar)
                          </label>
                          <Select
                            isDisabled
                            name="tourModuleId"
                            id="tourModuleId"
                            classNamePrefix="select"
                            // options={tour_module_by_city?.map((item, idx) => ({
                            //   value: item?._id,
                            //   label: item?.name,
                            // }))}
                            // isMulti
                            value={{
                              value: activity?.tourModuleId,
                              label: activity?.tourModuleName,
                            }}
                          />
                        </div>
                        <div style={{ backgroundColor: "yellow" }}>
                          {activity?.tourModuleName !==
                            tempData?.tourModuleName ? (
                            <p>{tempData?.tourModuleName}</p>
                          ) : (
                            ""
                          )}
                        </div>
                        {errors.tourModuleId && (
                          <span className="errorMessageText text-danger">
                            {errors.tourModuleId}
                          </span>
                        )}
                      </div>
                      <div className="col-md-4 d-none">
                        <div className="mb-3">
                          <label
                            htmlFor="activityActualPrice"
                            className="form-label"
                          >
                            Activity Price*
                          </label>
                          <input
                            disabled
                            name="activityActualPrice"
                            id="activityActualPrice"
                            // placeholder="Your first name, e.g. Walter"
                            type="number"
                            min={"0"}
                            className="form-control"
                            autoComplete="false"
                            value={activity?.activityActualPrice}
                          />
                        </div>
                        <div style={{ backgroundColor: "yellow" }}>
                          {activity?.activityActualPrice !==
                            tempData?.activityActualPrice ? (
                            <p>{tempData?.activityActualPrice}</p>
                          ) : (
                            ""
                          )}
                        </div>
                        {errors.activityActualPrice && (
                          <span className="errorMessageText text-danger">
                            {errors.activityActualPrice}
                          </span>
                        )}
                      </div>

                      <div className="col-md-4">
                        <div className="mb-3">
                          <label htmlFor="languageId" className="form-label">
                            Tour Language  <span
                              style={{
                                fontSize: "20px",
                                fontWeight: "bold",
                                color: "red",
                              }}
                            >
                              {" "}
                              *
                            </span>
                          </label>
                          <select
                            name="languageId"
                            id="languageId"
                            className="form-control form-select"
                            value={activity?.languageId}
                            onChange={(e) => handleChange(e)}
                            disabled
                          >
                            <option value="" key={0}>
                              Select
                            </option>
                            {languages?.map((item, idx) => {
                              return (
                                <>
                                  <option key={idx + 1} value={`${item?._id}`}>
                                    {item?.name}
                                  </option>
                                </>
                              );
                            })}
                          </select>
                        </div>
                        {errors.sectionId && (
                          <span className="errorMessageText text-danger">
                            {errors.sectionId}
                          </span>
                        )}
                      </div>
                      <div className="col-md-4">
                        <div className="mb-3">
                          <label htmlFor="currency" className="form-label">
                            Currency*
                          </label>
                          <Select
                            isDisabled
                            name="currency"
                            id="currency"
                            classNamePrefix="select"
                            options={currencies?.map((item, idx) => ({
                              value: JSON.stringify({
                                name: item?.name,
                                abbreviation: item?.code,
                                symbol: item?.symbol_native,
                              }),
                              label: item?.name + " (" + item?.code + ")",
                            }))}
                            value={{
                              value: JSON.stringify(activity?.currency),
                              label: currencyName,
                            }}
                          />
                        </div>
                        <div style={{ backgroundColor: "yellow" }}>
                          {activity?.currency?.name !==
                            tempData?.currency?.name ? (
                            <p>{tempData?.currency?.name}</p>
                          ) : (
                            ""
                          )}
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="mb-3">
                          <label htmlFor="tourPerson" className="form-label">
                            Maximum Person For Activity*
                          </label>
                          <input
                            disabled
                            name="tourPerson"
                            id="tourPerson"
                            type="number"
                            min={"0"}
                            className="form-control"
                            autoComplete="false"
                            value={activity?.tourPerson}
                          />
                        </div>
                        <div style={{ backgroundColor: "yellow" }}>
                          {activity?.tourPerson !== tempData?.tourPerson ? (
                            <p>{tempData?.tourPerson}</p>
                          ) : (
                            ""
                          )}
                        </div>
                        {errors.tourPerson && (
                          <span className="errorMessageText text-danger">
                            {errors.tourPerson}
                          </span>
                        )}
                      </div>

                      {/* front Row Setting ? */}
                      <div className="col-md-4 d-none">
                        <div className="mb-3">
                          <label
                            htmlFor="frontRowSitting"
                            className="form-label"
                          >
                            Front Row Sitting{" "}
                          </label>
                          <Select
                            name="frontRowSitting"
                            id="frontRowSitting"
                            classNamePrefix="select"
                            isDisabled={true}
                            options={[
                              { value: true, label: "Yes" },
                              { value: false, label: "No" },
                            ]}
                            value={
                              activity?.frontRowSitting !== undefined
                                ? {
                                  value: activity?.frontRowSitting,
                                  label:
                                    activity?.frontRowSitting === true
                                      ? "Yes"
                                      : "No",
                                }
                                : { value: false, label: "Yes" }
                            }
                          // onChange={(val) => {
                          //   setactivity((prev) => ({
                          //     ...prev,
                          //     frontRowSitting: val?.value,
                          //   }));
                          // }}
                          />
                        </div>

                        <div style={{ backgroundColor: "yellow" }}>
                          {activity?.frontRowSitting !==
                            tempData?.frontRowSitting ? (
                            <p>{tempData?.frontRowSitting}</p>
                          ) : (
                            ""
                          )}
                        </div>
                        {errors.frontRowSitting && (
                          <span className="errorMessageText text-danger">
                            {errors.frontRowSitting}
                          </span>
                        )}
                      </div>
                      {/* Front Row Seats */}
                      {activity?.frontRowSitting && (
                        <div className="col-md-4">
                          <div className="mb-3">
                            <label
                              htmlFor="frontRowSeats"
                              className="form-label"
                            >
                              Front Row Seats{" "}
                              <span
                                style={{
                                  color: "red",
                                  fontSize: "23px",
                                  fontWeight: "bold",
                                }}
                              >
                                {" "}
                                *
                              </span>
                            </label>
                            <input
                              name="frontRowSeats"
                              id="frontRowSeats"
                              disabled
                              type="number"
                              min={"0"}
                              className="form-control"
                              autoComplete="false"
                              value={activity?.frontRowSeats}
                            // onChange={(e) =>
                            //   setactivity((prev) => ({
                            //     ...prev,
                            //     frontRowSeats: e.target.value,
                            //   }))
                            // }
                            />
                          </div>

                          <div style={{ backgroundColor: "yellow" }}>
                            {activity?.frontRowSeats !==
                              tempData?.frontRowSeats ? (
                              <p>{tempData?.frontRowSeats}</p>
                            ) : (
                              ""
                            )}
                          </div>
                          {errors.frontRowSeats && (
                            <span className="errorMessageText text-danger">
                              {errors.frontRowSeats}
                            </span>
                          )}
                        </div>
                      )}

                      <div className="col-md-6">
                        <div className="mb-3">
                          <label className="form-label" id="tourDate">
                            Activity Starts On*
                          </label>
                          <input
                            disabled
                            name="tourDate"
                            id="tourDate"
                            // placeholder="Your first name, e.g. Walter"
                            type="text"
                            className="form-control"
                            autoComplete="false"
                            value={getOnlyFullDate(activity?.startDate)}
                          />
                        </div>
                        <div style={{ backgroundColor: "yellow" }}>
                          {tempData?.startDate ? (
                            <p>
                              {moment(tempData?.startDate).format("YYYY-MM-DD")}
                            </p>
                          ) : (
                            ""
                          )}
                        </div>
                        {errors.startDate && (
                          <span className="errorMessageText text-danger">
                            {errors.startDate}
                          </span>
                        )}
                      </div>
                      <div className="col-md-6">
                        <div className="mb-3">
                          <label className="form-label">
                            Activity Ends On*
                          </label>
                          <input
                            disabled
                            name="tourDate"
                            id="tourDate"
                            type="text"
                            className="form-control"
                            autoComplete="false"
                            value={getOnlyFullDate(activity?.endDate)}
                          />
                        </div>
                        <div style={{ backgroundColor: "yellow" }}>
                          {tempData?.endDate ? (
                            <p>
                              {moment(tempData?.endDate).format("YYYY-MM-DD")}
                            </p>
                          ) : (
                            ""
                          )}
                        </div>
                        {errors.endDate && (
                          <span className="errorMessageText text-danger">
                            {errors.endDate}
                          </span>
                        )}
                      </div>
                      <div className="col-md-6">
                        <div>
                          <label
                            htmlFor="duration_value"
                            className="form-label"
                          >
                            Duration Time
                          </label>
                        </div>
                        <div className="" style={{ display: "flex" }}>
                          <div
                            className="col-md-6"
                            style={{ marginLeft: "-15px" }}
                          >
                            <div className="mb-3">
                              <input
                                disabled
                                type="number"
                                name="duration_value"
                                id="duration_value"
                                min={0}
                                className="form-control"
                                autoComplete="false"
                                value={activity?.duration_value}
                              />
                            </div>
                            <div style={{ backgroundColor: "yellow" }}>
                              {tempData?.tourDuration?.value ? (
                                <p>{tempData?.tourDuration?.value}</p>
                              ) : (
                                ""
                              )}
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="mb-3">
                              <select
                                disabled
                                name="duration_unit"
                                id="duration_unit"
                                className="form-control form-select"
                                value={activity?.duration_unit}
                              >
                                <option value="" key={0}>
                                  Select
                                </option>
                                {duration_units?.map((item, idx) => {
                                  return (
                                    <>
                                      <option key={idx + 1} value={`${item}`}>
                                        {item}
                                      </option>
                                    </>
                                  );
                                })}
                              </select>
                            </div>
                            <div style={{ backgroundColor: "yellow" }}>
                              {tempData?.tourDuration?.unit ? (
                                <p>{tempData?.tourDuration?.unit}</p>
                              ) : (
                                ""
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="mb-3">
                          <label htmlFor="slug" className="form-label">
                            {/* Slug */}
                            Keywords (Add keywords that matches your activity
                            E.g: Cape town , Deepsea fishing Cape town,
                            sportfishing Cape Town)
                          </label>
                          <input
                            disabled
                            name="slug"
                            id="slug"
                            type="text"
                            className="form-control"
                            autoComplete="false"
                            value={activity?.slug}
                            onChange={(e) => handleChange(e)}
                          />
                        </div>
                        <div style={{ backgroundColor: "yellow" }}>
                          {activity?.slug !== tempData?.slug ? (
                            <p>{tempData?.slug}</p>
                          ) : (
                            ""
                          )}
                        </div>
                        {errors.slug && (
                          <span className="errorMessageText text-danger">
                            {errors.slug}
                          </span>
                        )}
                      </div>
                      <div className="col-md-6">
                        <div className="mb-3">
                          <label htmlFor="image" className="form-label">
                            Pictures
                          </label>
                          <input
                            disabled
                            name="image"
                            id="image"
                            type="file"
                            accept={"image/*"}
                            className="form-control"
                            multiple
                          />
                        </div>
                        <div style={{ display: "flex" }}>
                          {activity?.image.map((item, i) => {
                            return (
                              <div style={{ display: "flex" }}>
                                <img
                                  src={item}
                                  style={{ height: "100px", width: "100px" }}
                                  className="img-fluid px-2"
                                  alt="productimg"
                                />
                              </div>
                            );
                          })}
                        </div>
                        <div
                          style={{
                            display: !activity?.isEdited ? "block" : "none",
                          }}
                        >
                          <div style={{ display: "flex", marginTop: "10px" }}>
                            {tempData?.image?.map((item) => {
                              return (
                                <div style={{ display: "flex" }}>
                                  <img
                                    src={item}
                                    style={{ height: "100px", width: "100px" }}
                                    className="img-fluid px-2"
                                    alt="productimg"
                                  />
                                </div>
                              );
                            })}
                          </div>
                        </div>

                        {errors.image && (
                          <span className="errorMessageText text-danger">
                            {errors.image}
                          </span>
                        )}
                      </div>
                      <div className="col-md-6">
                        <FileSelect
                          label={
                            " Tour video (Min Video length 5 second Max video length 15 second)"
                          }
                          className={"col-md-12 mb-3 mt-2"}
                          disabled
                          videos={activity?.videos ?? []}
                          fileType="video/*"
                        />
                        <span style={{ color: "red" }}>
                          By adding a video here I agree that I’m liable for any
                          copyright infringement.
                        </span>
                      </div>
                    </div>
                  </TabPanel>

                  <TabPanel>
                    <label
                      htmlFor=""
                      className="form-label"
                      style={{
                        marginLeft: "15px",
                        fontSize: "20px",
                        fontWeight: "bold",
                      }}
                    >
                      Participant Type
                    </label>

                    {tempData?.participentType && (
                      <div className="view_btn">
                        <button
                          onClick={() => {
                            setupdateModal(true);
                            setiamFrom("participantType");
                          }}
                        >
                          view
                        </button>
                      </div>
                    )}
                    {participantType.map((item, index) => (
                      <div
                        className="d-flex"
                        key={index}
                        style={{ alignItems: "center" }}
                      >
                        <div className="card_add_more col-md-11">
                          <div className="col-md-12">
                            <div className="mb-3">
                              <label
                                htmlFor={`pertype_${index}`}
                                className="form-label"
                              >
                                Type Of Participant
                              </label>
                              <select
                                disabled
                                name={`pertype_${index}`}
                                id={`pertype_${index}`}
                                className="form-control form-select"
                                value={item.pertype}
                              >
                                <option value={item.pertype} key={0}>
                                  {item.pertype}
                                </option>
                              </select>

                              <div style={{ backgroundColor: "yellow" }}>
                              </div>
                            </div>
                          </div>
                          <div style={{ backgroundColor: "yellow" }}></div>
                          <div className="col-md-12">
                            <label
                              htmlFor={`age_${index}`}
                              className="form-label"
                            >
                              Age Range
                            </label>
                            <input
                              disabled
                              id={`age_${index}`}
                              name={`age_${index}`}
                              type="text"
                              className="form-control"
                              autoComplete="false"
                              value={item.age}
                            />
                            <div style={{ backgroundColor: "yellow" }}>
                            </div>
                          </div>

                          <div className="col-md-12">
                            <label htmlFor="" className="form-label">
                              Price
                            </label>
                            <input
                              disabled
                              id={`price_per_person_${index}`}
                              name={`price_per_person_${index}`}
                              type="number"
                              min={0}
                              className="form-control"
                              autoComplete="false"
                              value={item.price}
                            />
                            <div style={{ backgroundColor: "yellow" }}>
                            </div>
                          </div>

                          {activity?.frontRowSitting && (
                            <div>
                              <div className="col-md-12">
                                <label
                                  htmlFor={`front_row_seat_price_${index}`}
                                  className="form-label"
                                >
                                  Front Row Seat Price{" "}
                                  <span
                                    style={{
                                      color: "red",
                                      fontSize: "23px",
                                      fontWeight: "bold",
                                    }}
                                  >
                                    {" "}
                                    *
                                  </span>
                                </label>
                                <input
                                  id={`front_row_seat_price_${index}`}
                                  name={`front_row_seat_price_${index}`}
                                  type="number"
                                  min={0}
                                  disabled
                                  className="form-control"
                                  autoComplete="false"
                                  value={item.frontRowSeatPrice}
                                />
                              </div>
                              <div style={{ backgroundColor: "yellow" }}>
                              </div>
                            </div>
                          )}
                        </div>
                      </div>
                    ))}
                    <div>
                      {errors.participentType && (
                        <span className="errorMessageText text-danger">
                          {errors.participentType}
                        </span>
                      )}
                    </div>
                  </TabPanel>

                  <TabPanel>
                    <label
                      htmlFor=""
                      className="form-label"
                      style={{
                        marginLeft: "15px",
                        fontSize: "20px",
                        fontWeight: "bold",
                      }}
                    >
                      About This Activity
                    </label>
                    {tempData?.tourActivity && (
                      <div className="view_btn">
                        <button
                          onClick={() => {
                            setiamFrom("activitydetails");
                            setupdateModal(true);
                          }}
                        >
                          view
                        </button>
                      </div>
                    )}

                    {tourActivityData?.map((item, index) => {
                      return (
                        <div
                          className="d-flex"
                          style={{ alignItems: "center" }}
                        >
                          <div className="card_add_more col-md-11" key={index}>
                            <div className="col-md-12">
                              <div className="mb-3">
                                <label
                                  htmlFor={`tour_term_${index}`}
                                  className="form-label"
                                >
                                  {/* Topic */}
                                  Short main activity description/keywords (Eg:
                                  Rome: Colosseum, Palatine Hill and Roman Forum
                                  Guided Tour)
                                </label>
                                <input
                                  disabled
                                  name={`tour_term_${index}`}
                                  id={`tour_term_${index}`}
                                  type="text"
                                  className="form-control"
                                  autoComplete="false"
                                  value={item.term}
                                />
                              </div>
                              {errors.siteName && (
                                <span className="errorMessageText text-danger">
                                  {errors.siteName}
                                </span>
                              )}
                            </div>

                            <div className="col-md-12">
                              <div className="mb-3">
                                <label
                                  htmlFor="siteName"
                                  className="form-label"
                                >
                                  {/* Description */}
                                  Description (Short description of the main
                                  points/sites visited and keywords to attract
                                  attention to your product E.g: Visit the
                                  Colosseum, the largest amphitheater in the
                                  Roman world, the Palatine Hill, the oldest
                                  part of the city, and the Roman Forum, the
                                  beating heart of ancient Rome, with an expert
                                  guide.)
                                </label>
                                <input
                                  disabled
                                  name={`tour_desc_${index}`}
                                  id={`tour_desc_${index}`}
                                  type="text"
                                  className="form-control"
                                  autoComplete="false"
                                  value={item.description}
                                />
                                <CKEditor
                                  className="editor-container"
                                  editor={ClassicEditor}
                                  data={item.description}
                                  onReady={(editor) => {
                                    editor.enableReadOnlyMode("customReadOnly");
                                  }}
                                  config={{
                                    readOnly: true,
                                  }}
                                />
                              </div>

                              {errors.siteName && (
                                <span className="errorMessageText text-danger">
                                  {errors.siteName}
                                </span>
                              )}
                            </div>
                          </div>
                        </div>
                      );
                    })}
                    <div>
                      {errors.tourActivity && (
                        <span className="errorMessageText text-danger">
                          {errors.tourActivity}
                        </span>
                      )}
                    </div>
                  </TabPanel>

                  <TabPanel>
                    <label
                      htmlFor="siteName"
                      className="form-label"
                      style={{
                        marginLeft: "15px",
                        fontSize: "20px",
                        fontWeight: "bold",
                      }}
                    >
                      Experience
                    </label>
                    {tempData?.information && (
                      <div className="view_btn">
                        <button
                          onClick={() => {
                            setiamFrom("experience");
                            setupdateModal(true);
                          }}
                        >
                          view
                        </button>
                      </div>
                    )}

                    {experienceDetails?.map((item, index) => {
                      return (
                        <div
                          className="d-flex"
                          style={{ alignItems: "center" }}
                        >
                          <div className="card_add_more col-md-11" key={index}>
                            <div className="col-md-12">
                              <div className="mb-3">
                                <label
                                  htmlFor="siteName"
                                  className="form-label"
                                >
                                  {/* Experience Title */}
                                  {item.title === "Highlights"
                                    ? "Add the highlights of this activity one at a time by using the plus box (Keep it short and precise)"
                                    : item.title === "Full description"
                                      ? "Write a full description including everything that is included and will be seen/experience on this activity. Keep the description in correct order as the customer will see/experience this activity. This is your main description/selling point so make it stand out with at least 1000 characters"
                                      : item.title === "Includes"
                                        ? "Describe everything that is included in the tour price (E.g.: Entrance fees , bottle of water , transport. Use the plus button to add one at a time)"
                                        : item.title === "Excludes"
                                          ? "Describe everything that is excluded in the tour price (E.g.: Entrance fees , bottle of water , transport. Use the plus button to add one at a time)"
                                          : " Experience Title"}
                                </label>
                                <input
                                  disabled={
                                    item.title === "Highlights" ||
                                    item.title === "Full description" ||
                                    item.title === "Includes" ||
                                    item.title === "Not suitable for"
                                  }
                                  name={`exp_title_${index}`}
                                  id={`exp_title_${index}`}
                                  type="text"
                                  className="form-control"
                                  autoComplete="false"
                                  value={item.title}
                                  onChange={(e) => {
                                    setExperienceDetails((state) => {
                                      state[index].title = e.target.value;

                                      return JSON.parse(JSON.stringify(state));
                                    });
                                  }}
                                />
                              </div>
                            </div>

                            {item.desc.map((it, key) => {
                              return (
                                <div
                                  className="d-flex"
                                  style={{ alignItems: "center" }}
                                >
                                  <div className="col-md-11" key={key}>
                                    <div className="mb-3">
                                      <label
                                        htmlFor="siteName"
                                        className="form-label"
                                        style={{ marginLeft: "18px" }}
                                      >
                                        {/* Experience Details */}
                                        Details{" "}
                                        {item?.title !== "Not suitable for" && (
                                          <span
                                            style={{
                                              fontSize: "20px",
                                              fontWeight: "bold",
                                              color: "red",
                                            }}
                                          >
                                            {" "}
                                            *
                                          </span>
                                        )}
                                      </label>
                                      <CKEditor
                                        className="editor-container"
                                        editor={ClassicEditor}
                                        data={it}
                                        onReady={(editor) => {
                                          editor.enableReadOnlyMode(
                                            "customReadOnly"
                                          );
                                        }}
                                        onChange={(event, editor) => {
                                          const data = editor.getData();
                                          setExperienceDetails((state) => {
                                            state[index].desc[key] = data;

                                            return JSON.parse(
                                              JSON.stringify(state)
                                            );
                                          });
                                        }}
                                      />
                                    </div>
                                  </div>
                                </div>
                              );
                            })}
                          </div>
                        </div>
                      );
                    })}
                  </TabPanel>

                  <TabPanel>
                    <label
                      htmlFor=""
                      className="form-label"
                      style={{
                        marginLeft: "15px",
                        fontSize: "20px",
                        fontWeight: "bold",
                      }}
                    >
                      Meeting Point
                    </label>
                    {tempData?.meetingPoint && (
                      <div className="view_btn">
                        <button
                          onClick={() => {
                            setiamFrom("meetingPoint");
                            setupdateModal(true);
                          }}
                        >
                          view
                        </button>
                      </div>
                    )}

                    {meetingPointData?.map((item, index) => {
                      return (
                        <div
                          className="d-flex"
                          style={{ alignItems: "center" }}
                        >
                          <div className="card_add_more col-md-11" key={index}>
                            <div className="col-md-12">
                              <div className="mb-3">
                                <label htmlFor="" className="form-label">
                                  Meeting Point Description (Also add your drop of/end point if applicable)
                                </label>
                                <CKEditor
                                  className="editor-container"
                                  editor={ClassicEditor}
                                  data={item.desc}
                                  onReady={(editor) => {
                                    editor.enableReadOnlyMode("customReadOnly");
                                  }}
                                  onChange={(event, editor) => {
                                    const data = editor.getData();
                                    setmeetingPointData((state) => {
                                      state[index].desc = data;

                                      return JSON.parse(JSON.stringify(state));
                                    });
                                  }}
                                />
                              </div>
                              {errors.siteName && (
                                <span className="errorMessageText text-danger">
                                  {errors.siteName}
                                </span>
                              )}
                            </div>

                            <div className="col-md-12">
                              <div className="mb-3">
                                <label
                                  htmlFor="siteName"
                                  className="form-label"
                                >
                                  Meeting Point Link
                                </label>
                                <input
                                  disabled
                                  name={`meeting_point_link_${index}`}
                                  id={`meeting_point_link_${index}`}
                                  type="text"
                                  className="form-control"
                                  autoComplete="false"
                                  value={item.link}
                                />
                              </div>
                              {errors.siteName && (
                                <span className="errorMessageText text-danger">
                                  {errors.siteName}
                                </span>
                              )}
                            </div>
                          </div>
                        </div>
                      );
                    })}
                  </TabPanel>

                  <TabPanel>
                    <label
                      htmlFor="siteName"
                      className="form-label"
                      style={{
                        marginLeft: "15px",
                        fontSize: "20px",
                        fontWeight: "bold",
                      }}
                    >
                      Important Info
                    </label>
                    {tempData?.importentInfo && (
                      <div className="view_btn">
                        <button
                          onClick={() => {
                            setiamFrom("importantinfo");
                            setupdateModal(true);
                          }}
                        >
                          view
                        </button>
                      </div>
                    )}

                    {importentInfo?.map((item, index) => {
                      return (
                        <div
                          className="d-flex"
                          style={{ alignItems: "center" }}
                        >
                          <div className="card_add_more col-md-11" key={index}>
                            <div className="col-md-12">
                              <div className="mb-3">
                                <label
                                  htmlFor="siteName"
                                  className="form-label"
                                >
                                  {/* Title */}
                                  Important information about your activity
                                </label>
                                <input
                                  disabled
                                  name={`exp_title_${index}`}
                                  id={`exp_title_${index}`}
                                  type="text"
                                  className="form-control"
                                  autoComplete="false"
                                  value={item.title}
                                />
                              </div>
                            </div>

                            {item.description.map((it, key) => {
                              return (
                                <div
                                  className="d-flex"
                                  style={{ alignItems: "center" }}
                                >
                                  <div className="col-md-11" key={key}>
                                    <div className="mb-3">
                                      <label
                                        htmlFor="siteName"
                                        className="form-label ml-3"
                                      >
                                        {/* Description */}
                                        Description (Add important info that the
                                        customer have to be aware of E.g.: Bring
                                        ID , Height restriction etc.. Add one at
                                        a time using the plus button.)
                                      </label>
                                      <CKEditor
                                        className="editor-container"
                                        editor={ClassicEditor}
                                        data={it}
                                        onReady={(editor) => {
                                          editor.enableReadOnlyMode(
                                            "customReadOnly"
                                          );
                                        }}
                                        onChange={(event, editor) => {
                                          const data = editor.getData();
                                          setImportentInfo((state) => {
                                            state[index].description[key] =
                                              data;

                                            return JSON.parse(
                                              JSON.stringify(state)
                                            );
                                          });
                                        }}
                                      />
                                    </div>
                                  </div>
                                </div>
                              );
                            })}
                          </div>
                        </div>
                      );
                    })}
                  </TabPanel>

                  <TabPanel>
                    <label
                      htmlFor=""
                      className="form-label"
                      style={{
                        marginLeft: "15px",
                        fontSize: "20px",
                        fontWeight: "bold",
                      }}
                    >
                      Tour Places
                    </label>
                    {tempData?.activityCoordinates && (
                      <div className="view_btn">
                        <button
                          onClick={() => {
                            setiamFrom("tourPlaces");
                            setupdateModal(true);
                          }}
                        >
                          view
                        </button>
                      </div>
                    )}

                    {tourCoordinates?.map((item, index) => {
                      return (
                        <div
                          className="d-flex"
                          style={{ alignItems: "center" }}
                        >
                          <div className="card_add_more col-md-11" key={index}>
                            <div className="col-md-12">
                              <div className="mb-3">
                                <label htmlFor="" className="form-label">
                                  {/* Location Name */}
                                  Add all locations that will be visited during
                                  this activity (Use the plus button to add them
                                  one at a time)
                                </label>
                                <CKEditor
                                  className="editor-container"
                                  editor={ClassicEditor}
                                  data={item.location}
                                  onReady={(editor) => {
                                    editor.enableReadOnlyMode("customReadOnly");
                                  }}
                                  onChange={(event, editor) => {
                                    const data = editor.getData();
                                    settourCoordinates((state) => {
                                      state[index].location = data;

                                      return JSON.parse(JSON.stringify(state));
                                    });
                                  }}
                                />
                              </div>
                              {errors.siteName && (
                                <span className="errorMessageText text-danger">
                                  {errors.siteName}
                                </span>
                              )}
                            </div>

                            <div className="col-md-12 d-none">
                              <div className="mb-3">
                                <label htmlFor="" className="form-label">
                                  Location Latitude
                                </label>
                                <input
                                  disabled
                                  name={`location_latitude_${index}`}
                                  id={`location_latitude_${index}`}
                                  type="text"
                                  className="form-control"
                                  autoComplete="false"
                                  value={item.lat}
                                />
                              </div>
                              {errors.siteName && (
                                <span className="errorMessageText text-danger">
                                  {errors.siteName}
                                </span>
                              )}
                            </div>

                            <div className="col-md-12 d-none">
                              <div className="mb-3">
                                <label htmlFor="" className="form-label">
                                  Location Longitude
                                </label>
                                <input
                                  disabled
                                  name={`location_longitude_${index}`}
                                  id={`location_longitude_${index}`}
                                  type="text"
                                  className="form-control"
                                  autoComplete="false"
                                  value={item.lng}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      );
                    })}
                    <div>
                    </div>
                    <div className="col-md-6">
                      <div className="mb-3">
                        <label
                          htmlFor="topPriority"
                          className="form-label font-weight-bold"
                        >
                          Priority*
                        </label>
                        <input
                          disabled
                          name="topPriority"
                          id="topPriority"
                          type="number"
                          min={0}
                          className="form-control"
                          value={activity?.topPriority}
                          onChange={handleChange}
                        />
                      </div>
                    </div>
                  </TabPanel>

                  <TabPanel>
                    <div className="card">
                      <div className="card-body">
                        {tempData?.TourAdditionalStuff && (
                          <div className="view_btn">
                            <button
                              onClick={() => {
                                setiamFrom("aditionalStaff");
                                setupdateModal(true);
                              }}
                            >
                              view
                            </button>
                          </div>
                        )}
                        {stuff &&
                          stuff?.map((item) => (
                            <div key={item._id}>
                              <label>
                                {<div className="view_btn"></div>}
                                <input
                                  type="checkbox"
                                  name={item.stuffName}
                                  disabled
                                  checked={selectedItems.some(
                                    (selectedItem) => selectedItem === item._id
                                  )}
                                  onChange={(e) =>
                                    handleCheckboxChange(e, item._id)
                                  }
                                />
                                {item.stuffName} - $ {item.stuffprice}
                              </label>
                            </div>
                          ))}
                      </div>
                    </div>
                  </TabPanel>

                  <TabPanel>
                    <div className="card my-2">
                      <div className="card-body">
                        {tempData?.importantQuestionAboutThisTour && (
                          <div className="view_btn">
                            <button
                              onClick={() => {
                                setiamFrom("importantToKnow");
                                setupdateModal(true);
                              }}
                            >
                              view
                            </button>
                          </div>
                        )}

                        {importantQuestion?.map((list, i) => {
                          return (
                            <div className="card my-3" key={i}>
                              <div className="card-body row">
                                <div className="col-11">
                                  <TextInput
                                    label={
                                      "Provide your customers with the most frequently asked question and answers about your activity. E.g.: Is there a toilet on the bus?"
                                    }
                                    value={list.question}
                                    onChange={(e) => {
                                      let updated = [...importantQuestion];
                                      updated[i].question = e.target.value;
                                      setImportantQuestion(updated);
                                    }}
                                    disabled
                                  />
                                  <TextInput
                                    label={
                                      "Answer: Yes , a toilet is a available on our busses.(Add as many Q&A as to limit the amount of times our team have to contact you to clear up simple questions saving everyone time.)"
                                    }
                                    value={list.answer}
                                    onChange={(e) => {
                                      let updated = [...importantQuestion];
                                      updated[i].answer = e.target.value;
                                      setImportantQuestion(updated);
                                    }}
                                    disabled
                                  />
                                </div>
                                <div className="col-1 m-auto">
                                  {i !== 0 && (
                                    <button
                                      type="button"
                                      className="btn btn-danger ml-auto d-table"
                                      onClick={() => {
                                        let updated = [...importantQuestion];
                                        updated.splice(i, 1);
                                        setImportantQuestion(updated);
                                      }}
                                    >
                                      -
                                    </button>
                                  )}
                                </div>
                              </div>
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  </TabPanel>

                  <TabPanel>
                    <label
                      htmlFor=""
                      className="form-label"
                      style={{
                        marginLeft: "15px",
                        fontSize: "20px",
                        fontWeight: "bold",
                      }}
                    >
                      Third Party Review
                    </label>
                    <div id="participant_types">
                      {thirdPartyReviewrating.map((item, index) => (
                        <div
                          className="d-flex"
                          key={index}
                          style={{ alignItems: "center" }}
                        >
                          <div className="card_add_more col-md-11 ">
                            <div className="row">
                              <div className="col-md-6">
                                <div className="mb-3">
                                  <label
                                    htmlFor={`onlineTravelAdvisorId_${index}`}
                                    className="form-label"
                                  >
                                    Travel Advisor Name

                                  </label>
                                  <Select
                                    name={`onlineTravelAdvisorId_${index}`}
                                    id={`onlineTravelAdvisorId_${index}`}
                                    classNamePrefix="select"
                                    options={filterAgencyData.map((it) => {
                                      return {
                                        value: it?._id,
                                        label: it?.name,
                                      };
                                    })}
                                    value={{
                                      value: item?.onlineTravelAdvisorId,
                                      label: item?.travelAdvisorname,
                                    }}
                                    onChange={(selectedOption) => {
                                      setThirdPartyReviewRating((state) => {
                                        const newState = [...state];
                                        newState[index].onlineTravelAdvisorId = selectedOption.value;
                                        newState[index].travelAdvisorname = selectedOption.label;
                                        return newState;
                                      });
                                    }}
                                    isDisabled
                                  />

                                </div>
                              </div>
                              <div className="col-md-3">
                                <label
                                  htmlFor={`NumberOfStar_${index}`}
                                  className="form-label"
                                >
                                  Enter No of star
                                </label>
                                <input
                                  id={`NumberOfStar_${index}`}
                                  name={`NumberOfStar_${index}`}
                                  type="number"
                                  className="form-control"
                                  autoComplete="false"
                                  min={0}
                                  max={5}
                                  value={item.NumberOfStar}
                                  placeholder="Give Star from 1 to 5"
                                  onChange={(e) => {
                                    if (e.target.value < 0) {
                                      e.target.value = 0;
                                    }

                                    setThirdPartyReviewRating((state) => {
                                      state[index].NumberOfStar = e.target.value;

                                      return JSON.parse(JSON.stringify(state));
                                    });
                                  }}
                                  disabled
                                />
                              </div>
                              <div className="col-md-3">
                                <label htmlFor={`noOfView_${index}`} className="form-label">
                                  Enter No of view
                                </label>
                                <input
                                  id={`noOfView_${index}`}
                                  name={`noOfView_${index}`}
                                  type="number"
                                  min={0}
                                  className="form-control"
                                  autoComplete="false"
                                  value={item.noOfView}
                                  max={5}
                                  placeholder="Give No of view "
                                  onChange={(e) => {
                                    if (e.target.value < 0) {
                                      e.target.value = 0;
                                    }

                                    setThirdPartyReviewRating((state) => {
                                      state[index].noOfView = e.target.value;

                                      return JSON.parse(JSON.stringify(state));
                                    });
                                  }}
                                  disabled
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      ))}
                    </div>
                    <div>
                      {errors.participentType && (
                        <span className="errorMessageText text-danger">
                          {errors.participentType}
                        </span>
                      )}
                    </div>
                  </TabPanel>
                </Tabs>
              </div>
            </div>
            {!activity?.rejectedStatus &&
              activity?.isApproval &&
              !activity?.isEdited ? (
              <div className="btn btn-success">This is approved.</div>
            ) : activity?.rejectedStatus &&
              !activity?.isApproval &&
              !activity?.isEdited ? (
              <div className="btn btn-danger">This is rejected</div>
            ) : activity?.isEdited ? (
              <div>
                <button
                  type="button"
                  className="btn btn-link btn-danger mr-3"
                  onClick={() => {
                    openModal();
                    setmodalToggleView(1);
                  }}
                >
                  Reject Change
                </button>
                <button
                  type="submit"
                  className="btn btn-primary"
                  onClick={() => {
                    openModal();
                    setmodalToggleView(0);
                  }}
                >
                  Approve Change
                </button>
              </div>
            ) : (
              ""
            )}
          </div>
        </div>
      </div>
      {updateModel && (
        <ShowEditProduct
          tempData={tempData}
          setupdateModal={setupdateModal}
          stuff={stuff}
          iamFrom={iamFrom}
        />
      )}
    </>
  );
}

export default ProductChangeDetails;

// Hooks
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";

// Helpers
import { toast } from "react-toastify";

// Utils
import { apiCallError } from "../../../utils/common-utils";

// API functions
import { JSON_API } from "../../../services/api/jsonApiCallWithInterceptor";

// Redux actions
import { getAllPrivacyPolicies } from "../../../redux/adminSlice/privacyPolicySlice";

// Components
import Table from "../../../Component/Table";

const ManagePrivacy = () => {
  // export default function AddPrivacy() {
  const dispatch = useDispatch();
  const [error, setError] = useState("");
  const [modal, setModal] = useState(false);
  const [modalToggleView, setmodalToggleView] = useState(null);
  const [deleteId, setdeleteId] = useState("");
  const navigate = useNavigate();
  // Redux stores
  const { privacy_policies } = useSelector((state) => state.privacyPolicySlice);
  console.log("privacy_policies", privacy_policies);

  // Component did mount
  useEffect(() => {
    window.scrollTo(0, 0);
    dispatch(getAllPrivacyPolicies());
  }, [dispatch]);

  const openModal = () => {
    setModal(true);
  };

  const deletePrivacyInfo = async (id) => {
    try {
      let res = await JSON_API["adminDeletePrivacyPolicy"]({ id });

      if (res.isSuccess) {
        dispatch(getAllPrivacyPolicies());
        setModal(false);
        // apiCallSuccess(res.data);
        toast.success("Deleted successfully");
      } else {
        apiCallError(res?.error?.message, setError);
      }
    } catch (error) {
      apiCallError(error.message, setError);
    }
  };

  const tableData = {
    columns: [
      {
        label: "SL",
        field: "sl",
        sort: "asc",
        width: 50,
      },
      {
        label: "Description",
        field: "topic",
        sort: "asc",
        width: 100,
      },
      {
        label: "Action",
        field: "action",
        sort: "asc",
        width: 150,
      },
    ],
    rows:
      privacy_policies?.length > 0
        ? privacy_policies.map((item, idx) => {
            return {
              sl: idx + 1,
              topic: (
                <div className="widget-content p-0">
                  <div className="widget-content-wrapper">
                    <div className="widget-content-left flex2">
                      <div
                        className="widget-heading"
                        style={{ opacity: 0.75 }}
                        dangerouslySetInnerHTML={{
                          __html:
                            item?.topic?.length <= 75
                              ? item?.topic
                              : item?.topic?.slice(0, 75) + "....",
                        }}
                      />
                      {/* <div className="widget-subheading opacity-7">UI Designer</div> */}
                    </div>
                  </div>
                </div>
              ),
              action: (
                <div>
                  <button
                    className="me-2 btn-icon btn-icon-only btn btn-outline-danger"
                    onClick={() => {
                      openModal();
                      setmodalToggleView(0);
                      setdeleteId(item?._id);
                    }}
                  >
                    <i className="fa-solid fa-trash-can" />
                  </button>
                  <button
                    className="me-2 btn-icon btn-icon-only btn btn-outline-success"
                    onClick={() => {
                      navigate(`/edit-privacy-policy/${item?._id}`);
                    }}
                  >
                    <i class="fa-regular fa-pen-to-square"></i>
                  </button>
                  <Link to={`/privacy-details/${item._id}`}>
                    <button className="me-2 btn-icon btn-icon-only btn btn-outline-primary">
                      <i class="fa fa-info-circle" aria-hidden="true"></i>
                    </button>
                  </Link>
                </div>
              ),
            };
          })
        : [],
  };

  const renderModalView = (index) => {
    switch (index) {
      case 0:
        return (
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">Delete Info</h5>
            </div>
            <div className="modal-body">
              <div className="deleteModalContainer p-5">
                <i className="ri-close-circle-line deleteIcon"></i>
                <h3>Are You Sure?</h3>
                <span>Do You Want To Delete This Information?</span>
                <div className="deleteModalNote">
                  <span>*Note : This data will be permanently deleted</span>
                </div>
              </div>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-link"
                onClick={() => {
                  setModal(false);
                }}
              >
                Cancel
              </button>
              <button
                type="button"
                className="btn btn-primary"
                onClick={() => {
                  deletePrivacyInfo(deleteId);
                }}
              >
                Delete
              </button>
            </div>
          </div>
        );
      default:
        return null;
    }
  };

  return (
    <>
      <Table
        striped
        tableTitle={"All Privacy Policies"}
        data={tableData}
        modal={modal}
        setModal={setModal}
        modalToggleView={modalToggleView}
        renderModalView={renderModalView}
      />
    </>
  );
  //   }
};

export default ManagePrivacy;

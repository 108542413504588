import React, { useEffect, useState } from "react";
import $ from "jquery";
import { NavLink, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";


function activeMenu(pathname) {
  if (["/add-admin", "/manage-admin"].includes(pathname)) {
    $('#collapseOne').removeClass('in').addClass('show');
  }
  else if (["/add-merchant", "/manage-merchant", "/manage-merchant-drafts", "/merchant-details-change-requests", "/new-merchant-request-list"].includes(pathname)) {
    $('#collapseTwo').removeClass('in').addClass('show');
  }
  else if (["/add-commission", "/manage-commission"].includes(pathname)) {
    $('#collapseThirtyFive').removeClass('in').addClass('show');
  }
  else if (["/add-categories", "/manage-categories"].includes(pathname)) {
    $('#collapseEight').removeClass('in').addClass('show');
  }
  else if (["/add-country", "/manage-country"].includes(pathname)) {
    $('#collapseFour').removeClass('in').addClass('show');
  }
  else if (["/add-city", "/manage-cities"].includes(pathname)) {
    $('#collapseNine').removeClass('in').addClass('show');
  }
  else if (["/add-activity-type", "/manage-activity-type"].includes(pathname)) {
    $('#collapseThirty').removeClass('in').addClass('show');
  }
  else if (["/add-sections", "/manage-sections"].includes(pathname)) {
    $('#collapseThirtyFour').removeClass('in').addClass('show');
  }
  else if (["/add-language", "/manage-language"].includes(pathname)) {
    $('#collapseFive').removeClass('in').addClass('show');
  }
  else if (["/add-activity-site", "/manage-activity-site", "/add-site-destination", "/manage-site-destinations"].includes(pathname)) {
    $('#collapseThirtyOne').removeClass('in').addClass('show');
  }
  else if (["/add-tour-module", "/manage-tour-module"].includes(pathname)) {
    $('#collapseTwentyFour').removeClass('in').addClass('show');
  }
  else if (["/add-participants", "/manage-participants"].includes(pathname)) {
    $('#collapseTwentySeven').removeClass('in').addClass('show');
  }
  else if (["/edit-activity-drafts", "/add-activity", "/manage-activity", "/manage-activity-drafts", "/check-merchant-additions", "/product-change-requests", "/product-change-details"].includes(pathname)) {
    $('#collapseThirteen').removeClass('in').addClass('show');
  }
  else if (["/add-activity-availability", "/check-activity-availability", "/cancel-activity-availability"].includes(pathname)) {
    $('#collapseThirtyTwo').removeClass('in').addClass('show');
  }
  else if (["/add-bookings-status-tag", "/manage-bookings-status-tags", "/view-bookings", "/merchant-invoices"].includes(pathname)) {
    $('#collapseThirtyThree').removeClass('in').addClass('show');
  }
  else if (["/add-banner", "/manage-banner"].includes(pathname)) {
    $('#collapseSix').removeClass('in').addClass('show');
  }
  else if (["/add-press", "/manage-press"].includes(pathname)) {
    $('#collapseEleven').removeClass('in').addClass('show');
  }
  else if (["/add-blog-type", "/manage-blog-types", "/add-blog-type-category", "/manage-blog-type-category", "/add-blogs", "/manage-blogs"].includes(pathname)) {
    $('#collapseTwelve').removeClass('in').addClass('show');
  }
  else if (["/add-aboutUs", "/manage-aboutUs"].includes(pathname)) {
    $('#collapseTen').removeClass('in').addClass('show');
  }
  else if (["/add-legal-notices", "/manage-legal-notices"].includes(pathname)) {
    $('#collapseFifteen').removeClass('in').addClass('show');
  }
  else if (["/add-privacy-policy", "/manage-privacy-policy"].includes(pathname)) {
    $('#collapseSeventeen').removeClass('in').addClass('show');
  }
  else if (["/add-cookies-marketing", "/manage-cookies-marketing"].includes(pathname)) {
    $('#collapseEighteen').removeClass('in').addClass('show');
  }
  else if (["/add-topic", "/manage-topic", "/add-subtopic", "/manage-subtopic", "/add-contact-email", "/add-subquestion", "/manage-subquestion"].includes(pathname)) {
    $('#collapseNineteen').removeClass('in').addClass('show');
  }
  else if (["/add-special-offers", "/manage-special-offers", "/manage-merchant-offers"].includes(pathname)) {
    $('#collapseTwentyTwo').removeClass('in').addClass('show');
  }
  else if (["/add-terms-conditions", "/manage-terms-conditions"].includes(pathname)) {
    $('#collapseTwentyThree').removeClass('in').addClass('show');
  }
  else if (["/add-digital-info", "/manage-digital-info"].includes(pathname)) {
    $('#collapseTwentyFive').removeClass('in').addClass('show');
  }
  else if (["/add-sitemap", "/manage-sitemap"].includes(pathname)) {
    $('#collapseTwentySix').removeClass('in').addClass('show');
  }
  else if (["/add-faq-type", "/manage-faq-type", "/add-faq", "/manage-faq"].includes(pathname)) {
    $('#collapseTwentySeven').removeClass('in').addClass('show');
  }
  else if (["/add-gift", "/manage-gift", "/gift-questions", "/manage-gift-questions"].includes(pathname)) {
    $('#collapseTwentyNine').removeClass('in').addClass('show');
  }

}

export default function Sidebar() {

  const location = useLocation();
  activeMenu(location.pathname);

  const [newMerchantCount, setNewMerchantCount] = useState(null)
  const [productChangeCount, setProductChangeCount] = useState(null)
  const [allMerchantData, setallMerchantData] = useState([]);

  let userdata = localStorage.getItem('userdata');
  userdata = JSON.parse(userdata);
  let assignAccess = userdata?.assignAccess;
  let userType = userdata?.userType;

  const { merchant_additions } = useSelector(
    (state) => state.adminActivityActionSlice
  );
  const { merchants } = useSelector((state) => state.adminMerchantActionSlice);
  const { details_change_requests_new } = useSelector(
    (state) => state.adminMerchantActionSlice
  );
  let filterDetailsCgangeData = details_change_requests_new?.filter((item) => {
    return item?.rejectStatus === false;
  });


  const merchantType = "new";

  useEffect(() => {
    let filterData = merchant_additions?.filter((item) => item?.isApproval === false && !item?.rejectedStatus)
    setNewMerchantCount(filterData)

    let changeCountRequest = merchant_additions?.filter((item) => item?.isEdited === true)
    setProductChangeCount(changeCountRequest)
  }, [merchant_additions])

  useEffect(() => {
    if (merchants?.length > 0) {
      setallMerchantData(merchants.filter((item) => {
        if (merchantType === 'new') {
          if (item?.newSupplier && !item?.saveAsDraft) {
            return true;
          }
          else {
            return false;
          }
        }
        else {
          if (item?.isApproval) {
            return true;
          }
          else {
            return false;
          }
        }
      }));
    }
  }, [merchants, merchantType]);

  return (
    <>
      <div className="sidebar-mobile-overlay"></div>
      <div
        className="app-sidebar bg-royal sidebar-text-light sidebar-shadow appear-done enter-done fade-in slide-right"
        id="sidebar"
      >
        <div className="app-sidebar__inner">
          <div className="metismenu vertical-nav-menu">
            <ul className="metismenu-container">
              <li className="metismenu-item">
                <NavLink
                  className="metismenu-link"
                  to="/"
                  style={{ fontWeight: "bold" }}
                >
                  <i className="metismenu-icon fa-solid fa-gauge"></i>
                  Dashboard
                </NavLink>
              </li>
            </ul>
          </div>

          <div
            className="panel-group"
            id="accordionMenu"
            role="tablist"
            aria-multiselectable="true"
          >
            {
              (userType === "admin" || (userType === "subadmin" && assignAccess.includes('create'))) &&
              (
                <div className="panel panel-default metismenu vertical-nav-menu">
                  <div
                    className="panel-heading metismenu-container"
                    role="tab"
                    id="headingOne"
                  >
                    <div className="panel-title metismenu-item">
                      <a
                        className="collapsed metismenu-link"
                        role="button"
                        data-toggle="collapse"
                        data-parent="#accordionMenu"
                        href="#collapseOne"
                        aria-expanded="true"
                        aria-controls="collapseOne"
                      >
                        <i className="metismenu-icon fa-solid fa-screwdriver-wrench"></i>
                        Admin
                      </a>
                    </div>
                  </div>
                  <div
                    id="collapseOne"
                    className="panel-collapse collapse in"
                    role="tabpanel"
                    aria-labelledby="headingOne"
                  >
                    <div className="panel-body">
                      <ul className="metismenu-container">
                        <li className="metismenu-item ">
                          <NavLink to="/add-admin" className="metismenu-link">
                            Add Admin
                          </NavLink>
                        </li>
                        <li className="metismenu-item">
                          <NavLink to="/manage-admin" className="metismenu-link">
                            Manage Admin
                          </NavLink>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              )
            }
            <div className="panel panel-default metismenu vertical-nav-menu">
              <div
                className="panel-heading metismenu-container"
                role="tab"
                id="headingTwo"
              >
                <div className="panel-title metismenu-item">
                  <a
                    className="collapsed metismenu-link"
                    role="button"
                    data-toggle="collapse"
                    data-parent="#accordionMenu"
                    href="#collapseTwo"
                    aria-expanded="false"
                    aria-controls="collapseTwo"
                  >
                    <i className="metismenu-icon fa-brands fa-searchengin"></i>
                    Merchant
                  </a>
                </div>
              </div>
              <div
                id="collapseTwo"
                className="panel-collapse collapse"
                role="tabpanel"
                aria-labelledby="headingTwo"
              >
                <div className="panel-body">
                  <ul className="metismenu-container">
                    {(userType === "admin" || (userType === "subadmin" && assignAccess.includes('create'))) && (
                      <li className="metismenu-item">
                        <NavLink to="/add-merchant" className="metismenu-link">
                          Add Merchant
                        </NavLink>
                      </li>
                    )}
                    <li className="metismenu-item">
                      <NavLink to="/manage-merchant" className="metismenu-link">
                        Manage Merchant
                      </NavLink>
                    </li>
                    <li className="metismenu-item">
                      <NavLink
                        to="/manage-merchant-drafts"
                        className="metismenu-link"
                      >
                        Manage Drafts
                      </NavLink>
                    </li>
                    <li className="metismenu-item">
                      <NavLink
                        to="/merchant-details-change-requests"
                        className="metismenu-link"
                      >
                        Details Change Request

                        <sup style={{ display: filterDetailsCgangeData?.length <= 0 ? 'none' : '' }}> <div className="supelement prelative"> <div className="absolutep">{details_change_requests_new?.length} </div> </div> </sup>
                      </NavLink>
                    </li>
                    <li className="metismenu-item">
                      <NavLink
                        to="/manage-merchant/new"
                        className="metismenu-link"
                      >
                        New Merchant Requests

                        <sup style={{ display: allMerchantData?.length <= 0 ? 'none' : '' }}> <div className="supelement prelative"> <div className="absolutep">{allMerchantData?.length} </div> </div> </sup>
                      </NavLink>
                    </li>
                    <li className="metismenu-item">
                      <NavLink
                        to="/reject-merchant"
                        className="metismenu-link"
                      >
                        Rejected Merchants
                      </NavLink>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="panel panel-default metismenu vertical-nav-menu">
              <div
                className="panel-heading metismenu-container"
                role="tab"
                id="headingThirtyFive"
              >
                <div className="panel-title metismenu-item">
                  <a
                    className="collapsed metismenu-link"
                    role="button"
                    data-toggle="collapse"
                    data-parent="#accordionMenu"
                    href="#collapseThirtyFive"
                    aria-expanded="true"
                    aria-controls="collapseThirtyFive"
                  >
                    <i
                      class="metismenu-icon fa fa-percent"
                      aria-hidden="true"
                    ></i>
                    Merchant Commission
                  </a>
                </div>
              </div>
              <div
                id="collapseThirtyFive"
                className="panel-collapse collapse"
                role="tabpanel"
                aria-labelledby="headingThirtyFive"
              >
                <div className="panel-body">
                  <ul className="metismenu-container">
                    {(userType === "admin" || (userType === "subadmin" && assignAccess.includes('create'))) && (
                      <li className="metismenu-item">
                        <NavLink to="/add-commission" className="metismenu-link">
                          Add Commission
                        </NavLink>
                      </li>
                    )}
                    <li className="metismenu-item">
                      <NavLink
                        to="/manage-commission"
                        className="metismenu-link"
                      >
                        Manage Commission
                      </NavLink>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="panel panel-default metismenu vertical-nav-menu">
              <div
                className="panel-heading metismenu-container"
                role="tab"
                id="headingEight"
              >
                <div className="panel-title metismenu-item">
                  <a
                    className="collapsed metismenu-link"
                    role="button"
                    data-toggle="collapse"
                    data-parent="#accordionMenu"
                    href="#collapseEight"
                    aria-expanded="true"
                    aria-controls="collapseEight"
                  >
                    <i className="metismenu-icon fa fa-list-alt "></i>
                    Category
                  </a>
                </div>
              </div>
              <div
                id="collapseEight"
                className="panel-collapse collapse"
                role="tabpanel"
                aria-labelledby="headingEight"
              >
                <div className="panel-body">
                  <ul className="metismenu-container">
                    {(userType === "admin" || (userType === "subadmin" && assignAccess.includes('create'))) && (
                      <li className="metismenu-item">
                        <NavLink to="/add-categories" className="metismenu-link">
                          Add Category
                        </NavLink>
                      </li>
                    )}
                    <li className="metismenu-item">
                      <NavLink
                        to="/manage-categories"
                        className="metismenu-link"
                      >
                        Manage Category
                      </NavLink>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="panel panel-default metismenu vertical-nav-menu">
              <div
                className="panel-heading metismenu-container"
                role="tab"
                id="headingFour"
              >
                <div className="panel-title metismenu-item">
                  <a
                    className="collapsed metismenu-link"
                    role="button"
                    data-toggle="collapse"
                    data-parent="#accordionMenu"
                    href="#collapseFour"
                    aria-expanded="true"
                    aria-controls="collapseFour"
                  >
                    <i className="metismenu-icon fa fa-flag"></i>
                    Country
                  </a>
                </div>
              </div>
              <div
                id="collapseFour"
                className="panel-collapse collapse"
                role="tabpanel"
                aria-labelledby="headingFour"
              >
                <div className="panel-body">
                  <ul className="metismenu-container">
                    {(userType === "admin" || (userType === "subadmin" && assignAccess.includes('create'))) && (
                      <li className="metismenu-item">
                        <NavLink to="/add-country" className="metismenu-link">
                          Add Country
                        </NavLink>
                      </li>
                    )}
                    <li className="metismenu-item">
                      <NavLink to="/manage-country" className="metismenu-link">
                        Manage Country
                      </NavLink>
                    </li>
                  </ul>
                </div>
              </div>

            </div>

            <div className="panel panel-default metismenu vertical-nav-menu">
              <div
                className="panel-heading metismenu-container"
                role="tab"
                id="headingNine"
              >
                <div className="panel-title metismenu-item">
                  <a
                    className="collapsed metismenu-link"
                    role="button"
                    data-toggle="collapse"
                    data-parent="#accordionMenu"
                    href="#collapseNine"
                    aria-expanded="true"
                    aria-controls="collapseNine"
                  >
                    <i className="metismenu-icon fa-solid fa-city"></i>
                    City
                  </a>
                </div>
              </div>
              <div
                id="collapseNine"
                className="panel-collapse collapse"
                role="tabpanel"
                aria-labelledby="headingNine"
              >
                <div className="panel-body">
                  <ul className="metismenu-container">
                    {(userType === "admin" || (userType === "subadmin" && assignAccess.includes('create'))) && (
                      <li className="metismenu-item">
                        <NavLink to="/add-city" className="metismenu-link">
                          Add City
                        </NavLink>
                      </li>
                    )}
                    <li className="metismenu-item">
                      <NavLink to="/manage-cities" className="metismenu-link">
                        Manage City
                      </NavLink>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="panel panel-default metismenu vertical-nav-menu">
              <div
                className="panel-heading metismenu-container"
                role="tab"
                id="headingThirty"
              >
                <div className="panel-title metismenu-item">
                  <a
                    className="collapsed metismenu-link"
                    role="button"
                    data-toggle="collapse"
                    data-parent="#accordionMenu"
                    href="#collapseThirty"
                    aria-expanded="true"
                    aria-controls="collapseThirty"
                  >
                    <i
                      className="metismenu-icon fa fa-wrench"
                      aria-hidden="true"
                    ></i>
                    Activity Type
                  </a>
                </div>
              </div>
              <div
                id="collapseThirty"
                className="panel-collapse collapse"
                role="tabpanel"
                aria-labelledby="headingThirty"
              >
                <div className="panel-body">
                  <ul className="metismenu-container">
                    {(userType === "admin" || (userType === "subadmin" && assignAccess.includes('create'))) && (
                      <li className="metismenu-item">
                        <NavLink
                          to="/add-activity-type"
                          className="metismenu-link"
                        >
                          Add Activity Type
                        </NavLink>
                      </li>
                    )}
                    <li className="metismenu-item">
                      <NavLink
                        to="/manage-activity-type"
                        className="metismenu-link"
                      >
                        Manage Activity Types
                      </NavLink>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="panel panel-default metismenu vertical-nav-menu">
              <div
                className="panel-heading metismenu-container"
                role="tab"
                id="headingThirtyFour"
              >
                <div className="panel-title metismenu-item">
                  <a
                    className="collapsed metismenu-link"
                    role="button"
                    data-toggle="collapse"
                    data-parent="#accordionMenu"
                    href="#collapseThirtyFour"
                    aria-expanded="true"
                    aria-controls="collapseThirtyFour"
                  >
                    <i className="metismenu-icon fa-solid fa-section"></i>
                    Section
                  </a>
                </div>
              </div>
              <div
                id="collapseThirtyFour"
                className="panel-collapse collapse"
                role="tabpanel"
                aria-labelledby="headingThirtyFour"
              >
                <div className="panel-body">
                  <ul className="metismenu-container">
                    {(userType === "admin" || (userType === "subadmin" && assignAccess.includes('create'))) && (
                      <li className="metismenu-item">
                        <NavLink to="/add-sections" className="metismenu-link">
                          Add Section
                        </NavLink>
                      </li>
                    )}
                    <li className="metismenu-item">
                      <NavLink to="/manage-sections" className="metismenu-link">
                        Manage Section
                      </NavLink>
                    </li>
                  </ul>
                </div>
              </div>
            </div>

            <div className="panel panel-default metismenu vertical-nav-menu">
              <div
                className="panel-heading metismenu-container"
                role="tab"
                id="headingOne"
              >
                <div className="panel-title metismenu-item">
                  <a
                    className="collapsed metismenu-link"
                    role="button"
                    data-toggle="collapse"
                    data-parent="#accordionMenu"
                    href="#collapseFive"
                    aria-expanded="true"
                    aria-controls="collapseFive"
                  >
                    <i className="metismenu-icon fa fa-language"></i>
                    Language
                  </a>
                </div>
              </div>
              <div
                id="collapseFive"
                className="panel-collapse collapse"
                role="tabpanel"
                aria-labelledby="headingTwo"
              >
                <div className="panel-body">
                  <ul className="metismenu-container">
                    {(userType === "admin" || (userType === "subadmin" && assignAccess.includes('create'))) && (
                      <li className="metismenu-item">
                        <NavLink to="/add-language" className="metismenu-link">
                          Add Language
                        </NavLink>
                      </li>
                    )}
                    <li className="metismenu-item">
                      <NavLink to="/manage-language" className="metismenu-link">
                        Manage Language
                      </NavLink>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="panel panel-default metismenu vertical-nav-menu">
              <div
                className="panel-heading metismenu-container"
                role="tab"
                id="headingThirtyOne"
              >
                <div className="panel-title metismenu-item">
                  <a
                    className="collapsed metismenu-link"
                    role="button"
                    data-toggle="collapse"
                    data-parent="#accordionMenu"
                    href="#collapseThirtyOne"
                    aria-expanded="true"
                    aria-controls="collapseThirtyOne"
                  >
                    <i
                      className="metismenu-icon fa fa-globe"
                      aria-hidden="true"
                    ></i>
                    Product Sites
                  </a>
                </div>
              </div>
              <div
                id="collapseThirtyOne"
                className="panel-collapse collapse"
                role="tabpanel"
                aria-labelledby="headingThirtyOne"
              >
                <div className="panel-body">
                  <ul className="metismenu-container">
                    {(userType === "admin" || (userType === "subadmin" && assignAccess.includes('create'))) && (
                      <li className="metismenu-item">
                        <NavLink
                          to="/add-activity-site"
                          className="metismenu-link"
                        >
                          Add Product Site
                        </NavLink>
                      </li>
                    )}
                    <li className="metismenu-item">
                      <NavLink
                        to="/manage-activity-site"
                        className="metismenu-link"
                      >
                        Manage Product Site
                      </NavLink>
                    </li>
                    {(userType === "admin" || (userType === "subadmin" && assignAccess.includes('create'))) && (
                      <li className="metismenu-item">
                        <NavLink
                          to="/add-site-destination"
                          className="metismenu-link"
                        >
                          Add Site Destination
                        </NavLink>
                      </li>
                    )}
                    <li className="metismenu-item">
                      <NavLink
                        to="/manage-site-destinations"
                        className="metismenu-link"
                      >
                        Manage Site Destinations
                      </NavLink>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="panel panel-default metismenu vertical-nav-menu">
              <div
                className="panel-heading metismenu-container"
                role="tab"
                id="headingTwentyFour"
              >
                <div className="panel-title metismenu-item">
                  <a
                    className="collapsed metismenu-link"
                    role="button"
                    data-toggle="collapse"
                    data-parent="#accordionMenu"
                    href="#collapseTwentyFour"
                    aria-expanded="true"
                    aria-controls="collapseTwentyFour"
                  >
                    <i className="metismenu-icon fa fa-briefcase"></i>
                    Tour Modules
                  </a>
                </div>
              </div>
              <div
                id="collapseTwentyFour"
                className="panel-collapse collapse"
                role="tabpanel"
                aria-labelledby="headingTwentyFour"
              >
                <div className="panel-body">
                  <ul className="metismenu-container">
                    {(userType === "admin" || (userType === "subadmin" && assignAccess.includes('create'))) && (
                      <li className="metismenu-item">
                        <NavLink to="/add-tour-module" className="metismenu-link">
                          Add Tour Module
                        </NavLink>
                      </li>
                    )}
                    <li className="metismenu-item">
                      <NavLink
                        to="/manage-tour-module"
                        className="metismenu-link"
                      >
                        Manage Tour Module
                      </NavLink>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="panel panel-default metismenu vertical-nav-menu">
              <div
                className="panel-heading metismenu-container"
                role="tab"
                id="headingTwentySeven"
              >
                <div className="panel-title metismenu-item">
                  <a
                    className="collapsed metismenu-link"
                    role="button"
                    data-toggle="collapse"
                    data-parent="#accordionMenu"
                    href="#collapseTwentySeven"
                    aria-expanded="true"
                    aria-controls="collapseTwentySeven"
                  >
                    <i
                      className="metismenu-icon fa fa-users"
                      aria-hidden="true"
                    ></i>
                    Participant Type
                  </a>
                </div>
              </div>
              <div
                id="collapseTwentySeven"
                className="panel-collapse collapse"
                role="tabpanel"
                aria-labelledby="headingTwentySeven"
              >
                <div className="panel-body">
                  <ul className="metismenu-container">
                    {(userType === "admin" || (userType === "subadmin" && assignAccess.includes('create'))) && (
                      <li className="metismenu-item">
                        <NavLink
                          to="/add-participants"
                          className="metismenu-link"
                        >
                          Add Participant Type
                        </NavLink>
                      </li>
                    )}
                    <li className="metismenu-item">
                      <NavLink
                        to="/manage-participants"
                        className="metismenu-link"
                      >
                        Manage Participant Type
                      </NavLink>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="panel panel-default metismenu vertical-nav-menu"
            >
              <div
                className="panel-heading metismenu-container"
                role="tab"
                id="headingThirteen"
              >
                <div className="panel-title metismenu-item">
                  <a
                    className="collapsed metismenu-link"
                    role="button"
                    data-toggle="collapse"
                    data-parent="#accordionMenu"
                    href="#collapseThirteen"
                    aria-expanded="true"
                    aria-controls="collapseThirteen"
                  >
                    <i
                      className="metismenu-icon fa fa-tasks"
                      aria-hidden="true"
                    ></i>
                    Products
                  </a>
                </div>
              </div>
              <div
                id="collapseThirteen"
                className="panel-collapse collapse"
                role="tabpanel"
                aria-labelledby="headingThirteen"
              >
                <div className="panel-body">
                  <ul className="metismenu-container">
                    {(userType === "admin" || (userType === "subadmin" && assignAccess.includes('create'))) && (
                      <li className="metismenu-item">
                        <NavLink to="/add-activity" className="metismenu-link">
                          Add Product
                        </NavLink>
                      </li>
                    )}
                    <li className="metismenu-item">
                      <NavLink to="/manage-activity" className="metismenu-link">
                        Manage Product
                      </NavLink>
                    </li>
                    <li className="metismenu-item">
                      <NavLink
                        to="/manage-activity-drafts"
                        className="metismenu-link"
                      >
                        Manage Draft Product
                      </NavLink>
                    </li>
                    <li className="metismenu-item">
                      <NavLink
                        to="/check-merchant-additions"
                        className="metismenu-link"
                      >

                        Merchants New Products
                        <sup style={{ display: newMerchantCount?.length <= 0 ? 'none' : '' }}> <div className="supelement prelative"> <div className="absolutep">{newMerchantCount?.length} </div> </div> </sup>
                      </NavLink>
                    </li>

                    <li className="metismenu-item">
                      <NavLink
                        to="/product-change-requests"
                        className="metismenu-link"
                      >
                        Product Change Requests
                        <sup style={{ display: productChangeCount?.length <= 0 ? 'none' : '' }}> <div className="supelement prelative"> <div className="absolutep">{productChangeCount?.length}</div> </div> </sup>
                      </NavLink>
                    </li>

                    <li className="metismenu-item">
                      <NavLink
                        to="/check-merchant-reject-product"
                        className="metismenu-link"
                      >
                        Merchants Rejected Products
                      </NavLink>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="panel panel-default metismenu vertical-nav-menu d-none">
              <div
                className="panel-heading metismenu-container"
                role="tab"
                id="headingThirtyTwo"
              >
                <div className="panel-title metismenu-item">
                  <a
                    className="collapsed metismenu-link"
                    role="button"
                    data-toggle="collapse"
                    data-parent="#accordionMenu"
                    href="#collapseThirtyTwo"
                    aria-expanded="true"
                    aria-controls="collapseThirtyTwo"
                  >
                    <i
                      className="metismenu-icon fas fa-calendar-alt"
                      aria-hidden="true"
                    ></i>
                    Product Availability
                  </a>
                </div>
              </div>
              <div
                id="collapseThirtyTwo"
                className="panel-collapse collapse"
                role="tabpanel"
                aria-labelledby="headingThirtyTwo"
              >
                <div className="panel-body">
                  <ul className="metismenu-container">

                    <li className="metismenu-item">
                      <NavLink
                        to="/add-activity-availability"
                        className="metismenu-link"
                      >
                        Add Product Availability
                      </NavLink>
                    </li>

                    <li className="metismenu-item">
                      <NavLink
                        to="/check-activity-availability"
                        className="metismenu-link"
                      >
                        Check Product Availability
                      </NavLink>
                    </li>
                    <li className="metismenu-item">
                      <NavLink
                        to="/cancel-activity-availability"
                        className="metismenu-link"
                      >
                        Cancel Product Availability
                      </NavLink>
                    </li>
                  </ul>
                </div>
              </div>
            </div>


            <div className="panel panel-default metismenu vertical-nav-menu">
              <div
                className="panel-heading metismenu-container"
                role="tab"
                id="headingOne"
              >
                <div className="panel-title metismenu-item">
                  <a
                    className="collapsed metismenu-link"
                    role="button"
                    data-toggle="collapse"
                    data-parent="#accordionMenu"
                    href="#collapseSixtt"
                    aria-expanded="true"
                    aria-controls="collapseSixtt"
                  >
                    <i
                      className="metismenu-icon fa fa-shopping-cart"
                      aria-hidden="true"
                    ></i>
                    Product Stuff
                  </a>
                </div>
              </div>
              <div
                id="collapseSixtt"
                className="panel-collapse collapse"
                role="tabpanel"
                aria-labelledby="headingTwo"
              >
                <div className="panel-body">
                  <ul className="metismenu-container">
                    <li className="metismenu-item">
                      <NavLink to="/manageAdditionalStuff" className="metismenu-link">
                        Add Manage Stuff
                      </NavLink>
                    </li>

                  </ul>
                </div>
              </div>
            </div>
            <div className="panel panel-default metismenu vertical-nav-menu">
              <div
                className="panel-heading metismenu-container"
                role="tab"
                id="headingOne"
              >
                <div className="panel-title metismenu-item">
                  <a
                    className="collapsed metismenu-link"
                    role="button"
                    data-toggle="collapse"
                    data-parent="#accordionMenu"
                    href="#collapsefiftyx"
                    aria-expanded="true"
                    aria-controls="collapsefiftyx"
                  >
                    <i className="metismenu-icon fa fa-ticket-alt" aria-hidden="true"></i>
                    Coupon
                  </a>
                </div>
              </div>
              <div
                id="collapsefiftyx"
                className="panel-collapse collapse"
                role="tabpanel"
                aria-labelledby="headingTwo"
              >
                <div className="panel-body">
                  <ul className="metismenu-container">
                    <li className="metismenu-item">
                      <NavLink to="/manage-coupon" className="metismenu-link">
                        Add Manage Coupon
                      </NavLink>
                    </li>

                  </ul>
                </div>
              </div>
            </div>


            <div className="panel panel-default metismenu vertical-nav-menu">
              <div
                className="panel-heading metismenu-container"
                role="tab"
                id="headingThirtyThree"
              >
                <div className="panel-title metismenu-item">
                  <a
                    className="collapsed metismenu-link"
                    role="button"
                    data-toggle="collapse"
                    data-parent="#accordionMenu"
                    href="#collapseThirtyThree"
                    aria-expanded="true"
                    aria-controls="collapseThirtyThree"
                  >
                    <i
                      className="metismenu-icon fa fa-shopping-cart"
                      aria-hidden="true"
                    ></i>
                    Product Bookings
                  </a>
                </div>
              </div>
              <div
                id="collapseThirtyThree"
                className="panel-collapse collapse"
                role="tabpanel"
                aria-labelledby="headingThirtyThree"
              >
                <div className="panel-body">
                  <ul className="metismenu-container">

                    <li className="metismenu-item d-none">
                      <NavLink
                        to="/add-bookings-status-tag"
                        className="metismenu-link"
                      >
                        Add Booking Status Tag
                      </NavLink>
                    </li>

                    <li className="metismenu-item d-none">
                      <NavLink
                        to="/manage-bookings-status-tags"
                        className="metismenu-link"
                      >
                        Manage Booking Status Tag
                      </NavLink>
                    </li>
                    <li className="metismenu-item">
                      <NavLink to="/view-bookings" className="metismenu-link">
                        View Bookings
                      </NavLink>
                    </li>
                    <li className="metismenu-item">
                      <NavLink to="/order-invoice" className="metismenu-link">
                        Order Invoice
                      </NavLink>
                    </li>
                    <li className="metismenu-item">
                      <NavLink
                        to="/merchant-invoices"
                        className="metismenu-link"
                      >
                        Merchant Invoice
                      </NavLink>
                    </li>


                  </ul>

                </div>
              </div>
            </div>



            <div className="panel panel-default metismenu vertical-nav-menu">
              <div
                className="panel-heading metismenu-container"
                role="tab"
                id="headingThreenine"
              >
                <div className="panel-title metismenu-item">
                  <a
                    className="collapsed metismenu-link"
                    role="button"
                    data-toggle="collapse"
                    data-parent="#accordionMenu"
                    href="#collapseThreenine"
                    aria-expanded="true"
                    aria-controls="collapseThreenine"
                  >
                    <i
                      className="metismenu-icon fa fa-envelope"
                      aria-hidden="true"
                    ></i>
                    Mails
                  </a>
                </div>
              </div>
              <div
                id="collapseThreenine"
                className="panel-collapse collapse"
                role="tabpanel"
                aria-labelledby="headingThreenine"
              >
                <div className="panel-body">
                  <ul className="metismenu-container">
                    <li className="metismenu-item">
                      <NavLink
                        to="/add-mail"
                        className="metismenu-link"
                      >
                        Add Mail
                      </NavLink>
                    </li>
                    <li className="metismenu-item">
                      <NavLink
                        to="/manage-mail"
                        className="metismenu-link"
                      >
                        Manage Mails
                      </NavLink>
                    </li>
                  </ul>
                </div>
              </div>
            </div>



            <div className="panel panel-default metismenu vertical-nav-menu">
              <div
                className="panel-heading metismenu-container"
                role="tab"
                id="headingThreenine"
              >
                <div className="panel-title metismenu-item">
                  <a
                    className="collapsed metismenu-link"
                    role="button"
                    data-toggle="collapse"
                    data-parent="#accordionMenu"
                    href="#collapseThreeninety"
                    aria-expanded="true"
                    aria-controls="collapseThreeninety"
                  >
                    <i className="metismenu-icon fa-solid fa-city" aria-hidden="true"></i>
                    Day Out In (City)
                  </a>
                </div>
              </div>
              <div
                id="collapseThreeninety"
                className="panel-collapse collapse"
                role="tabpanel"
                aria-labelledby="headingThreenine"
              >
                <div className="panel-body">
                  <ul className="metismenu-container">
                    <li className="metismenu-item">
                      <NavLink
                        to="/add-dayOutInCity"
                        className="metismenu-link"
                      >
                        Add Day Out In (City)
                      </NavLink>
                    </li>
                    <li className="metismenu-item">
                      <NavLink
                        to="/manage-dayOutInCity"
                        className="metismenu-link"
                      >
                        Manage Day Out In (City)
                      </NavLink>
                    </li>
                  </ul>
                </div>
              </div>
            </div>

            <div className="panel panel-default metismenu vertical-nav-menu">
              <div
                className="panel-heading metismenu-container"
                role="tab"
                id="headingOne"
              >
                <div className="panel-title metismenu-item">
                  <a
                    className="collapsed metismenu-link"
                    role="button"
                    data-toggle="collapse"
                    data-parent="#accordionMenu"
                    href="#collapseSix"
                    aria-expanded="true"
                    aria-controls="collapseSix"
                  >
                    {/* <i className="metismenu-icon fa fa-wrench"></i> */}
                    <i className="metismenu-icon fa fa-picture-o"></i>
                    Banner
                  </a>
                </div>
              </div>
              <div
                id="collapseSix"
                className="panel-collapse collapse"
                role="tabpanel"
                aria-labelledby="headingTwo"
              >
                <div className="panel-body">
                  <ul className="metismenu-container">
                    <li className="metismenu-item">
                      <NavLink to="/add-banner" className="metismenu-link">
                        Add Banner
                      </NavLink>
                    </li>
                    <li className="metismenu-item">
                      <NavLink to="/manage-banner" className="metismenu-link">
                        Manage Banner
                      </NavLink>
                    </li>
                  </ul>
                </div>
              </div>
              {/* <ul className="pandelmetismenu-container">
                <li className="metismenu-item">
                  <a className="metismenu-link" href="#/dashboards/basic">
                    <i className="metismenu-icon fa-regular fa-map"></i>
                    Add Banner
                  </a>
                </li>
              </ul> */}
            </div>

            <div className="panel panel-default metismenu vertical-nav-menu">
              <div
                className="panel-heading metismenu-container"
                role="tab"
                id="headingOne"
              >
                <div className="panel-title metismenu-item">
                  <a
                    className="collapsed metismenu-link"
                    role="button"
                    data-toggle="collapse"
                    data-parent="#accordionMenu"
                    href="#collapseEleven"
                    aria-expanded="true"
                    aria-controls="collapseEleven"
                  >
                    <i className="metismenu-icon fas fa-newspaper"></i>
                    Press
                  </a>
                </div>
              </div>
              <div
                id="collapseEleven"
                className="panel-collapse collapse"
                role="tabpanel"
                aria-labelledby="headingTwo"
              >
                <div className="panel-body">
                  <ul className="metismenu-container">
                    <li className="metismenu-item">
                      <NavLink to="/add-press" className="metismenu-link">
                        Add Press Release
                      </NavLink>
                    </li>
                    <li className="metismenu-item">
                      <NavLink to="/manage-press" className="metismenu-link">
                        Manage Press Release
                      </NavLink>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="panel panel-default metismenu vertical-nav-menu">
              <div
                className="panel-heading metismenu-container"
                role="tab"
                id="headingOne"
              >
                <div className="panel-title metismenu-item">
                  <a
                    className="collapsed metismenu-link"
                    role="button"
                    data-toggle="collapse"
                    data-parent="#accordionMenu"
                    href="#collapseTwelve"
                    aria-expanded="true"
                    aria-controls="collapseTwelve"
                  >
                    <i className="metismenu-icon fa-solid fa-blog"></i>
                    Blogs
                  </a>
                </div>
              </div>
              <div
                id="collapseTwelve"
                className="panel-collapse collapse"
                role="tabpanel"
                aria-labelledby="headingTwo"
              >
                <div className="panel-body">
                  <ul className="metismenu-container">
                    <li className="metismenu-item">
                      <NavLink to="/add-blog-type" className="metismenu-link">
                        Add Blog Type
                      </NavLink>
                    </li>
                    <li className="metismenu-item">
                      <NavLink
                        to="/manage-blog-types"
                        className="metismenu-link"
                      >
                        Manage Blog Type
                      </NavLink>
                    </li>
                    <li className="metismenu-item">
                      <NavLink
                        to="/add-blog-type-category"
                        className="metismenu-link"
                      >
                        Add Blog Type Category
                      </NavLink>
                    </li>
                    <li className="metismenu-item">
                      <NavLink
                        to="/manage-blog-type-category"
                        className="metismenu-link"
                      >
                        Manage Blog Type Categories
                      </NavLink>
                    </li>
                    <li className="metismenu-item">
                      <NavLink to="/add-blogs" className="metismenu-link">
                        Add Blogs
                      </NavLink>
                    </li>
                    <li className="metismenu-item">
                      <NavLink to="/manage-blogs" className="metismenu-link">
                        Manage Blogs
                      </NavLink>
                    </li>
                  </ul>
                </div>
              </div>
            </div>

            <div className="panel panel-default metismenu vertical-nav-menu">
              <div
                className="panel-heading metismenu-container"
                role="tab"
                id="headingOne"
              >
                <div className="panel-title metismenu-item">
                  <a
                    className="collapsed metismenu-link"
                    role="button"
                    data-toggle="collapse"
                    data-parent="#accordionMenu"
                    href="#collapseTen"
                    aria-expanded="true"
                    aria-controls="collapseTen"
                  >
                    {/* <i className="metismenu-icon fa fa-wrench"></i> */}
                    <i className="metismenu-icon fa fa-info-circle "></i>
                    About Us
                  </a>
                </div>
              </div>
              <div
                id="collapseTen"
                className="panel-collapse collapse"
                role="tabpanel"
                aria-labelledby="headingTwo"
              >
                <div className="panel-body">
                  <ul className="metismenu-container">
                    {/* <li className="metismenu-item">
                      <NavLink to="/add-aboutUs" className="metismenu-link">
                        Add About Us
                      </NavLink>
                    </li> */}
                    <li className="metismenu-item">
                      <NavLink to="/manage-aboutUs" className="metismenu-link">
                        Manage About Us
                      </NavLink>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            {/* <div className="panel panel-default metismenu vertical-nav-menu">
              <div
                className="panel-heading metismenu-container"
                role="tab"
                id="headingOne"
              >
                <div className="panel-title metismenu-item">
                  <a
                    className="collapsed metismenu-link"
                    role="button"
                    data-toggle="collapse"
                    data-parent="#accordionMenu"
                    href="#collapseFourteen"
                    aria-expanded="true"
                    aria-controls="collapseFourteen"
                  >
                    <i className="metismenu-icon fa fa-graduation-cap"></i>
                    Careers
                  </a>
                </div>
              </div>
              <div
                id="collapseFourteen"
                className="panel-collapse collapse"
                role="tabpanel"
                aria-labelledby="headingTwo"
              >
                <div className="panel-body">
                  <ul className="metismenu-container">
                    <li className="metismenu-item">
                      <NavLink to="/add-careers" className="metismenu-link">
                        Add Careers
                      </NavLink>
                    </li>
                    <li className="metismenu-item">
                      <NavLink to="/manage-careers" className="metismenu-link">
                        Manage Careers
                      </NavLink>
                    </li>
                  </ul>
                </div>
              </div>
            </div> */}
            <div className="panel panel-default metismenu vertical-nav-menu">
              <div
                className="panel-heading metismenu-container"
                role="tab"
                id="headingOne"
              >
                <div className="panel-title metismenu-item">
                  <a
                    className="collapsed metismenu-link"
                    role="button"
                    data-toggle="collapse"
                    data-parent="#accordionMenu"
                    href="#collapseFifteen"
                    aria-expanded="true"
                    aria-controls="collapseFifteen"
                  >
                    <i className="metismenu-icon fa fa-legal"></i>
                    Legal Notices
                  </a>
                </div>
              </div>
              <div
                id="collapseFifteen"
                className="panel-collapse collapse"
                role="tabpanel"
                aria-labelledby="headingTwo"
              >
                <div className="panel-body">
                  <ul className="metismenu-container">
                    {/* <li className="metismenu-item">
                      <NavLink
                        to="/add-legal-notices"
                        className="metismenu-link"
                      >
                        Add Legal Notice
                      </NavLink>
                    </li> */}
                    <li className="metismenu-item">
                      <NavLink
                        to="/manage-legal-notices"
                        className="metismenu-link"
                      >
                        Manage Legal Notice
                      </NavLink>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="panel panel-default metismenu vertical-nav-menu">
              <div
                className="panel-heading metismenu-container"
                role="tab"
                id="headingOne"
              >
                <div className="panel-title metismenu-item">
                  <a
                    className="collapsed metismenu-link"
                    role="button"
                    data-toggle="collapse"
                    data-parent="#accordionMenu"
                    href="#collapseSeventeen"
                    aria-expanded="true"
                    aria-controls="collapseSeventeen"
                  >
                    <i className="metismenu-icon fa-solid fa-blog"></i>
                    Privacy Policy
                  </a>
                </div>
              </div>
              <div
                id="collapseSeventeen"
                className="panel-collapse collapse"
                role="tabpanel"
                aria-labelledby="headingTwo"
              >
                <div className="panel-body">
                  <ul className="metismenu-container">
                    {/* <li className="metismenu-item">
                      <NavLink
                        to="/add-privacy-policy"
                        className="metismenu-link"
                      >
                        Add Privacy Policy
                      </NavLink>
                    </li> */}
                    <li className="metismenu-item">
                      <NavLink
                        to="/manage-privacy-policy"
                        className="metismenu-link"
                      >
                        Manage Privacy Policy
                      </NavLink>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="panel panel-default metismenu vertical-nav-menu">
              <div
                className="panel-heading metismenu-container"
                role="tab"
                id="headingOne"
              >
                <div className="panel-title metismenu-item">
                  <a
                    className="collapsed metismenu-link"
                    role="button"
                    data-toggle="collapse"
                    data-parent="#accordionMenu"
                    href="#collapseEighteen"
                    aria-expanded="true"
                    aria-controls="collapseEighteen"
                  >
                    <i className="metismenu-icon fa-solid fa-cookie"></i>
                    Cookies Marketing
                  </a>
                </div>
              </div>
              <div
                id="collapseEighteen"
                className="panel-collapse collapse"
                role="tabpanel"
                aria-labelledby="headingTwo"
              >
                <div className="panel-body">
                  <ul className="metismenu-container">
                    {/* <li className="metismenu-item">
                      <NavLink
                        to="/add-cookies-marketing"
                        className="metismenu-link"
                      >
                        Add Cookies Marketing
                      </NavLink>
                    </li> */}
                    <li className="metismenu-item">
                      <NavLink
                        to="/manage-cookies-marketing"
                        className="metismenu-link"
                      >
                        Manage Cookies Marketing
                      </NavLink>
                    </li>
                  </ul>
                </div>
              </div>
            </div>

            <div className="panel panel-default metismenu vertical-nav-menu">
              <div
                className="panel-heading metismenu-container"
                role="tab"
                id="headingOne"
              >
                <div className="panel-title metismenu-item">
                  <a
                    className="collapsed metismenu-link"
                    role="button"
                    data-toggle="collapse"
                    data-parent="#accordionMenu"
                    href="#collapseNineteen"
                    aria-expanded="true"
                    aria-controls="collapseNineteen"
                  >
                    <i
                      className="metismenu-icon fa fa-address-book"
                      aria-hidden="true"
                    ></i>
                    Contacts
                  </a>
                </div>
              </div>
              <div
                id="collapseNineteen"
                className="panel-collapse collapse"
                role="tabpanel"
                aria-labelledby="headingTwo"
              >
                <div className="panel-body">
                  <ul className="metismenu-container">
                    <li className="metismenu-item">
                      <NavLink to="/add-topic" className="metismenu-link">
                        Create Topic
                      </NavLink>
                    </li>
                    <li className="metismenu-item">
                      <NavLink to="/manage-topic" className="metismenu-link">
                        Manage Topic
                      </NavLink>
                    </li>
                    <li className="metismenu-item">
                      <NavLink to="/add-subtopic" className="metismenu-link">
                        Create Sub Topic
                      </NavLink>
                    </li>
                    <li className="metismenu-item">
                      <NavLink to="/manage-subtopic" className="metismenu-link">
                        Manage Sub Topic
                      </NavLink>
                    </li>
                    <li className="metismenu-item">
                      <NavLink
                        to="/add-contact-email"
                        className="metismenu-link"
                      >
                        Add Contact Email
                      </NavLink>
                    </li>
                    <li className="metismenu-item">
                      <NavLink to="/viewAllContuctIssue" className="metismenu-link">
                        view all Contuct issues
                      </NavLink>
                    </li>
                    {/*<li className="metismenu-item">
                      <NavLink
                        to="/manage-subquestion"
                        className="metismenu-link"
                      >
                        Manage Sub-Question
                      </NavLink>
                    </li> */}
                  </ul>
                </div>
              </div>
            </div>
            <div className="panel panel-default metismenu vertical-nav-menu">
              <div
                className="panel-heading metismenu-container"
                role="tab"
                id="headingOne"
              >
                <div className="panel-title metismenu-item">
                  <a
                    className="collapsed metismenu-link"
                    role="button"
                    data-toggle="collapse"
                    data-parent="#accordionMenu"
                    href="#collapseTwentyTwo"
                    aria-expanded="true"
                    aria-controls="collapseTwentyTwo"
                  >
                    <i
                      className="metismenu-icon fa fa-gift"
                      aria-hidden="true"
                    ></i>
                    Offers
                  </a>
                </div>
              </div>
              <div
                id="collapseTwentyTwo"
                className="panel-collapse collapse"
                role="tabpanel"
                aria-labelledby="headingTwo"
              >
                <div className="panel-body">
                  <ul className="metismenu-container">
                    <li className="metismenu-item">
                      <NavLink
                        to="/add-special-offers"
                        className="metismenu-link"
                      >
                        Add Special Offers
                      </NavLink>
                    </li>
                    <li className="metismenu-item">
                      <NavLink
                        to="/manage-special-offers"
                        className="metismenu-link"
                      >
                        Manage Special Offers
                      </NavLink>
                    </li>
                    <li className="metismenu-item">
                      <NavLink
                        to="/manage-merchant-offers"
                        className="metismenu-link"
                      >
                        Merchant Offers
                      </NavLink>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="panel panel-default metismenu vertical-nav-menu">
              <div
                className="panel-heading metismenu-container"
                role="tab"
                id="headingOne"
              >
                <div className="panel-title metismenu-item">
                  <a
                    className="collapsed metismenu-link"
                    role="button"
                    data-toggle="collapse"
                    data-parent="#accordionMenu"
                    href="#collapseTwentyTwoAgency"
                    aria-expanded="true"
                    aria-controls="collapseTwentyTwoAgency"
                  >
                    <i
                      className="metismenu-icon fa fa-gift"
                      aria-hidden="true"
                    ></i>
                    Online Travel Agency
                  </a>
                </div>
              </div>
              <div
                id="collapseTwentyTwoAgency"
                className="panel-collapse collapse"
                role="tabpanel"
                aria-labelledby="headingTwo"
              >
                <div className="panel-body">
                  <ul className="metismenu-container">
                    <li className="metismenu-item">
                      <NavLink
                        to="/add_travel_agency"
                        className="metismenu-link"
                      >
                        Add Travel Agency
                      </NavLink>
                    </li>
                    <li className="metismenu-item">
                      <NavLink
                        to="/manage_travel_agency"
                        className="metismenu-link"
                      >
                        Manage Travel Agency
                      </NavLink>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="panel panel-default metismenu vertical-nav-menu">
              <div
                className="panel-heading metismenu-container"
                role="tab"
                id="headingOne"
              >
                <div className="panel-title metismenu-item">
                  <a
                    className="collapsed metismenu-link"
                    role="button"
                    data-toggle="collapse"
                    data-parent="#accordionMenu"
                    href="#collapseTwentyThirdParty"
                    aria-expanded="true"
                    aria-controls="collapseTwentyThirdParty"
                  >
                    <i className="metismenu-icon fa-solid fa-file-contract"></i>
                    Third Party Platform
                  </a>
                </div>
              </div>
              <div
                id="collapseTwentyThirdParty"
                className="panel-collapse collapse"
                role="tabpanel"
                aria-labelledby="headingTwo"
              >
                <div className="panel-body">
                  <ul className="metismenu-container">
                    <li className="metismenu-item">
                      <NavLink
                        to="/add_trird_party_platform"
                        className="metismenu-link"
                      >
                        Add Third Party Platform
                      </NavLink>
                    </li>
                    <li className="metismenu-item">
                      <NavLink
                        to="/manage_trird_party_platform"
                        className="metismenu-link"
                      >
                        Manage Third Party Platform
                      </NavLink>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="panel panel-default metismenu vertical-nav-menu">
              <div
                className="panel-heading metismenu-container"
                role="tab"
                id="headingOne"
              >
                <div className="panel-title metismenu-item">
                  <a
                    className="collapsed metismenu-link"
                    role="button"
                    data-toggle="collapse"
                    data-parent="#accordionMenu"
                    href="#collapseTwentyThree"
                    aria-expanded="true"
                    aria-controls="collapseTwentyThree"
                  >
                    <i className="metismenu-icon fa-solid fa-file-contract"></i>
                    Terms & Conditions
                  </a>
                </div>
              </div>
              <div
                id="collapseTwentyThree"
                className="panel-collapse collapse"
                role="tabpanel"
                aria-labelledby="headingTwo"
              >
                <div className="panel-body">
                  <ul className="metismenu-container">
                    {/* <li className="metismenu-item">
                      <NavLink
                        to="/add-terms-conditions"
                        className="metismenu-link"
                      >
                        Add Terms & Conditions
                      </NavLink>
                    </li> */}
                    <li className="metismenu-item">
                      <NavLink
                        to="/manage-terms-conditions"
                        className="metismenu-link"
                      >
                        Manage Terms & Conditions
                      </NavLink>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="panel panel-default metismenu vertical-nav-menu">
              <div
                className="panel-heading metismenu-container"
                role="tab"
                id="headingTwentyFive"
              >
                <div className="panel-title metismenu-item">
                  <a
                    className="collapsed metismenu-link"
                    role="button"
                    data-toggle="collapse"
                    data-parent="#accordionMenu"
                    href="#collapseTwentyFive"
                    aria-expanded="true"
                    aria-controls="collapseTwentyFive"
                  >
                    <i className="metismenu-icon fas fa-digital-tachograph"></i>
                    Digital Service Information
                  </a>
                </div>
              </div>
              <div
                id="collapseTwentyFive"
                className="panel-collapse collapse"
                role="tabpanel"
                aria-labelledby="headingTwentyFive"
              >
                <div className="panel-body">
                  <ul className="metismenu-container">
                    {/* <li className="metismenu-item">
                      <NavLink
                        to="/add-digital-info"
                        className="metismenu-link"
                      >
                        Add Digital Information
                      </NavLink>
                    </li> */}
                    <li className="metismenu-item">
                      <NavLink
                        to="/manage-digital-info"
                        className="metismenu-link"
                      >
                        Manage Digital Information
                      </NavLink>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="panel panel-default metismenu vertical-nav-menu">
              <div
                className="panel-heading metismenu-container"
                role="tab"
                id="headingTwentySix"
              >
                <div className="panel-title metismenu-item">
                  <a
                    className="collapsed metismenu-link"
                    role="button"
                    data-toggle="collapse"
                    data-parent="#accordionMenu"
                    href="#collapseTwentySix"
                    aria-expanded="true"
                    aria-controls="collapseTwentySix"
                  >
                    <i
                      className="metismenu-icon fa fa-sitemap"
                      aria-hidden="true"
                    ></i>
                    Sitemap
                  </a>
                </div>
              </div>
              <div
                id="collapseTwentySix"
                className="panel-collapse collapse"
                role="tabpanel"
                aria-labelledby="headingTwentySix"
              >
                <div className="panel-body">
                  <ul className="metismenu-container">
                    <li className="metismenu-item">
                      <NavLink to="/add-sitemap" className="metismenu-link">
                        Add Sitemap
                      </NavLink>
                    </li>
                    <li className="metismenu-item">
                      <NavLink to="/manage-sitemap" className="metismenu-link">
                        Manage Sitemap
                      </NavLink>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="panel panel-default metismenu vertical-nav-menu">
              <div
                className="panel-heading metismenu-container"
                role="tab"
                id="headingTwentySeven"
              >
                <div className="panel-title metismenu-item">
                  <a
                    className="collapsed metismenu-link"
                    role="button"
                    data-toggle="collapse"
                    data-parent="#accordionMenu"
                    href="#collapseTwentySeven"
                    aria-expanded="true"
                    aria-controls="collapseTwentySeven"
                  >
                    <i
                      className="metismenu-icon fa fa-question"
                      aria-hidden="true"
                    ></i>
                    FAQ
                  </a>
                </div>
              </div>
              <div
                id="collapseTwentySeven"
                className="panel-collapse collapse"
                role="tabpanel"
                aria-labelledby="headingTwentySeven"
              >
                <div className="panel-body">
                  <ul className="metismenu-container">
                    <li className="metismenu-item">
                      <NavLink to="/add-faq-type" className="metismenu-link">
                        Add Faq Type
                      </NavLink>
                    </li>
                    <li className="metismenu-item">
                      <NavLink to="/manage-faq-type" className="metismenu-link">
                        Manage Faq Type
                      </NavLink>
                    </li>
                    <li className="metismenu-item">
                      <NavLink to="/add-faq" className="metismenu-link">
                        Add Faq
                      </NavLink>
                    </li>
                    <li className="metismenu-item">
                      <NavLink to="/manage-faq" className="metismenu-link">
                        Manage Faq
                      </NavLink>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="panel panel-default metismenu vertical-nav-menu">
              <div
                className="panel-heading metismenu-container"
                role="tab"
                id="headingTwentyNine"
              >
                <div className="panel-title metismenu-item">
                  <a
                    className="collapsed metismenu-link"
                    role="button"
                    data-toggle="collapse"
                    data-parent="#accordionMenu"
                    href="#collapseTwentyNine"
                    aria-expanded="true"
                    aria-controls="collapseTwentyNine"
                  >
                    {/* <i className="metismenu-icon fa fa-wrench"></i> */}
                    <i
                      className="metismenu-icon fa fa-gift"
                      aria-hidden="true"
                    ></i>
                    Gift
                  </a>
                </div>
              </div>
              <div
                id="collapseTwentyNine"
                className="panel-collapse collapse"
                role="tabpanel"
                aria-labelledby="headingTwentyNine"
              >
                <div className="panel-body">
                  <ul className="metismenu-container">
                    <li className="metismenu-item">
                      <NavLink to="/add-gift" className="metismenu-link">
                        Add Gift
                      </NavLink>
                    </li>
                    <li className="metismenu-item">
                      <NavLink to="/manage-gift" className="metismenu-link">
                        Manage Gift
                      </NavLink>
                    </li>
                    <li className="metismenu-item">
                      <NavLink to="/gift-questions" className="metismenu-link">
                        Add Gift Question
                      </NavLink>
                    </li>
                    <li className="metismenu-item">
                      <NavLink
                        to="/manage-gift-questions"
                        className="metismenu-link"
                      >
                        Manage Gift Questions
                      </NavLink>
                    </li>
                  </ul>
                </div>
              </div>
            </div>

            <div className="metismenu vertical-nav-menu">
              <ul className="metismenu-container">
                <li className="metismenu-item">
                  <NavLink
                    className="metismenu-link"
                    to="/merchants-chat"
                    style={{ fontWeight: "bold" }}
                  >
                    <i className=" metismenu-icon fas fa-comment"></i>
                    Merchant Chat
                  </NavLink>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

import React, { useState, useRef, useEffect } from "react";
import Layout from "../../../Layout";
import toast, { Toaster } from 'react-hot-toast';
import HomeService from "../../../services/api/HomeService";
import { capitalizeFirstLetter } from '../../../utils';

function AddFaqType() {
    const [faqType, setFaqType] = useState("")

    const handleSubmit = async (event) => {
        event.preventDefault();
        if (faqType == "") {
            return toast.error('Please Enter Faq Type')
        }
        let data = {
            name: faqType
        }
        let res = await HomeService.addfaqtype(data);
        if (res && res.status) {
            toast.success('FAQ type added successfully')
            setFaqType("")
        } else {
            return toast.error('Please Enter Faq Type')
        }
    }
    return (

        <form className=""
            onSubmit={(e) => handleSubmit(e)}
        >
            <div component="div" className="TabsAnimation appear-done enter-done">
                <div className="main-card mb-3 card">
                    <div className="card-body font-weight-bold">FAQ</div>
                    <div className="col-md-12">
                        <div className="mb-3">
                            <label className="form-label">
                                Add Faq Type
                            </label>
                            <input
                                name="Faq Type"
                                type="text"
                                className="form-control"
                                value={faqType}
                                onChange={(e) => setFaqType(capitalizeFirstLetter(e))}
                            />
                        </div>
                    </div>
                    <button
                        className="mt-2 btn btn-primary ml-auto d-table"
                        type="submit"
                        style={{ margin: "5px" }}
                    >
                        Add
                    </button>
                </div>
            </div>
        </form>

    )
}

export default AddFaqType;
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Table from "../../../Component/Table";
import { toast } from "react-toastify";
import { JSON_API } from "../../../services/api/jsonApiCallWithInterceptor";
import { getAllContacts } from "../../../redux/adminSlice/contactsSlice";
import Loader from "../../../Component/Loader";
import { capitalizeFirstLetter } from "../../../utils";
import { useNavigate } from "react-router-dom";

let initialValues = {
  id: "",
  topic: "",
  topicId: "",
  questions: "",
};
const ManageQuestions = () => {
  const dispatch = useDispatch();
  const [selectedQue, setSelectedQue] = useState({ ...initialValues });
  const [deleteId, setDeleteId] = useState("");
  const [modalToggleView, setmodalToggleView] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [modal, setModal] = useState(false);
  const navigate = useNavigate();
  // Redux stores
  const { contacts } = useSelector((state) => state.contactsSlice);
  console.log("contacts", contacts);

  useEffect(() => {
    dispatch(getAllContacts());
  }, []);

  // @ Submit Handler
  const submitHandler = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    try {
      let res = await JSON_API["editQuestion"]({ ...selectedQue });
      if (res.isSuccess) {
        dispatch(getAllContacts());
        toast.success("Question updated successfully");
        setSelectedQue({ ...initialValues });
        setModal(false);
      } else toast.error(res?.message || "Something went wrong");
    } catch (error) {
      toast.error(error?.message || "Something went wrong");
    }
    setIsLoading(false);
  };

  // ~ Delete Handler
  const deleteHandler = async (id) => {
    setIsLoading(true);
    try {
      let res = await JSON_API["deleteQuestion"]({ id });
      if (res.isSuccess) {
        dispatch(getAllContacts());
        toast.success("Question deleted successfully");
        setModal(false);
      } else toast.error(res?.message || "Something went wrong");
    } catch (error) {
      toast.error(error?.message || "Something went wrong");
    }
    setIsLoading(false);
  };

  const tableData = {
    columns: [
      {
        label: "SL",
        field: "sl",
        sort: "asc",
        width: 50,
      },
      {
        label: "Topic",
        field: "topic",
        sort: "asc",
        width: 100,
      },

      {
        label: "Question",
        field: "question",
        sort: "asc",
        width: 100,
      },
      {
        label: "Action",
        field: "action",
        sort: "asc",
        width: 150,
      },
    ],
    rows:
      contacts?.length > 0
        ? contacts.map((item, idx) => {
            return {
              sl: idx + 1,
              topic: (
                <div className="widget-content p-0">
                  <div className="widget-content-wrapper">
                    <div className="widget-content-left flex2">
                      <div
                        className="widget-heading"
                        style={{ opacity: 0.75, textAlign: "center" }}
                      >
                        {item?.topic}
                      </div>
                    </div>
                  </div>
                </div>
              ),

              question: (
                <div className="widget-content p-0">
                  <div className="widget-content-wrapper">
                    <div className="widget-content-left flex2">
                      <div
                        className="widget-heading"
                        style={{ opacity: 0.75, textAlign: "center" }}
                      >
                        {item?.questions}
                      </div>
                    </div>
                  </div>
                </div>
              ),
              action: (
                <div>
                  <button
                    className="me-2 btn-icon btn-icon-only btn btn-outline-danger"
                    onClick={() => {
                      setDeleteId(item?._id);
                      setmodalToggleView(1);
                      setModal(true);
                    }}
                  >
                    <i className="fa-solid fa-trash-can" />
                  </button>
                  <button
                    className="me-2 btn-icon btn-icon-only btn btn-outline-success"
                    onClick={() => {
                      // setSelectedQue(prev => {
                      //   let update = JSON.parse(JSON.stringify(prev));
                      //   update.id = item?._id;
                      //   update.topic = item?.topic;
                      //   update.topicId = item?.topicId;
                      //   update.questions = item?.questions;
                      //   return { ...update };
                      // });
                      // setmodalToggleView(0);
                      // setModal(true);
                      navigate(`/edit-question/${item._id}`);
                    }}
                  >
                    <i class="fa-regular fa-pen-to-square"></i>
                  </button>
                </div>
              ),
            };
          })
        : [],
  };

  const renderModalView = (index) => {
    switch (index) {
      case 0:
        return (
          <>
            {isLoading && <Loader />}
            <div className="card p-4">
              <h4
                className="text-primary pb-4 font-weight-bold"
                style={{ fontSize: "16px" }}
              >
                Edit Question
              </h4>
              <form onSubmit={submitHandler}>
                <div className="mb-3">
                  <label
                    htmlFor="exampleInputEmail1"
                    className="form-label font-weight-bold"
                  >
                    Topic
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="exampleInputEmail1"
                    aria-describedby="emailHelp"
                    value={selectedQue.topic}
                    disabled={true}
                  />
                </div>
                <div className="mb-3">
                  <label
                    htmlFor="exampleInputPassword1"
                    className="form-label font-weight-bold"
                  >
                    Question
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="exampleInputPassword1"
                    value={selectedQue.questions}
                    onChange={(e) =>
                      setSelectedQue((prev) => {
                        let update = JSON.parse(JSON.stringify(prev));
                        update.questions = capitalizeFirstLetter(e);
                        return { ...update };
                      })
                    }
                  />
                </div>
                <button
                  type="submit"
                  className="btn btn-primary font-weight-bold"
                  style={{ fontSize: "14px" }}
                >
                  Save Changes
                </button>
              </form>
            </div>
          </>
        );
      case 1:
        return (
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">Delete Info</h5>
            </div>
            <div className="modal-body">
              <div className="deleteModalContainer p-5">
                <i className="ri-close-circle-line deleteIcon"></i>
                <h3>Are You Sure?</h3>
                <span>Do You Want To Delete This Information?</span>
                <div className="deleteModalNote">
                  <span>*Note : This data will be permanently deleted</span>
                </div>
              </div>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-link"
                onClick={() => {
                  setModal(false);
                }}
              >
                Cancel
              </button>
              <button
                type="button"
                className="btn btn-primary"
                onClick={deleteHandler.bind(null, deleteId)}
              >
                Delete
              </button>
            </div>
          </div>
        );
      default:
        return null;
    }
  };

  return (
    <>
      {isLoading && <Loader />}
      <Table
        striped
        tableTitle={"Manage Questions"}
        data={tableData}
        modal={modal}
        setModal={setModal}
        modalToggleView={modalToggleView}
        renderModalView={renderModalView}
      />
    </>
  );
  //   }
};

export default ManageQuestions;

// Hooks
import React, { useState, useRef, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

// Helpers
import { toast } from "react-toastify";

// Utilities
import {
  capitalizeFirstLetter,
  apiCallSuccess,
  apiCallError,
} from "../../../utils";

// API functions
import { JSON_API } from "../../../services/api/jsonApiCallWithInterceptor";

// Redux actions
import { getAllSections } from "../../../redux/adminSlice/sectionSlice";
import { getAllActivityTypes } from "../../../redux/adminSlice/activitySlice";
import { getAllCategories } from "../../../redux/adminSlice/categorySlice";

// Components
import Loader from "../../../Component/Loader";

const initialValues = {
  categoryName: "",
};

const createErrors = {
  categoryName: "",
  //   activityTypeId: "",
};

function AddCategory() {
  const dispatch = useDispatch();
  const [category, setsection] = useState(initialValues);
  const [error, setError] = useState("");
  const [errors, seterrors] = useState(createErrors);
  const [isLoading, setisLoading] = useState(false);

  // Component did mount
  useEffect(() => {
    dispatch(getAllActivityTypes());
    dispatch(getAllCategories());
  }, []);

  // Redux stores
  const { activity_types } = useSelector(
    (state) => state.adminActivityActionSlice
  );
  const { categories } = useSelector((state) => state.adminCategoryActionSlice);
  // console.log("categories", categories);

  const handleChange = (event) => {
    event.preventDefault();
    let input = capitalizeFirstLetter(event);
    // console.log(event.target.name, event.target.value);
    setsection({ ...category, [event.target.name]: input });
  };

  const handleValidation = () => {
    const { categoryName } = category;

    if (categoryName === "") {
      seterrors({ ...errors, categoryName: "Category name is required" });

      return false;
    }

    return true;
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    // console.log("submitting");
    if (handleValidation()) {
      try {
        setisLoading(true);
        let res = await JSON_API["adminAddCategory"](category);

        if (res.isSuccess) {
          dispatch(getAllCategories());
          seterrors(createErrors);
          setsection(initialValues);
          setisLoading(false);
          // apiCallSuccess(res.data);
          toast.success('Category added successfully!');
        } else {
          setisLoading(false);
          apiCallError(res?.error?.message, setError);
        }
      } catch (error) {
        setisLoading(false);
        apiCallError(error.message, setError);
      }
    }
  };

  return (
    <>
      {isLoading && <Loader />}
      <form className="" onSubmit={(e) => handleSubmit(e)}>
        <div component="div" className="TabsAnimation appear-done enter-done">
          <div className="main-card mb-3 card">
          <div className="card-body">
            <div className="card-title">ADD CATEGORY</div>
            
            <div className="col-md-12">
              <div className="mb-3">
                <label htmlFor="categoryName" className="form-label">
                  Name*
                </label>
                <input
                  name="categoryName"
                  id="categoryName"
                  type="text"
                  className="form-control"
                  autoComplete="false"
                  value={category.categoryName}
                  onChange={(e) => handleChange(e)}
                />
              </div>
              {errors.categoryName && (
                <span className="errorMessageText text-danger">
                  {errors.categoryName}
                </span>
              )}
            </div>

            

            <button
              className="mt-2 btn btn-primary ml-auto d-table"
              type="submit"
              style={{ margin: "5px" }}
            >
              Add
            </button>
            </div>
          </div>
        </div>
      </form>
    </>
  );
}

export default AddCategory;

// Hooks
import React, { useState, useRef, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

// Helpers
import { toast } from "react-toastify";

// Utilities
import {
  apiCallSuccess,
  apiCallError,
  capitalizeFirstLetter,
} from "../../../utils/common-utils";

// API functions
import { fileUpload } from "../../../services/api/apiHelpers";
import { JSON_API } from "../../../services/api/jsonApiCallWithInterceptor";

// Redux actions
import { getAllSitemaps } from "../../../redux/adminSlice/sitemapSlice";
import { getAllcountres } from "../../../redux/adminSlice/countrySlice";

// Components
import Layout from "../../../Layout";
import Table from "../../../Component/Table";
import { useNavigate, useParams } from "react-router-dom";

const initialValues = {
  continent: "",
  countryId: "",
};

const createErrors = {
  continent: "",
  countryId: "",
  //   activityTypeId: "",
};

function EditSiteMap() {
  const dispatch = useDispatch();
  const [category, setsection] = useState(initialValues);
  const [error, setError] = useState("");
  const [errors, seterrors] = useState(createErrors);
  const [modal, setModal] = useState(false);
  const [modalToggleView, setmodalToggleView] = useState(null);
  const [itemIndex, setitemIndex] = useState(0);
  const [categoryEdit, setCategoryEdit] = useState(initialValues);
  const [deleteId, setdeleteId] = useState("");
  const navigate = useNavigate();
  const params = useParams();

  // Redux stores
  const { countres } = useSelector((state) => state.countrySlice);
  const { sitemaps } = useSelector((state) => state.sitemapSlice);
  console.log("sitemaps", sitemaps);
  const sitemapData = sitemaps.find((item) => item?._id === params.id);
  useEffect(() => {
    dispatch(getAllSitemaps());
    dispatch(getAllcountres());
    // setitemIndex(idx);
    setCategoryEdit({
      ...categoryEdit,
      continent: sitemapData?.continent,
      countryId: sitemapData?.countryId,
    });
  }, []);

  const handleEditChange = (event) => {
    event.preventDefault();
    // console.log(event.target.name, event.target.value);
    let input;
    if (event.target.name !== "countryId") {
      input = capitalizeFirstLetter(event);
    } else {
      input = event.target.value;
    }
    setCategoryEdit({
      ...categoryEdit,
      [event.target.name]: input,
    });
  };

  const handleEditValidation = () => {
    const { continent } = categoryEdit;
    if (continent === "") {
      seterrors({ ...createErrors, continent: "Continent is required" });
      return false;
    }
    return true;
  };

  const handleEditSubmit = async (event) => {
    event.preventDefault();
    console.log("Edit submit", categoryEdit);

    if (handleEditValidation()) {
      try {
        let res = await JSON_API["adminEditSitemap"]({
          id: params.id,
          ...categoryEdit,
        });

        if (res.isSuccess) {
          dispatch(getAllSitemaps());
          setModal(false);
          seterrors(createErrors);
          setCategoryEdit(initialValues);
          toast.success('Sitemap updated successfully')
          navigate("/manage-sitemap");
        } else {
          apiCallError(res?.error?.message, setError);
        }
      } catch (error) {
        apiCallError(error.message, setError);
      }
    }
  };

  return (
    <div>
      <form
        className=""
        onSubmit={(e) => handleEditSubmit(e)}
        encType="multipart/form-data"
      >
        <div component="div" className="TabsAnimation appear-done enter-done">
          <div className="main-card mb-3 card">
            <div className="card-body">
            <div className="card-title">Edit Sitemap</div>
            <div className="col-md-12">
              <div className="mb-3">
                <label htmlFor="continent" className="form-label">
                  Continent
                </label>
                <input
                  name="continent"
                  id="continent"
                  type="text"
                  className="form-control"
                  autoComplete="false"
                  value={categoryEdit.continent}
                  onChange={(e) => handleEditChange(e)}
                />
              </div>
              {errors.continent && (
                <span className="errorMessageText text-danger">
                  {errors.continent}
                </span>
              )}
            </div>
            <div className="col-md-12">
              <div className="mb-3">
                <label htmlFor="countryId" className="form-label">
                  Country
                </label>
                <select
                  name="countryId"
                  id="countryId"
                  className="form-control form-select"
                  onChange={handleEditChange}
                  value={categoryEdit.countryId}
                >
                  <option value="" key={0}>
                    Select
                  </option>
                  {countres.map((item, idx) => {
                    return (
                      <>
                        <option key={idx + 1} value={`${item?._id}`}>
                          {item?.name}
                        </option>
                      </>
                    );
                  })}
                </select>
              </div>
              {errors.desc && (
                <span className="errorMessageText text-danger">
                  {errors.desc}
                </span>
              )}
            </div>
            <div className="d-flex m-2">
              <button
                type="button"
                className="btn btn-link"
                onClick={() => {
                  // setModal(false);
                  // setCategoryEdit(initialValues);
                  navigate("/manage-sitemap");
                }}
              >
                Cancel
              </button>
              <button
                type="submit"
                className="mt-2 btn btn-primary  d-table"
              >
                Edit
              </button>
            </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  );
}

export default EditSiteMap;

import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { requestData } from "../../../services/api/apiHelpers";
import coupon from "../../../images/coupon.jpeg";
import bookBtn from "../../../images/BookNowBtn.png";
import "./coupon.css";
import Loader from "../../../Component/Loader";
import moment from "moment"

const ManageCoupon = () => {
  const initial = {
    couponTitle: "",
    discountPercentage: null,
    startDate: "",
    endDate: "",
    usageUser: null,
  };

  const [couponData, setCouponData] = useState(initial);
  console.log("couponData", couponData)
  const [allCoupon, setAllCoupon] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const getCoupon = async () => {
    setIsLoading(true);
    try {
      let res = await requestData("/admin/get-all-coupon", "GET");
      if (res && res.status) {
        setAllCoupon(res?.data);
        setIsLoading(false);
      }
    } catch (error) {
      console.log(error);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getCoupon();
  }, []);

  const handleValidation = () => {
    if (!couponData.couponTitle) {
      toast.error("coupon title is required");
      return false;
    }
    if (!couponData.discountPercentage || couponData.discountPercentage === 0) {
      toast.error("Discount Percentage is required ans always grater than 0 ");
      return false;
    }
    if (!couponData.startDate) {
      toast.error("coupon start date is required");
      return false;
    }
    if (!couponData.endDate) {
      toast.error("coupon end date is required");
      return false;
    }
    if (!couponData.usageUser || couponData.usageUser === 0) {
      toast.error("usage user is required ans always grater than 0 ");
      return false;
    }
    return true;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      if (handleValidation()) {
        const data = couponData;
        let res = await requestData("/admin/add-coupon", "POST", data);
        if (res && res.status) {
          toast.success("Coupon Added Successfully");
          setCouponData(initial);
          getCoupon();
        } else {
          toast.error(res.message);
        }
      }
    } catch (error) {
      toast.error(error);
    }
  };

  return (
    <>
      {isLoading && <Loader />}
      <div className="addMainDiv">
        <div className="coupon_text">Add and manage coupon</div>
        <hr />
        <form onSubmit={handleSubmit}>
          <div class="form-group">
            <label for="exampleFormControlTextarea1">Coupon Title</label>
            <input
              name="couponTitle"
              value={couponData.couponTitle}
              type="text"
              class="form-control"
              id="exampleFormControlInput1"
              placeholder="Enter Coupon Title"
              onChange={(e) =>
                setCouponData((prev) => ({
                  ...prev,
                  couponTitle: e.target.value,
                }))
              }
            />
          </div>
          <div className="row">
            <div class="form-group col-md-3">
              <label for="exampleFormControlInput1">Discount Percentage</label>
              <input
                name="discountPercentage"
                value={couponData.discountPercentage}
                type="number"
                class="form-control"
                id="exampleFormControlInput1"
                placeholder="Enter discount percentage"
                onChange={(e) =>
                  setCouponData((prev) => ({
                    ...prev,
                    discountPercentage: Number(e.target.value),
                  }))
                }
              />
            </div>
            <div class="form-group col-md-3">
              <label for="exampleFormControlInput1">Start Date</label>
              <input
                name="startDate"
                value={couponData.startDate}
                type="Date"
                class="form-control"
                id="exampleFormControlInput1"
                placeholder="Enter Start Date"
                onChange={(e) =>
                  setCouponData((prev) => ({
                    ...prev,
                    startDate: e.target.value,
                  }))
                }
              />
            </div>
            <div class="form-group col-md-3">
              <label for="exampleFormControlInput1">End Date</label>
              <input
                name="endDate"
                value={couponData.endDate}
                type="Date"
                class="form-control"
                id="exampleFormControlInput1"
                placeholder="Enter End Date"
                min={couponData.startDate}
                onChange={(e) =>
                  setCouponData((prev) => ({
                    ...prev,
                    endDate: e.target.value,
                  }))
                }
              />
            </div>
            <div class="form-group col-md-3">
              <label for="exampleFormControlInput1">Usage/User</label>
              <input
                name="usageUser"
                value={couponData.usageUser}
                type="Number"
                class="form-control"
                id="exampleFormControlInput1"
                placeholder="Enter User Usage"
                onChange={(e) =>
                  setCouponData((prev) => ({
                    ...prev,
                    usageUser: Number(e.target.value),
                  }))
                }
              />
            </div>
          </div>

          <div className="coupon_submit">
            <button className="btn btn-primary">Submit</button>
          </div>
        </form>
      </div>

      <hr style={{ marginTop: "60px" }} />
      <hr />

      <div className="manage_main_div">
        {allCoupon?.length > 0 &&
          allCoupon.map((item, idx) => {
            console.log("ssss", item);
            return (
              <div className="manage_coupon">
                <div className="indx">{idx + 1}</div>
                <div className="main_coupon">
                  <div className="coupon_img">
                    <img id="cupn" src={coupon} alt="Coupon Card" />
                    <div className="Show_percentage">
                      {item?.discountPercentage}%
                    </div>
                    <div className="book_btn_img">
                      <img src={bookBtn} alt="Book Btn" />
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
      </div>
    </>
  );
};

export default ManageCoupon;

import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { ApiHelperFunction } from "../../services/api/apiHelpers";

let initialState = {
  status: "idle",
  profile_data: {},
  isLoading: false,
  isSuccess: false,
  isError: false,
  isLogdedIn: false,
  userToken: ""
};

export const getProfileDetails = createAsyncThunk(
  "admin_get_profile_details",
  async () => {
    let response = await ApiHelperFunction({
      urlPath: "/admin/get-profile",
      method: "GET",
    });
    if (response.status === 200) {
      return response.data;
    } else {
      return false;
    }
  }
);

export const ProfileSlice = createSlice({
  name: "admin_profile_data",
  initialState,
  reducers: {
    loginStatusClearState: (state) => {
      state.status = "idle";
      state.profile_data = {};
      state.isLoading = false;
      state.isSuccess = false;
      state.isError = false;
      state.isLogdedIn = false;
      state.userToken = "";
    },
    loginState: (state) => {
      state.isLogdedIn = true;
    },
    setToken: (state, payload) => {
      state.userToken = payload
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(getProfileDetails.pending, (state) => {
        state.status = "loading";
        state.isLoading = true;
      })
      .addCase(getProfileDetails.fulfilled, (state, { payload }) => {
        state.status = "success";
        state.isSuccess = true;
        state.profile_data = payload.data;
      })
      .addCase(getProfileDetails.rejected, (state) => {
        state.status = "failed";
        state.isError = true;
        state.isSuccess = false;
      });
  },
});

export const { loginStatusClearState, loginState, setToken } = ProfileSlice.actions;

export default ProfileSlice.reducer;


import React from "react";
import classnames from "classnames";
import { getFileTypeFromUrl } from "../utils/common-utils";

const Label = ({ error, className, children, ...props }) => {
  return (
    <label className={className} {...props}>
      {children}
    </label>
  );
};
const InputFeedback = ({ error }) =>
  error ? <div className="invalid-feedback">{error}</div> : null;

const PreviewComponent = ({ file, className }) => {
  const fileType = getFileTypeFromUrl(file);
  if (fileType === "Image")
    return (
      <img
        className={className}
        src={file}
        style={{
          height: "120px",
          width: "120px",
          borderRadius: "5px",
        }}
        alt="thingtodo"
      />
    );

  if (fileType === "PDF")
    return (
      <img
        className={className}
        src={"/images/pdf.png"}
        style={{
          height: "50px",
          width: "50px",
          borderRadius: "5px",
          cursor: "pointer",
        }}
        alt="pdf"
        onClick={() => window.open(file, "_blank")}
      />
    );
};

const Progressbar = () => {
  return (
    <div
      className="progress"
      role="progressbar"
      aria-label="Animated striped example"
      aria-valuenow="75"
      aria-valuemin="0"
      aria-valuemax="100"
    >
      <div
        className="progress-bar progress-bar-striped progress-bar-animated"
        style={{ width: "100%" }}
      ></div>
    </div>
  );
};

const VideoRender = ({src})=>{
  return <div className="mt-3" style={{height:"200px",width:"300px"}}>
  <video controls key={src} style={{height:"200px",width:"300px"}}>
    <source src={src} type="video/mp4" />
    Your browser does not support the video tag.
  </video>
</div>
}
const FileSelect = ({
  uploading,
  type,
  id,
  label,
  error,
  onChange,
  className,
  fileType = "image/*",
  images,
  labelClassName,
  labelStyle,
  videos,
  ...props
}) => {
  const classes = classnames(
    "",
    {
      "animated shake error": !!error,
    },
    className
  );
  return (
    <div className={classes}>
      <Label className={labelClassName} htmlFor={id} error={error} style={labelStyle}>
        {label}
      </Label>
      <input
        id={id}
        className={error ? "form-control is-invalid" : `form-control`}
        type={"file"}
        onChange={onChange}
        {...props}
        accept={fileType}
      />
      <InputFeedback error={error} />

      {uploading && <Progressbar />}
      {Array.isArray(images) ? (
        <div className="d-flex mt-2 gap-2">
          {images.map((item, i) => {
            if (getFileTypeFromUrl(item) === "Image")
              return <PreviewComponent file={item} />;

            if (getFileTypeFromUrl(item) === "PDF")
              return <img src="/images/pdf.png" alt="pdf" />;

            return null
          })}
        </div>
      ) : images ? (
        <PreviewComponent file={images} className={"mt-2"} />
      ) : null}

     {Array.isArray(videos) && videos.map((src,i)=>{
      return <VideoRender src={src}  key={i}/>
     })} 
    </div>
  );
};

export default FileSelect;
